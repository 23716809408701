import React, { ReactNode, useContext, useState } from 'react'

export type NewActivityContextType = {
  newCommentIds?: string[]
  setNewCommentIds?: React.Dispatch<React.SetStateAction<string[]>>
}
export const NewActivityContext = React.createContext<NewActivityContextType>({})

// Used for detecting the new comment "alerts" we get from any expanded posts. This is used when we show the "show new activity" button on top of the feed.
// If the only new activity pertains to comments on expanded posts, we do not show the "show new activity" button.
// However, if we get new comment activity on any unexpanded posts/new posts, then we show the button.
// Therefore, the post list / feed component needs to know about which posts are expanded / have comment alerts.
export const NewActivityProvider = ({ children }: { children: ReactNode }) => {
  const [newCommentIds, setNewCommentIds] = useState<string[]>([])

  return (
    <NewActivityContext.Provider value={{ newCommentIds, setNewCommentIds }}>{children}</NewActivityContext.Provider>
  )
}

export const useNewActivity = () => useContext(NewActivityContext)
