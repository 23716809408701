import { useCommunity } from '~/contexts/CommunityContext'
import {
  CommunityType,
  GetCustomerMembershipCommunitiesDocument,
  GetCustomerActivityCommunitiesDocument,
} from '~/api/generated/graphql'
import { useMemo } from 'react'
import '@css/pages/company/CustomerActivity.scss'
import CommunityActivityRow from '~/pages/company/CommunityActivityRow'
import CommunityMembershipRow from '~/pages/company/CommunityMembershipRow'
import { Button } from 'react-bootstrap'
import { useQuery } from '@apollo/client'

export type CustomerActivity = {
  postTitle: string
  actor: {
    userId: string
    title: string
    photo: string
    fullName: string
  }
  postId: string
  commentId?: string
  time: Date
  poster?: string
}

export type CommunityActivity = {
  postCount: number
  commentCount: number
  likeCount: number
  joinedDate?: Date
  lastActivity?: Date
}

export type CommunityMembershipPreview = {
  id: string
  name: string
  photo: string
  memberCount: number
  viewCount?: number
  postCount: number
  commentCount: number
  likeCount: number
  lastActivity: Date | null
  type: CommunityType
}

export type CommunityActivityPreview = {
  id: string
  name: string
  photo: string
  type: CommunityType
  lastActivity: Date | null
}

export enum ActivityType {
  post,
  comment,
  view,
  like,
}

const PAGE_SIZE = 25

const CustomerActivity = () => {
  const { companyId, communityId, companyName } = useCommunity()
  const { data: activityCommunitiesData, fetchMore: fetchMoreActivity } = useQuery(
    GetCustomerActivityCommunitiesDocument,
    {
      variables: { companyId: companyId ?? '', pageSize: PAGE_SIZE },
      skip: !companyId,
    }
  )
  const activityPageInfo = activityCommunitiesData?.customerActivityCommunities?.pageInfo
  const { data: membershipCommunitiesData, fetchMore: fetchMoreMemberships } = useQuery(
    GetCustomerMembershipCommunitiesDocument,
    {
      variables: { companyId: companyId ?? '', pageSize: PAGE_SIZE },
      skip: !companyId,
    }
  )
  const membershipPageInfo = membershipCommunitiesData?.customerMembershipCommunities?.pageInfo

  const activityCommunities: CommunityActivityPreview[] | undefined = useMemo(() => {
    return activityCommunitiesData?.customerActivityCommunities?.edges
      .map(c => {
        if (c?.node?.communityId !== communityId) {
          const comm = c?.node
          return {
            id: comm?.communityId ?? '',
            name: comm?.name,
            photo: comm?.photo,
            type: comm?.type,
            lastActivity: comm?.lastActivity ? new Date(comm.lastActivity) : null,
          }
        }
      })
      .filter(Boolean) as CommunityActivityPreview[]
  }, [activityCommunitiesData, communityId])

  const membershipCommunities: CommunityMembershipPreview[] | undefined = useMemo(() => {
    return membershipCommunitiesData?.customerMembershipCommunities?.edges
      .map(c => {
        if (c?.node?.communityId !== communityId) {
          const comm = c?.node
          return {
            id: comm?.communityId ?? '',
            name: comm?.name ?? '',
            photo: comm?.photo ?? '',
            memberCount: comm?.memberCount ?? 0,
            postCount: comm?.posts?.totalCount ?? 0,
            commentCount: comm?.comments?.totalCount ?? 0,
            likeCount: (comm?.postLikes?.totalCount ?? 0) + (comm?.commentLikes?.length ?? 0),
            lastActivity: comm?.lastActivity ? new Date(comm.lastActivity) : null,
            type: comm?.type ?? CommunityType.Public,
          }
        }
      })
      .filter(c => c?.postCount || c?.commentCount || c?.likeCount || c?.memberCount) as CommunityMembershipPreview[]
  }, [communityId, membershipCommunitiesData])

  const loadMoreActivity = () => {
    void fetchMoreActivity({ variables: { cursor: activityPageInfo?.endCursor } }).then()
  }

  const loadMoreMemberships = () => {
    void fetchMoreMemberships({ variables: { cursor: membershipPageInfo?.endCursor } }).then()
  }

  return (
    <div className={'customer-activity-container'}>
      <h3>Customer Activity</h3>
      {!!companyName && (
        <>
          <p>This page represents {companyName}'s Veeva Connect activity data and is accessible to Veeva users only.</p>
          <h3 className={'activity-header'}>{companyName}'s Community Activity</h3>
          {activityCommunitiesData ? (
            activityCommunities?.length ? (
              <>
                <div className={'community-activity-title'}>
                  <h6 className={'main-column'}>Community</h6>
                  <h6 className={'side-column'}>Last Activity</h6>
                </div>
                {activityCommunities?.map(comm => <CommunityActivityRow key={comm.id} communityInfo={comm} />)}
                {activityPageInfo?.hasNextPage && (
                  <div className={'show-more'}>
                    <Button onClick={loadMoreActivity}>Show More</Button>
                  </div>
                )}
              </>
            ) : (
              <>No Activity</>
            )
          ) : (
            <>Loading...</>
          )}

          <h3 className={'membership-header'}>{companyName}'s Community Membership</h3>
          {membershipCommunitiesData ? (
            membershipCommunities?.length ? (
              <>
                <div className={'community-membership-title'}>
                  <h6>Community</h6>
                  <div className={'stats'}>
                    <div>Members</div>
                    <div>Activity</div>
                  </div>
                  <div className={'side-column'}>Last Activity</div>
                </div>
                {membershipCommunities.map(comm => (
                  <CommunityMembershipRow key={comm.id} communityInfo={comm} />
                ))}
                {membershipPageInfo?.hasNextPage && (
                  <div className={'show-more'}>
                    <Button onClick={loadMoreMemberships}>Show More</Button>
                  </div>
                )}
              </>
            ) : (
              <>No Community Memberships</>
            )
          ) : (
            <>Loading...</>
          )}
        </>
      )}
    </div>
  )
}

export default CustomerActivity
