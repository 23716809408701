import React, { SyntheticEvent, useState } from 'react'
import { Button } from 'react-bootstrap'
import AddIcon from '@web/images/community/AddIcon'
import '@css/common/AddPersonBox.scss'
import AddMultipleModal from '~/common/addPerson/AddMultipleModal'
import AddPersonBox from '~/common/addPerson/AddPersonBox'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { AddPersonBoxProvider } from '~/common/addPerson/AddPersonBoxContext'

type AddMembershipBoxProps = {
  searchText: string
  placeholder: string
  onTextChange: (inputText: string) => void
  onSubmit: (userId?: string, e?: SyntheticEvent) => void
  veevansOnly: boolean
  excludeIds?: Set<string>
  communityId?: string
  leaders: boolean
  showExcluded?: boolean
  excludedMessage?: string
  disabled?: boolean
  refetchMembers?: () => void
}

const AddMembershipBox = ({
  searchText,
  placeholder,
  onSubmit,
  onTextChange,
  veevansOnly,
  communityId,
  leaders,
  showExcluded,
  excludedMessage,
  disabled,
  refetchMembers,
}: AddMembershipBoxProps) => {
  const [showMultipleModal, setShowMultipleModal] = useState<boolean>(false)

  const onHideMultipleModal = () => {
    refetchMembers?.()
    setShowMultipleModal(false)
  }
  const { isCondensed } = useWindowSize()

  return (
    <div className={`add-person ${isCondensed ? 'condensed' : ''}`}>
      <AddPersonBoxProvider>
        <AddPersonBox
          searchText={searchText}
          placeholder={placeholder}
          onTextChange={onTextChange}
          onSubmit={onSubmit}
          veevansOnly={veevansOnly}
          leaders={leaders}
          communityId={communityId}
          showExcluded={showExcluded}
          excludedMessage={excludedMessage}
          disabled={disabled}
        />
      </AddPersonBoxProvider>
      {!leaders && (
        <Button onClick={() => setShowMultipleModal(true)} className={'btn-add'}>
          <AddIcon fill="#FAFAFA" />
          Add multiple
        </Button>
      )}
      <AddMultipleModal show={showMultipleModal} hide={onHideMultipleModal} />
    </div>
  )
}

export default AddMembershipBox
