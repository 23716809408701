import React, { ReactNode, useContext, useEffect, useState } from 'react'

export const MOBILE_WIDTH_MAX = 580
export const MOBILE_HEIGHT_MAX = 1200

export enum SizeBreakpoint {
  xs = 320,
  sm = 576,
  md = 992,
  lg = 1200,
  xl = 1800,
}

export type WindowSize = {
  width: number
  height: number
}

// Inspired By: https://usehooks.com/useWindowSize/
export type WindowContextType = {
  windowSize: WindowSize
  isCondensed: boolean
  isCondensedPortrait: boolean
  isCondensedLandscape: boolean
  breakpoint: SizeBreakpoint
}

const WindowContext = React.createContext<WindowContextType>({
  windowSize: { width: 0, height: 0 },
  isCondensed: false,
  isCondensedPortrait: false,
  isCondensedLandscape: false,
  breakpoint: SizeBreakpoint.xs,
})

export const WindowProvider = ({ children }: { children: ReactNode }) => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  let isCondensedPortrait = false
  let isCondensedLandscape = false
  let breakpoint = SizeBreakpoint.xl

  if (windowSize.width < MOBILE_WIDTH_MAX && windowSize.height < MOBILE_HEIGHT_MAX) {
    isCondensedPortrait = true
  }
  if (windowSize.width < MOBILE_HEIGHT_MAX && windowSize.height < MOBILE_WIDTH_MAX) {
    isCondensedLandscape = true
  }

  if (windowSize.width < SizeBreakpoint.xs.valueOf()) {
    breakpoint = SizeBreakpoint.xs
  } else if (windowSize.width < SizeBreakpoint.sm.valueOf()) {
    breakpoint = SizeBreakpoint.sm
  } else if (windowSize.width < SizeBreakpoint.md.valueOf()) {
    breakpoint = SizeBreakpoint.md
  } else if (windowSize.width < SizeBreakpoint.lg.valueOf()) {
    breakpoint = SizeBreakpoint.lg
  }

  const isCondensed = isCondensedPortrait || isCondensedLandscape

  return (
    <WindowContext.Provider
      value={{
        windowSize,
        isCondensed,
        isCondensedPortrait,
        isCondensedLandscape,
        breakpoint,
      }}
    >
      {children}
    </WindowContext.Provider>
  )
}

export const useWindowSize = (): WindowContextType => useContext(WindowContext)
