import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AppHeader } from '~/common/AppHeader'
import '@css/common/OneColumnLayout.scss'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Footer } from '~/common/Footer'

type Props = {
  children: [top: JSX.Element, bottom: JSX.Element]
  currentPage?: string
}

export const OneColumnLayout: React.FunctionComponent<Props> = ({ children: [top, bottom], currentPage }) => {
  const { isCondensed } = useWindowSize()

  return (
    <>
      <div className={`one-column ${isCondensed ? 'condensed' : ''}`}>
        <AppHeader currentPage={currentPage} />
        <div className="topper-zone">
          <Container className="content">
            <Row>
              <Col sm={12} className="header-area">
                {top}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-zone">
          <Container className="content">
            <Row className="content-zone">
              <Col sm={12} className={`content-area ${isCondensed ? 'condensed' : ''}`}>
                {bottom}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  )
}
