import React, { useMemo } from 'react'
import '@css/pages/notifications/NotificationListItem.scss'
import { NotificationModel } from '~/types'
import { getFullName, htmlToText } from '~/utils'
import { useAuth } from '~/auth/Auth'
import {
  CommunityType,
  NotificationType,
  GetNotificationMessageUserDocument,
  NotificationMessageCommunityDocument,
  NotificationMessageUserDocument,
} from '~/api/generated/graphql'
import { useQuery } from '@apollo/client'

type NotificationMessageProps = {
  notification: NotificationModel
}

const UserLikeDisplay = ({ userId }: { userId: string }) => {
  const { data } = useQuery(GetNotificationMessageUserDocument, { variables: { id: userId } })

  return (
    <>
      {data?.user?.firstName} ({data?.user?.company?.name})
    </>
  )
}

const NotificationMessage = ({ notification }: NotificationMessageProps) => {
  const { authUserId } = useAuth()
  const type = notification.notificationType
  const { data: communityData, loading: loadingCommunity } = useQuery(NotificationMessageCommunityDocument, {
    variables: { id: notification.communityId ?? '' },
    skip: !notification.communityId,
  })
  const { community } = communityData ?? {}
  const { data: userData, loading: loadingUser } = useQuery(NotificationMessageUserDocument, {
    variables: { id: notification.userId ?? '' },
    skip: !notification.userId,
  })
  const { user } = userData ?? {}
  const { data: actorData, loading: loadingActor } = useQuery(NotificationMessageUserDocument, {
    variables: { id: notification.actorId ?? '' },
    skip: !notification.actorId,
  })
  const { user: actor } = actorData ?? {}
  const loadingAll = loadingCommunity || loadingUser || loadingActor

  const communityName = community?.name ?? ''
  const deletedCommunityName = notification.extra?.name
  const actorName = getFullName(actor)
  const actorAndCompanyName = actor ? `${actorName} (${actor?.company?.name})` : ''
  const userName = getFullName(user)
  const userAndCompanyName = user ? `${userName} (${user?.company?.name})` : ''
  const postTitle = notification.post
    ? htmlToText(notification.post.title ?? '')
    : htmlToText(notification.comment?.post?.title ?? '')
  const parsedTitle = notification.extra?.contentTitle ?? ''
  const likes = type === NotificationType.PostLikeTellOwnr ? notification.post?.likes : notification.comment?.likes
  const filteredLikes = useMemo(() => likes?.filter(like => like !== authUserId), [likes, authUserId])
  const leaderType = community?.type == CommunityType.Homepage ? 'an Account Partner' : 'a Leader'
  const commentTypeMention = notification.comment?.isVeevanDiscussion ? 'Veeva Discussion comment' : 'comment'
  const commentType = notification.comment?.isVeevanDiscussion ? 'added a Veeva Discussion comment' : 'commented'
  const likeCountText: JSX.Element = useMemo(() => {
    if (filteredLikes) {
      const likesCount = filteredLikes.length
      switch (filteredLikes.length) {
        case 0:
          return <></>
        case 1:
          return <UserLikeDisplay userId={filteredLikes[0]} />
        case 2:
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} /> and <UserLikeDisplay userId={filteredLikes[1]} />
            </>
          )
        case 3:
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} />, <UserLikeDisplay userId={filteredLikes[1]} /> and{' '}
              <UserLikeDisplay userId={filteredLikes[2]} />
            </>
          )
        default: {
          const numOthers = likesCount - 3
          const othersText = `${numOthers} ${numOthers > 1 ? 'others' : 'other'}`
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} />, <UserLikeDisplay userId={filteredLikes[1]} />,{' '}
              <UserLikeDisplay userId={filteredLikes[2]} /> and {othersText}
            </>
          )
        }
      }
    } else {
      return <></>
    }
  }, [filteredLikes])

  let messageSpan: JSX.Element
  switch (type) {
    case NotificationType.CommentLikeTellOwnr:
      messageSpan = (
        <span>
          <b>{likeCountText} liked your comment</b> on "{postTitle}"
        </span>
      )
      break
    case NotificationType.CommentMentionTellSubj:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned you in their {commentTypeMention}
          </b>{' '}
          on "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewCommentTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} commented on the post</b> "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewCommentTellOwnr:
    case NotificationType.NewCommentTellLdrOwnr:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on your post
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewCommentMeetupTellViewers:
    case NotificationType.NewCommentMeetupTellLeaders:
    case NotificationType.NewPostMeetupTellMembers:
    case NotificationType.NewPostMeetupTellLeaders:
      messageSpan = (
        <span>
          <b>A new meetup has been added</b> by {actorAndCompanyName} for "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewCommentTellLdrCommenters:
    case NotificationType.NewCommentTellCommenters:
    case NotificationType.NewCommentTellLdrParticipants:
    case NotificationType.NewCommentTellParticipants:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on a post you participated in
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewCommentTellLdrFollowers:
    case NotificationType.NewCommentTellFollowers:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on a post you are following
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case NotificationType.DelContentTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was deleted
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.AddMemberTellMmbr:
      messageSpan = (
        <span>
          {actorAndCompanyName} <b>added you to the {communityName} community</b>
        </span>
      )
      break
    case NotificationType.DelCommunityTellLdrs:
      messageSpan = (
        <span>
          <b>{deletedCommunityName} was deleted</b> by {actorName}
        </span>
      )
      break
    case NotificationType.DemotedTellSubj:
      messageSpan = (
        <span>
          <b>
            You were changed from {leaderType} to a Member in the {communityName} community
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.PromotedTellSubj:
      messageSpan = (
        <span>
          <b>
            You were made {leaderType} in the {communityName} community
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.DemotedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {userName} was removed as {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.PromotedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: {userName} was made {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.MemberRemovedTellLdrs:
      messageSpan = (
        <span>
          <b>{userName} was removed</b> by {actorName}
        </span>
      )
      break
    case NotificationType.MemberRemovedTellMmbr:
      messageSpan = (
        <span>
          <b>You were removed from the {communityName} community</b> by {actorName}
        </span>
      )
      break
    case NotificationType.NewEventTellMmbr:
      messageSpan = (
        <span>
          <b>A new event has been added</b> by {actorAndCompanyName}: {`\n`}
          <b>{notification.event?.title}</b>
        </span>
      )
      break
    case NotificationType.UpdEventTellMmbr:
      messageSpan = (
        <span>
          <b>An event has been updated</b> by {actorAndCompanyName}: {`\n`}
          <b>{notification.event?.title}</b>
        </span>
      )
      break
    case NotificationType.LdrLeftTellLdrs:
      messageSpan = (
        <span>
          <b>
            {actorName} left as {leaderType}
          </b>
        </span>
      )
      break
    case NotificationType.LdrRemovedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {userName} was removed as {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.AddMemberTellLdrs:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} was added</b> by {actorAndCompanyName}
        </span>
      )
      break
    case NotificationType.MemberJoinTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} joined</b>
        </span>
      )
      break
    case NotificationType.MemberLeftTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} left</b>
        </span>
      )
      break
    case NotificationType.PostLikeTellOwnr:
      messageSpan = (
        <span>
          <b>{likeCountText} liked your post</b> "{postTitle}"
        </span>
      )
      break
    case NotificationType.PostMentionTellSubj:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} mentioned you in their post</b> "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewPostTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} posted</b> "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewPostTellMmbr:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} posted</b> "{postTitle}"
        </span>
      )
      break
    case NotificationType.CommentAllTellMmbrs:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned the {communityName} community in their comment
          </b>{' '}
          on "{postTitle}"
        </span>
      )
      break
    case NotificationType.PostAllTellMmbrs:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned the {communityName} community in their post
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case NotificationType.NewContentTellMmbr:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} added content</b> "{parsedTitle}"
        </span>
      )
      break
    case NotificationType.NewContentTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was added
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.NonVeevanRegistersTellLdrs:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} registered after being a pending member</b>
        </span>
      )
      break
    case NotificationType.InvitationAccepted:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} accepted your invitation to join the community</b>
        </span>
      )
      break
    case NotificationType.VeevanProfileModified:
      messageSpan = (
        <span>
          <b>Your profile was updated by </b>
          {actorAndCompanyName}
        </span>
      )
      break
    case NotificationType.UpdContentTellOwnr:
    case NotificationType.UpdContentTellLdrs:
    case NotificationType.UpdContentTellOldAuthor:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was updated
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.ConvPostTellOwnr:
    case NotificationType.ConvPostTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Post "{postTitle}" was converted
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case NotificationType.PostMovedTellLeaders:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} moved "{postTitle}"
          </b>
        </span>
      )
      break
    default:
      messageSpan = <span>There was an error displaying this notification</span>
  }
  return (
    <>
      {!loadingAll && (
        <>
          <div className={'message'}>{messageSpan}</div>
        </>
      )}
    </>
  )
}

export default NotificationMessage
