import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import PlainTextInput from '~/common/PlainTextInput'
import '@css/pages/community/LiveStreamQA.scss'
import FadingAlert from '~/common/FadingAlert'
import { SendQuestionDocument } from '~/api/generated/graphql'
import { useMutation } from '@apollo/client'
import { useAuth } from '~/auth/Auth'
import { calculateFieldLengthSeverity } from '~/pages/posts/PostUtils'

const MAX_QUESTION_LENGTH = 250

const LiveStreamQA = ({ eventId }: { eventId: string }) => {
  const [question, setQuestion] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [sendQuestion] = useMutation(SendQuestionDocument)
  const [sendingQuestion, setSendingQuestion] = useState(false)
  const { authUserId } = useAuth()

  const submitQuestion = async () => {
    setSendingQuestion(true)
    setShowAlert(false) // to re-trigger the animation in case the alert is still present
    if (authUserId && eventId) {
      await sendQuestion({ variables: { userId: authUserId, eventId: eventId, question: question } }).then(r => {
        if (r.data?.sendQuestion?.ok) {
          setQuestion('')
          setShowAlert(true)
        }
      })
    }
    setSendingQuestion(false)
  }

  const disableSubmit = !question.trim() || question.length > MAX_QUESTION_LENGTH || sendingQuestion

  return (
    <div className={'live-stream-qa-container'}>
      <p className={'qa-header'}>We welcome your questions! Enter your question:</p>
      <div className={'question-wrapper'}>
        <PlainTextInput
          placeholder={'Ask the host'}
          onChange={setQuestion}
          value={question}
          disabled={sendingQuestion}
        />
        <span
          hidden={question.length < 200}
          className={`character-counter ${calculateFieldLengthSeverity(question.length, MAX_QUESTION_LENGTH)}`}
        >
          {question.length < 10 ? `0${question.length}` : question.length} / {MAX_QUESTION_LENGTH}
        </span>
        <Button onClick={submitQuestion} disabled={disableSubmit}>
          Submit
        </Button>
      </div>
      <FadingAlert
        timeout={5}
        message={'Your question has been successfully submitted!'}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
    </div>
  )
}
export default LiveStreamQA
