import { CommunityActivity } from '~/pages/company/CustomerActivity'
import { GetUserMembershipRowDocument } from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import ActivityOverlay from '~/pages/company/ActivityOverlay'
import { useQuery } from '@apollo/client'

type UserMembershipRowProps = {
  userId: string
  activity: CommunityActivity | undefined
}

const UserMembershipRow = ({ userId, activity }: UserMembershipRowProps) => {
  const { data, loading } = useQuery(GetUserMembershipRowDocument, { variables: { id: userId } })

  const user = data?.user
  if (loading) return null
  return (
    <div className={'user-activity-row'}>
      <Link to={`/profiles/${userId}`} className={'user-info'}>
        <div
          className="profile-photo"
          style={user?.photo ? { backgroundImage: `url(${user.photo})` } : {}}
          role={'img'}
        />
        <div className={'user-identity'}>
          <h6>
            {user?.firstName} {user?.lastName}
          </h6>
          <p>{user?.title}</p>
        </div>
      </Link>
      <div className={'user-stats'}>
        <div className={'joined-status'}>
          {activity?.joinedDate ? (
            <>
              <div>Joined:</div>
              <div>{activity?.joinedDate.toLocaleDateString()}</div>
            </>
          ) : (
            <>{activity?.postCount || activity?.commentCount ? 'No longer a member' : 'Not a member'}</>
          )}
        </div>
        <div>
          <ActivityOverlay
            postCount={activity?.postCount ?? 0}
            commentCount={activity?.commentCount ?? 0}
            likeCount={activity?.likeCount ?? 0}
          />
        </div>
      </div>
      <div className={'side-column'}>{activity?.lastActivity?.toLocaleDateString()}</div>
    </div>
  )
}

export default UserMembershipRow
