import { useQuery } from '@apollo/client'
import React from 'react'
import { GetPostSearchResultAuthorDocument } from '~/api/generated/graphql'
import { isAuthorSelected } from '~/pages/search/SearchUtilities'

type AdvancedSearchExtraAuthorProps = {
  authorId: string
  isCommenter: boolean
  selectedAuthorIds: string[]
  selectedCompanyIds: string[]
}

export const AdvancedSearchExtraAuthor = ({
  authorId,
  isCommenter,
  selectedAuthorIds,
  selectedCompanyIds,
}: AdvancedSearchExtraAuthorProps) => {
  const { data: authorData } = useQuery(GetPostSearchResultAuthorDocument, {
    variables: { id: authorId ?? [] },
    skip: !authorId,
  })
  const author = authorData?.user

  return (
    <>
      {isAuthorSelected(author?.userId, author?.company?.companyId, selectedAuthorIds, selectedCompanyIds) ? (
        <div key={`extra-hl-author-${authorId}`} className={'highlight'}>
          <span className={'highlight-key'}>{isCommenter ? 'Comment by' : 'Post by'}: </span>
          <span
            className={'highlight-text'}
          >{`${author?.firstName} ${author?.lastName} (${author?.company?.name})`}</span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default AdvancedSearchExtraAuthor
