import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import headerBellUnread from '@web/images/header/header-bell-unread.svg'
import React, { RefObject, useCallback, useMemo } from 'react'
import { elementClicked } from '~/common/EventLogger'
import { useAuth } from '~/auth/Auth'
import { asNotification } from '~/utils'
import { NotificationModel } from '~/types'
import NotificationListItem from '~/pages/notifications/NotificationListItem'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'
import { useMutation, useQuery } from '@apollo/client'
import {
  GetNotificationsDocument,
  MarkSeenUpToDocument,
  MarkViewedUpToDocument,
  NotificationType,
} from '~/api/generated/graphql'

const filterLikeNotifications = (notifications: NotificationModel[]): NotificationModel[] => {
  const map = new Map()
  for (const n of notifications.filter(
    n =>
      n.notificationType === NotificationType.PostLikeTellOwnr ||
      n.notificationType === NotificationType.CommentLikeTellOwnr
  )) {
    const key =
      n.notificationType === NotificationType.PostLikeTellOwnr ? `P${n.post?.postId}` : `C${n.comment?.commentId}`
    if (!map.has(key)) map.set(key, n)
  }
  return Array.from(map.values())
}

const NotificationList = ({
  setShowReleasesDropdownOptions,
  setShowSummitDropdownOptions,
  setShowDropdownOptions,
  setShowNotifications,
  showNotifications,
  notifRef,
  notifIconRef,
}: {
  setShowReleasesDropdownOptions: (visible: boolean) => void
  setShowSummitDropdownOptions: (visible: boolean) => void
  setShowDropdownOptions: (visible: boolean) => void
  setShowNotifications: (visible: boolean) => void
  showNotifications: boolean
  notifRef: React.MutableRefObject<null>
  notifIconRef: RefObject<HTMLImageElement>
}) => {
  const { authUserId } = useAuth()

  const {
    loading: NotificationsLoading,
    data,
    fetchMore,
  } = useQuery(GetNotificationsDocument, {
    variables: { recipientId: authUserId },
    skip: !authUserId,
  })

  // const {
  //   loading: NotificationsLoading,
  //   data,
  //   fetchMore,
  // } = useQuery(GetNotificationsDocument, {
  //   variables: { recipientId: authUserId },
  //   skip: !authUserId,
  // })
  const { loadingFirstPage } = useLoadObjUsers(data?.notifications ?? null, 'notificationId')

  const notifications = useMemo(() => {
    if (!NotificationsLoading && authUserId) {
      const allNotifications = data?.notifications?.edges?.map(e => e?.node).map(asNotification) || []
      const nonLikes = allNotifications.filter(
        n =>
          n?.notificationType !== NotificationType.PostLikeTellOwnr &&
          n?.notificationType !== NotificationType.CommentLikeTellOwnr
      )
      const filteredLikes = filterLikeNotifications(allNotifications)

      return nonLikes
        .concat(filteredLikes)
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
    }
    return []
  }, [NotificationsLoading, data, authUserId])

  const notificationCount = notifications.length
  const unviewedNotificationCount = notifications.filter(e => !e.viewedAt).length
  const unseenNotificationCount = notifications.filter(e => !e.seenAt).length
  const pageInfo = data?.notifications?.pageInfo
  const hasMore = !!pageInfo?.hasNextPage

  const loadMore = () => {
    return fetchMore({ variables: { cursor: pageInfo?.endCursor } })
  }

  const { breakpoint } = useWindowSize()
  const condensed = breakpoint <= SizeBreakpoint.lg
  const hasUnseen = unseenNotificationCount > 0
  const hasUnviewed = unviewedNotificationCount > 0
  const notificationHeaderText = notificationCount > 0 ? 'Notifications' : 'No Notifications'

  const [markSeenUpTo] = useMutation(MarkSeenUpToDocument, { refetchQueries: ['GetNotifications'] })
  const [markViewedUpTo] = useMutation(MarkViewedUpToDocument, { refetchQueries: ['GetNotifications'] })
  // Load more notifications when scrolling to bottom
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    // The scroll bar does not fully extend to the bottom by ~1 pixel depending on display scaling
    const atBottom =
      Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight) <= 1
    if (atBottom && hasMore) {
      void loadMore()
    }
  }
  const handleNotificationsBellClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-notifications')
      setShowNotifications(!showNotifications)
      setShowReleasesDropdownOptions(false)
      setShowSummitDropdownOptions(false)
      setShowDropdownOptions(false)
      if (notifications && notifications?.length > 0) {
        elementClicked(e, 'click-notification-list-displayed')
        void markSeenUpTo({
          variables: {
            notificationId: notifications[0].notificationId,
          },
        })
      }
    },
    [
      setShowNotifications,
      showNotifications,
      setShowReleasesDropdownOptions,
      setShowSummitDropdownOptions,
      setShowDropdownOptions,
      notifications,
      markSeenUpTo,
    ]
  )

  const handleMarkAllRead = useCallback(
    (e: React.MouseEvent) => {
      if (notifications && notifications?.length > 0) {
        elementClicked(e, 'click-notification-mark-all-read')
        void markViewedUpTo({
          variables: {
            notificationId: notifications[0].notificationId,
          },
        })
      }
    },
    [notifications, markViewedUpTo]
  )
  const loading = NotificationsLoading || loadingFirstPage

  return (
    <div className={`notification-bell-wrapper ${condensed ? 'condensed' : ''}`}>
      {hasUnseen ? (
        <>
          <img
            className={'notifications'}
            src={headerBellUnread}
            alt={'Notifications'}
            role={'button'}
            onClick={handleNotificationsBellClicked}
            tabIndex={0}
            ref={notifIconRef}
            data-testid={'notification-button'}
          />
          <div className={`badge-circle${condensed ? ' condensed' : ''}`} onClick={handleNotificationsBellClicked}>
            {unseenNotificationCount}
          </div>
        </>
      ) : (
        <img
          className={'notifications'}
          src={headerBellUnread}
          alt={'Notifications'}
          role={'button'}
          onClick={handleNotificationsBellClicked}
          tabIndex={0}
          ref={notifIconRef}
          data-testid={'notification-button'}
        />
      )}
      {showNotifications && !loading && (
        <div
          className={`dropdown-menu notification-dropdown-menu arrow-box${condensed ? ' condensed' : ''}`}
          ref={notifRef}
        >
          <div className={'notification-dropdown-header'}>
            <span id={'notifications-text'}>{notificationHeaderText}</span>
            {hasUnviewed && (
              <a href={'#'} id={'mark-all-read-text'} onClick={handleMarkAllRead}>
                Mark all Read
              </a>
            )}
          </div>
          <div className={'notification-list'} onScroll={handleScroll} role={'notification-list'}>
            {notifications?.map((notification: NotificationModel) => (
              <NotificationListItem
                key={notification.notificationId}
                notification={notification}
                onClick={() => {
                  setShowNotifications(!showNotifications)
                }}
              />
            ))}
            {hasMore && <span className={'loading-notifications'}>Loading more Notifications...</span>}
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationList
