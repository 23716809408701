import { UserProfileModel } from '~/types'
import { ApolloError, useQuery } from '@apollo/client'
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Community, GetUserProfileDocument, Role } from '~/api/generated/graphql'
import { asCommunity, asRole, asUser } from '~/utils'
import { usePrompt } from '~/contexts/PromptContext'

export type ProfileContextType = {
  loading: boolean
  userId?: string
  user?: UserProfileModel
  error?: ApolloError
  profileInfoChanges?: boolean
  setProfileInfoChanges?: (b: boolean) => void
  updateEditingRoles?: (s: string, b: boolean) => void
}

export const ProfileContext = React.createContext<ProfileContextType>({ loading: false })

export const ProfileProvider = ({
  userId,
  waitForLoad,
  children,
}: {
  waitForLoad?: boolean
  userId: string
  children: ReactNode
}) => {
  const { loading, data, error } = useQuery(GetUserProfileDocument, {
    variables: { id: userId },
    skip: !userId,
  })

  const user = useMemo(() => {
    if (!loading && data?.user && userId) {
      const user = asUser(data?.user)
      const {
        createdTime,
        aboutMe,
        aboutMeModifiedTime,
        orgwikiProfile,
        linkedinUrl,
        primaryLocationType,
        primaryLocationCountry,
        primaryLocationState,
        primaryLocationCity,
        primaryLocationCountryCode,
        secondaryLocationType,
        secondaryLocationCountry,
        secondaryLocationState,
        secondaryLocationCity,
        secondaryLocationCountryCode,
        syncOrgwikiLocation,
        roleAtCompany,
        roleModifiedTime,
        roles: rolesConnection,
        memberships: membershipsConnection,
        company,
      } = data.user

      const memberships = membershipsConnection?.edges
        .map(e => e?.node?.community as Partial<Community>)
        .filter(Boolean)
        .map(asCommunity)
        .sort((a, b) => a.name.localeCompare(b.name))

      const roles = rolesConnection?.edges
        .map(e => e?.node as Partial<Role>)
        .filter(Boolean)
        .map(asRole)
        .sort((a, b) => {
          return a.company.name.localeCompare(b.company.name)
        })

      return {
        ...user,
        createdTime: createdTime ?? '',
        orgwikiProfile: orgwikiProfile ?? undefined,
        company: {
          companyId: company?.companyId ?? '',
          name: company?.name ?? '',
          isVeeva: company?.isVeeva ?? false,
        },
        aboutMe: aboutMe ?? null,
        aboutMeModifiedTime,
        linkedinUrl: linkedinUrl ?? undefined,
        primaryLocation: {
          type: primaryLocationType ?? undefined,
          country: primaryLocationCountry ?? undefined,
          state: primaryLocationState ?? undefined,
          city: primaryLocationCity ?? undefined,
          countryCode: primaryLocationCountryCode ?? undefined,
        },
        secondaryLocation: {
          type: secondaryLocationType ?? undefined,
          country: secondaryLocationCountry ?? undefined,
          state: secondaryLocationState ?? undefined,
          city: secondaryLocationCity ?? undefined,
          countryCode: secondaryLocationCountryCode ?? undefined,
        },
        syncOrgwikiLocation: syncOrgwikiLocation ?? undefined,
        roleAtCompany,
        roleModifiedTime,
        roles,
        memberships,
      }
    }
  }, [loading, data, userId])

  const [profileInfoChanges, setProfileInfoChanges] = useState(false)
  const [editingRoles, setEditingRoles] = useState<string[]>([])

  const hasChanges = useMemo(() => {
    return editingRoles.length > 0 || profileInfoChanges
  }, [editingRoles, profileInfoChanges])
  const { block, unblock } = usePrompt()
  useEffect(() => {
    if (hasChanges) {
      block({ key: 'profile', message: 'You have unsaved changes. Are you sure you want to leave?' })
    } else {
      unblock('profile')
    }
  }, [block, hasChanges, unblock])

  const updateEditingRoles = useCallback(
    (companyId: string, adding: boolean) => {
      setEditingRoles?.(roles => {
        const r = [...roles]
        if (companyId) {
          const i = r.indexOf(companyId)
          const includesRole = i !== -1
          if (adding && !includesRole) {
            r.push(companyId)
          } else if (includesRole) {
            r.splice(i, 1)
          }
        }
        return r
      })
    },
    [setEditingRoles]
  )

  return waitForLoad && (loading || !user) ? (
    <></>
  ) : (
    <ProfileContext.Provider
      value={{ loading, userId, user, error, profileInfoChanges, setProfileInfoChanges, updateEditingRoles }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)
