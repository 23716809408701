import { useEffect, useRef } from 'react'

export const useEventListener = (
  /** eventName - The name of the event. */
  eventName: string,
  /** A function that handles the event or an object implementing the `EventListener` interface. */
  handler: EventListenerOrEventListenerObject,
  element: EventTarget
) => {
  const savedHandlerRef = useRef<EventListenerOrEventListenerObject>()

  useEffect(() => {
    savedHandlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const currentTarget = element
    if (currentTarget === null || typeof currentTarget.addEventListener !== 'function') {
      return
    }

    const eventListener = (event: Event) => {
      if (Object.prototype.hasOwnProperty.call(savedHandlerRef.current!, 'handleEvent')) {
        const handler = savedHandlerRef.current! as EventListenerObject
        handler.handleEvent(event)
      } else {
        const handler = savedHandlerRef.current! as EventListener
        handler(event)
      }
    }

    currentTarget.addEventListener(eventName, eventListener)
    return () => {
      currentTarget.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
