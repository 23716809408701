import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import '@css/pages/feed/MyCommunitiesTile.scss'
import arrow from '@web/images/feed/link-arrow.svg'
import { elementClicked } from '~/common/EventLogger'
import { GetRecentViewedCommunitiesDocument } from '~/api/generated/graphql'
import { FeedCommunityListViewModel } from '~/types'
import { useQuery } from '@apollo/client'

const RecentCommunitiesTile = () => {
  const { data: commViewData, loading: commViewLoading } = useQuery(GetRecentViewedCommunitiesDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const recentCommunities = useMemo(() => {
    if (!commViewLoading && commViewData) {
      return (
        (commViewData.recentCommunities
          ?.map(community => {
            return {
              communityId: community?.communityId,
              name: community?.name,
              photo: community?.photo,
            }
          })
          .filter(Boolean) as FeedCommunityListViewModel[]) ?? []
      )
    }
  }, [commViewLoading, commViewData])

  return (
    <div className={'feed-tile-full'} role={'recent-communities-tile'}>
      <div className={'tile-title'}>
        <h1>Recently Viewed</h1>
      </div>
      <div className={'tile-container'}>
        {recentCommunities?.map(community => (
          <Link
            onClick={e =>
              elementClicked(e, 'click-recent-community-tile', {
                community_id: community.communityId,
              })
            }
            to={`/communities/${community.communityId}`}
            className={'tile community-link'}
            key={`${community.communityId}recent`}
          >
            <div
              className={'profile-photo community-photo'}
              style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
            />
            <div className={'community-name'}>{community.name}</div>
            <img src={arrow} alt={'Go'} />
          </Link>
        ))}
        <div className={'tile community-link'}>
          <Link to={'/allcommunities'} onClick={e => elementClicked(e, 'click-feed-discover-communities')}>
            Discover more communities
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RecentCommunitiesTile
