import { elementClicked } from '~/common/EventLogger'
import '@css/pages/search/AdvancedSearch.scss'
import React from 'react'
import { ResultsTab } from '~/pages/search/AdvancedSearch'
import { getPluralizedPost } from '~/utils'

type SearchMatchesToggleProps = {
  currentResultsTab: ResultsTab
  fullMatchCountAll: number
  fullMatchCount: number
  partialMatchCountAll: number
  partialMatchCount: number
  showingPartialMatches: boolean
  toggleShowingPartialMatches: (showing: boolean) => void
  selectAllTab: () => void
}

export const AdvancedSearchWordMatching = ({
  currentResultsTab,
  fullMatchCountAll,
  fullMatchCount,
  partialMatchCountAll,
  partialMatchCount,
  showingPartialMatches,
  toggleShowingPartialMatches,
  selectAllTab,
}: SearchMatchesToggleProps) => {
  const noMatchesAtAll = () => {
    return (
      <>
        <span className={'no-multiword-match-header'}>There are no posts that match your search</span>
        <span className={'helper-message'}>
          <i>Maybe you should create a post about your topic!</i>
        </span>
      </>
    )
  }

  const noPartialMatches = () => {
    return (
      <>
        {fullMatchCountAll === 0 ? (
          noMatchesAtAll()
        ) : (
          <>
            {fullMatchCount === 0 ? (
              <>
                <span className={'no-match-header'}>
                  We couldn't find any posts that match your search in{' '}
                  {currentResultsTab === ResultsTab.my ? 'your' : 'these'} communities
                </span>
                <span className={'helper-message no-flex'}>
                  <i>
                    If you like, you can see{' '}
                    <span className={'link'} onClick={selectAllTab}>
                      {showingPartialMatches ? partialMatchCountAll + fullMatchCountAll : fullMatchCountAll}{' '}
                      {getPluralizedPost(
                        showingPartialMatches ? partialMatchCountAll + fullMatchCountAll : fullMatchCountAll
                      )}{' '}
                      in other communities
                    </span>
                  </i>
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div className={'word-matching'}>
      {partialMatchCount > 0 ? (
        <>
          {fullMatchCount === 0 ? (
            <>
              <span className={'no-multiword-match-header'}>
                There are no posts with all of the words in your search
              </span>
              <span className={'helper-message'}>
                {showingPartialMatches ? (
                  <i>
                    Showing posts with <b>some of the words in your search</b>
                  </i>
                ) : (
                  <i>
                    See posts with{' '}
                    <span
                      className={'link'}
                      onClick={e => {
                        elementClicked(e, 'click-advanced-search-result-show-partial')
                        toggleShowingPartialMatches(!showingPartialMatches)
                      }}
                      role={'button'}
                      data-testid={'partial-toggle'}
                    >
                      some of the words in your search
                    </span>
                  </i>
                )}
              </span>
            </>
          ) : (
            <div className={'helper-message'}>
              <i>
                Showing posts with <b>{showingPartialMatches ? 'some' : 'all'} of the words in your search</b>
              </i>
              <i>
                See {showingPartialMatches ? 'just the posts' : 'posts'} with{' '}
                <span
                  className={'link'}
                  onClick={e => {
                    elementClicked(e, 'click-advanced-search-result-show-partial')
                    toggleShowingPartialMatches(!showingPartialMatches)
                  }}
                  role={'button'}
                >
                  {showingPartialMatches ? 'all' : 'some'} of the words in your search
                </span>{' '}
              </i>
            </div>
          )}
        </>
      ) : (
        noPartialMatches()
      )}
    </div>
  )
}
