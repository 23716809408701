import React from 'react'
import PostHeaderLayoutComponent from '~/pages/posts/PostHeaderLayoutComponent'
import { GetCommentHeaderDocument, MediaType } from '~/api/generated/graphql'
import TimeAgo from '~/common/TimeAgo'
import { useQuery } from '@apollo/client'

type Props = {
  commentId: string
  onDelete?: (id: string) => void
  onClickEdit?: () => void
  isOnSinglePost?: boolean
  isVeevanDiscussion?: boolean
  hidden?: boolean
}

export const CommentHeader = ({
  commentId,
  onDelete,
  onClickEdit,
  isOnSinglePost,
  isVeevanDiscussion,
  hidden,
}: Props) => {
  const { data: commentData } = useQuery(GetCommentHeaderDocument, { variables: { id: commentId } })
  const comment = commentData?.comment

  if (!comment?.createdById) return null
  return (
    <div data-testid={`comment-header-${commentId}`}>
      <PostHeaderLayoutComponent
        views={comment.viewCount}
        likes={comment.likeCount}
        last_activity={
          <div>
            <TimeAgo time={new Date(comment.createdTime)} />
          </div>
        }
        hidden={hidden ?? false}
        isComment={true}
        fromContentPage={false}
        contentTitle={''}
        entry={{
          createdTime: new Date(comment.createdTime),
          authorId: comment.createdById ?? '',
          commentId,
          veevanLikeCount: comment.veevanLikeCount,
          veevanViewCount: comment.veevanViewCount,
        }}
        mediaType={MediaType.Other}
        isSinglePost={isOnSinglePost}
        onDelete={onDelete}
        onClickEdit={onClickEdit}
        isVeevanDiscussion={isVeevanDiscussion}
      />
    </div>
  )
}
export default CommentHeader
