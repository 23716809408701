import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface CountsProps {
  isComment: boolean
  veevanViews: number
  veevanLikes: number
  views: number
  likes: number
  comments?: number
  veevanComments?: number
  isVeevan?: boolean
  isReleasePage?: boolean
  postViewCount?: number
  veevanPostViewCount?: number
}

const Counts = (props: CountsProps) => {
  const comments = props.comments ?? 0
  const veevanComments = props.veevanComments ?? 0

  const postViewCount = props.postViewCount ?? 0
  const veevanPostViewCount = props.veevanPostViewCount ?? 0

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="post-metrics-tooltip">
          {!!props.isReleasePage && <div className={'post-views-inner'}>Total across all posts</div>}
          <div className={'post-views'}>
            {props.views}
            {props.isReleasePage ? ' Unique ' : ' '}
            {props.isReleasePage ? (props.views === 1 ? 'Viewer' : 'Viewers') : props.views == 1 ? 'View' : 'Views'}
            {props.isVeevan && (
              <>
                <div className={'post-views-inner'}>
                  {props.views - props.veevanViews} {props.views - props.veevanViews == 1 ? 'Customer' : 'Customers'}
                </div>
                <div className={'post-views-inner'}>
                  {props.veevanViews} {props.veevanViews == 1 ? 'Veevan' : 'Veevans'}
                </div>
              </>
            )}
          </div>
          <div>
            {props.likes ?? 0} {props.likes == 1 ? 'Like' : 'Likes'}
          </div>
          {props.isVeevan && (
            <>
              <div className={'post-views-inner'}>
                {props.likes - props.veevanLikes} {props.likes - props.veevanLikes == 1 ? 'Customer' : 'Customers'}
              </div>
              <div className={'post-views-inner'}>
                {props.veevanLikes} {props.veevanLikes == 1 ? 'Veevan' : 'Veevans'}
              </div>
            </>
          )}
          {!props.isComment && <div>{`${comments} ${comments == 1 ? 'Comment' : 'Comments'}`}</div>}
          {props.isVeevan && !props.isComment && (
            <>
              <div className={'post-views-inner'}>
                {comments - veevanComments} {comments - veevanComments == 1 ? 'Customer' : 'Customers'}
              </div>
              <div className={'post-views-inner'}>
                {veevanComments} {veevanComments == 1 ? 'Veevan' : 'Veevans'}
              </div>
            </>
          )}
          {!!props.isReleasePage && (
            <>
              <div>
                {postViewCount} Unique Post {postViewCount === 1 ? 'View' : 'Views'}
              </div>
              <div className={'post-views-inner'}>
                {postViewCount - veevanPostViewCount}{' '}
                {postViewCount - veevanPostViewCount === 1 ? 'Customer' : 'Customer'} Views
              </div>
              <div className={'post-views-inner'}>
                {veevanPostViewCount} {veevanPostViewCount === 1 ? 'Veevan' : 'Veevan'} Views
              </div>
            </>
          )}
        </Tooltip>
      }
    >
      <div>
        <span className={'post-views-count'} data-testid={'post-views-count'}>
          {props.views ?? 0}
        </span>{' '}
        &#183; <span className={'post-likes-count'}>{(props.likes ?? 0) + comments}</span>
      </div>
    </OverlayTrigger>
  )
}

export default Counts
