import { getNormalizedTokens } from '~/utils'
import {
  Company,
  GetSuggestionCommunityDocument,
  GetSuggestionCommunityQuery,
  GetSuggestionCompanyDocument,
  GetSuggestionCompanyQuery,
} from '~/api/generated/graphql'
import { Suggestion } from '~/common/quill/QuillEditor'
import { ApolloCache } from '@apollo/client'

const NUM_SUGGESTIONS = 7

const CompanySuggestionService = {
  getSuggestions: (
    search: string,
    cache: ApolloCache<object>,
    onlyIncludeIds?: Set<string>,
    excludeIds?: Set<string>,
    showExcluded?: boolean
  ): Suggestion[] => {
    const prefixCache = globalThis.prefixCache
    const names = getNormalizedTokens(search)
    const resultDict = new Map<string, number>()
    for (const tok of names) {
      if (prefixCache?.has(tok)) {
        for (const match of prefixCache?.get(tok) ?? []) {
          resultDict.set(match, (resultDict.get(match) || 0) + 1)
        }
      }
    }
    const filteredResults = Array.from(resultDict.keys())
      .filter(k => resultDict.get(k) === names.length)
      .filter(k => k.startsWith('Company'))
    const result: GetSuggestionCompanyQuery['company'][] = []
    filteredResults.map(k => {
      const companyId = k.split(':')[1]
      result.push(
        cache.readQuery<GetSuggestionCompanyQuery>({
          query: GetSuggestionCompanyDocument,
          variables: { id: companyId },
        })?.company
      )
    })

    // Company aliases are cached with a community id rather than company id
    const aliasedResults = Array.from(resultDict.keys())
      .filter(k => resultDict.get(k) === names.length)
      .filter(k => k.startsWith('Community'))
    const communities: GetSuggestionCommunityQuery['community'][] = []
    aliasedResults.map(k => {
      const communityId = k.split(':')[1]
      communities.push(
        cache.readQuery<GetSuggestionCommunityQuery>({
          query: GetSuggestionCommunityDocument,
          variables: { communityId: communityId },
        })?.community
      )
    })
    communities
      .filter(c => c?.companyId)
      .map(c => {
        const companyId = c?.companyId
        result.push(
          cache.readQuery<GetSuggestionCompanyQuery>({
            query: GetSuggestionCompanyDocument,
            variables: { id: companyId },
          })?.company
        )
      })

    let matchedCompanies: Suggestion[] = result
      ?.filter(c => c)
      .map((c: Company) => {
        return {
          id: c.companyId ?? '',
          community: {
            companyId: c.companyId ?? '',
            name: c.name,
          },
          value: c.name,
          link: `/${c.companyId ? 'companies' : 'communities'}}/${c.companyId}`,
          photo: c.homepage?.photo ?? null,
        }
      })
    // Filter duplicates due to aliases
    matchedCompanies = matchedCompanies.filter(
      (value, index, self) => index === self.findIndex(suggestion => suggestion.id === value.id)
    )

    if (onlyIncludeIds) {
      matchedCompanies = matchedCompanies.filter(c => onlyIncludeIds.has(c?.community?.companyId || ''))
    }

    if (excludeIds) {
      const matchedCompaniesWithoutExcluded = matchedCompanies.filter(
        c => !excludeIds.has(c?.community?.companyId || '')
      )
      if (matchedCompaniesWithoutExcluded.length >= NUM_SUGGESTIONS || !showExcluded) {
        matchedCompanies = matchedCompaniesWithoutExcluded
      }
    }

    return matchedCompanies.slice(0, NUM_SUGGESTIONS)
  },
}

export default CompanySuggestionService
