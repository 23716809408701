import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useAuth } from '~/auth/Auth'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import {
  PostType,
  GetActivityAuthorDocument,
  GetActivityCommunityDocument,
  GetActivityLastCommenterDocument,
  GetActivityPostAuthorDocument,
  GetFeedPostDocument,
} from '~/api/generated/graphql'
import { getFullName, getPostPath, toTimeAgo } from '~/utils'
import { Accordion, useAccordionButton } from 'react-bootstrap'
import { elementClicked } from '~/common/EventLogger'
import TimeAgo from '~/common/TimeAgo'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { useShortcuts } from '~/contexts/ShortcutContext'
import ToastComponent from '~/common/ToastComponent'
import { Link } from 'react-router-dom'
import Counts from '~/pages/posts/Counts'
import MentionableText from '~/common/MentionableText'
import ExpandedActivityRow from '~/pages/feed/ExpandedActivityRow'
import { PostEdit } from '~/pages/posts/PostEdit'
import { isMacOs } from 'react-device-detect'
import { useQuery } from '@apollo/client'

type Props = {
  postId: string
  rowExpanded?: (rowId: string, expanded: boolean) => void
}

export const FeedActivityRow = ({ postId, rowExpanded }: Props) => {
  // Ensure the user cache data is loaded before we try to fetch any data since it should all be
  // present in the cache.
  // const { loading } = useSearchCacheContext()
  const { isCondensedPortrait } = useWindowSize()
  const { isVeevan, actingSysAdmin } = useAuth()
  const eventKey = postId
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isEditingActivity, setIsEditingActivity] = useState<boolean>(false)

  const { data: postData, loading: loadingPost } = useQuery(GetFeedPostDocument, { variables: { id: postId } })
  const post = postData?.post
  const readPost = post?.viewed ?? false

  useEffect(() => {
    if (rowExpanded) {
      rowExpanded(postId, isExpanded)
    }
  }, [postId, isExpanded, rowExpanded])

  const authorId = post?.lastComment?.createdById || post?.createdById
  const { data: authorData, loading: loadingAuthor } = useQuery(GetActivityAuthorDocument, {
    variables: { id: authorId ?? '' },
    skip: !authorId,
  })
  const { data: postAuthorData, loading: loadingPostAuthor } = useQuery(GetActivityPostAuthorDocument, {
    variables: { id: post?.createdById ?? '' },
    skip: !post?.createdById,
  })
  const profilePhoto = postAuthorData?.user?.photo
  const authorIsInactive = postAuthorData?.user?.hidden

  const lastCommentAuthorId = post?.lastComment?.createdById

  const { data: lastCommentAuthorData, loading: loadingLastCommentAuthor } = useQuery(
    GetActivityLastCommenterDocument,
    {
      variables: { id: lastCommentAuthorId ?? '' },
      skip: !lastCommentAuthorId,
    }
  )
  const lastCommentPhoto = lastCommentAuthorData?.user?.photo
  const lastCommentAuthorHidden = lastCommentAuthorData?.user?.hidden

  const loadingAuthors =
    (loadingAuthor && !authorData) ||
    (loadingPostAuthor && !postAuthorData) ||
    (loadingLastCommentAuthor && !lastCommentAuthorData)

  const fullName = `${postAuthorData?.user?.firstName} ${postAuthorData?.user?.lastName} (${postAuthorData?.user?.company?.name})`

  const expand = useAccordionButton(eventKey, (e: SyntheticEvent) => {
    setSelectedPost?.({ postId: eventKey, expanded: true })
    elementClicked(e, 'click-post-expanded', { postId })
  })

  const { data: communityData, loading: loadingCommunity } = useQuery(GetActivityCommunityDocument, {
    variables: { id: post?.communityId ?? '' },
    skip: !post?.communityId,
  })
  const community = communityData?.community

  const communityLink = community?.companyId
    ? `/companies/${community?.companyId}/home`
    : `/communities/${post?.communityId}/about`

  const postLink = getPostPath(community, { postId }, post?.postType == PostType.Content)

  const activityText = (
    <>
      {post?.isRepost ? 'reposted' : 'posted'}
      &nbsp;
      <TimeAgo time={new Date(post?.createdTime)} />
    </>
  )

  const handleClickPostLink = (e: React.MouseEvent<HTMLElement>) => {
    elementClicked(e, 'click-feed-post-link', {
      postId,
    })
    if (isMacOs ? e.metaKey : e.ctrlKey) {
      window.open(getPostPath(community, post))
    } else {
      expand(e)
    }
  }

  const outerDivRef = React.useRef<HTMLDivElement>(null)

  const rowRef = useClickOnEnter<HTMLDivElement>()
  const commRef = useClickOnEnter<HTMLDivElement>()

  const lastCommentTime: Date | undefined = post?.lastComment?.createdTime
    ? new Date(post.lastComment.createdTime)
    : undefined

  const {
    viewCount = 0,
    veevanViewCount = 0,
    likeCount = 0,
    veevanLikeCount = 0,
    commentCount = 0,
    veevanCommentCount = 0,
  } = post ?? {}

  const [showDeleteToast, setShowDeleteToast] = useState<boolean>(false)
  const { selectedPost, expandCurrentPost, setSelectedPost } = useShortcuts()
  const expandRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!isExpanded && selectedPost?.postId === postId && !selectedPost?.expanded) {
      expandRef?.current?.click()
      expandCurrentPost?.()
      outerDivRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
    } else if (selectedPost?.postId === postId && !selectedPost?.expanded) {
      expandCurrentPost?.()
      outerDivRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
    }
    const rowDiv = rowRef.current
    return () => {
      // prevent previously expanded row from holding onto focus after expanding new row
      rowDiv?.blur()
    }
  }, [selectedPost, postId, expandCurrentPost, isExpanded, outerDivRef, rowRef])

  if (post?.hidden && !actingSysAdmin)
    return (
      <ToastComponent show={showDeleteToast} onClose={() => setShowDeleteToast(false)} bg={'success'}>
        {'The post was deleted successfully.'}
      </ToastComponent>
    )

  if (loadingAuthors || (loadingCommunity && !communityData) || (loadingPost && !postData)) return null
  const communityInner = (
    <>
      <div
        className="profile-photo community-photo"
        style={community?.photo ? { backgroundImage: `url(${community?.photo})` } : {}}
      />
      <div className={`community-name ${isCondensedPortrait ? 'condensed' : ''}`} ref={commRef}>
        {community?.name}
      </div>
    </>
  )
  const displayLayout = (
    <>
      <div
        ref={outerDivRef}
        className={`feed-row activity-row${isExpanded ? ' expanded' : ''}${post?.hidden ? ' hidden' : ''}${
          isCondensedPortrait ? ' condensed' : ''
        }`}
      >
        <div
          className={`activity-item ${isCondensedPortrait ? 'condensed' : ''}`}
          onClick={handleClickPostLink}
          role={'link'}
          ref={expandRef}
        >
          <div className={`activity-post-info ${isCondensedPortrait ? 'condensed' : 'not-condensed'}`}>
            <div
              className={`post-link${isExpanded ? ' expanded' : ''} ${isCondensedPortrait ? 'condensed' : ''}`}
              ref={rowRef}
            >
              <div className={`community-identity ${isCondensedPortrait ? 'condensed' : ''}`}>
                {isExpanded || !isCondensedPortrait ? (
                  <>
                    <Link
                      to={communityLink}
                      className={`community-link ${isCondensedPortrait ? 'condensed' : ''}`}
                      tabIndex={0}
                      onClick={e => {
                        elementClicked(e, 'click-feed-community-link', {
                          postId,
                        })
                        e.stopPropagation()
                      }}
                    >
                      {communityInner}
                    </Link>
                    <div className={'mobile-link-spacer'} />
                  </>
                ) : (
                  communityInner
                )}

                {!post?.hidden && isCondensedPortrait && !isExpanded && (
                  <div className={`activity-menu condensed`}>
                    <Counts
                      isComment={false}
                      likes={likeCount}
                      veevanLikes={veevanLikeCount ?? 0}
                      views={viewCount}
                      veevanViews={veevanViewCount ?? 0}
                      comments={commentCount}
                      veevanComments={veevanCommentCount}
                      isVeevan={isVeevan}
                    />
                  </div>
                )}
              </div>
              {!isExpanded && (
                <div className={`activity-info ${isCondensedPortrait ? 'condensed' : ''}`} tabIndex={0}>
                  <div className={`title-wrapper ${post?.isFollowing ? 'bookmark' : ''}`}>
                    <div className={post?.isFollowing ? 'bookmark' : ''} />
                    <div className={`post-title${readPost ? ' read' : ' unread'} feed-post`}>
                      {isCondensedPortrait ? (
                        <MentionableText value={post?.title} postHeader={true} />
                      ) : (
                        <Link
                          to={postLink}
                          tabIndex={-1}
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                            elementClicked(e, 'click-feed-post-link', {
                              postId,
                            })
                            setIsExpanded(false)
                            e.stopPropagation()
                          }}
                        >
                          <MentionableText value={post?.title} postHeader={true} />
                        </Link>
                      )}
                    </div>
                  </div>
                  {isCondensedPortrait ? (
                    <div className="activity-description">
                      <div
                        className={'profile-photo'}
                        style={
                          lastCommentTime
                            ? lastCommentPhoto && !lastCommentAuthorHidden
                              ? { backgroundImage: `url(${lastCommentPhoto})` }
                              : {}
                            : profilePhoto && !authorIsInactive
                              ? { backgroundImage: `url(${profilePhoto})` }
                              : {}
                        }
                      />
                      <div className={`activity-author condensed`}>
                        <div className={`${authorIsInactive ? 'inactive' : ''}`}>{getFullName(authorData?.user)}</div>{' '}
                        {post?.lastComment ? 'commented' : post?.isRepost ? 'reposted' : 'posted'}{' '}
                        {toTimeAgo(new Date(post?.lastActivityTime))}
                      </div>
                    </div>
                  ) : (
                    <div className={`activity-description`}>
                      <div
                        className={'profile-photo'}
                        style={profilePhoto && !authorIsInactive ? { backgroundImage: `url(${profilePhoto})` } : {}}
                      />
                      <div className={`activity-author${isCondensedPortrait ? ' condensed' : ''}`}>
                        <div className={`${authorIsInactive ? ' inactive' : ''}`}>{fullName}</div>{' '}
                        <div>{activityText}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {!isExpanded && (
            <div className={'activity-meta-info'}>
              <div className={'post-stats'}>
                <Counts
                  isComment={false}
                  likes={likeCount}
                  veevanLikes={veevanLikeCount ?? 0}
                  views={viewCount}
                  veevanViews={veevanViewCount ?? 0}
                  comments={commentCount}
                  veevanComments={veevanCommentCount}
                  isVeevan={isVeevan}
                />
              </div>
              <div className={`activity-time${readPost ? ' read' : ' unread'}`}>
                <div>
                  <TimeAgo time={new Date(post?.lastActivityTime)} />
                </div>
                {!!post?.lastComment && (
                  <div className="comment-info">{`${authorData?.user?.firstName} ${authorData?.user?.lastName} commented`}</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Accordion.Collapse
        eventKey={eventKey ?? ''}
        className={`post-expand-zone home-feed ${isCondensedPortrait ? 'condensed' : ''}`}
        onEnter={() => {
          setIsExpanded(true)
          setSelectedPost?.({ postId, expanded: true })
        }}
        onExited={() => {
          setIsExpanded(false)
          setSelectedPost?.({ postId, expanded: true })
        }}
      >
        <ExpandedActivityRow
          postId={postId}
          postType={post?.postType ?? PostType.Post}
          communityId={post?.communityId ?? ''}
          isExpanded={isExpanded}
          eventKey={eventKey ?? ''}
          onDelete={() => setShowDeleteToast(true)}
        />
      </Accordion.Collapse>
    </>
  )

  if (isEditingActivity) return <PostEdit postId={postId} onDone={() => setIsEditingActivity(false)} />
  else return displayLayout
}
