import React, { SyntheticEvent } from 'react'
import { Suggestion } from '~/common/quill/QuillEditor'

type SuggestionFacetProps = {
  index: number
  suggestion: Suggestion & { count: number }
  selectedSuggestion: number
  selectSuggestion: (e: SyntheticEvent) => void
}

export const FacetSuggestion = ({ index, suggestion, selectedSuggestion, selectSuggestion }: SuggestionFacetProps) => {
  const { value, photo, count } = suggestion
  const photoStyle = photo ? { backgroundImage: `url(${photo})` } : {}

  return (
    <li
      className={index === selectedSuggestion ? 'selected' : ''}
      onClick={e => selectSuggestion(e)}
      onKeyDown={e => {
        if (e.key === 'Enter') selectSuggestion(e)
      }}
      role={'list-item'}
      tabIndex={0}
    >
      <div className={`user-badge`}>
        <div className="badge-body">
          <div className="profile-photo" style={photoStyle} role={'img'}>
            <div title={value || ''} data-company={value} />
          </div>
          <h6 className="facet-name">{value}</h6>
          <span className={'count'} data-testid={'suggestion-count'}>
            ({count})
          </span>
        </div>
      </div>
    </li>
  )
}
