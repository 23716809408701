import { useAuth } from '~/auth/Auth'
import { useCommunity } from '~/contexts/CommunityContext'
import { useMemo } from 'react'
import { ChildNode, Comment, Element, ProcessingInstruction, Text } from 'domhandler'
import * as HTMLReactParser from 'html-react-parser'
import {
  CanAddContentDocument,
  CanAddContentQuery,
  GetContentsDocument,
  GetContentsQuery,
  GetFollowedPostsDocument,
  GetFollowedPostsQuery,
  GetFollowingPostsDocument,
  GetMyCommunitiesPostsDocument,
  GetPostPermissionsDocument,
  GetPostsDocument,
  GetPostsQuery,
  GetUserCommunitiesDocument,
  Html,
  HtmlWithMentions,
  Maybe,
  Post,
  PostEdge,
  PostType,
} from '~/api/generated/graphql'
import { asString } from '~/utils'
import { ApolloCache, ApolloClient, useQuery } from '@apollo/client'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'

export const calculateFieldLengthSeverity = (fieldLength: number, maxLength: number) => {
  return fieldLength > maxLength ? ' red' : fieldLength > maxLength - 10 ? ' yellow' : ' gray'
}

export const usePermissions = (
  authorId?: Maybe<string>,
  postId?: string
): {
  canEdit: boolean
  canDelete: boolean
  hasLeaderPermissions: boolean
  hasMemberPermissions: boolean
  loading: boolean
} => {
  const { authUserId, actingSysAdmin } = useAuth()
  const userId = authUserId
  const { data, loading } = useQuery(GetUserCommunitiesDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
  })
  const { data: postData, loading: postsDataLoading } = useQuery(GetPostPermissionsDocument, {
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const communityIds = useMemo(() => data?.user?.memberships?.edges.map(e => e?.node?.communityId) || [], [data])
  const leadershipIds = useMemo(
    () => data?.user?.memberships?.edges?.filter(e => e?.node?.leader).map(e => e?.node?.communityId) || [],
    [data]
  )
  const { communityId } = useCommunity()
  const isLeader = useMemo(
    () => actingSysAdmin || leadershipIds.includes(communityId),
    [leadershipIds, communityId, actingSysAdmin]
  )
  const isMember = useMemo(
    () => actingSysAdmin || communityIds.includes(communityId),
    [communityIds, communityId, actingSysAdmin]
  )

  const canEdit = Boolean(
    (authorId === userId ||
      (!postData?.post?.draft && isLeader && postData?.post?.postType == PostType.Content) ||
      (postData?.post?.draft && actingSysAdmin)) &&
      !postData?.post?.hidden
  )

  const canDelete = canEdit || actingSysAdmin || isLeader

  return {
    canEdit,
    canDelete,
    hasLeaderPermissions: isLeader,
    hasMemberPermissions: isMember,
    loading: loading || postsDataLoading,
  }
}

export const getNodeText = (
  node:
    | (ChildNode | Comment | Element | ProcessingInstruction | Text)[]
    | (ChildNode | Comment | Element | ProcessingInstruction | Text)
): string => {
  if (!node) return ''
  if (node instanceof Text) return node.data
  if (Array.isArray(node)) return node.map(getNodeText).join('')
  if (node instanceof Element) return getNodeText(node.childNodes)
  return ''
}

export const getHtmlLength = (content: Maybe<Html> | Maybe<HtmlWithMentions> | undefined) => {
  const value = asString(content)
  return getNodeText(HTMLReactParser.htmlToDOM(value)).trim().length
}

export const updateCachePosts = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  authUserId: string | null | undefined,
  newPost?: Post
) => {
  if (!newPost) return
  const communityId = newPost.communityId
  const oldFollowedPosts = cache.readQuery<GetFollowedPostsQuery>({
    query: GetFollowedPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
  })

  const oldPosts = cache.readQuery<GetPostsQuery>({
    query: GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
  })

  const getSortedEdges = (oldEdges: PostEdge[]): PostEdge[] => {
    const edges = [
      { node: newPost, typename: 'PostEdge' },
      ...(oldEdges?.filter(e => e.node?.postId !== newPost.postId) ?? []),
    ]
    return edges.sort((a, b) => {
      const aCreatedDate = new Date(a?.node?.createdTime)
      const bCreatedDate = new Date(b?.node?.createdTime)
      if (a?.node?.draft === b?.node?.draft) {
        if (bCreatedDate > aCreatedDate) return 1
        return -1
      } else if (b?.node?.draft) {
        return 1
      } else if (a?.node?.draft) {
        return -1
      }
      return 0
    }) as PostEdge[]
  }

  const newFollowedPostsData = {
    posts: {
      ...(oldFollowedPosts?.posts ?? { pageInfo: { hasNextPage: false } }),
      edges: getSortedEdges(oldFollowedPosts?.posts?.edges as PostEdge[]).filter(p => p.node?.isFollowing),
    },
  }

  const newPostsData = {
    posts: {
      ...(oldPosts?.posts ?? { pageInfo: { hasNextPage: false } }),
      edges: getSortedEdges(oldPosts?.posts?.edges as PostEdge[]),
    },
  }

  client.writeQuery({
    query: GetFollowedPostsDocument,
    variables: { communityId: communityId ?? '' },
    data: newFollowedPostsData,
  })

  client.writeQuery({
    query: GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
    data: newPostsData,
  })
}

const getFollowingSortedEdges = (newPost: Partial<Post>, oldEdges: PostEdge[], oldPost?: Partial<Post>): PostEdge[] => {
  const updatedPost = { ...oldPost, ...newPost }
  const following = updatedPost?.isFollowing
  const edges = following
    ? [{ node: updatedPost, typename: 'PostEdge' }, ...(oldEdges ?? [])]
    : (oldEdges?.filter(e => e.node?.postId !== updatedPost.postId) ?? [])

  if (!following) {
    return edges as PostEdge[]
  }

  return edges.sort((a, b) => {
    const aLastActivityTime = new Date(a?.node?.lastActivityTime)
    const bLastActivityTime = new Date(b?.node?.lastActivityTime)
    if (a?.node?.draft === b?.node?.draft) {
      if (bLastActivityTime > aLastActivityTime) {
        return 1
      }
      return -1
    } else if (b?.node?.draft) {
      return 1
    } else if (a?.node?.draft) {
      return -1
    }
    return 0
  }) as PostEdge[]
}

export const updateFeedPostFollow = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  updatedPost?: Partial<Post>
) => {
  if (!updatedPost) return
  const oldFollowedPosts = cache.readQuery<GetFollowedPostsQuery>({
    query: GetFollowingPostsDocument,
  })

  const oldPosts = cache.readQuery<GetPostsQuery>({
    query: GetMyCommunitiesPostsDocument,
  })
  const oldPost =
    oldFollowedPosts?.posts?.edges.find(p => p?.node?.postId === updatedPost.postId) ||
    oldPosts?.posts?.edges.find(p => p?.node?.postId === updatedPost.postId)

  const maxPageSize = Math.max(
    Math.ceil((oldFollowedPosts?.posts?.edges?.length ?? 0) / POSTS_PAGE_SIZE) * POSTS_PAGE_SIZE,
    POSTS_PAGE_SIZE
  )

  let edges = getFollowingSortedEdges(
    updatedPost,
    oldFollowedPosts?.posts?.edges as PostEdge[],
    oldPost?.node as Partial<Post>
  )

  if (oldFollowedPosts?.posts?.pageInfo?.hasNextPage) {
    edges = edges.slice(0, maxPageSize)
  }

  const newFollowedPostsData = {
    posts: {
      ...(oldFollowedPosts?.posts ?? { pageInfo: { hasNextPage: false } }),
      edges: edges,
    },
  }

  client.writeQuery({
    query: GetFollowingPostsDocument,
    data: newFollowedPostsData,
  })
}

export const updateCommunityPostFollow = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  authUserId?: string | null,
  updatedPost?: Partial<Post>,
  communityId?: string | null
) => {
  if (!updatedPost) return
  const oldFollowedPosts = cache.readQuery<GetFollowedPostsQuery>({
    query: GetFollowedPostsDocument,
    variables: { communityId: communityId ?? '' },
  })

  const oldPosts = cache.readQuery<GetPostsQuery>({
    query: GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
  })

  const oldPost =
    oldPosts?.posts?.edges.find(p => p?.node?.postId === updatedPost.postId) ||
    oldFollowedPosts?.posts?.edges.find(p => p?.node?.postId === updatedPost.postId)

  const maxPageSize = Math.max(
    Math.ceil((oldFollowedPosts?.posts?.edges?.length ?? 0) / POSTS_PAGE_SIZE) * POSTS_PAGE_SIZE,
    POSTS_PAGE_SIZE
  )

  let edges = getFollowingSortedEdges(
    updatedPost,
    oldFollowedPosts?.posts?.edges as PostEdge[],
    oldPost?.node as Partial<Post>
  )

  if (oldFollowedPosts?.posts?.pageInfo?.hasNextPage) {
    edges = edges.slice(0, maxPageSize)
  }

  const newFollowedPostsData = {
    posts: {
      ...(oldFollowedPosts?.posts ?? { pageInfo: { hasNextPage: false } }),
      edges: edges,
    },
  }

  client.writeQuery({
    query: GetFollowedPostsDocument,
    data: newFollowedPostsData,
    variables: { communityId: communityId ?? '' },
  })
}

export const updateCacheContent = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  communityId: string | null | undefined,
  authUserId: string | null | undefined,
  newPost?: Post
) => {
  if (!newPost) return
  const oldCount = cache.readQuery<CanAddContentQuery>({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? newPost.communityId },
  })

  const oldContent = cache.readQuery<GetContentsQuery>({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? newPost.communityId, userId: authUserId ?? '' },
  })

  const oldData = oldCount?.canAddContent
  const newCount = (oldData?.count ?? 0) + (newPost?.draft ? 0 : 1)

  const newCountData = {
    canAddContent: {
      ok: newCount !== oldData?.max,
      count: newCount >= (oldData?.max ?? 0) ? newCount : (oldData?.count ?? 0),
      max: oldData?.max ?? 0,
      __typename: 'ContentValidator' as const,
    },
  }

  const newContentData = {
    posts: {
      ...oldContent?.posts,
      edges: [{ node: newPost, typename: 'PostEdge' }, ...(oldContent?.posts?.edges ?? [])],
    },
  }

  client.writeQuery({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? newPost.communityId },
    data: newCountData,
  })

  client.writeQuery({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? newPost.communityId, userId: authUserId ?? '' },
    data: newContentData,
  })
}

export const removeCacheContent = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  communityId: string | null | undefined,
  authUserId: string | null | undefined,
  removedContent?: Post
) => {
  if (!removedContent) return
  const oldCount = cache.readQuery<CanAddContentQuery>({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? removedContent.communityId },
  })

  const oldContent = cache.readQuery<GetContentsQuery>({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? removedContent.communityId, userId: authUserId ?? '' },
  })

  const oldData = oldCount?.canAddContent
  const newCount = (oldData?.count ?? 0) - 1

  const newCountData = {
    canAddContent: {
      ok: newCount !== oldData?.max,
      count: newCount > (oldData?.max ?? 0) ? oldData?.count : newCount,
      max: oldData?.max ?? 0,
      __typename: 'ContentValidator' as const,
    },
  }

  const newContentData = {
    posts: {
      edges: oldContent?.posts?.edges.filter(e => e?.node?.postId !== removedContent.postId) ?? [],
    },
  }

  client.writeQuery({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? removedContent.communityId },
    data: newCountData,
  })

  client.writeQuery({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? removedContent.communityId, userId: authUserId ?? '' },
    data: newContentData,
  })
}
