import Hls from 'hls.js'
import { useCallback, useMemo } from 'react'

export const VideoPlayer = ({ src, className = 'video', ...props }: { src: string; className: string }) => {
  const hls = useMemo(() => {
    return new Hls({
      debug: true,
    })
  }, [])
  const registerHls = useCallback(
    (videoRef: HTMLVideoElement) => {
      if (Hls.isSupported()) {
        // hls.log = true
        hls.loadSource(src)
        hls.attachMedia(videoRef)
        hls.on(Hls.Events.ERROR, (code, err) => {
          console.log('hlserror', code, err)
        })
        const loadedmetadatahandler = () => {
          void videoRef.play()
        }
        videoRef.addEventListener('loadedmetadata', loadedmetadatahandler)
      } else {
        console.log('load')
      }
    },
    [hls, src]
  )
  return <video ref={r => r && registerHls(r)} className={className} controls {...props} src={src} />
}
