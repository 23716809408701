import React from 'react'
import { AppHeader } from '~/common/AppHeader'
import '@css/pages/admin/AdminPage.scss'
import { useAuth } from '~/auth/Auth'
import AdminTable from '~/pages/admin/AdminTable'
import { Footer } from '~/common/Footer'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { useNavigate } from 'react-router'

const AdminLayout = () => {
  const { actingSysAdmin } = useAuth()
  const { breakpoint } = useWindowSize()
  const isMediumOrSmaller = breakpoint <= SizeBreakpoint.md

  const navigate = useNavigate()
  if (!actingSysAdmin) {
    navigate('/')
    return <></>
  }

  return (
    <div>
      <AppHeader />
      <div className={`admin-container${isMediumOrSmaller ? ' condensed' : ''}`}>
        <AdminTable />
        <Footer />
      </div>
    </div>
  )
}

export default AdminLayout
