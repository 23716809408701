import React, { useEffect, useRef, useState } from 'react'
import deleteIcon from '@web/images/community/icon-xdelete.png'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { Button, Modal } from 'react-bootstrap'
import PlainTextInput from '~/common/PlainTextInput'
import { SubcategoryRow } from '~/pages/page/AddPage'

type SubcategoryRowProps = {
  subcategory: SubcategoryRow
  addingRow?: boolean
  setAddingRow?: (b: boolean) => void
  onSave?: (c: SubcategoryRow) => Promise<boolean | undefined>
  onDelete?: (c: SubcategoryRow) => Promise<boolean | undefined>
}

const AddReleaseSubcategoryRow = ({ subcategory, addingRow, setAddingRow, onSave, onDelete }: SubcategoryRowProps) => {
  const [editing, setEditing] = useState(addingRow)
  const [subcategoryName, setSubcategoryName] = useState(subcategory.name)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (addingRow && inputRef) {
      inputRef?.current?.focus()
    }
  }, [addingRow, inputRef])

  const saveRow = async () => {
    const success = await onSave?.({ ...subcategory, name: subcategoryName })
    if (success) {
      setEditing(false)
      setAddingRow?.(false)
    }
  }
  const deleteRow = () => {
    const success = onDelete?.(subcategory)
    if (success) {
      setEditing(false)
      setAddingRow?.(false)
    }
  }

  return (
    <>
      <div className={`category-row ${editing ? 'editing' : ''}`}>
        <div className={'left-items'}>
          <div className={`category-title`} data-testid={'subcategory-name'}>
            {editing ? (
              <PlainTextInput
                placeholder={'Add a Subcategory Name'}
                onChange={s => {
                  setSubcategoryName(s.slice(0, 200))
                }}
                value={subcategoryName}
                inputRef={inputRef}
                className={'category-name-input'}
              />
            ) : (
              subcategoryName
            )}
          </div>
        </div>
        {editing ? (
          <div className={'editing-action-buttons'}>
            <Button
              variant="light"
              onClick={() => {
                setEditing(false)
                setAddingRow?.(false)
                setSubcategoryName(subcategory.name)
              }}
              data-testid={'subcategory-row-cancel'}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveRow} disabled={!subcategoryName}>
              Save
            </Button>
            {!addingRow && (
              <img
                className={`delete-button`}
                src={deleteIcon}
                alt="Delete"
                role={'button'}
                tabIndex={0}
                onClick={deleteRow}
                data-testid={'subcategory-delete-button'}
              />
            )}
          </div>
        ) : (
          <img
            className="edit-button"
            src={pencilIcon}
            alt="Edit"
            role={'button'}
            tabIndex={0}
            onClick={() => setEditing(true)}
            data-testid={'subcategory-edit-pencil'}
          />
        )}
      </div>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Are you sure you want to delete this subcategory?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowConfirmation(false)
              deleteRow()
            }}
          >
            {'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddReleaseSubcategoryRow
