import React, { ReactNode, useContext, useRef } from 'react'
import { Outlet } from 'react-router'

export type TypeaheadContextType = {
  searchInputRef?: React.RefObject<HTMLInputElement>
}

export const TypeaheadContext = React.createContext<TypeaheadContextType>({})

export const TypeaheadProvider = ({ children }: { children: ReactNode }) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  return (
    <TypeaheadContext.Provider
      value={{
        searchInputRef,
      }}
    >
      {children}
    </TypeaheadContext.Provider>
  )
}

export const useTypeahead = () => useContext(TypeaheadContext)

export const RequireTypeahead = () => {
  return (
    <TypeaheadProvider>
      <Outlet />
    </TypeaheadProvider>
  )
}
