// @flow
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Footer } from '~/common/Footer'

interface Props {
  children: React.ReactNode
  className?: string
  navState?: string
  searchCallback?: (data: string) => void
}

/**
 * Single Column Layout for Summit agenda pages
 */
export default function LayoutSingleCol(props: Props) {
  return (
    <div className={`${props.className} wrapper`}>
      <Container fluid="xl" className={'content'}>
        <Row className={'real-height outer'}>
          <Col className={'Zone MainZone'}>
            <div className={'inner'}>{props.children}</div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}
