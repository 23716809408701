import React, { MouseEventHandler, ReactNode } from 'react'

export const CustomToggle = React.forwardRef<
  HTMLDivElement | null,
  { children: ReactNode; onClick: MouseEventHandler<HTMLDivElement> }
>(({ children, onClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  )
})
