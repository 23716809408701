import React, { useEffect, useRef, useState } from 'react'
import contentReorderIcon from '@web/images/posts/DragDropContent.svg'
import deleteIcon from '@web/images/community/icon-xdelete.png'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import { Button, Modal } from 'react-bootstrap'
import { Maybe } from 'graphql/jsutils/Maybe'
import PlainTextInput from '~/common/PlainTextInput'
import { CategoryRow } from '~/pages/page/AddPage'

type CategoryRowProps = {
  category: CategoryRow
  addingRow?: boolean
  setAddingRow?: (b: boolean) => void
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableProps?: Maybe<DraggableProvidedDraggableProps>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draggableInnerRef?: (element?: Maybe<HTMLElement>) => any
  onSave?: (c: CategoryRow) => Promise<boolean | undefined>
  onDelete?: (c: CategoryRow) => Promise<boolean | undefined>
}
const AddPageCategoryRow = ({
  category,
  addingRow,
  setAddingRow,
  dragHandleProps,
  draggableProps,
  draggableInnerRef,
  onSave,
  onDelete,
}: CategoryRowProps) => {
  const [editing, setEditing] = useState(addingRow)
  const [categoryTitle, setCategoryTitle] = useState(category.name)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (addingRow && inputRef) {
      inputRef?.current?.focus()
    }
  }, [addingRow, inputRef])

  const saveRow = async () => {
    const success = await onSave?.({ ...category, name: categoryTitle })
    if (success) {
      setEditing(false)
      setAddingRow?.(false)
    }
  }
  const sendDelete = () => {
    const success = onDelete?.(category)
    if (success) {
      setEditing(false)
      setAddingRow?.(false)
    }
  }

  const deleteRow = () => {
    if (category.postCount) {
      setShowConfirmation(true)
    } else {
      sendDelete()
    }
  }

  return (
    <>
      <div ref={draggableInnerRef} {...draggableProps} className={`category-row ${editing ? 'editing' : ''}`}>
        <div className={'left-items'}>
          <img
            alt={'row-drag-drop-handle'}
            src={contentReorderIcon}
            {...dragHandleProps}
            className={`drag-drop-image ${addingRow && 'hidden'}`}
            title={'Click and drag to reorder'}
            data-testid={'dnd-handle'}
          />
          <div className={`category-title`}>
            {editing ? (
              <PlainTextInput
                placeholder={'Add a Category Name'}
                onChange={s => {
                  setCategoryTitle(s.slice(0, 200))
                }}
                value={categoryTitle}
                inputRef={inputRef}
                className={'category-name-input'}
              />
            ) : (
              categoryTitle
            )}
          </div>
        </div>
        {editing ? (
          <div className={'editing-action-buttons'}>
            <Button
              variant="light"
              onClick={() => {
                setEditing(false)
                setAddingRow?.(false)
                setCategoryTitle(category.name)
              }}
              data-testid={'category-row-cancel'}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveRow} disabled={!categoryTitle}>
              Save
            </Button>
            {!addingRow && (
              <img
                className={`delete-button`}
                src={deleteIcon}
                alt="Delete"
                role={'button'}
                tabIndex={0}
                onClick={deleteRow}
                data-testid={'category-delete-button'}
              />
            )}
          </div>
        ) : (
          <img
            className="edit-button"
            src={pencilIcon}
            alt="Edit"
            role={'button'}
            tabIndex={0}
            onClick={() => setEditing(true)}
            data-testid={'category-edit-pencil'}
          />
        )}
      </div>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Are you sure you want to delete this category?</p>
          <p className={'notation warning'}>Posts are attached.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowConfirmation(false)
              sendDelete()
            }}
          >
            {'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddPageCategoryRow
