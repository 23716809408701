import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import '@css/pages/company/ActivityOverlay.scss'

type ActivityOverlayProps = {
  postCount: number
  commentCount: number
  likeCount: number
}

const ActivityOverlay = ({ postCount, commentCount, likeCount }: ActivityOverlayProps) => {
  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip>
          <div className={'activity-overlay-tooltip'}>
            <div>{postCount} Posts</div>
            <div>{commentCount} Comments</div>
            <div>{likeCount} Likes</div>
          </div>
        </Tooltip>
      }
    >
      <span className={'activity-counts'}>
        {postCount} &#183; {commentCount} &#183; {likeCount}
      </span>
    </OverlayTrigger>
  )
}

export default ActivityOverlay
