import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import '@css/pages/PostSelectionModal.scss'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useMutation } from '@apollo/client'
import { PostDraftsDocument } from '@web/js/api/generated/graphql'

type PostDraftsModalProps = {
  showModal: boolean
  setShowModal: (b: boolean) => void
  releaseId: string
  posts: DraftPostsDisplayList[]
}

type PostDraftsListProps = {
  posts: DraftPostsDisplayList[]
  setShowModal: (b: boolean) => void
  releaseId: string
}

export type DraftPostsDisplayList = {
  postId: string
  author: string
  communityName: string
  contentTitle: string
  summitTitle: string
  checked: boolean
  draft: boolean
  categoryName: string
}

export type CategoryItem = {
  name: string
  categoryId: string
  posts: DraftPostsDisplayList[]
}

const PostDraftsList = ({ posts, setShowModal, releaseId }: PostDraftsListProps) => {
  const [postsToDisplay, setPostsToDisplay] = useState<DraftPostsDisplayList[]>(posts)
  const [postDrafts] = useMutation(PostDraftsDocument)
  const { isCondensed } = useWindowSize()

  const checkBox = (index: number) => {
    setPostsToDisplay(posts => {
      const postsCopy = [...posts]
      const post = postsCopy[index]
      if (post) {
        postsCopy[index].checked = !posts[index].checked
      }
      return postsCopy
    })
  }

  const cancelPostSelection = () => {
    setShowModal(false)
  }

  const savePostSelection = async () => {
    const ids: string[] = []

    for (const element of postsToDisplay) {
      if (element.checked) {
        ids.push(element.postId)
      }
    }

    if (ids.length > 0) {
      const resp = await postDrafts({
        variables: { postIds: ids, releaseId: releaseId },
      })

      if (!resp.data?.postDrafts?.ok) {
        return
      }
    }

    setShowModal(false)
  }

  return (
    <>
      <Modal.Body>
        <div className={'content-table-wrapper'}>
          <div className={'content-table'}>
            <div className={'content-labels-drafts'}>
              <p>Summit Category</p>
              <p>Summit Title</p>
              <p>Content Post Details</p>
            </div>
            <div className={'content-rows'}>
              {postsToDisplay &&
                postsToDisplay.map((post, index) => (
                  <div
                    className={`content-row ${isCondensed ? 'condensed' : ''}`}
                    key={post.postId}
                    data-testid={'content-row'}
                  >
                    <div className={'checkbox-container'}>
                      <input
                        type={'checkbox'}
                        onChange={() => checkBox(index)}
                        data-testid={'checkbox'}
                        defaultChecked={true}
                      />
                    </div>
                    <p>{post.categoryName}</p>
                    <div className={'content-title-container'}>
                      <p>{post.summitTitle ?? post.contentTitle}</p>
                    </div>
                    <div className={'content-title-container'}>
                      <div className={'draft-indicator'}>DRAFT</div>
                      <p>
                        <span className={'content-title'}>{post.contentTitle}</span>
                        <span className={'title-phrase'}>
                          {' '}
                          by {post.author} in {post.communityName}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={cancelPostSelection}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={savePostSelection} data-testid={'post-drafts-modal'}>
          Post Drafts
        </Button>
      </Modal.Footer>
    </>
  )
}

const PostDraftsModal = ({ posts, showModal, setShowModal, releaseId }: PostDraftsModalProps) => {
  const { isCondensed } = useWindowSize()

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className={`post-drafts-modal ${isCondensed && 'condensed'}`}
    >
      <Modal.Header>
        <div>
          <h4>Post Drafts</h4>
        </div>
      </Modal.Header>
      {posts.length > 0 ? (
        <PostDraftsList posts={posts} setShowModal={setShowModal} releaseId={releaseId} />
      ) : (
        <>
          <Modal.Body>
            <div className={'content-table-wrapper'}>
              <div className={'content-table'}>
                <div className={'content-labels-drafts'}>
                  <p>Summit Category</p>
                  <p>Summit Title</p>
                  <p>Content Post Details</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setShowModal(false)
              }}
              data-testid={'post-drafts-modal'}
            >
              Post Drafts
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default PostDraftsModal
