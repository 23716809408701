import React from 'react'
import errorAlert from '@web/images/community/error-alert.svg'
import '@css/common/FormError.scss'

type FormErrorProps = {
  message: string
}

export const FormError = ({ message }: FormErrorProps) => {
  return (
    <div className={'error-container'}>
      <img className="error-alert" src={errorAlert} />
      <span className="editor-error" data-testid="editor-error">
        {message}
      </span>
    </div>
  )
}

export default FormError
