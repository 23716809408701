import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useCommunity } from '~/contexts/CommunityContext'
import { Dropdown, Nav, Navbar } from 'react-bootstrap'
import '@css/pages/community/CommunitySidebar.scss'
import { useAuth } from '~/auth/Auth'
import gearIcon from '@web/images/community/gear-icon.svg'
import JoinCommunityButton, { MembershipAction } from '~/common/JoinCommunityButton'
import CommunitySelectorDropdown from '~/common/CommunitySelectorDropdown'
import { usePermissions } from '~/pages/posts/PostUtils'
import ToastComponent from '~/common/ToastComponent'
import { useNavigate } from 'react-router'
import { useCommunities } from '@web/js/contexts/CommunitiesContext'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { CommunityListTab } from '~/pages/community/CommunityListLayout'
import { GetSidebarCommunityDocument } from '~/api/generated/graphql'
import { elementClicked } from '~/common/EventLogger'
import { useTitle } from '~/common/hooks/useTitle'
import { useQuery } from '@apollo/client'

type CommunitySidebarProps = {
  isCompany?: boolean
  onChangedMembershipStatus?: (action: MembershipAction) => void
}

const CommunitySidebar = ({ isCompany, onChangedMembershipStatus }: CommunitySidebarProps) => {
  const { breakpoint } = useWindowSize()
  const location = useLocation()
  const { loading, communityId, refetch, isPrivate, isVeeva, companyId } = useCommunity()
  const { data: basicCommunityData } = useQuery(GetSidebarCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId || loading,
  })
  const communityName = basicCommunityData?.community?.name || ''
  const membersTabLink = isVeeva ? 'members' : 'members#all'

  const { searchMine, searchAll } = useCommunities()
  const photo = basicCommunityData?.community?.photo
  const { authUserId, isVeevan, companyId: userCompanyId, actingSysAdmin } = useAuth()
  const { hasLeaderPermissions, hasMemberPermissions } = usePermissions(authUserId)
  const imgStyle = { ...(photo && { backgroundImage: `url(${photo})` }) }
  const [showEditSuccess, setShowEditSuccess] = useState<boolean>(false)
  const handleCloseToast = () => setShowEditSuccess(false)
  const showJoinButton = !isCompany || userCompanyId !== companyId
  const canViewHistory = hasLeaderPermissions || (isVeevan && !isPrivate) || (isVeevan && hasMemberPermissions)
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const { page: currentPage, skip: skipTitle } = useMemo(() => {
    const currentPath = location.pathname.toString()
    let page = ''
    let skip = false
    switch (true) {
      case currentPath.includes('/home'):
        page = 'Overview'
        break
      case currentPath.includes('/about'):
        page = 'About'
        break
      case currentPath.includes('/posts'):
        page = 'Posts'
        skip = !currentPath.endsWith('/posts')
        break
      case currentPath.includes('/events'):
        page = 'Events'
        break
      case currentPath.includes('/partners'):
        page = 'Account Partners'
        break
      case currentPath.includes('/members'):
        page = 'Members'
        break
      case currentPath.includes('/content'):
        page = 'Content'
        break
      case currentPath.includes('/activity'):
        page = 'Customer Activity'
        break
      case currentPath.includes('/settings'):
        page = 'Settings'
        break
      case currentPath.includes('/history'):
        page = 'History'
        break
    }
    return { page, skip }
  }, [location])

  useTitle(
    `${
      basicCommunityData?.community?.name
        ? `${basicCommunityData?.community?.name} ${currentPage ? ` - ${currentPage} - ` : ''}`
        : ''
    }Veeva Connect`,
    skipTitle
  )

  const onJoinClick = (action: MembershipAction, e: SyntheticEvent) => {
    elementClicked(e, `click-community-sidebar-${action}`, { communityId: communityId })
    onChangedMembershipStatus?.(action)
    searchMine('')
    searchAll('')
    refetch()
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (!loading && !communityId) {
      navigate('/communities', { replace: true })
    }
  }, [loading, communityId, navigate])

  const standardLayout = (
    <>
      <ToastComponent show={showEditSuccess} onClose={handleCloseToast}>
        {`${isCompany ? 'Customer Homepage' : 'Community'} updated successfully`}
      </ToastComponent>
      <Navbar className="navbar-container" role="tablist">
        <CommunitySelectorDropdown
          communityId={communityId || ''}
          communityListTab={CommunityListTab.All}
          listPage={false}
        />
        <div className="type-row">
          <div>
            <img
              hidden={!(hasLeaderPermissions || (isCompany && isVeevan))}
              className="edit-button"
              src={gearIcon}
              alt="Edit"
              role={'button'}
              onClick={() => navigate('settings')}
              tabIndex={0}
            />
          </div>
        </div>
        <div className="navbar-section centered community-description">
          <div className="object-icon-container large-community-photo" style={imgStyle} />
          <h1>{communityName}</h1>
          <p>{basicCommunityData?.community?.description}</p>
        </div>
        {showJoinButton && communityId && (
          <JoinCommunityButton
            communityName={communityName}
            communityId={communityId}
            isCompany={isCompany}
            onAction={onJoinClick}
          />
        )}
        <Nav role="tablist" aria-orientation="vertical" className={`navbar-section`}>
          <Nav.Item>
            <NavLink
              onClick={e =>
                elementClicked(e, isCompany ? 'click-community-sidebar-overview' : 'click-community-sidebar-about', {
                  community_id: communityId,
                })
              }
              role="tab"
              to={isCompany ? 'home' : 'about'}
              end
            >
              {isCompany ? 'Overview' : 'About'}
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              className={`${currentPage === 'Posts' ? 'active' : ''}`}
              onClick={e => elementClicked(e, 'click-community-sidebar-posts', { community_id: communityId })}
              role="tab"
              to="posts"
              end
            >
              Posts
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              className={`${currentPage === 'Events' ? 'active' : ''}`}
              onClick={e => elementClicked(e, 'click-community-sidebar-events', { community_id: communityId })}
              role="tab"
              to="events"
              end
            >
              Events
            </NavLink>
          </Nav.Item>
          {isCompany && !isVeeva && (
            <Nav.Item>
              <NavLink
                onClick={e => elementClicked(e, 'click-community-sidebar-partners', { community_id: communityId })}
                role="tab"
                to="partners"
                end
              >
                Account Partners
              </NavLink>
            </Nav.Item>
          )}
          {(hasMemberPermissions || isCompany || isVeevan) && (
            <Nav.Item>
              <NavLink
                onClick={e => elementClicked(e, 'click-community-sidebar-members', { community_id: communityId })}
                role="tab"
                to={membersTabLink}
                end
              >
                Members
              </NavLink>
            </Nav.Item>
          )}
          <Nav.Item>
            <NavLink
              className={`${currentPage === 'Content' ? 'active' : ''}`}
              onClick={e => elementClicked(e, 'click-community-sidebar-content', { community_id: communityId })}
              role="tab"
              to="content"
              end
            >
              Content
            </NavLink>
          </Nav.Item>
          {/* hiding Customer Activity for non-admins for now while we figure out some details - will show for all Veevans next release */}
          {actingSysAdmin && isCompany && (
            <Nav.Item>
              <NavLink
                onClick={e => elementClicked(e, 'click-community-sidebar-activity', { community_id: communityId })}
                role="tab"
                to="activity"
                end
              >
                Customer Activity
              </NavLink>
            </Nav.Item>
          )}
          {canViewHistory && (
            <Nav.Item>
              <NavLink
                onClick={e => elementClicked(e, 'click-community-sidebar-history', { community_id: communityId })}
                role="tab"
                to="history"
                end
              >
                History
              </NavLink>
            </Nav.Item>
          )}
        </Nav>
      </Navbar>
    </>
  )

  const mobileLayout = (
    <>
      <ToastComponent show={showEditSuccess} onClose={handleCloseToast}>
        {`${isCompany ? 'Customer Homepage' : 'Community'} updated successfully`}
      </ToastComponent>
      <div className={'condensed-navbar'}>
        <div className="condensed-icon-name">
          <div className="object-icon-container large-community-photo" style={imgStyle} />
          <h1>{basicCommunityData?.community?.name}</h1>
          {showJoinButton && communityId && (
            <JoinCommunityButton
              communityName={communityName}
              communityId={communityId}
              isCompany={isCompany}
              onAction={onJoinClick}
            />
          )}
        </div>
        <div className={'condensed-page-selector'}>
          <span className={'dropdown-header'}>Showing: </span>
          <Dropdown id="dropdown-community-page">
            <Dropdown.Toggle id="dropdown-basic" role="community-dropdown" className={'community-page-toggle'}>
              <span>{currentPage}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item
                onClick={e =>
                  elementClicked(e, isCompany ? 'click-community-sidebar-overview' : 'click-community-sidebar-about', {
                    community_id: communityId,
                  })
                }
                className={'community-page-item'}
                key="home"
                as={Link}
                to={isCompany ? 'home' : 'about'}
              >
                <span>{isCompany ? 'Overview' : 'About'}</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={e => elementClicked(e, 'click-community-sidebar-posts', { community_id: communityId })}
                className={'community-page-item'}
                key="posts"
                as={Link}
                to={'posts'}
              >
                <span>Posts</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={e => elementClicked(e, 'click-community-sidebar-events', { community_id: communityId })}
                className={'community-page-item'}
                key="events"
                as={Link}
                to={'events'}
              >
                <span>Events</span>
              </Dropdown.Item>
              {isCompany && !isVeeva && (
                <Dropdown.Item
                  onClick={e => elementClicked(e, 'click-community-sidebar-partners', { community_id: communityId })}
                  className={'community-page-item'}
                  key="partners"
                  as={Link}
                  to={'partners'}
                >
                  <span>Account Partners</span>
                </Dropdown.Item>
              )}
              {(hasMemberPermissions || isCompany || isVeevan) && (
                <Dropdown.Item
                  onClick={e => elementClicked(e, 'click-community-sidebar-members', { community_id: communityId })}
                  className={'community-page-item'}
                  key="members"
                  as={Link}
                  to={'members'}
                >
                  <span>Members</span>
                </Dropdown.Item>
              )}
              <Dropdown.Item className={'community-page-item'} key="content" as={Link} to={'content'}>
                <span>Content</span>
              </Dropdown.Item>
              {(hasLeaderPermissions || (isCompany && isVeevan)) && (
                <Dropdown.Item
                  onClick={e => elementClicked(e, 'click-community-sidebar-settings', { community_id: communityId })}
                  className={'community-page-item'}
                  key="settings"
                  as={Link}
                  to={'settings'}
                >
                  <span>Settings</span>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  )

  if (isCondensed) return mobileLayout
  else return standardLayout
}

export default CommunitySidebar
