import React from 'react'

type Props = {
  urls: string[]
}

export const ImagePreloader = ({ urls }: Props) => {
  return (
    <div style={{ display: 'none' }}>
      {urls.map((u, i) => (
        <img key={i} src={u} alt="a hidden element used for preloading images" />
      ))}
    </div>
  )
}
