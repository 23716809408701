import { Button, Modal } from 'react-bootstrap'
import React, { SyntheticEvent } from 'react'

type PostConfirmationModalProps = {
  show: boolean
  hide: () => void
  onSubmit: (e: SyntheticEvent) => void
  submitText: string
  message?: string
}

const PostConfirmationModal = ({ show, hide, onSubmit, submitText, message }: PostConfirmationModalProps) => {
  const msg =
    message ||
    'As a reminder, please do not attach any files, screenshots or code snippets that may contain sensitive information.'
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body>{msg}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={hide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={e => {
            hide()
            onSubmit(e)
          }}
        >
          {submitText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PostConfirmationModal
