import React from 'react'
import LayoutSingleCol from '~/common/LayoutSingleCol'
import '@css/pages/TermsPrivacy.scss'

export const Privacy = () => {
  return (
    <LayoutSingleCol className={'terms-privacy'}>
      <p className="text-center my-1">Veeva Connect</p>
      <h2 className="text-center display-6 mb-3">Veeva Connect Privacy Policy</h2>
      <i>Last Updated: November 21, 2022</i>
      <p>
        This Veeva Connect Privacy Policy (“
        <a href={'/privacy-policy'} target="_blank">
          Privacy Policy
        </a>
        ”) applies to the collection, use, transfer, and disclosure of personal information for users of Veeva Connect
        (“Connect”), a website and application of Veeva Systems Inc. (“<b>Veeva</b>”, <b>we</b>”, “<b>our</b>”, and/or “
        <b>us</b>”). We value the privacy of individuals (“you”, “your” and/or “users”) who use Connect.
      </p>
      <h5>Your Personal Information</h5>
      <p>
        We may collect a variety of information from or about you or your devices from various sources, as described
        below. If you do not provide your information when requested, you may not be able to use Connect if that
        information is necessary to provide you with Connect or if we are legally required to collect it.
      </p>
      <p>
        When you use Connect, you may provide us with personal information, such as your name, telephone number and
        email address. You may also provide your profile preferences including your availability, communication channel
        preferences and your connections with other users.
      </p>
      <p>
        We may also collect your IP address, web browser type, operating system version, phone or internet carrier,
        manufacturer, application installation details of Connect, and device identifiers.
      </p>
      <p>
        We may use the information we collect for the following purposes and as otherwise described in this Privacy
        Policy
      </p>
      <ul>
        <li>To provide, maintain, improve, and enhance Connect;</li>
        <li>
          To create and improve a profile about you within Connect and other Veeva products that we may share with third
          parties;
        </li>
        <li>To personalize your experience on Connect, such as by providing tailored content and recommendations;</li>
        <li>
          To understand and analyze how you use Connect and to help us improve it to develop new products, features, and
          functionalities by collecting information about your use of and interactions within Connect, like the pages or
          other content you view, the searches you conduct, people you follow or connect to, your comments, any content
          you post, the types of communications you have via Connect, the types of services or transactions you request
          via Connect, and the dates and times of your interactions;
        </li>
        <li>
          To communicate with you, provide you with updates and other information relating to Connect, provide
          information that you request, respond to comments and questions, and otherwise provide customer support;
        </li>
        <li>To facilitate the connection of third-party services or applications;</li>
        <li>
          To generate and publish reports, provide analytics or to support Connect and other Veeva products that we may
          provide to Veeva customers and for which we may charge a fee;
        </li>
        <li>To find and prevent fraud and respond to trust and safety issues that may arise;</li>
        <li>
          For compliance purposes, including enforcing our{' '}
          <a href={'/terms'} target="_blank">
            Terms
          </a>{' '}
          or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial
          process or governmental agency; and
        </li>
        <li>For other purposes for which we provide specific notice at the time the information is collected.</li>
      </ul>
      <h5>Sharing your Personal Information</h5>
      <p>We may share personal information about you as described in this Privacy Policy.</p>
      <p>
        <i>Veeva Affiliates, Subsidiaries, Service Providers, and other third parties</i>
      </p>
      <p>
        We may share personal information about you with our affiliates and subsidiaries and with our service providers,
        such as Amazon Web Services, for the purpose of providing Connect. We may also share personal information about
        you with third parties who access Veeva products, reports or analytics that include information you have shared
        through Connect.
      </p>
      <p>
        We use Brainshark to make video content available to you. If you choose to view a video, we make limited
        information about you (such as your name, email address and the company you work for) available to Brainshark.
      </p>
      <p>
        <i>Other Users of Connect</i>
      </p>
      <p>We display your user profile and your posts on Veeva Connect for other Connect users to view.</p>
      <p>
        <i>As Required By Law and Similar Disclosures</i>
      </p>
      <p>
        We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a)
        comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your
        requests; or (c) protect your, our, or others’ rights, property, or safety.
      </p>
      <p>
        <i>Merger, Sale, or Other Asset Transfers</i>
      </p>
      <p>
        We may transfer your information to Connect providers, advisors, potential transactional partners, or other
        third parties in connection with the consideration, negotiation, or completion of a corporate transaction in
        which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of
        our assets.
      </p>
      <p>
        <i>Consent</i>
      </p>
      <p>We may also disclose your information with your permission or at your direction.</p>
      <h5>Information from Cookies and Similar Technologies</h5>
      <p>
        We collect information using cookies, pixel tags, and similar technologies. Cookies are small text files
        containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A
        session cookie disappears after you close your browser. A persistent cookie remains after you close your browser
        and may be used by your browser on subsequent visits to Connect.
      </p>
      <p>
        Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note
        that if you delete or choose not to accept cookies from Connect, you may not be able to use the features of
        Connect to their fullest potential.
      </p>
      <h5>Third Parties</h5>
      <p>
        Connect may contain links to other websites, products, or services that we do not own or operate. We are not
        responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not
        apply to your activities on these third-party sites and services or any information you disclose to these third
        parties. We encourage you to read their privacy policies before providing any information to them.
      </p>
      <h5>Security</h5>
      <p>
        Connect implements security safeguards to protect the personal information it collects and uses to provide
        Connect. Veeva partners with internationally recognized cloud platform providers, such as Amazon Web Services
        (AWS) to provide Connect. These cloud providers operate data centers that are Tier3+ facilities, which are
        certified against established standards such as SSAE 18 and/or ISO27001. The processing of data is performed
        with a combination of a Veeva-maintained mobile application and web front-end running on top of AWS. The
        security of your personal information, the service, and the infrastructure it operates on are protected by a
        combination of security controls provided by AWS and 3rd party Internet security software providers, such as but
        not limited to AWS Guard Duty, AWS Shield, AWS CloudTrail, and Checkpoint Dome9 (Cloud security policy
        enforcement) which are monitored by a dedicated Veeva Security Operations team 24/7. Additionally, AWS operates
        its own dedicated Security Operations team that monitors the confidentiality, integrity, and availability of the
        underlying infrastructure.
      </p>
      <h5>Children’s Privacy</h5>
      <p>
        We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no
        part of Connect is directed or marketed to children.
      </p>
      <h5>HIPAA</h5>
      <p>Connect is not designed to store Protected Health Information or be HIPAA-compliant.</p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
        We will post adjustments to the Privacy Policy on this page, and the revised version will be effective when it
        is posted. If we materially change the ways in which we use or share personal information previously collected
        from you through Connect, we will notify you through Connect, by email, or other form of communication..
      </p>
      <h5>Right to Change or Delete Your Personal Data</h5>
      <p>
        If you wish to access, amend, or delete any personal information you have provided to us, you may contact us via
        email at{' '}
        <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
          VeevaConnect@veeva.com
        </a>
        .
      </p>
      <h6>Region specific policies</h6>
      <ol className={'region-policies'}>
        <li>
          <h5>CCPA</h5>
          <p>
            This section supplements the information contained in the rest of our Privacy Policy and applies to all
            Consumers residing in the state of California according to “The California Consumer Privacy Act of 2018”
            (California Civil Code §§ 1798.100 to 1798.199) and its implementing regulations, as amended or superseded
            from time to time (“CCPA”). Consumers are referred to below as “you”, “your”, “yours”, and, for such
            Consumers, these provisions supersede any other possibly divergent or conflicting provisions contained in
            the Privacy Policy. This part of the Privacy Policy uses the terms “Consumer”, “Personal Information”,
            “Sale” and “Business Purpose” as they are defined in the CCPA. All other capitalized terms in this section
            of the Privacy Policy are intended to have the same meaning as in the CCPA.
          </p>
          <h5>Categories of Personal Information Collected:</h5>
          <table>
            <tr>
              <th>Category of Personal Information</th>
              <th>Collected</th>
            </tr>
            <tr>
              <td>
                A. Identifiers such as a real name, alias, postal address, telephone or mobile contact number, unique
                personal identifier, online identifier, Internet Protocol address, email address and account name.{' '}
              </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>
                B. Personal Information as defined in the California customer records law, Section 1798.80(e), such as
                name, contact information, education, employment, employment history and financial information.{' '}
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>C. Characteristics of Protected Classifications under California or Federal Law. </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                D. Commercial information, such as transaction information, purchase history, financial details, and
                payment information.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>E. Biometric Information, such as fingerprints and voiceprints.</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                F. Internet or other electronic network activity information, such as browsing history, search history,
                online behavior, interest data, and interactions with our and other websites, applications, systems, and
                advertisements.
              </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>G. Geolocation Data, such as Precise physical location. </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>
                H. Audio, electronic, visual, and similar information, such as images and audio, video or call
                recordings created in connection with your practice.{' '}
              </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>
                I. Professional or employment-related information, such as job title as well as work history and
                experience in connection with your practice.{' '}
              </td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>
                J. Non-Public Education information subject to the federal Family Educational Rights and Privacy Act,
                (as defined in 20 U.S.C. 1232g; 34 C.F.R. Part 99) such as student records.{' '}
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                K. Inferences drawn from any of the Personal information Collected to create a profile or summary about,
                for example, an individual’s preferences and characteristics.{' '}
              </td>
              <td>Yes</td>
            </tr>
          </table>
          <h5>Categories of Third Parties With Whom Personal Information is Shared:</h5>
          <ul>
            <li>Internet service providers</li>
            <li>Data analytics providers</li>
            <li>Operating systems and platforms</li>
            <li>Veeva Connect users</li>
            <li>Affiliates and subsidiaries</li>
            <li>Vendors and service providers</li>
            <li>Third parties integrated into our services</li>
            <li>Third parties as required by law and similar disclosures</li>
            <li>Third parties in connection with a merger, sale, or asset transfer</li>
            <li>Other third parties for whom we have obtained your permission to disclose your Personal Information</li>
          </ul>
          <p>Not shared with:</p>
          <ul>
            <li>Advertising networks</li>
            <li>Government entities (unless required by law)</li>
            <li>Third Party Social networks</li>
            <li>Consumer data resellers</li>
          </ul>
          <h5>As the effective date, our Disclosures for a Business Purpose Include:</h5>
          <ol className={'normal-list'}>
            <li>To provide, maintain, improve, and enhance Connect;</li>
            <li>
              To generate and publish reports, provide analytics or to support Connect and other Veeva products that we
              may provide to Veeva customers and for which we may charge a fee;
            </li>
            <li>
              To create and improve a profile about you within Connect and other Veeva products that we may provide to
              Veeva customers and for which we may charge a fee;
            </li>
            <li>
              Auditing related to interactions with you and concurrent transactions, including but not limited to,
              auditing compliance with this Privacy Policy and other standards;
            </li>
            <li>
              Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity,
              and prosecuting those responsible for that activity;
            </li>
            <li>Debugging to identify and repair errors that impair existing intended functionality;</li>
            <li>
              Performing services, including maintaining or servicing accounts, providing customer service, processing
              or fulfilling orders and transactions, verifying your information, processing payments, providing
              financing, providing advertising or marketing services, providing analytic services, or providing similar
              services;
            </li>
            <li>To facilitate the connection of third-party services or applications;</li>
            <li>Undertaking internal research for technological development and demonstration;</li>
            <li>Undertaking activities to improve, upgrade, enhance, verify and maintain Connect’s quality;</li>
            <li>
              Other business purposes where the third party restricted from further disclose of such information and is
              prohibited from Selling such information;
            </li>
            <li>Identity verification; and</li>
            <li>
              For compliance purposes, including enforcing our Terms or other legal rights, or as may be required by
              applicable laws and regulations or requested by any judicial process or governmental agency; and
            </li>
            <li>
              For other purposes for which we provide specific notice at the time the information is collected
              Verifiable Consumer Requests.
            </li>
          </ol>
          <p>
            You can make requests related to your rights under the CCPA by contacting us via the Connect support email
            at{' '}
            <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
              VeevaConnect@veeva.com
            </a>
            . Please note that we cannot accept or process requests through any other means (such as via fax or social
            media).You have the right to request that we disclose to you (i) the categories of Personal Information we
            Collected about you and the categories of sources from which we Collected such information; (ii) the
            specific pieces of Personal Information we Collected about you; (iii) the Business or Commercial Purpose for
            Collecting Personal Information about you; and (iv) the categories of Personal Information about you that we
            shared or Disclosed and the Categories of third parties with whom we shared or to whom we Disclosed such
            information in the preceding 12 months. You also have the right to request that we delete Personal
            Information we Collected from you subject to certain exceptions explained below.
          </p>
          <p>
            We will not discriminate against you in pricing and services because you exercise your rights under the
            CCPA. Veeva does not offer financial incentives or price or service differences in exchange for the
            retention or Sale of your Personal Information.
          </p>
          <p>
            You may only make a request for access or data portability twice within a 12-month period. The request must:
          </p>
          <ul>
            <li>
              Provide sufficient information that allows us to reasonably verify you are the individual about whom we
              Collected personal information, or you are their Authorized Agent (i.e., a person registered with the
              California Secretary of State that you authorize to act on your behalf). You may be required to submit
              proof of your identity. Only you or your Authorized Agent may make a verifiable consumer request regarding
              your Personal Information.
            </li>
            <li>
              Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond
              to it.
            </li>
          </ul>
          <p>
            We will confirm receipt of your verifiable consumer request promptly and aim to respond to your request
            within 45 days of its receipt. Should we need more time, we will explain to you the reasons why and how much
            more time we need to complete the request. Please note that we may need to take up to 90 days to fulfill
            your request.
          </p>
          <p>
            We will not charge a fee to process or respond to your verifiable consumer request unless we reasonably
            determine it is excessive, repetitive, or manifestly unfounded. As such, if we determine that the request
            warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before
            completing your request.
          </p>
          <p>We will respond to your request consistent with the CCPA requirements.</p>
          <p>
            After review of the information related to your request we may request additional information from you to
            help ensure we are interacting with the correct individual. If you have an online account with us, you may
            be required to log-in to your account for identity verification. This other information may vary depending
            on the nature of your request and/or the nature of the information about which your request relates. We may
            also be required by law to obtain a signed declaration under penalty of perjury from you. If we suspect
            fraudulent or malicious activity, we will delay taking action on your request until we can appropriately
            verify your identity and the request as authentic.
          </p>
          <p>
            By law, we are not required to Collect Personal Information that we otherwise would not Collect in the
            ordinary course of our business, retain Personal Information for longer than we would otherwise retain such
            information in the ordinary course of our business, or reidentify or otherwise link information that is not
            maintained in a manner that would be considered Personal Information. If we have not requested specific
            information from you to Verify your request, please do not send such information.
          </p>
          <p>
            We generally will aim to avoid requesting additional information from you for the purposes of verification.
            However, if we cannot reasonably Verify your identity or more information is needed for security or
            fraud-prevention purposes, we may consider any of the following factors, alone or in combination, in
            requesting additional information:
          </p>
          <ul>
            <li>
              The type, sensitivity, and value of the Personal Information Collected and maintained about the Consumer,
              as applicable law requires a more stringent verification process for sensitive or valuable Personal
              Information;
            </li>
            <li>The risk of harm to the Consumer posed by any unauthorized access or deletion;</li>
            <li>The likelihood that fraudulent or malicious actors would seek the Personal Information;</li>
            <li>
              Whether the Personal Information you provide to Verify your identity is sufficiently robust to protect
              against fraudulent requests or being spoofed or fabricated;
            </li>
            <li>The manner in which we interact with you;</li>
            <li>Available technology for verification; and</li>
            <li>
              Other factors that may be reasonable in the circumstances, are consistent with industry practice, are
              recommended by California government officials, or which may be required by law or regulation following
              the effective date of this Privacy Policy.
            </li>
          </ul>
          <p>
            Information that you submit for the purpose of allowing us to Verify your identity will only be used by us,
            and our service providers if any, for that purpose and no other. Except where we are required by law to
            maintain such information for record-keeping purposes, we will take steps to delete any new Personal
            Information Collected for the purpose of verification as soon as practical after processing your request.
          </p>
          <p>
            Please also be aware that making any such request does not ensure complete or comprehensive removal or
            deletion of Personal Information or content you may have posted. When we receive a deletion request, it may
            be necessary for us to flag certain Personal Information and suppress any future processing or sharing of
            that information in order to ensure proper fulfillment and implementation of the deletion request on an
            ongoing basis. In addition, there may be circumstances in which the law does not require or allow us to
            fulfill your request, including, for example, where retaining the information is necessary for us or our
            service providers to:
          </p>
          <ol className={'normal-list'}>
            <li>
              Complete the transaction for which we Collected the Personal Information, provide a good or service that
              you requested, take actions reasonably anticipated within the context of our ongoing business relationship
              with you, or otherwise perform our contract with you;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
              prosecute those responsible for such activities;
            </li>
            <li>Debug products to identify and repair errors that impair existing intended functionality;</li>
            <li>
              Exercise free speech, ensure the right of another user to exercise their free speech rights, or exercise
              another right provided for by law;
            </li>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.);</li>
            <li>
              Connect in public or peer-reviewed scientific, historical, or statistical research in the public interest
              that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely
              render impossible or seriously impair the research’s achievement, if you previously provided informed
              consent;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with any consumer expectations based on your
              relationship with us;
            </li>
            <li>Comply with a legal obligation; or</li>
            <li>
              Make other internal and lawful uses of that information that are compatible with the context in which you
              provided it.
            </li>
          </ol>
        </li>
        <li>
          <h5>GDPR</h5>
          <p>
            This section provides specific information about how Connect complies with the EU General Data Protection
            Regulation (“<b>GDPR</b>”). It supplements the information contained in the rest of our Privacy Policy and
            applies to all data subjects residing in the European Union, the United Kingdom or Switzerland.
          </p>
          <p>
            Our EU Data Protection Officer and Information Security Officer have assessed our obligations as a data
            controller for Connect. Operating in a way that fosters trust and transparency, we appreciate the GDPR
            benefits of improving our business, becoming more efficient and creating better relationships with our
            customers and those whose data they collect.
          </p>
          <ol className={'normal-list'}>
            <li>
              In compliance with the EU General Data Protection Regulation, Veeva processes your personal data in the
              ways described in this Privacy Policy:
              <ul>
                <li>
                  as necessary we seek your <u>consent</u> to process your personal data as provided below;
                </li>
                <li>
                  as necessary for our or third-parties' <u>legitimate interests</u>, including our interests in
                  providing an innovative, safe, personalized and profitable service to our users and partners, unless
                  those interests are overridden by your interests or fundamental rights and freedoms that require
                  protection of personal data.
                </li>
                <li>
                  as necessary to fulfill our <u>Terms</u>;
                </li>
                <li>
                  as necessary to comply with our <u>legal obligations</u>;
                </li>
              </ul>
            </li>
            <li>
              Veeva respects your privacy and assures that your personal data will be kept securely according to GDPR.
            </li>
            <b>
              <li>
                By accepting you give your acknowledgement and consent to Veeva to use your personal data for the
                purposes stated in this Privacy Policy.{' '}
              </li>
            </b>
            <li>
              You agree that your consent will remain in place until your withdrawal. You may withdraw by contacting
              Veeva via email at{' '}
              <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
                VeevaConnect@veeva.com
              </a>
              .
            </li>
          </ol>
          <h5>Transfer of your personal data outside of the EU?</h5>
          <p>
            Veeva collects and processes your data in the European Economic Area (EEA). Transfer or access by Veeva
            personnel, datacenter providers and as provided above, outside of the EEA will only be made using legal
            mechanisms approved by the EU (e.g., the European Standard Contractual Clauses, intergroup agreements).
          </p>
          <h5>Your data privacy rights under the GDPR?</h5>
          <p>
            You can exercise your data protection rights to access, rectify, restrict, or erase your data, to object to
            the processing and to data portability/transfer by contacting Veeva at{' '}
            <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
              VeevaConnect@veeva.com
            </a>
            . You may also contact your relevant Data Protection Authority with any privacy concerns that you may have.
          </p>
          <h5>How long your personal data is kept?</h5>
          <p>
            We will retain this information until it is no longer necessary to provide our services and products or
            until you request its deletion by contacting Veeva at{' '}
            <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
              VeevaConnect@veeva.com
            </a>
            , whichever comes first (or as otherwise required by law).
          </p>
          <h5>Veeva’s Data Protection Officer?</h5>
          <p>
            Veeva’s Data Protection officer can be found at:{' '}
            <a href={'https://www.veeva.com/privacy/'} target="_blank">
              https://www.veeva.com/privacy/
            </a>
            .
          </p>
        </li>
        <li>
          <h5>LGPD</h5>
          <p>
            Law no. 13.709/2018 of Brazil, the <i>Lei Geral de Proteção de Dados Pessoais</i> (“LGPD”), entered into
            effect on August 16, 2020. The LGPD applies to businesses (both inside and outside Brazil) that process the
            personal data of users who are located in Brazil. The LGPD provides users with the following rights
            regarding their data:
          </p>
          <ol className={'normal-list'}>
            <li>confirmation of the existence of treatment;</li>
            <li>access to data;</li>
            <li>correction of incomplete, inaccurate or outdated data;</li>
            <li>
              anonymization, blocking or elimination of unnecessary, excessive or treated data in discrepancy with the
              provisions of the law;
            </li>
            <li>
              data portability to another service provider or product, upon express request and observance of commercial
              and industrial secrets, in accordance with the regulations of the controlling body;
            </li>
            <li>
              data portability to another service or product provider, upon express request, in accordance with the
              national authority regulations, observing the commercial and industrial secrets;
            </li>
            <li>
              elimination of personal data processed with the consent of the holder, except in the cases provided for in
              Article 16 of the law;
            </li>
            <li>
              information of any public and private entities with which the controller has made shared use of data;
            </li>
            <li>information on the possibility of not providing consent and on the consequences of refusal;</li>
            <li>revocation of consent, pursuant to paragraph 5 of Article 8 of the law.</li>
          </ol>
          <p>
            A Brazilian Data Protection Authority, Brazilian National Data Protection Authority (
            <i>Autoridade Nacional de Proteção de Dados</i> or “ANPD”) has been established to provide forthcoming rules
            and guidance on how to interpret and implement the LGPD’s requirements. Pending such developments, Veeva’s
            LGPD approach may be subject to further change.
          </p>
        </li>
      </ol>
    </LayoutSingleCol>
  )
}
