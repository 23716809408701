type AddIconProps = {
  fill: string
}

const AddIcon = ({ fill }: AddIconProps) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.136 5.256H11.672V7.824H7.136V12.336H4.568V7.824H0.032V5.256H4.568V0.696H7.136V5.256Z" fill={fill} />
    </svg>
  )
}

export default AddIcon
