import React, { useRef, useState } from 'react'
import { Button, Dropdown, Form, Modal } from 'react-bootstrap'
import FormError from '~/common/FormError'
import { WISTIA_REGEX, WISTIA_REGEX_ALT } from '~/utils'
import '@css/pages/posts/VideoURLUpload.scss'
import { useMediaUpload } from '~/contexts/MediaUploadContext'
import { KebabToggle } from '~/common/KebabToggle'
import { useAuth } from '~/auth/Auth'
import { Maybe } from '~/api/generated/graphql'

type VideoURLUploadProps = {
  initialURL: Maybe<string>
  setVideoURL: (url: string | null) => void
  className?: string
  isMainTarget?: boolean
  onDelete?: () => void
}

const VideoURLUpload = ({ initialURL, setVideoURL, className, isMainTarget, onDelete }: VideoURLUploadProps) => {
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const [showVideURLError, setShowVideoURLError] = useState<boolean>(false)
  const videoURLInput = useRef<HTMLInputElement>(null)
  const { mediaUrl, removeMedia } = useMediaUpload()
  const { isVeevan } = useAuth()

  const handleAddVideo = () => {
    if (videoURLInput.current?.value.match(WISTIA_REGEX) || videoURLInput.current?.value.match(WISTIA_REGEX_ALT)) {
      setShowVideoModal(false)
      removeMedia()
      setVideoURL(videoURLInput.current?.value ?? '')
      return
    }
    setShowVideoURLError(true)
  }

  const handleVideoModalClose = () => {
    setShowVideoURLError(false)
    setVideoURL(null)
    setShowVideoModal(false)
  }

  const showWarning = (showVideoModal && initialURL) || (isMainTarget && mediaUrl)

  return (
    <>
      <Dropdown className={`video-dropdown ${className}`}>
        <Dropdown.Toggle as={KebabToggle} />
        <Dropdown.Menu>
          {isVeevan && (
            <Dropdown.Item
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setShowVideoModal(true)
              }}
            >
              Set online video URL
            </Dropdown.Item>
          )}
          {!!onDelete && (
            <Dropdown.Item
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                onDelete()
              }}
            >
              Remove placeholder
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Modal id={'video-upload-modal'} show={showVideoModal}>
        <Modal.Header closeButton onHide={handleVideoModalClose}>
          <Modal.Title>Set Online Video URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You can add a URL from Wistia</p>
          {/*without showVideoModal check, warning briefly displays when first adding video due to animation delays*/}
          {showWarning && <p className={'warning'}>This will replace your existing media</p>}
          <Form.Control
            placeholder={'Add Wistia URL'}
            ref={videoURLInput}
            defaultValue={initialURL ?? undefined}
            onChange={() => setShowVideoURLError(false)}
            data-testid={'video-upload-input'}
            maxLength={150}
          />
          {showVideURLError && <FormError message={'Please enter a valid Wistia URL'} />}
          <div className={'examples'}>
            Example Wistia URLs:
            <p className={'no-margin'}>https://fast.wistia.com/embed/medias/[...].jsonp</p>
            <p>https://veevasystems.wistia.com/medias/[...]</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleVideoModalClose}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleAddVideo}>
            Add Video
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VideoURLUpload
