import React, { FocusEvent, SyntheticEvent, useRef, useState } from 'react'
import { Suggestion } from '~/common/quill/QuillEditor'
import { useApolloClient } from '@apollo/client'
import CommunitySuggestionService from '~/common/CommunitySuggestionService'
import '@css/common/AddPersonBox.scss'
import { FacetResponse } from '~/pages/search/AdvancedSearch'
import { CommunityFacet } from '~/pages/search/AdvancedSearchSidebar'
import { FacetSuggestion } from '~/pages/search/FacetSuggestion'
import { Form } from 'react-bootstrap'

type AddCommunityBoxProps = {
  searchText: string
  placeholder: string
  isCondensed: boolean
  selectedCommunities: CommunityFacet[]
  responseFacets: FacetResponse[]
  onTextChange: (inputText: string) => void
  onAddCommunity: (communityFacet?: CommunityFacet, e?: SyntheticEvent) => void
}

const AddCommunityBox = ({
  searchText,
  placeholder,
  isCondensed,
  selectedCommunities,
  responseFacets,
  onTextChange,
  onAddCommunity,
}: AddCommunityBoxProps) => {
  const client = useApolloClient()
  const [matchedCommunities, setMatchedCommunities] = useState<Suggestion[]>([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const getSuggestions = (search: string) => {
    return CommunitySuggestionService.getSuggestions(
      search,
      client.cache,
      new Set(selectedCommunities.map(c => c.communityId)),
      false
    )
  }

  const handleAddCommunityTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    setMatchedCommunities(getSuggestions(search))
    onTextChange(search)
  }

  const getSuggestionList = () => {
    // Combine matched communities with facet counts from the server response, then order by highest count
    const suggestionsWithCounts = matchedCommunities
      .map(s => ({
        ...s,
        count: responseFacets?.find(f => f.key === s.community?.communityId)?.count ?? 0,
      }))
      .sort((a, b) => {
        return b.count - a.count
      })

    return suggestionsWithCounts.map((s, i) => {
      return (
        <div key={s.id}>
          <FacetSuggestion
            index={i}
            suggestion={s}
            selectedSuggestion={selectedSuggestion}
            selectSuggestion={e => selectSuggestion(s, e)}
          />
        </div>
      )
    })
  }

  const handleInputKeyPresses = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && matchedCommunities.length > 0) {
      selectSuggestion(matchedCommunities[selectedSuggestion], e)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedSuggestion(Math.min(selectedSuggestion + 1, matchedCommunities.length - 1))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedSuggestion(Math.max(selectedSuggestion - 1, 0))
    }
  }

  const selectSuggestion = (suggestion: Suggestion, e: SyntheticEvent) => {
    onTextChange('')
    setMatchedCommunities([])
    onAddCommunity(suggestion.community as CommunityFacet, e)
  }

  const handleBlur = (e: FocusEvent) => {
    // Don't blur if child elements (community list items) are focused
    if (!e.currentTarget?.contains(e.relatedTarget)) {
      setMatchedCommunities([])
    }
  }

  const handleFocus = () => {
    setMatchedCommunities(getSuggestions(searchText))
  }

  return (
    <div className={`add-community ${isCondensed ? 'condensed' : ''}`} data-testid={'add-community-facet'}>
      <div className="input-container" onBlur={handleBlur}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          onChange={handleAddCommunityTextChanged}
          value={searchText}
          onKeyDown={handleInputKeyPresses}
          role="input"
          onFocus={handleFocus}
          ref={inputRef}
          data-testid={'add-community-input'}
        />
        {matchedCommunities.length > 0 && (
          <div className="suggestion-list">
            <ul>{getSuggestionList()}</ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddCommunityBox
