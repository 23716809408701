import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useCommunity } from '~/contexts/CommunityContext'
import '@css/pages/company/CompanyAccountPartners.scss'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import AccountPartnerJoin from '~/pages/company/AccountPartnerJoin'
import { Alert, Form, Modal } from 'react-bootstrap'
import { useAuth } from '~/auth/Auth'
import { searchCache, sortMembers, updateLeadersCache } from '~/utils'
import {
  AddLeaderByEmailDocument,
  AddLeaderDocument,
  ChangeMemberTypeDocument,
  GetCommunityLeadersDocument,
  GetCompanyRolesDocument,
  Membership,
} from '~/api/generated/graphql'
import { useStickyState } from '~/common/hooks/useStickyState'
import searchIcon from '@web/images/community/icon-search.svg'
import searchClear from '@web/images/community/search-clear.svg'
import { ChangeMemberTypeParams, CompanyMemberRow, getLeads } from '~/pages/company/CompanyMemberRow'
import AddMembershipBox from '~/common/addPerson/AddMembershipBox'
import { elementClicked } from '~/common/EventLogger'
import { useMutation, useQuery } from '@apollo/client'

const CompanyAccountPartners = () => {
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md
  const isMedium = isCondensed && SizeBreakpoint.sm < breakpoint
  const isNotXLarge = breakpoint <= SizeBreakpoint.lg
  const { loading: loadingCommunity, error, communityId, isVeeva, companyId } = useCommunity()
  const { isVeevan, profileVisible } = useAuth()
  const ACCOUNT_PARTNERS_SEARCH_KEY = `${communityId}PartnersSearchKey`
  const [searchText, setSearchText] = useStickyState('', ACCOUNT_PARTNERS_SEARCH_KEY)
  const showSearchClear = searchText !== ''
  const [addEmailText, setAddEmailText] = useState<string>('')
  const showAddButton = profileVisible && isVeevan
  const [modalText, setModalText] = useState<string | null>(null)

  const { data: leadersData, loading: leadersLoading } = useQuery(GetCommunityLeadersDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
  })
  const loading = loadingCommunity || leadersLoading

  const { data: roleData } = useQuery(GetCompanyRolesDocument, {
    variables: { companyId: companyId ?? '' },
    skip: !companyId,
  })
  const roles = useMemo(() => {
    const rolesMap = new Map<string, string | undefined>()
    for (const role of roleData?.company?.roles?.edges ?? []) {
      if (role?.node?.userId) {
        rolesMap.set(role?.node?.userId, role?.node?.description ?? undefined)
      }
    }
    return rolesMap
  }, [roleData])

  const accountPartners = useMemo(() => {
    return leadersData?.community?.members?.edges
      .map(e => e?.node)
      .filter(n => !n?.user?.hidden)
      .filter(Boolean) as Membership[] | undefined
  }, [leadersData?.community?.members])

  const partnersToDisplay = useMemo(() => {
    if (accountPartners && searchText) {
      const matchedUserIds = new Set(
        Array.from(searchCache(searchText).values())
          .map(k => k.split(':'))
          .filter(e => e[0] === 'User')
          .map(m => m[1])
      )
      return accountPartners?.filter(m => matchedUserIds.has(m.userId || ''))
    }
    return sortMembers(accountPartners ?? [])
  }, [accountPartners, searchText])

  const leads = useMemo(() => getLeads(partnersToDisplay), [partnersToDisplay])
  const clearSearch = () => {
    setSearchText('')
  }

  const handleSearchTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== undefined) {
        setSearchText(e.target.value)
      }
    },
    [setSearchText]
  )

  const [addLeaderByEmail] = useMutation(AddLeaderByEmailDocument, {
    update: (cache, { data: addLeader }) => {
      updateLeadersCache(cache, addLeader?.addLeaderByEmail?.data ?? null)
    },
  })

  const [addLeader] = useMutation(AddLeaderDocument, {
    update: (cache, { data: addLeader }) => {
      updateLeadersCache(cache, addLeader?.addLeader?.data ?? null)
    },
  })

  const handleAddMemberTextChanged = (email: string) => {
    setAddEmailText(email)
  }

  const handleAddMemberClicked = async (userId?: string, e?: SyntheticEvent) => {
    elementClicked(e as SyntheticEvent, 'click-community-partner-add-email', { communityId: communityId })
    if (userId) {
      try {
        const response = await addLeader({
          variables: {
            communityId: communityId ?? '',
            userId: userId,
          },
        })
        if (response.data?.addLeader?.ok) {
          setModalText('The account partner was added successfully')
          setAddEmailText('')
        } else if (response.data?.addLeader?.error) {
          switch (response.data.addLeader?.error.code) {
            case 'alreadyALeader':
              setModalText('This user is already an account partner.')
              setAddEmailText('')
              break
            case 'ineligibleToLead':
              setModalText('This user cannot be added as an account partner')
              setAddEmailText('')
              break
            case 'hiddenUser':
            case 'ineligibleMember':
              setModalText('This user cannot be added as an account partner.')
              return
            default:
              setModalText('Something went wrong. Please try again.')
              break
          }
        }
      } catch {
        setModalText('Something went wrong. Please try again.')
      }
    } else {
      try {
        const response = await addLeaderByEmail({
          variables: {
            communityId: communityId ?? '',
            email: addEmailText ?? '',
          },
        })
        if (response.data?.addLeaderByEmail?.ok) {
          setModalText('The account partner was added successfully')
          setAddEmailText('')
        } else if (response.data?.addLeaderByEmail?.error) {
          switch (response.data.addLeaderByEmail?.error.code) {
            case 'alreadyAMember':
              setModalText('You entered an email address for someone who is already a member.')
              setAddEmailText('')
              break
            case 'alreadyALeader':
              setModalText('You entered an email address for someone who is already an account partner.')
              setAddEmailText('')
              break
            case 'ineligibleToLead':
            case 'hiddenUser':
            case 'ineligibleMember':
              setModalText('This user cannot be added as an account partner')
              setAddEmailText('')
              break
            case 'unregisteredVeevan':
              setModalText('Unregistered users cannot be added as account partners')
              setAddEmailText('')
              break
            case 'badEmail':
              setModalText('The email address you entered is not a valid format.')
              setAddEmailText('')
              break
            case 'noOrgwikiUser':
              setModalText("You entered a Veeva email address that doesn't match anyone in OrgWiki.")
              break
            case 'blockedSubdomain':
            case 'notACustomer':
            case 'publicDomain':
              setModalText('The email address you entered is from a domain that is not authorized in Veeva Connect')
              setAddEmailText('')
              break
            default:
              setModalText('Something went wrong. Please try again.')
              break
          }
        }
      } catch {
        setModalText('Something went wrong. Please try again.')
      }
    }
  }

  const [changeMemberType] = useMutation(ChangeMemberTypeDocument)
  const handleChangeMemberType = useCallback(
    ({ userId, leader, accountLead, commercialLead, rdqLead }: ChangeMemberTypeParams) => {
      return changeMemberType({
        variables: {
          userId,
          communityId: communityId ?? '',
          leader,
          accountLead,
          commercialLead,
          rdqLead,
        },
      }).then(() => {})
    },
    [changeMemberType, communityId]
  )

  if (error) return <Alert variant="danger">{error?.message}</Alert>
  if (!leadersData && loading) return <div>Loading ...</div>

  const addButton = (
    <AddMembershipBox
      searchText={addEmailText}
      placeholder={'Enter email address or name'}
      onTextChange={handleAddMemberTextChanged}
      onSubmit={handleAddMemberClicked}
      veevansOnly={true}
      communityId={communityId || ''}
      leaders={true}
      data-testid={'add-person-box'}
    />
  )

  return (
    <div className={'account-partners-container'}>
      <h3 className={'comm-title'}>Account Partners</h3>
      <AccountPartnerJoin />
      <div className={`table-list`} role="list">
        <div className={`top-actions top-actions-flex-row ${isVeeva && 'right-align'}`}>
          {showAddButton && addButton}
        </div>
        <div className="top-actions">
          <div className="filtering">
            <div className={`searchbar ${isCondensed ? 'condensed-searchbar' : ''} ${isMedium ? 'medium' : ''}`}>
              <img className="search-icon" src={searchIcon} alt={'Search'} />
              <Form.Control
                type="text"
                placeholder="Search Members"
                value={searchText}
                onChange={handleSearchTextChanged}
                role="searchBar"
                bsPrefix={' '}
              />
              {showSearchClear && (
                <img className={'search-clear'} src={searchClear} alt={'Clear'} title={'Clear'} onClick={clearSearch} />
              )}
            </div>
          </div>
        </div>
        {!isCondensed && (
          <div className="table-title account-partners">
            {!isNotXLarge && (
              <>
                {isVeeva ? (
                  <>
                    <span className={`semi-wide-column`}>Name & Job Title</span>
                  </>
                ) : (
                  <>
                    <span className={`semi-wide-column`}>Name & Job Title</span>
                    <span className="company-type-column">Type</span>
                    <span className="wide-column">Role</span>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {partnersToDisplay && partnersToDisplay.length > 0 ? (
          <div className={'members-list'}>
            {partnersToDisplay.map(accountPartner => (
              <CompanyMemberRow
                key={accountPartner?.userId}
                member={accountPartner}
                onChangeMemberType={handleChangeMemberType}
                isVeeva={isVeeva}
                leads={leads}
                setParentModalText={setModalText}
                isAccountPartner={true}
                role={roles.get(accountPartner.userId ?? '')}
              />
            ))}
          </div>
        ) : (
          <>{!loading && <div>No results found</div>}</>
        )}
      </div>
      <Modal show={!!modalText} onHide={() => setModalText(null)}>
        <Modal.Header closeButton>
          <Modal.Body>
            <p>{modalText}</p>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </div>
  )
}

export default CompanyAccountPartners
