import React, { SyntheticEvent } from 'react'
import { Button } from 'react-bootstrap'
import timeAgoIcon from '@web/images/profile/time-ago-icon.svg'
import { getGlobalLeadList, toTimeAgo } from '~/utils'
import pencilIcon from '@web/images/profile/pencil-icon.svg'
import { Maybe } from 'graphql/jsutils/Maybe'
import '@css/pages/profile/ProfileEditHeader.scss'
import { Link } from 'react-router-dom'
import Globe from '@web/images/community/globe-icon.svg'
import Warning from '@web/images/community/error-alert.svg'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { elementClicked } from '~/common/EventLogger'
import { useAuth } from '~/auth/Auth'
import { ProfileEditHeaderUserDocument } from '~/api/generated/graphql'
import { useProfile } from '~/contexts/ProfileContext'
import { useQuery } from '@apollo/client'

type ProfileEditHeaderProps = {
  userId?: string
  editing: boolean
  headerText: Maybe<string>
  clickCancel: () => void
  clickSave: () => void
  modifiedTime?: Date
  canEdit: boolean
  setEditing: (editing: boolean) => void
  isRole?: boolean
  isGlobalLead?: boolean
  member?: { rdqLead: boolean; commercialLead: boolean; accountLead: boolean }
  profileName?: string
  companyId?: string
  companyName?: string
}

const ProfileEditHeader = ({
  userId,
  editing,
  headerText,
  clickCancel,
  clickSave,
  modifiedTime,
  canEdit,
  setEditing,
  isRole,
  isGlobalLead,
  member,
  profileName,
  companyId,
  companyName,
}: ProfileEditHeaderProps) => {
  const { authUserId, isVeevan } = useAuth()
  const editRef = useClickOnEnter<HTMLImageElement>()
  const globeTitle = getGlobalLeadList(member?.accountLead, member?.commercialLead, member?.rdqLead)
  const isSelf = authUserId === userId
  const { updateEditingRoles } = useProfile()

  const { data: userData, loading: userLoading } = useQuery(ProfileEditHeaderUserDocument, {
    variables: { id: userId ?? '' },
    skip: !userId,
  })

  const fullName = `${userData?.user?.firstName} ${userData?.user?.lastName}`
  const headerTextUpdated = isRole ? (
    <h1>
      {profileName}'s Role at{' '}
      {isVeevan ? (
        <Link to={`/companies/${companyId}`} onClick={e => elementClicked(e, 'click-profile-community')}>
          {companyName}
        </Link>
      ) : (
        <span>{companyName}</span>
      )}
    </h1>
  ) : (
    <h1>{headerText}</h1>
  )

  const handleClickEdit = (e: SyntheticEvent) => {
    setEditing(true)
    elementClicked(e, isRole ? 'click-profile-company-edit' : 'click-profile-about-edit', {
      userId,
    })
    updateEditingRoles?.(companyId ?? 'about', true)
  }

  if (userLoading && !userData) return null

  return editing ? (
    <div className="heading-section">
      <div className={'header-information'}>
        <div className={'title-with-globe'}>
          {headerTextUpdated}
          {isGlobalLead && (
            <div className={'globe-container'}>
              <img className="globe" src={Globe} alt={'Global'} />
              <span className="globe-tooltip">{globeTitle}</span>
            </div>
          )}
        </div>
        {!isSelf && (
          <div className={'edit-warning'}>
            <img className="warning-icon" src={Warning} alt={'Warning'} />
            <span className="warning-message">{fullName} will be notified when changes are saved.</span>
          </div>
        )}
      </div>
      <div className="edit-section">
        <Button variant={'light'} size={'sm'} onClick={clickCancel} role="cancel-edit">
          Cancel
        </Button>
        <Button variant={'primary'} size={'sm'} onClick={clickSave} role="save-edit">
          Save
        </Button>
      </div>
    </div>
  ) : (
    <div className="heading-section">
      <div className={'header-information'}>
        <div className={'title-with-globe'}>
          {headerTextUpdated}
          {isGlobalLead && (
            <div className={'globe-container'}>
              <img className="globe" src={Globe} alt={'Global'} />
              <span className="globe-tooltip">{globeTitle}</span>
            </div>
          )}
        </div>
        <div className={'modified-time'}>
          <img src={timeAgoIcon} alt="Modified Time" />
          {/* The only time there is no modified time is very brief-- when the editable text has just updated.*/}
          <span>{modifiedTime ? toTimeAgo(modifiedTime) : '0 seconds ago'}</span>
        </div>
      </div>
      <div className="edit-section">
        {canEdit && (
          <img
            className="edit-button"
            alt="Edit"
            src={pencilIcon}
            onClick={handleClickEdit}
            ref={editRef}
            tabIndex={0}
            role="edit-button"
          />
        )}
      </div>
    </div>
  )
}

export default ProfileEditHeader
