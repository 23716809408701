import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from '~/auth/Auth'
import { Button, Form } from 'react-bootstrap'
import UnauthLayout from '~/common/UnauthLayout'
import { Navigate, useLocation, useNavigate } from 'react-router'
import '@css/pages/Login.scss'
import { Link } from 'react-router-dom'
import { getEmailParts } from '~/utils'
import { AuthServerResponse, AuthStatus } from '~/api/types'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { RegistrationPage } from '~/pages/RegistrationPage'
import { PreUserModel } from '~/types'
import { elementClicked } from '~/common/EventLogger'
import { GetLastKnownUserDocument } from '~/api/generated/graphql'
import { useQuery } from '@apollo/client'
import { useStickyState } from '~/common/hooks/useStickyState'

export const Login = ({
  showCompleteProfile,
  initialAuthStatus,
}: {
  showCompleteProfile?: boolean
  initialAuthStatus?: AuthStatus
}) => {
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint < SizeBreakpoint.md
  const { signIn, verifyCode, authUserId, loading, signOut } = useAuth()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const codeInputRef = useRef<HTMLInputElement>(null)

  const { data: lastKnownUserData } = useQuery(GetLastKnownUserDocument, {
    fetchPolicy: 'cache-only',
    skip: !globalThis.lastKnownUserId,
    variables: {
      id: globalThis.lastKnownUserId ?? '',
    },
  })

  const lastKnownUser = {
    email: lastKnownUserData?.user?.email ?? '',
    firstName: lastKnownUserData?.user?.firstName ?? '',
  }
  const lastKnownUserExists = !!lastKnownUserData?.user
  const LOGIN_EMAIL = 'login-email'
  const AUTH_STATUS = 'auth-status'

  const [authStatus, setAuthStatus] = useStickyState(initialAuthStatus || AuthStatus.initial, AUTH_STATUS)
  const [email, setEmail] = useStickyState(lastKnownUser.email, LOGIN_EMAIL)
  const [showMalformedError, setShowMalformedError] = useState<boolean>(false)
  const [code, setCode] = useState<string | null>(null)
  const [verifyDisabled, disableVerify] = useState<boolean>(false)
  const [destination] = useState<string>((useLocation().state as string) || '/')
  const [verifyDelayText, setVerifyDelayText] = useState<string | null>(null)
  const [hasResentEmail, setHasResentEmail] = useState<boolean>(false)
  const [resendDisabled, disableResend] = useState<boolean>(false)
  const [resendDelayText, setResendDelayText] = useState<string | undefined>()
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false)
  const [preUser, setPreUser] = useState<PreUserModel>()
  const navigate = useNavigate()

  useEffect(() => {
    if (lastKnownUser.email) {
      setEmail(lastKnownUser.email)
    }
  }, [lastKnownUser.email, setEmail])

  useEffect(() => {
    if (initialAuthStatus) setAuthStatus(initialAuthStatus)
  }, [initialAuthStatus, setAuthStatus])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [authStatus])

  const linkStyle = {
    color: 'var(--color-veeva-blue)',
  }

  const emailDomain = useMemo(() => {
    return getEmailParts(email)?.domain
  }, [email])

  useEffect(() => {
    const listener = (e: { preventDefault: () => void }) => e.preventDefault()
    const node = codeInputRef.current
    node?.addEventListener('wheel', listener)
    return () => node?.removeEventListener('wheel', listener)
  }, [])

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    } else if (codeInputRef.current) {
      codeInputRef.current.focus()
    }
  }, [authStatus])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => setEmail(event.target.value),
    [setEmail]
  )
  const handleConnectClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (!emailDisabled && email) {
      setEmailDisabled(true)
      signIn(email, signinCallback)
    }
  }
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter' && !emailDisabled) {
      elementClicked(event, 'click-login.connect.enter')
      const email = (event.target as HTMLInputElement).value
      if (email) {
        setEmailDisabled(true)
        signIn(email, signinCallback)
      }
    } else {
      setShowMalformedError(false)
    }
  }

  const handleCodeKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      elementClicked(event, 'click-login.verify.enter')
      const code = (event.target as HTMLInputElement).value
      if (code) handleVerifyClick()
    }
  }

  const signinCallback = (resp: AuthServerResponse) => {
    setEmailDisabled(false)
    setAuthStatus(resp.authStatus)
    if (resp.authStatus === AuthStatus.emailMalformed) {
      setShowMalformedError(true)
    }
    if (resp.authStatus === AuthStatus.emailAccepted && resp.resendDelay && resp.resendDelay > 0) {
      setResendDelayText(resp.resendDelayText)
      disableResend(true)
      setTimeout(() => disableResend(false), resp.resendDelay)
    } else {
      if (resp.authStatus !== AuthStatus.emailAccepted) {
        navigate('/login/bad')
      }
      disableResend(false)
    }
  }

  const handleVerifyClick = () => {
    if (email && code) {
      disableVerify(true)
      const numbers = code.match(/\d/g)
      if (numbers) {
        const numCode = numbers.join('')
        if (numCode.length == 6) {
          verifyCode(email, code, handleVerification)
          return
        }
      }
    }
    setAuthStatus(AuthStatus.codeMalformed)
    disableVerify(false)
  }

  const handleVerification = (resp: AuthServerResponse) => {
    setAuthStatus(resp.authStatus)
    if (resp.authStatus === AuthStatus.codeRejected && resp.verifyDelay && resp.verifyDelay > 0) {
      setVerifyDelayText(resp.verifyDelayText || null)
      disableVerify(true)
      setTimeout(() => disableVerify(false), resp.verifyDelay)
    } else if (resp.authStatus === AuthStatus.codeVerified) {
      disableVerify(true)
    } else if (resp.authStatus === AuthStatus.registrationReady) {
      setPreUser(resp.preUser)
      disableVerify(true)
      navigate('/login/complete')
    } else disableVerify(false)
  }

  const handleSetCode = (code: string) => {
    setAuthStatus(AuthStatus.emailAccepted)
    const value = code.slice(0, 6)
    if (codeInputRef.current) codeInputRef.current.value = value
    setCode(value)
  }

  // If we are showing a personalized login screen but a different user wants to sign in, we can call signOut()
  // to remove the cookie and reroute them to the default login page.
  const defaultSignIn = (e: SyntheticEvent) => {
    signOut(() => (location.href = '/login'), e)
  }

  if (loading) {
    return null
  }

  if (authUserId) {
    return <Navigate to={destination} state={{ fromLoginRedirect: true }} replace />
  }

  const handleResendEmail = () => {
    setHasResentEmail(true)
    if (email) signIn(email, signinCallback)
  }
  return (
    <>
      {authStatus === AuthStatus.registrationReady || (showCompleteProfile && authStatus !== AuthStatus.error) ? (
        <RegistrationPage
          preUser={preUser}
          showCompleteProfile={showCompleteProfile}
          setAuthStatus={setAuthStatus}
        ></RegistrationPage>
      ) : (
        <UnauthLayout className={'login'} xs={12} sm={12}>
          <div className={'dialog'}>
            <div className={'dialog-content'}>
              <div className={'dialog-head'} />
              {[AuthStatus.initial, AuthStatus.emailMalformed].includes(authStatus) && (
                <>
                  <div className={'dialog-heading'}>
                    {lastKnownUserExists ? `Welcome back, ${lastKnownUser.firstName}!` : 'Get Connected:'}
                  </div>
                  <p className="dialog-subheading">
                    {lastKnownUserExists
                      ? "Let's get you signed back into Veeva Connect."
                      : 'Welcome to the Veeva customer community.'}
                  </p>
                  {lastKnownUserExists ? (
                    <p>
                      Not {lastKnownUser.firstName}?{' '}
                      <span className={'link'} onClick={defaultSignIn}>
                        Click here to sign in
                      </span>
                    </p>
                  ) : (
                    <p>
                      Connect with fellow customers and colleagues from Veeva. Get answers, offer ideas and advice, and
                      hear the latest news about Veeva products and processes.
                    </p>
                  )}
                  <div className={'dialog-form'}>
                    <div className={'button-zone'}>
                      <Form.Control
                        className={'email-input'}
                        type="email"
                        id="email"
                        name="email"
                        ref={emailInputRef}
                        placeholder={'Enter your business email address'}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        defaultValue={email ?? ''}
                        data-testid={'email-input'}
                        bsPrefix={' '}
                      />
                      <Button
                        className={'connect-button'}
                        onClick={e => {
                          elementClicked(e, 'login-connect')
                          handleConnectClick(e)
                        }}
                        disabled={emailDisabled}
                      >
                        {'Connect'}
                      </Button>
                    </div>
                  </div>
                  {authStatus === AuthStatus.emailMalformed && showMalformedError && (
                    <p>
                      <strong className={'error'}>Please enter a valid email address</strong>
                    </p>
                  )}
                  <p className={'dialog-body-sm'}>
                    By signing in, you agree to the Veeva Connect{' '}
                    <Link to={'/terms'} style={linkStyle} target="_blank">
                      Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link to={'/privacy-policy'} style={linkStyle} target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </>
              )}
              {authStatus !== AuthStatus.initial && (
                <>
                  {[
                    AuthStatus.emailAccepted,
                    AuthStatus.codeMalformed,
                    AuthStatus.codeRejected,
                    AuthStatus.codeVerified,
                  ].includes(authStatus) && (
                    <>
                      <div className={'dialog-heading'}>Check your email</div>
                      <p className={'dialog-body'}>
                        We sent a 6-digit validation code to your email: <strong>{email}</strong>.
                      </p>
                      <p>
                        <strong>Enter the validation code below:</strong>
                      </p>
                      <div className={'dialog-form'}>
                        <div className={'button-zone'}>
                          <input
                            className={`code-input ${isCondensed ? 'condensed' : ''}`}
                            type={'number'}
                            id="code"
                            name="code"
                            ref={codeInputRef}
                            placeholder={'XXXXXX'}
                            onChange={value => handleSetCode(value.target.value)}
                            onKeyDown={handleCodeKeyDown}
                            role={'textbox'}
                          />
                          <Button
                            className={'connect-button'}
                            onClick={e => {
                              elementClicked(e, 'login-verify')
                              handleVerifyClick()
                            }}
                            disabled={verifyDisabled}
                          >
                            {'Verify'}
                          </Button>
                        </div>
                        {[AuthStatus.codeMalformed, AuthStatus.codeRejected].includes(authStatus) && (
                          <p>
                            <strong className={'error'}>
                              {authStatus === AuthStatus.codeMalformed
                                ? 'Verification code should be six digits'
                                : 'Code was invalid. Please re-check your email and try again.'}
                            </strong>
                            {verifyDisabled &&
                              ' Please wait ' + verifyDelayText + ' for the Verify button to be enabled.'}
                          </p>
                        )}
                        {[AuthStatus.emailAccepted, AuthStatus.codeVerified].includes(authStatus) && (
                          <>
                            <p className={'dialog-body-sm'}>
                              {hasResentEmail && <b>Email re-sent. </b>}
                              Still haven't received the code?
                              {resendDisabled ? (
                                <> You can request another re-send in {resendDelayText}.</>
                              ) : (
                                <a
                                  href={'#'}
                                  onClick={e => {
                                    elementClicked(e, 'login-resend')
                                    handleResendEmail()
                                  }}
                                  style={linkStyle}
                                >
                                  {' Resend email.'}
                                </a>
                              )}
                            </p>
                          </>
                        )}
                      </div>
                      <p className={'dialog-body-sm'}>
                        By signing in, you agree to the Veeva Connect{' '}
                        <Link to={'/terms'} style={linkStyle} target="_blank">
                          Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link to={'/privacy-policy'} style={linkStyle} target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.unknownEmail && (
                    <>
                      <p className="dialog-subheading">
                        We don't recognize <strong>{email}</strong>.
                      </p>
                      <p className="error-message">The page for creating a new user is coming soon.</p>
                      <p className={'dialog-body-sm'}>
                        Or, you can{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.unknownEmail')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.publicDomain && (
                    <>
                      <div className="error-message">
                        <p className="error-heading">Please enter a Veeva customer email address</p>
                        <p className="error-body" data-testid="public-domain-error">
                          The domain <span className={'error-domain'}>{emailDomain}</span> is not recognized as a work
                          address. If you believe you got this message in error, please contact your Veeva account
                          partner, customer success manager, or veevaconnect@veeva.com for assistance.
                        </p>
                      </div>
                      <p className={'dialog-body-sm'}>
                        Please{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.publicDomain')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again with your work email.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.partnerDomain && (
                    <>
                      <div className="error-message">
                        <p className="error-heading">Please enter a Veeva customer email address</p>
                        <p className="error-body" data-testid="partner-domain-error">
                          The domain <span className={'error-domain'}>{emailDomain}</span> is recognized as a Veeva
                          partner. Veeva partners are not included in Veeva Connect at this time. If you believe you got
                          this message in error, please contact your Veeva account partner, customer success manager, or
                          veevaconnect@veeva.com for assistance.
                        </p>
                      </div>
                      <p className={'dialog-body-sm'}>
                        Please{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.partnerDomain')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again with your Veeva Customer email.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.competitorDomain && (
                    <>
                      <div className="error-message">
                        <p className="error-heading">
                          The domain <span className={'error-domain'}>{emailDomain}</span> is not authorized
                        </p>
                        <p className="error-body">
                          Veeva Connect is for the use of Veeva customer employees only. Use of Veeva Connect for other
                          purposes is not authorized by the terms and conditions of Veeva Connect.
                        </p>
                      </div>
                    </>
                  )}
                  {authStatus === AuthStatus.blockedSubdomain && (
                    <>
                      <p className="error-message">
                        You entered an email address with a sub-domain that is not authorized. Please contact your
                        account team for assistance accessing Veeva Connect.
                      </p>
                      <p className={'dialog-body-sm'}>
                        Please{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.blockedSubdomain')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again with your Veeva Customer email.
                      </p>
                    </>
                  )}

                  {authStatus === AuthStatus.unknownDomain && (
                    <>
                      <div className="error-message">
                        <p className="error-heading">Please enter a Veeva customer email address</p>
                        <p className="error-body" data-testid="unknown-domain-error">
                          The domain <span className={'error-domain'}>{emailDomain}</span> is not recognized as a
                          customer or prospective customer. If you believe you got this message in error, please contact
                          your Veeva account partner, customer success manager, or veevaconnect@veeva.com for
                          assistance.
                        </p>
                      </div>
                      <p className={'dialog-body-sm'}>
                        Please{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.unknownDomain')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again with your Veeva Customer email.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.tooManyResends && (
                    <>
                      <p className="error-message">You requested too many re-sends for the verification code.</p>
                      <p className={'dialog-body-sm'}>
                        Please click{' '}
                        <Link onClick={e => elementClicked(e, 'login-restart.tooManyResends')} to={destination}>
                          here
                        </Link>{' '}
                        to restart the login process.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.tooManyFailures && (
                    <>
                      <p className="error-message">You entered the wrong code too many times.</p>
                      <p className={'dialog-body-sm'}>
                        Please click{' '}
                        <Link onClick={e => elementClicked(e, 'login-restart.tooManyFailures')} to={destination}>
                          here
                        </Link>{' '}
                        to restart the login process.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.notInOrgwiki && (
                    <>
                      <p className="error-message">
                        Sorry, we couldn't find this user in OrgWiki. An active OrgWiki profile is required to access
                        Veeva Connect.
                      </p>
                      <p className={'dialog-body-sm'}>Contact veevaconnect@veeva.com</p>
                      <p className={'dialog-body-sm'}>
                        Or, you can{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.notInOrgwiki')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        and try again.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.resendBeforeDelay && (
                    <>
                      <>
                        <p className="error-message">
                          You must wait before requesting a resend. Please try again shortly.
                        </p>
                        <p className={'dialog-body-sm'}>
                          Please click{' '}
                          <Link
                            onClick={e => {
                              elementClicked(e, 'login-restart.resendBeforeDelay')
                              setAuthStatus(AuthStatus.initial)
                            }}
                            to={destination}
                          >
                            here
                          </Link>{' '}
                          to go back and try again.
                        </p>
                      </>
                    </>
                  )}
                  {authStatus === AuthStatus.hiddenUser && (
                    <>
                      <p className="error-message">
                        The user you have attempted to log in as is no longer active in Veeva Connect.
                      </p>
                      <p className={'dialog-body-sm'}>
                        Please click{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart.hiddenUser')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          here
                        </Link>{' '}
                        to go back and try again.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.authExpired && (
                    <>
                      <p className="error-message">Sorry, but the code we sent you has expired.</p>
                      <p className={'dialog-body-sm'}>
                        Please{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={destination}
                        >
                          go back
                        </Link>{' '}
                        to the sign in page to request a new one.
                      </p>
                    </>
                  )}
                  {authStatus === AuthStatus.error && (
                    <>
                      <p className="error-message">An unknown error has occurred.</p>
                      <p className={'dialog-body-sm'}>
                        Please contact your Veeva account executive, customer success manager, or veevaconnect@veeva.com
                        for assistance.
                      </p>
                      <p className={'dialog-body-sm'}>
                        Or, you can{' '}
                        <Link
                          onClick={e => {
                            elementClicked(e, 'login-restart')
                            setAuthStatus(AuthStatus.initial)
                          }}
                          to={`${destination}`}
                        >
                          go back
                        </Link>{' '}
                        and try again.
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </UnauthLayout>
      )}
    </>
  )
}
