import React, { ReactNode, useContext, useEffect, useState } from 'react'

export type PageVisibilityContextType = {
  pageIsVisible: boolean
}
export const PageVisibilityContext = React.createContext<PageVisibilityContextType>({ pageIsVisible: true })

export const PageVisibilityProvider = ({ children }: { children: ReactNode }) => {
  const [pageIsVisible, setPageIsVisible] = useState(true)
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        setPageIsVisible(false)
      } else {
        setPageIsVisible(true)
      }
    }
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [setPageIsVisible])

  return <PageVisibilityContext.Provider value={{ pageIsVisible }}>{children}</PageVisibilityContext.Provider>
}

export const usePageVisibility = () => useContext(PageVisibilityContext)
