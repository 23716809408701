import { LocationModel } from '~/types'
import React from 'react'
import { getCountryFlag, getOfficeHomeLocation } from '~/utils'
import officePinIcon from '@web/images/profile/icon-wfh-office.svg'
import homeIcon from '@web/images/profile/icon-wfh-home.svg'

const getLocationString = (location: LocationModel) => {
  const { city, state, country, countryCode } = location

  let locationString = ''

  if (city && state) {
    locationString += `${city}, ${state}`
  } else if (city || state) {
    locationString += city || state
  }

  const flagCharacter = getCountryFlag(countryCode)

  let countrySection = <></>
  if (flagCharacter) {
    const spacer = locationString.length > 0 ? <>&nbsp;&nbsp;</> : <></>
    countrySection = (
      <>
        {spacer}
        <span className="flag" title={country}>
          {getCountryFlag(countryCode)}
        </span>
      </>
    )
  } else if (country) {
    countrySection = (
      <>
        {locationString.length > 0 ? ', ' : ''}
        {country}
      </>
    )
  } else if (locationString === '') {
    return ''
  }

  return (
    <>
      {locationString}
      {countrySection}
    </>
  )
}

export const LocationList = ({
  user,
  isVeevan,
}: {
  user?: { primaryLocation?: LocationModel; secondaryLocation?: LocationModel }
  isVeevan?: boolean
}) => {
  const locationList = (() => {
    if (isVeevan) {
      const { office, home } = getOfficeHomeLocation(user?.primaryLocation, user?.secondaryLocation)

      return home || office ? (
        <>
          {office && (
            <li>
              <img className="icon" alt="Office Location" src={officePinIcon} />
              {getLocationString(office)}
            </li>
          )}
          {home && (
            <li>
              <img className="icon" alt="Home Location" src={homeIcon} />
              {getLocationString(home)}
            </li>
          )}
        </>
      ) : null
    } else if (user?.primaryLocation) {
      const locationString = getLocationString(user.primaryLocation)
      if (locationString === '') return <></>
      else
        return (
          <li>
            <div className="icon office-pin" role="img" />
            {locationString}
          </li>
        )
    } else {
      return null
    }
  })()

  return locationList ? <ul className="user-locations">{locationList}</ul> : null
}
