import React, { useMemo } from 'react'
import { useProfile } from '~/contexts/ProfileContext'
import '@css/pages/profile/ProfileContent.scss'
import { useAuth } from '~/auth/Auth'
import { ProfileCommunityRow } from '~/pages/profile/ProfileCommunityRow'
import { ProfileEditableText } from '~/pages/profile/ProfileEditableText'
import { ProfileRole, ProfileRoleAtCompany } from '~/pages/profile/ProfileRole'
import { EditRoleDocument, EditUserDocument, Html, Maybe } from '~/api/generated/graphql'
import { CompanyModel } from '~/types'
import { ProfileActivityList } from '~/pages/profile/ProfileActivityList'
import ProfileHistory from '~/pages/profile/ProfileHistory'
import { MOBILE_HEIGHT_MAX, useWindowSize } from '~/common/hooks/useWindowSize'
import { useTitle } from '~/common/hooks/useTitle'
import { getFullName } from '~/utils'
import { useMutation } from '@apollo/client'

export const ProfileContent = ({ updated, setUpdated }: { updated: boolean; setUpdated: () => void }) => {
  const { windowSize, isCondensedPortrait } = useWindowSize()
  const { loading: profileLoading, user } = useProfile()
  const { authUserId, isVeevan } = useAuth()
  const [editUser] = useMutation(EditUserDocument)
  const [editRole] = useMutation(EditRoleDocument)

  const shouldCenterAlign = windowSize.width < MOBILE_HEIGHT_MAX
  const authUserIsUser = user && user.userId === authUserId
  const usersAreVeevans = (user?.isVeevan && isVeevan) ?? false
  const canEdit = authUserIsUser || usersAreVeevans

  const communitiesToDisplay = useMemo(
    () => user?.memberships?.filter(c => c.type != 'Company') ?? [],
    [user?.memberships]
  )
  const fullUserName = getFullName(user)
  useTitle(`${fullUserName ? `Profile - ${fullUserName} - ` : ''}Veeva Connect`)

  if (profileLoading || !user || user.hidden) return null

  const saveRole = (newRole: Maybe<Html>) => {
    setUpdated()
    return editUser({ variables: { user_id: user.userId, role_at_company: newRole?.html } })
  }

  const saveAbout = (newAbout: Maybe<Html>) => {
    setUpdated()
    return editUser({ variables: { user_id: user.userId, about_me: newAbout?.html } })
  }

  const saveCompanyRole = (company: CompanyModel, newRole: Maybe<string>) => {
    setUpdated()
    return editRole({ variables: { userId: user.userId, companyId: company.companyId, description: newRole } })
  }

  return (
    <div className={`profile-content ${shouldCenterAlign ? 'condensed' : ''}`}>
      <ProfileRoleAtCompany
        user={user}
        company={user.company}
        modifiedTime={user.roleModifiedTime}
        roleAtCompany={user.roleAtCompany}
        canEdit={canEdit}
        saveRole={saveRole}
        isOwnProfile={authUserIsUser}
      />
      {user.roles
        ?.filter(role => !role.company.isVeeva)
        .map(r => (
          <ProfileRole
            user={user}
            key={r.company.companyId}
            role={r}
            canEdit={canEdit}
            saveRole={newRole => saveCompanyRole(r.company, newRole)}
            isOwnProfile={authUserIsUser}
          />
        ))}
      <ProfileEditableText
        user={user}
        headerText={`About ${user.firstName}`}
        modifiedTime={user.aboutMeModifiedTime}
        isOwnProfile={authUserIsUser}
        content={user.aboutMe}
        canEdit={canEdit}
        placeHolder="Please share a bit about yourself"
        saveContent={saveAbout}
      />
      <div className="profile-community-list section">
        <h1>Communities</h1>
        <div className="list-container">
          {communitiesToDisplay.length > 0 ? (
            communitiesToDisplay.map(c => (
              <ProfileCommunityRow key={c.communityId} community={c} setUpdated={setUpdated} />
            ))
          ) : (
            <div className="section-empty">{user.firstName} has not joined any communities</div>
          )}
        </div>
      </div>
      <div className="recent-activity profile section">
        <h1>Recent Activity</h1>
        <ProfileActivityList />
      </div>
      {!isCondensedPortrait && <ProfileHistory updated={updated} />}
    </div>
  )
}
