import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '@web/js/auth/Auth'
import '@css/pages/posts/Likes.scss'
import {
  GetCommentLikesDocument,
  GetLikeUserPartsDocument,
  GetPostLikesDocument,
  SetCommentLikeDocument,
  SetPostLikeDocument,
} from '@web/js/api/generated/graphql'
import { Link } from 'react-router-dom'
import ToastComponent from '~/common/ToastComponent'
import { asGQL } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import { useMutation, useQuery } from '@apollo/client'

const UserLike = ({ likeId, useLinkStyling = true }: { likeId: string; useLinkStyling?: boolean }) => {
  const { data } = useQuery(GetLikeUserPartsDocument, { variables: { id: likeId } })
  const like = data?.user
  const name = `${like?.firstName} ${like?.lastName} (${like?.company?.name})`
  if (!like) return null

  return like.hidden ? (
    <span className={'like-link inactive'}>{name}</span>
  ) : (
    <Link className={useLinkStyling ? 'like-link' : 'like-list-item'} to={`/profiles/${like.userId}`}>
      {name}
    </Link>
  )
}

const LikeCount = ({ type, likes }: { type: string; likes: string[] }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const otherTextRef = useRef<HTMLElement>(null)

  useEffect(() => {
    // detect click outside to dismiss tooltip
    const handleClickOutside = (event: Event) => {
      if (!otherTextRef?.current?.contains(event.target as HTMLElement)) {
        setIsClicked(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { authUserId } = useAuth()
  if (!authUserId) return null
  const isLiked = likes.includes(authUserId)
  const filteredLikes = likes.filter(like => like !== authUserId)
  switch (likes.length) {
    case 0:
      return <span>Like this {type}</span>
    case 1:
      return (
        <span>
          {isLiked ? (
            'You liked this.'
          ) : (
            <>
              <UserLike likeId={filteredLikes[0]} /> liked this.
            </>
          )}
        </span>
      )
    case 2:
      return (
        <span>
          {isLiked ? (
            <>
              You and <UserLike likeId={filteredLikes[0]} /> liked this.
            </>
          ) : (
            <>
              <UserLike likeId={likes[0]} /> and <UserLike likeId={likes[1]} /> liked this.
            </>
          )}
        </span>
      )
    case 3:
      return (
        <span>
          {isLiked ? (
            <>
              You, <UserLike likeId={filteredLikes[0]} />, and <UserLike likeId={filteredLikes[1]} /> liked this.
            </>
          ) : (
            <>
              <UserLike likeId={likes[0]} />, <UserLike likeId={likes[1]} />, and <UserLike likeId={likes[2]} /> liked
              this.
            </>
          )}
        </span>
      )
    default: {
      const numOthers = likes.length - 3
      const othersText = `${numOthers} ${numOthers > 1 ? 'others ' : 'other '}`
      // noinspection RequiredAttributes
      return (
        <span>
          <span>
            {isLiked ? (
              <>
                You, <UserLike likeId={filteredLikes[0]} />, <UserLike likeId={filteredLikes[1]} />, and{' '}
              </>
            ) : (
              <>
                <UserLike likeId={likes[0]} />, <UserLike likeId={likes[1]} />, <UserLike likeId={likes[2]} />, and{' '}
              </>
            )}
          </span>
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            show={isClicked || isHovering}
            overlay={
              <Tooltip id="tooltip-bottom" className={'other-likes-tooltip'}>
                {(isLiked ? filteredLikes.slice(2) : likes.slice(3)).map(like => {
                  return like ? <UserLike key={like} likeId={like} useLinkStyling={false} /> : null
                })}
              </Tooltip>
            }
          >
            <span
              className={'link'}
              onClick={() => setIsClicked(!isClicked)}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              onFocus={() => setIsHovering(true)}
              onBlur={() => setIsHovering(false)}
              ref={otherTextRef}
              tabIndex={0}
            >
              {othersText}
            </span>
          </OverlayTrigger>
          <span>liked this.</span>
        </span>
      )
    }
  }
}

export const CommentLikes = ({ id, hidden = false }: { id: string; hidden?: boolean }) => {
  const { authUserId, isVeevan } = useAuth()
  const [setCommentLikeMutation] = useMutation(SetCommentLikeDocument)
  const [toast, setToast] = useState<string | null>(null)
  const { data, loading } = useQuery(GetCommentLikesDocument, { variables: { id } })
  const likes = useMemo(() => data?.comment?.likes?.edges ?? [], [data])
  const likeUsers = useMemo(() => likes.map(l => l?.node?.userId).filter(Boolean) as string[], [likes])

  const handleLikeClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: SyntheticEvent) => {
      elementClicked(e, 'click-comment-like', { commentId: id })
      try {
        const isLiked = likes.some(like => like?.node?.userId === authUserId)
        const newVeevanLikeCount = isVeevan
          ? (data?.comment?.veevanLikeCount ?? 0) + (isLiked ? -1 : 1)
          : data?.comment?.veevanLikeCount
        const newLikes = isLiked
          ? likes.filter(r => authUserId !== r?.node?.userId)
          : likes.concat([
              {
                node: {
                  userId: authUserId,
                  commentId: id,
                },
              },
            ])

        const comment = asGQL('Comment', {
          commentId: id,
          veevanLikeCount: newVeevanLikeCount,
          likeCount: newLikes.length,
          likes: newLikes.map(l => {
            return {
              userId: l?.node?.userId,
              commentId: id,
              user: asGQL('User', { ...l?.node }),
            }
          }),
        })
        void setCommentLikeMutation({
          variables: { id, value: !isLiked },
          optimisticResponse: {
            setCommentLike: {
              __typename: 'SetCommentLike' as const,
              comment,
            },
          },
        })
      } catch (error) {
        setToast('There was an error, please try again later.')
        console.error(error)
      }
    },
    [likes, authUserId, id, setCommentLikeMutation, data?.comment?.veevanLikeCount, isVeevan]
  )

  if (!authUserId || loading) return null
  return (
    <>
      <div className={`like-zone`}>
        <button
          className={`btn-plain ${likes.some(like => like?.node?.userId === authUserId) && 'liked'}`}
          onClick={handleLikeClick}
          disabled={hidden}
        >
          <div className={`btn-title`} />
          <span>{likes.some(like => like?.node?.userId === authUserId) ? 'Liked' : 'Like'}</span>
        </button>
        <LikeCount type="comment" likes={likeUsers} />
      </div>
      <ToastComponent show={!!toast} onClose={() => setToast(null)}>
        {toast ?? ''}
      </ToastComponent>
    </>
  )
}

export const PostLikes = ({ id, hidden = false }: { id: string; hidden?: boolean }) => {
  const { authUserId, isVeevan } = useAuth()
  const [setPostLikeMutation] = useMutation(SetPostLikeDocument)
  const [toast, setToast] = useState<string | null>(null)
  const { data, loading } = useQuery(GetPostLikesDocument, { variables: { id } })
  const likes = useMemo(() => data?.post?.likes?.edges ?? [], [data])
  const likeUsers = useMemo(() => likes.map(l => l?.node?.userId).filter(Boolean) as string[], [likes])

  const handleLikeClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: SyntheticEvent) => {
      elementClicked(e, 'click-post-like', { postId: id })
      try {
        const isLiked = likes.some(like => like?.node?.userId === authUserId)
        const newVeevanLikeCount = isVeevan
          ? (data?.post?.veevanLikeCount ?? 0) + (isLiked ? -1 : 1)
          : data?.post?.veevanLikeCount
        const newLikes = isLiked
          ? likes.filter(r => authUserId !== r?.node?.userId)
          : likes.concat([
              {
                node: {
                  userId: authUserId,
                  postId: id,
                },
              },
            ])

        const post = asGQL('Post', {
          postId: id,
          veevanLikeCount: newVeevanLikeCount,
          likeCount: newLikes.length,
          likes: newLikes.map(l => {
            return {
              userId: l?.node?.userId,
              postId: id,
              user: asGQL('User', { ...l?.node }),
            }
          }),
        })
        void setPostLikeMutation({
          variables: { id, value: !isLiked },
          optimisticResponse: {
            setPostLike: {
              __typename: 'SetPostLike' as const,
              post,
            },
          },
        })
      } catch (error) {
        setToast('There was an error, please try again later.')
        console.error(error)
      }
    },
    [likes, authUserId, id, setPostLikeMutation, data?.post?.veevanLikeCount, isVeevan]
  )

  if (!authUserId || loading) return null
  return (
    <>
      <div className={`like-zone`}>
        <button
          className={`btn-plain ${likes.some(like => like?.node?.userId === authUserId) && 'liked'}`}
          onClick={handleLikeClick}
          disabled={hidden}
        >
          <div className={`btn-title`} />
          <span>{likes.some(like => like?.node?.userId === authUserId) ? 'Liked' : 'Like'}</span>
        </button>
        <LikeCount type="post" likes={likeUsers} />
      </div>
      <ToastComponent show={!!toast} onClose={() => setToast(null)}>
        {toast ?? ''}
      </ToastComponent>
    </>
  )
}
