import ReactQuill from 'react-quill'
import Clipboard from 'quill/modules/clipboard'
import { Range } from 'quill/core/selection'

const Delta = ReactQuill.Quill.import('delta')

// Overrides default quill pasting behavior to scroll the editing area if needed
export class PlainClipboard extends Clipboard {
  onPaste(
    range: Range,
    {
      text,
      html,
    }: {
      text?: string
      html?: string
    }
  ) {
    super.onPaste(range, { text, html })
    const quill = this.quill
    const delta = new Delta().retain(range?.index ?? 0)
    const index = (text?.length ?? 0) + range?.index
    const length = 0

    const updateSelection = () => {
      try {
        quill.selection.update('silent')
        quill.updateContents(delta, 'silent')
        quill.setSelection(index, length, 'silent')
      } catch (error) {
        console.error(error)
      }
    }

    let timeout: NodeJS.Timeout
    void new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => resolve({ updateSelection }), 10)
    })
  }
}
