import React from 'react'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { AppHeader } from '~/common/AppHeader'
import { Footer } from '~/common/Footer'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'react-bootstrap'
import '@css/pages/page/PageLayout.scss'
import Page from '~/pages/page/Page'
import { PageType, GetReleasePageTypeDocument } from '~/api/generated/graphql'
import { SummitCategoryPostsProvider } from '~/contexts/SummitCategoryPostsContext'
import { useQuery } from '@apollo/client'
import { PromptProvider } from '~/contexts/PromptContext'

const PageLayout = ({ pageType }: { pageType: PageType }) => {
  const { isCondensed } = useWindowSize()
  const navigate = useNavigate()

  const { releaseId } = useParams<{ releaseId: string }>()
  const { data, loading } = useQuery(GetReleasePageTypeDocument, {
    variables: { id: releaseId ?? '' },
    skip: !releaseId,
  })

  if (!loading && data?.release?.section?.pageType != pageType) {
    navigate(`/${data?.release?.section?.pageType === PageType.Summit ? 'summits' : 'releases'}/${releaseId}`)
  }

  return (
    <SummitCategoryPostsProvider pageType={pageType} key={releaseId}>
      <div className={`one-column ${isCondensed ? 'condensed' : ''}`}>
        <AppHeader currentPage={pageType === PageType.Release ? 'releases' : 'summits'} />
        <div className={`releases-content-container ${isCondensed ? 'condensed' : ''}`}>
          <Container className={'content'}>
            <Row className={'content-zone'}>
              <Col sm={12} className={`content-area ${isCondensed ? 'condensed' : ''}`}>
                <div className={'release-container row'}>
                  <div className={'col-11 px-1 release-card'}>
                    <PromptProvider>
                      <Page id={releaseId} />
                    </PromptProvider>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </SummitCategoryPostsProvider>
  )
}

export default PageLayout
