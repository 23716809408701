import React, { useState } from 'react'
import UnauthLayout from '~/common/UnauthLayout'
import { VeevanReadonlyOption } from '~/pages/registration/VeevanReadonlyOption'
import CompleteProfile from '~/pages/registration/CompleteProfile'
import VeevanCompleteProfile from '~/pages/registration/VeevanCompleteProfile'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { PreUserModel } from '~/types'
import { useAuth } from '~/auth/Auth'
import { AuthRegistrationRequest, AuthStatus } from '~/api/types'
import { useLocation } from 'react-router'

interface RegistrationPageProps {
  preUser?: PreUserModel
  showCompleteProfile?: boolean
  setAuthStatus: (status: AuthStatus) => void
}

export const RegistrationPage = ({ preUser, showCompleteProfile, setAuthStatus }: RegistrationPageProps) => {
  const { registerPreUser } = useAuth()
  const { isCondensedPortrait } = useWindowSize()
  const isVeevan = preUser?.isVeevan
  const [page, setPage] = useState(isVeevan ? 1 : 2)
  const linkUrl = useLocation().state as string | undefined
  const showComplete = page === 2 || (page !== 3 && showCompleteProfile)

  const registerReadOnly = () => {
    const request = {
      email: preUser?.email ?? '',
      profileVisible: false,
      linkUrl,
    }
    void registerPreUser(request)
  }
  const registerUser = async (request: AuthRegistrationRequest) => {
    request.linkUrl = linkUrl
    await registerPreUser(request).then(r => {
      if (r.includes('Unauthorized')) {
        setAuthStatus(AuthStatus.authExpired)
      }
    })
  }

  return (
    <UnauthLayout className={'create-profile'} md={9} lg={8} xl={7} xxl={6}>
      <div className={`dialog${page === 3 ? ' veevan-complete' : ''}${isCondensedPortrait ? ' condensed' : ''}`}>
        <div className={'dialog-content'}>
          <div className={'dialog-head'} />
          {page === 1 && (
            <VeevanReadonlyOption clickStartRegistration={() => setPage(3)} clickSetReadOnly={registerReadOnly} />
          )}
          {showComplete && <CompleteProfile user={preUser} onSavedProfile={registerUser} />}
          {page === 3 && <VeevanCompleteProfile user={preUser} onSavedProfile={registerUser} />}
        </div>
      </div>
    </UnauthLayout>
  )
}
