import React, { useState } from 'react'
import { ProfileEdit } from '~/pages/profile/ProfileEdit'
import { ProfileHeader } from '~/pages/profile/ProfileHeader'
import { useProfile } from '~/contexts/ProfileContext'
import '@css/pages/profile/ProfileContent.scss'

interface ProfileUserInfoProps {
  setUpdated: (updated: boolean) => void
  isEditing?: boolean
}

const ProfileUserInfo = ({ setUpdated, isEditing }: ProfileUserInfoProps) => {
  const [editing, setEditing] = useState(isEditing ?? false)

  const { user, loading, setProfileInfoChanges } = useProfile()

  const updateCloseEdit = () => {
    setEditing(false)
    setUpdated(true)
    setProfileInfoChanges?.(false)
  }

  const updateClickEdit = () => {
    setEditing(true)
    setUpdated(false)
  }

  if (user?.hidden) {
    return <div>This person is no longer a member of Veeva Connect</div>
  }

  return (
    <div className={'profile-top'}>
      {editing && !loading ? (
        <ProfileEdit closeEdit={updateCloseEdit} user={user} loading={loading} />
      ) : (
        <ProfileHeader clickEdit={updateClickEdit} />
      )}
    </div>
  )
}

export default ProfileUserInfo
