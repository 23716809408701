import React from 'react'
import { Outlet, useParams } from 'react-router'
import { CommunityProvider } from '~/contexts/CommunityContext'
import CommunitySidebar from '~/pages/community/CommunitySidebar'
import { SizeBreakpoint } from '~/common/hooks/useWindowSize'
import { MediaUploadProvider } from '~/contexts/MediaUploadContext'
import ColumnLayout from '~/common/ColumnLayout'
import { DraftingCommentPostProvider } from '~/contexts/DraftingCommentPostContext'
import { NewActivityProvider } from '~/contexts/NewActivityContext'
import { PromptProvider } from '~/contexts/PromptContext'

const CompanyLayout = () => {
  const { companyId } = useParams<{ companyId: string | undefined }>()

  return (
    <CommunityProvider companyId={companyId}>
      <NewActivityProvider>
        <ColumnLayout threshold={SizeBreakpoint.md} additionalColumnClass={'community-layout'} currentPage={'comm'}>
          <CommunitySidebar isCompany={true} />
          <PromptProvider>
            <DraftingCommentPostProvider>
              <MediaUploadProvider>
                <Outlet />
              </MediaUploadProvider>
            </DraftingCommentPostProvider>
          </PromptProvider>
        </ColumnLayout>
      </NewActivityProvider>
    </CommunityProvider>
  )
}

export default CompanyLayout
