import React from 'react'
import { GetSubcategoryRowDocument } from '~/api/generated/graphql'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import { Maybe } from '~/api/generated/graphql'
import dragDropIcon from '@web/images/posts/DragDrop.svg'
import { useQuery } from '@apollo/client'

type SubcategoryRowProps = {
  id: string
  isEditing: boolean
  draggableProps: DraggableProvidedDraggableProps
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableInnerRef: (element: HTMLElement | null) => void
  isDragging: boolean
  isAtBottom: boolean
}

const SubcategoryRow = ({
  id,
  isEditing,
  draggableProps,
  draggableInnerRef,
  dragHandleProps,
  isDragging,
  isAtBottom,
}: SubcategoryRowProps) => {
  const { data } = useQuery(GetSubcategoryRowDocument, { variables: { id } })

  const name = data?.subcategory?.name

  return isEditing ? (
    <div className={`post-row subcategory${isDragging ? ' dragging' : ''}`} ref={draggableInnerRef} {...draggableProps}>
      <img
        alt={isEditing ? 'reorder drag handle' : ''}
        src={dragDropIcon}
        {...dragHandleProps}
        className={'drag-handle'}
        data-testid={'dnd-handle'}
      />
      <div className={'content-title-container'}>
        <span className={'post-title subcategory'}>{name}</span>
      </div>
    </div>
  ) : (
    <div
      className={`post-row subcategory${isDragging ? ' dragging' : ''}${isAtBottom ? ' hidden' : ''}`}
      ref={draggableInnerRef}
      {...draggableProps}
    >
      <img
        alt={isEditing ? 'reorder drag handle' : ''}
        src={dragDropIcon}
        {...dragHandleProps}
        className={'drag-handle hidden'}
      />
      <div className={'content-title-container'}>
        <span className={'post-title display subcategory'}>{name}</span>
      </div>
    </div>
  )
}

export default SubcategoryRow
