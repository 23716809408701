import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'

export type ShortcutContextType = {
  focusSearch: boolean
  setFocusSearch?: (focusSearch: boolean) => void
  setPostList?: (list: string[]) => void
  selectedPost?: {
    postId: string
    expanded: boolean
  }
  expandCurrentPost?: () => void
  setSelectedPost?: (post: { postId: string; expanded: boolean }) => void
  resetSelectedPostState?: () => void
  postList?: string[]
}
export const ShortcutContext = React.createContext<ShortcutContextType>({
  focusSearch: false,
})

const isEditor = () =>
  document.activeElement?.getAttribute('contenteditable') ||
  document.activeElement?.tagName === 'INPUT' ||
  document.activeElement?.tagName === 'TEXTAREA' ||
  !!document.querySelector('.fade.modal-backdrop.show')
export const ShortcutContextProvider = ({ children }: { children: ReactNode }) => {
  const [focusSearch, setFocusSearch] = useState(false)
  const [postList, setPostList] = useState<string[]>([])
  const [selectedPost, setSelectedPost] = useState<{
    postId: string
    expanded: boolean
  }>({
    postId: '',
    expanded: false,
  })

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === '/' && !isEditor()) {
        setFocusSearch(false)
        event.preventDefault()
        setFocusSearch(true)
      }
      if (event.key === 'j' && !isEditor()) {
        event.preventDefault()
        setSelectedPost(s => {
          if (s.postId === '') {
            return { postId: postList[0], expanded: false }
          } else if (postList.indexOf(s.postId) + 1 < postList.length) {
            return {
              postId: postList.includes(s.postId) ? postList[postList.indexOf(s.postId) + 1] : '',
              expanded: false,
            }
          } else {
            return s
          }
        })
      }
      if (event.key === 'k' && !isEditor()) {
        event.preventDefault()
        setSelectedPost(s => {
          if (postList.indexOf(s.postId) - 1 >= 0) {
            return {
              postId: postList.includes(s.postId) ? postList[postList.indexOf(s.postId) - 1] : '',
              expanded: false,
            }
          } else {
            return s
          }
        })
      }
    },
    [postList, setSelectedPost]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const expandCurrentPost = useCallback(() => {
    setSelectedPost(s => {
      return { ...s, expanded: true }
    })
  }, [setSelectedPost])

  const resetSelectedPostState = useCallback(() => {
    setPostList?.([])
    setSelectedPost({ postId: '', expanded: false })
  }, [setPostList, setSelectedPost])

  return (
    <ShortcutContext.Provider
      value={{
        focusSearch,
        setFocusSearch,
        setPostList,
        selectedPost,
        expandCurrentPost,
        resetSelectedPostState,
        setSelectedPost,
        postList,
      }}
    >
      {children}
    </ShortcutContext.Provider>
  )
}

export const useShortcuts = () => useContext(ShortcutContext)
