import { Button } from 'react-bootstrap'
import React from 'react'
import '@css/pages/VeevanRegistration.scss'

export const VeevanReadonlyOption = ({
  clickStartRegistration,
  clickSetReadOnly,
}: {
  clickStartRegistration: () => void
  clickSetReadOnly: () => void
}) => {
  return (
    <>
      <div className={'dialog-heading'}>Welcome to Veeva Connect!</div>
      <p className="dialog-subheading">
        Before you can join communities and participate, you will need to complete your profile. It's super quick!
      </p>
      <p className={'dialog-body'}>
        If you join as a read-only user, you will be able to view content, but you will not be able to post or comment,
        and your profile will be hidden.
      </p>
      <div className={'dialog-form'}>
        <div className={'button-zone register-buttons'}>
          <Button className={'connect-button'} variant={'outline-secondary'} onClick={clickSetReadOnly}>
            {'Join as a Read-Only User'}
          </Button>
          <Button className={'connect-button'} variant={'primary'} onClick={clickStartRegistration}>
            {'Complete My Profile'}
          </Button>
        </div>
      </div>
    </>
  )
}
