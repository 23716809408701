// These types are used by the mock server, so they need to be simple types without including other
// modules that might prevent mocking.

import { PreUserModel } from '~/types'

export enum AuthStatus {
  initial = 'initial',
  emailAccepted = 'emailAccepted',
  codeVerified = 'codeVerified',
  unknownDomain = 'unknownDomain',
  unknownEmail = 'unknownEmail',
  publicDomain = 'publicDomain',
  partnerDomain = 'partnerDomain',
  competitorDomain = 'competitorDomain',
  codeRejected = 'codeRejected',
  emailMalformed = 'emailMalformed',
  codeMalformed = 'codeMalformed',
  tooManyResends = 'tooManyResends',
  tooManyFailures = 'tooManyFailures',
  notInOrgwiki = 'notInOrgwiki',
  error = 'error',
  blockedSubdomain = 'blockedSubdomain',
  resendBeforeDelay = 'resendBeforeDelay',
  hiddenUser = 'hiddenUser',
  registrationReady = 'registrationReady',
  authExpired = 'authExpired',
}

export interface AuthServerResponse {
  authStatus: AuthStatus
  verifyDelay?: number // These are not populated when code fails client-side validation
  verifyDelayText?: string
  resendDelay?: number
  resendDelayText?: string
  life?: number
  preUser?: PreUserModel
}

export interface AuthRegistrationRequest {
  email: string
  firstName?: string
  lastName?: string
  nickName?: string
  title?: string
  photo?: string
  profileVisible?: boolean
  aboutMe?: string
  roleAtCompany?: string
  linkUrl?: string
}
