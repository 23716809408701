import React from 'react'
import '@css/common/BadgeComponent.scss'
import { EventCalendarIconComponent } from '~/common/EventCalendarIconComponent'
import { Maybe } from '~/api/generated/graphql'
import { useClickOnEnter } from './hooks/useClickOnEnter'

interface EventBadgeProps {
  event?: Maybe<{
    title?: Maybe<string>
    hidden?: Maybe<boolean>
    eventStart?: string
  }>
  clickEvent: () => void
  canEdit: boolean
}

export const EventBadgeComponent = ({ event, clickEvent }: EventBadgeProps) => {
  const badgeRef = useClickOnEnter<HTMLDivElement>()

  return (
    <div
      className={`event-badge${event?.hidden ? ' deleted' : ''}`}
      title={event?.title || ''}
      onClick={clickEvent}
      tabIndex={0}
      ref={badgeRef}
    >
      <div className="badge-body">
        <EventCalendarIconComponent eventStart={event?.eventStart ?? ''} className={'small-card'} />
        <div className={'event-info'}>
          <p className={'title'}>{event?.title || ''}</p>
        </div>
      </div>
    </div>
  )
}
