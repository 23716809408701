import React from 'react'
import LayoutSingleCol from '~/common/LayoutSingleCol'
import '@css/pages/TermsPrivacy.scss'

export const Terms = () => {
  return (
    <LayoutSingleCol className={'terms-privacy'}>
      <p className="text-center my-1">Veeva Connect</p>
      <h2 className="text-center display-6 mb-3">Terms of Use</h2>
      <i>Last Updated: November 21, 2022</i>
      <p>
        Thank you for your interest in the Veeva Connect platform and the products, services, and features that Veeva
        Systems Inc. (“<b>Veeva</b>”, the “<b>Company</b>”, “<b>us</b>”, “<b>we</b>” and “<b>our</b>”) makes available
        to you as part of the platform (collectively the “<b>Service</b>”). These Terms govern your access to, and use
        of the Service.
      </p>
      <h6>PLEASE READ THE FOLLOWING TERMS CAREFULLY:</h6>
      <p>
        <b>BY CLICKING “I ACCEPT,” OR OTHERWISE ACCESSING OR USING THE SERVICE</b>, YOU AGREE THAT YOU HAVE READ AND
        UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND
        CONDITIONS, INCLUDING THE{' '}
        <a href={'/privacy-policy'} target="_blank">
          VEEVA CONNECT PRIVACY POLICY
        </a>{' '}
        (“
        <a href={'/privacy-policy'} target="_blank">
          PRIVACY POLICY
        </a>
        ”) (TOGETHER, THESE “
        <a href={'/terms'} target="_blank">
          TERMS
        </a>
        ”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
        SERVICE. YOUR USE OF THE SERVICE, AND VEEVA’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY VEEVA
        AND BY YOU TO BE BOUND BY THESE TERMS.{' '}
        <b>
          BY USING THE SERVICE, YOU FURTHER ACKNOWLEDGE THAT THE SERVICE IS NOT INTENDED TO STORE PROTECTED HEALTH
          INFORMATION AND THAT YOU ARE NOT PERMITTED TO TRANSMIT ANY SUCH PROTECTED HEALTH INFORMATION.
        </b>
      </p>
      <ol>
        <li>
          <h6>Overview</h6>
          <p>
            The Service offers a community environment allowing staff of Veeva customers and potential customers
            together with Veeva personnel supporting such customer users to access and share content and provides a
            forum for users of the Service to connect and communicate with each other to share content and insights
            around Veeva products and services. You do not need to register for the Service if you want to use Veeva
            software and services to which your company has purchased a license. Joining the Veeva Connect community is
            entirely your decision. You acknowledge that information may be provided by other users, and your use of
            such information is at your own discretion and at your own risk. You also acknowledge that all information
            provided by and entered by you into the service, including personal identifiable information, may be
            available to other users of the service.
          </p>
        </li>
        <li>
          <h6>Use of the Service</h6>
          <p>
            The information made available through or in connection with the Service is provided for informational
            purposes only. You acknowledge that information may be provided by other users, and your use of such
            information is at your own discretion and at your own risk. By using the Service, you further acknowledge
            that any content or information that you share on or through the Service (including User Content) may be
            seen by others and maybe used or re-shared by others, whether or not in connection with the Service.
            Accordingly, you agree that VEEVA IS NOT RESPONSIBLE FOR ANY SUCH USE, SHARING, MISUSE OR MISAPPROPRIATION
            OF SUCH CONTENT OR INFORMATION.{' '}
          </p>
        </li>
        <li>
          <h6>Service Usage Information</h6>
          <p>
            You acknowledge that Veeva may maintain a database of information related to use of the Service, and you
            acknowledge and agree that the information Veeva obtains from you in connection with your use of the
            Service, including User Content, may be added to such database (“<b>Service Usage Information</b>”) and that
            Veeva may use such Service Usage Information for its own business purposes, in accordance with the Privacy
            Policy. Accordingly, you hereby grant Veeva an unrestricted, perpetual, worldwide, fully paid up, royalty
            free, transferrable, sublicensable license to exploit such Service Usage Information in any manner and for
            any purpose in accordance with the Privacy Policy.
          </p>
        </li>
        <li>
          <h6>User Account and Registration</h6>
          <p>
            To access the Service, you must register for an account. When you register you may be asked to provide
            information about yourself, including your e-mail address, name, or other contact information. You must have
            an email address that links to a current Veeva customer or potential customer that will be verified by
            Veeva. You agree that the information you provide to us is accurate and that you will keep it accurate and
            up to date at all times. Veeva reserves the right to require regular re-authentication of your email to
            enable you to continue your access to the Service. Veeva reserves the right to deny access to the Service to
            any user at Veeva’s discretion. Veeva reserves the right (but does not undertake the obligation) to conduct
            a review, at any time, to validate your user account information to ensure that your access to the Service
            does not breach these Terms, and/or any applicable law. You authorize us and our agents to make any
            inquiries of you and for us to use and disclose to any third party we consider necessary to validate this
            information. You are soley responsible for all actions taken by you when accessing the Service. You must
            make every effort to keep your access details safe and should not disclose to anyone. You shall not permit,
            either directly or indirectly, any other person to use the Service under your Account. If you believe that
            your account is no longer secure, then you must immediately notify us at{' '}
            <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
              VeevaConnect@veeva.com
            </a>
          </p>
        </li>
        <li>
          <h6>Competitors</h6>
          <p>
            By using the Service, you affirm, represent and warrant to Veeva that (i) you are using the Service as an
            employee of a Veeva customer or a potential customer explictly invited by Veeva; (ii) you are not an
            employee of a company that competes with Veeva in any manner whatsoever; and (iii) you will not use or
            disclose to others information from the Service to produce products and/or services that compete with
            Veeva’s products and services.
          </p>
        </li>
        <li>
          <h6>Eligibilty</h6>
          <p>
            By using the Service you agree that (a) your use of the Service is in compliance with any and all applicable
            laws and regulations and (b) all information that you provide when registering to use the Service is true
            and correct and that you will promptly notify us of any changes.{' '}
          </p>
        </li>
        <li>
          <h6>Licenses and Ownership</h6>
          <ol>
            <li>
              <b>Limited Licence.</b> Subject to your complete and ongoing compliance with these Terms, Veeva grants you
              a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the
              Service via a website that we operate or to install and use copies of the object code of any mobile
              application associated with the Service on a mobile device that you own or control should we offer such a
              mobile application in the future.
            </li>
            <li>
              <b>License Restrictions.</b> Except and solely to the extent such a restriction is impermissible under
              applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service;
              (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service,
              including any security or access control mechanism. If you are prohibited under applicable law from using
              the Service, you may not use it.
            </li>
            <li>
              <b>Feedback.</b> We welcome any feedback that you may have regarding the Service. You do not have to
              provide it to us. However, if you choose to provide input and suggestions regarding the Service or any
              improvements to the Service (“<b>Feedback</b>”), then you hereby grant Veeva an unrestricted, perpetual,
              irrevocable, non-exclusive, fully paid up, royalty free right to exploit the Feeback in any manner and for
              any purpose, including to improve the Service and create other products or Service, related or otherwise.
            </li>
            <li>
              <b>Ownership, Proprietary Rights.</b> The Service is owned and operated by Veeva. All elements of the
              Service, including the visual interfaces, graphics, designs, compilations, information, data, computer
              code (including source code or object code), products, software, Service, and all other elements of the
              Service (“<b>Materials</b>”) provided by Veeva are protected by intellectual property and other laws. All
              Materials included in the Service are the property of Veeva or its third-party licensors. Except as
              expressly authorized by Veeva, you may not make use of the Materials. Veeva, on behalf of itself and its
              third-party licensors, reserves all rights to the Materials not granted expressly in these Terms. You
              acknowledge that some Materials, including materials submitted by other users of the Service are not owned
              by Veeva, and Veeva disclaims any and all liability with respect to such materials.
            </li>
          </ol>
        </li>
        <li>
          <h6>Third-Party Terms</h6>
          <ol>
            <li>
              <b>Third-Party Services and Linked Websites.</b> Some parts of the Service may include functionality that
              enables you to transfer information, including User Content (defined below), to third parties. By making
              use of these functions, you agree that Veeva may transfer such information to the applicable third-party.
              Such third parties are not under Veeva’s control, and, to the fullest extent permitted by law, Veeva is
              not responsible for any third-party’s use of your exported information. The Service may also contain links
              to third-party websites. Linked websites are not under Veeva’s control, and Veeva is not responsible for
              their content.
            </li>
            <li>
              <b>Third-Party Software.</b> The Service may include or incorporate third-party software components that
              are generally available free of charge under licenses granting recipients broad rights to copy, modify,
              and distribute those components (“Third-Party Components”). Although the Service is provided to you
              subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict
              you from obtaining Third-Party Components under the applicable third-party licenses or to limit your use
              of Third-Party Components under those third-party licenses.
            </li>
          </ol>
        </li>
        <li>
          <h6>Information You Provide Through the Service</h6>
          <ol>
            <li>
              <b>User Content Generally.</b> Certain features of the Service may permit you to upload content to the
              Service, including posts, messages, reviews, invitations to join events within the Service, product
              demonstrations, photos, videos, images, folders, data, text, and other types of works (“
              <b>User Content</b>”) and to make available for viewing and transmit User Content to other users of the
              Service. You retain any copyright and other proprietary rights that you may hold in the User Content that
              you post, share and upload to and within the Service.
            </li>
            <li>
              <b>Limited Licence Grant to Veeva.</b> By providing User Content to or via the Service, you grant Veeva a
              perpetual worldwide, non-exclusive, irrevocable, royalty-free, fully paid right and license (with the
              right to sublicense) to use, host, store, transfer, display, perform, reproduce, modify and distribute
              your User Content, in whole or in part, subject to the limitations of the Privacy Policy.
            </li>
            <li>
              <b>Limited License Grant to Other Users.</b> By providing User Content to or via the Service to other
              users of the Service, you grant those users a non-exclusive license to access and use that User Content
              for their internal business purposes and as permitted by the functionality of the Service.
            </li>
            <li>
              <b>User Content Representations and Warranties.</b> Veeva disclaims any and all liability in connection
              with User Content. You are solely responsible for your User Content and the consequences of providing User
              Content via the Service. By providing User Content via the Service, you affirm, represent, and warrant to
              us that:
              <ol className={'normal-list'} type={'a'}>
                <li>
                  you are the creator and owner of the User Content, or have the necessary licenses, rights, consents,
                  and permissions to authorize Veeva and other users of the Service as applicable to use and distribute
                  your User Content as necessary to exercise the licenses granted by you in this Section, in the manner
                  contemplated by Veeva, the Service, and these Terms;
                </li>
                <li>
                  your User Content, and the use of your User Content as contemplated by these Terms, does not and will
                  not: (i) infringe, violate, or misappropriate any third-party right, including any copyright,
                  trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other
                  intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of
                  privacy, publicity or other property rights of any other person; or (iii) cause Veeva to violate any
                  law or regulation;
                </li>
                <li>
                  your User Content does not include any Protected Health Information, as defined by the Health
                  Insurance Portability and Accountability Act of 1996 (“<b>HIPAA</b>”) and you acknowledge that the
                  Service is not intended to store Personal Health Information and that Veeva is not your Business
                  Associate, as defined by HIPAA;
                </li>
                <li>
                  your User Content does not include any confidential information, and you acknowledge that neither
                  Veeva or other users of the Service shall have any obligation to keep such User Content confidential;
                  and
                </li>
                <li>
                  your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent,
                  pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.
                </li>
              </ol>
            </li>
            <li>
              <b>User Content Disclaimer.</b> This Service is intended to be used as a forum to connect, communicate and
              share content. We are under no obligation to edit or control User Content that you or other users post or
              publish and will not be in any way responsible or liable for User Content. Veeva may, however, at any time
              and without prior notice, screen, remove, edit, or block any User Content that in our sole judgment
              violates these Terms or is otherwise objectionable. You understand that, when using the Service, you may
              be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate,
              offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or
              remedy you have or may have against Veeva with respect to User Content. If notified by a user or content
              owner that User Content allegedly does not conform to these Terms, we may investigate the allegation and
              determine in our sole discretion whether to remove the User Content, which we reserve the right to do at
              any time and without notice. For clarity, Veeva does not permit copyright-infringing activities on the
              Service.
            </li>
            <li>
              <b>Monitoring Content.</b> Veeva does not control and does not have any obligation to monitor: (a) User
              Content; (b) any content made available by third parties; or (c) the use of the Service by its users. You
              acknowledge and agree that Veeva reserves the right to, and may from time to time, monitor any and all
              information transmitted or received through the Service. If at any time Veeva chooses to monitor the
              content, Veeva still assumes no responsibility or liability for content or any loss or damage incurred as
              a result of the use of content. During monitoring, information maybe examined, recorded, copied, and used
              in accordance with our{' '}
              <a href={'/privacy-policy'} target="_blank">
                Privacy Policy
              </a>
              .
            </li>
          </ol>
        </li>
        <li>
          <h6>Communications</h6>
          <li>
            <b>With Other Users.</b> You may use the Service to communicate with other users and other users may attempt
            to communicate with you via the Service.
          </li>
          <li>
            <b>Email.</b> We may send you emails concerning our products and Service, as well as those of third parties.
            You may opt out of promotional emails by following the unsubscribe instructions in the promotional email
            itself.
          </li>
        </li>
        <li>
          <h6>Prohibited Conduct.</h6> BY USING THE SERVICE, YOU AGREE NOT TO:
          <ol className={'normal-list'} type={'a'}>
            <li>
              use the Service for any illegal purpose or in violation of any local, state, national, or international
              law;
            </li>
            <li>harass, threaten, demean, embarrass, bully, or otherwise harm any other user of the Service;</li>
            <li>
              violate, or encourage others to violate, any right of a third party, including by infringing or
              misappropriating any third-party intellectual property right;
            </li>
            <li>
              interfere with security-related features of the Service, including by: (a) disabling or circumventing
              features that prevent or limit use or copying of any content; or (b) reverse engineering or otherwise
              attempting to discover the source code of any portion of the Service except to the extent that the
              activity is expressly permitted by applicable law;
            </li>
            <li>
              interfere with the operation of the Service or any user’s enjoyment of the Service, including by: (i)
              uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii)
              making any unsolicited offer or advertisement to another user of the Service; (iii) collecting personal
              information about another user or third party without consent; or (iv) interfering with or disrupting any
              network, equipment, or server connected to or used to provide the Service;
            </li>
            <li>
              perform any fraudulent activity including impersonating any person or entity, claiming a false
              affiliation, or accessing any other Service account without permission;
            </li>
            <li>
              sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section 7)
              or any right or ability to view, access, or use any Materials; or
            </li>
            <li>
              attempt to do any of the acts described in this Section 11 or assist or permit any person in engaging in
              any of the acts described in this Section 11.
            </li>
          </ol>
        </li>
        <li>
          <h6> Copyright and Intellectual Property Protection</h6>
          <ol>
            <li className={'info'}>
              <b>DMCA Notification.</b> We comply with the provisions of the Digital Millennium Copyright Act applicable
              to Internet service providers (17 U.S.C. § 512, as amended). If you have an intellectual property
              rights-related complaint about material posted on the Service, you may contact our Designated Agent at the
              following address:
              <p> Veeva Systems Inc.</p>
              <p> Attn: Legal Department (Copyright Notification)</p>
              <p>4280 Hacienda Drive, Pleasanton, CA 94588</p>
              <p>
                Email:{' '}
                <a href={'mailto:contracts@veeva.com'} target="_blank">
                  contracts@veeva.com
                </a>
              </p>
            </li>
            <li>
              <b>Content of Notification.</b> Any notice alleging that materials hosted by or distributed through the
              Service infringe intellectual property rights must include the following information:
              <ol type={'a'} className={'normal-list'}>
                <li>
                  an electronic or physical signature of the person authorized to act on behalf of the owner of the
                  copyright or other right being infringed;
                </li>
                <li>
                  a description of the copyrighted work or other intellectual property that you claim has been
                  infringed;
                </li>
                <li>
                  a description of the material that you claim is infringing and where it is located on the Service;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that you have a good faith belief that the use of the materials on the Service of
                  which you are complaining is not authorized by the copyright or intellectual property owner, its
                  agent, or the law; and
                </li>
                <li>
                  a statement by you that the above information in your notice is accurate and that, under penalty of
                  perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or
                  intellectual property owner’s behalf.
                </li>
              </ol>
            </li>
            <li>
              <b>Repeat Infringers.</b> Veeva will promptly terminate the accounts of users that are determined by Veeva
              to be repeat infringers.
            </li>
          </ol>
        </li>
        <li>
          <h6>Term, Termination, and Modification of the Service</h6>
          <ol>
            <li>
              <b>Term.</b> These Terms are effective beginning when you accept the Terms or first download, install,
              access, or use the Service, and ending when terminated as described in Section 13.2.
            </li>
            <li>
              <b>Termination.</b> If you violate any provision of these Terms, your authorization to access the Service
              and these Terms automatically terminate. In addition, Veeva may, at its sole discretion, terminate these
              Terms or your account on the Service, or suspend or terminate your access to the Service, at any time for
              any reason or no reason, with or without notice. You may terminate your account and these Terms at any
              time by contacting Veeva at{' '}
              <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
                VeevaConnect@veeva.com
              </a>
              .
            </li>
            <li>
              <b>Effect of Termination.</b> Upon termination of these Terms: (a) your license rights will terminate and
              you must immediately cease all use of the Service; (b) you will no longer be authorized to access your
              account or the Service; and (c) Sections 2, 3, 7.2, 7.3, 7.4, 9, 11, 13, 14, 15, 16, 17.9,18 and 19 will
              survive any such termination.
            </li>
            <li>
              <b>Modification of the Service.</b> Veeva reserves the right to modify or discontinue the Service at any
              time (including by limiting or discontinuing certain features of the Service), temporarily or permanently,
              without notice to you. Veeva will have no liability for any change to the Service or any suspension or
              termination of your access to or use of the Service.
            </li>
          </ol>
        </li>
        <li>
          <h6>Indemnity</h6>
          <p>
            To the fullest extent permitted by law, you are responsible for your use of the Service, and you will defend
            and indemnify Veeva, its affiliates and their respective shareholders, directors, managers, members,
            officers, employees, consultants, and agents (together, the “<b>Veeva Entities</b>”) from and against every
            claim brought by a third party, and any related liability, damage, loss, and expense, including attorneys’
            fees and costs, arising out of or connected with: (a) your unauthorized use of, or misuse of, the Service;
            (b) your use of or reliance on any information received through the Service; (c) your violation of any
            portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any
            applicable law or regulation; (d) your violation of any third-party right, including any intellectual
            property right or publicity, confidentiality, other property, or privacy right; or (e) any dispute or issue
            between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by you (without limiting your indemnification
            obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those
            claims.
          </p>
        </li>
        <li>
          <h6>Disclaimers; No Warranties</h6>
          <ol>
            <li>
              THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS
              AVAILABLE” BASIS. VEEVA DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE
              SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (a) ANY IMPLIED WARRANTY
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (b)
              ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. YOU ACKNOWLEDGE THAT YOU USE ANY
              INFORMATION OR MATERIALS PROVIDED TO YOU VIA THE SERVICE, WHETHER BY VEEVA OR ANOTHER USER OF THE SERVICE,
              AT YOUR OWN DISCRETION AND AT YOUR OWN RISK, AND VEEVA HEREBY DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT
              TO SUCH USE. VEEVA DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
              CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
              HARMFUL COMPONENTS, AND VEEVA DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
            </li>
            <li>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR VEEVA ENTITIES OR
              ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE VEEVA
              ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE
              THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE
              THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE
              FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH
              THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
            </li>
            <li>
              THE LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS IN THIS SECTION 15 APPLY TO THE FULLEST EXTENT PERMITTED BY
              LAW. Veeva does not disclaim any warranty or other right that Veeva is prohibited from disclaiming under
              applicable law.
            </li>
          </ol>
        </li>
        <li>
          <h6>Limitation of Liability.</h6>
          <ol>
            <li>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE VEEVA ENTITIES BE LIABLE TO YOU FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS,
              GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR
              INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON
              WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
              ANY VEEVA ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
            </li>
            <li>
              EXCEPT AS PROVIDED FOR IN SECTION 17.6 TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF
              THE VEEVA ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE
              ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS
              LIMITED TO THE GREATER OF: (a) THE AMOUNT YOU HAVE PAID TO VEEVA FOR ACCESS TO AND USE OF THE SERVICE IN
              THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM AND (b) US$100.
            </li>
            <li>
              EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
              EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS.
              THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
              PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS
              SECTION 6 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </li>
          </ol>
        </li>
        <li>
          <h6>Dispute Resolution and Arbitration</h6>
          <ol>
            <li>
              <b>Generally.</b> In the interest of resolving disputes between you and Veeva in the most expedient and
              cost effective manner, and except as described in Sections 17.2 and 17.3 below, you and Veeva agree that
              every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration
              is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury,
              may allow for more limited discovery than in court, and can be subject to very limited review by courts.
              Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate
              disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in
              contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a
              claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING
              INTO THESE TERMS, YOU AND VEEVA ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
              ACTION.
            </li>
            <li>
              <b>Exceptions.</b> Despite the provisions of Section 17.1, nothing in these Terms will be deemed to waive,
              preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims
              court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that
              action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file
              suit in a court of law to address an intellectual property infringement claim.
            </li>
            <li>
              <b>Opt-Out.</b> If you do not wish to resolve disputes by binding arbitration, you may opt out of the
              provisions of this this Section 17 within 30 days after the date that you agree to these Terms by sending
              a letter to Veeva Systems Inc., Attention: Legal Department – Arbitration Opt-Out, 4280 Hacienda Drive,
              Pleasanton, CA 94588 that specifies: your full legal name, the email address associated with your account
              on the Service, and a statement that you wish to opt out of arbitration (“Opt-Out Notice”). Once Veeva
              receives your Opt-Out Notice, this Section will be void and any action arising out of these Terms will be
              resolved as set forth in Section 19.2. The remaining provisions of these Terms will not be affected by
              your Opt-Out Notice.
            </li>
            <li>
              <b>Arbitrator.</b> Any arbitration between you and Veeva will be settled under the Federal Arbitration Act
              and administered by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules
              (collectively, “AAA Rules”) as modified by these Terms. The AAA Rules and filing forms are available
              online at www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting Veeva. The arbitrator has
              exclusive authority to resolve any dispute relating to the interpretation, applicability, or
              enforceability of this binding arbitration agreement.
            </li>
            <li>
              <b>Notice of Arbitration; Process.</b> A party who intends to seek arbitration must first send a written
              notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required)
              or, only if that other party has not provided a current physical address, then by electronic mail (“
              <b>Notice of Arbitration</b>”). Veeva’s address for Notice is: Veeva Systems Inc., 4280 Hacienda Drive,
              Pleasanton, CA 94588. The Notice of Arbitration must: (a) describe the nature and basis of the claim or
              dispute; and (b) set forth the specific relief sought (“<b>Demand</b>”). The parties will make good faith
              efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30
              days after the Notice of Arbitration is received, you or Veeva may commence an arbitration proceeding. All
              arbitration proceedings between the parties will be confidential unless otherwise agreed by the parties in
              writing. During the arbitration, the amount of any settlement offer made by you or Veeva must not be
              disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the
              arbitrator awards you an amount higher than the last written settlement amount offered by Veeva in
              settlement of the dispute prior to the award, Veeva will pay to you the higher of: (a) the amount awarded
              by the arbitrator and (b) US$10,000.
            </li>
            <li>
              <b>Fees.</b> If you commence arbitration in accordance with these Terms, Veeva will reimburse you for your
              payment of the filing fee, unless your claim is for more than US$10,000, in which case the payment of any
              fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed
              upon in Alameda County, California, but if the claim is for US $10,000 or less, you may choose whether the
              arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b)
              through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA
              Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance
              of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as
              measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
              fees will be governed by the AAA Rules. In that case, you agree to reimburse Veeva for all monies
              previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of
              the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision
              sufficient to explain the essential findings and conclusions on which the decision and award, if any, are
              based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or
              expenses at any time during the proceeding and upon request from either party made within 14 days of the
              arbitrator’s ruling on the merits.
            </li>
            <li>
              <b>No Class Actions.</b> YOU AND VEEVA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
              ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. Further, unless both you and Veeva agree otherwise, the arbitrator may not consolidate more
              than one person’s claims, and may not otherwise preside over any form of a representative or class
              proceeding.
            </li>
            <li>
              <b>Modications to this Arbitration Provision.</b> If Veeva makes any future change to this arbitration
              provision, other than a change to Veeva’s address for Notice of Arbitration, you may reject the change by
              sending us written notice within 30 days of the change to Veeva’s address for Notice of Arbitration, in
              which case your account with Veeva will be immediately terminated and this arbitration provision, as in
              effect immediately prior to the changes you rejected will survive.
            </li>
            <li>
              <b>Enforceability.</b> If Section 17.7 or the entirety of this Section 17 is found to be unenforceable, or
              if Veeva receives an Opt-Out Notice from you, then the entirety of this Section 17 will be null and void
              and, in that case, the exclusive jurisdiction and venue described in Section 19.2 will govern any action
              arising out of or related to these Terms.
            </li>
          </ol>
        </li>
        <li>
          <h6>Modification of Terms. </h6>
          <p>
            Please check these Terms periodically for changes. We reserve the right to change these Terms on a
            going-forward basis at any time, provided however that any such changes shall be effective upon the earlier
            of your use of the Service with knowledge of such changes and thirty days after posting. Except as expressly
            permitted in this Section 18, these Terms may be amended only by a written agreement signed by authorized
            representatives of the parties to these Terms. Disputes arising under these Terms will be resolved in
            accordance with the version of these Terms that was in effect at the time the dispute arose.
          </p>
        </li>
        <li>
          <h6>Miscellaneous</h6>
          <ol>
            <li>
              <b>General Terms.</b> These Terms, together with the Privacy Policy and any other agreements expressly
              incorporated by reference into these Terms, are the entire and exclusive understanding and agreement
              between you and Veeva regarding your use of the Service. You may not assign or transfer these Terms or
              your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior
              written consent. We may assign these Terms at any time without notice or consent. The failure to require
              performance of any provision will not affect our right to require performance at any other time after
              that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be
              a waiver of any subsequent breach or default or a waiver of the provision itself. Use of Section headers
              in these Terms is for convenience only and will not have any impact on the interpretation of any
              provision. Throughout these Terms the use of the word “including” means “including but not limited to.” If
              a court or arbitrator of competent jurisdiction determines that any part of these Terms is invalid or
              unenforceable, such court or arbitration shall reduce or otherwise modify such invalid or unenforceable
              part to the minimum extent necessary to make it valid and enforceable. If such part cannot be so modified,
              it will be given effect to the greatest extent possible, and the remaining parts will remain in full force
              and effect.
            </li>
            <li>
              <b>Governing Law.</b> These Terms are governed by the laws of the State of California without regard to
              conflict of law principles. You and Veeva submit to the personal and exclusive jurisdiction of the state
              courts and federal courts located within Alameda County, California for resolution of any lawsuit or court
              proceeding permitted under these Terms. We operate the Service from our offices in California, and we make
              no representation that Materials included in the Service are appropriate or available for use in other
              locations.
            </li>
            <li>
              <b>Privacy Policy.</b> Please read the{' '}
              <a href={'/privacy-policy'} target="_blank">
                Privacy Policy
              </a>{' '}
              carefully for information relating to our collection, use, storage, and disclosure of your personal
              information. The Privacy Policy is incorporated by this reference into, and made a part of, these Terms.
            </li>
            <li>
              <b>Additional Terms.</b> Your use of the Service is subject to all additional terms, policies, rules, or
              guidelines applicable to the Service or certain features of the Service that we may post on or link to
              from the Service (the “<b>Additional Terms</b>”). All Additional Terms are incorporated by this reference
              into, and made a part of, these Terms.
            </li>
            <li>
              <b>Consent to Electronic Communications.</b> By using the Service, you consent to receiving certain
              electronic communications from us as further described in our Privacy Policy. Please read our Privacy
              Policy to learn more about our electronic communications practices. You agree that any notices,
              agreements, disclosures, or other communications that we send to you electronically will satisfy any legal
              communication requirements, including that those communications be in writing.
            </li>
            <li>
              <b>Contact Information.</b> The Service is offered by Veeva Systems Inc., located at 4280 Hacienda Drive,
              Pleasanton, CA 94588. You may contact us by sending correspondence to that address or by emailing us at{' '}
              <a href={'mailto:VeevaConnect@veeva.com'} target="_blank">
                VeevaConnect@veeva.com
              </a>
            </li>
            <li>
              <b>Notice to California Residents.</b> If you are a California resident, under California Civil Code
              Section 1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Service of the
              California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
              California 95834, or by telephone at +1-800-952-5210 in order to resolve a complaint regarding the Service
              or to receive further information regarding use of the Service.
            </li>
            <li>
              <b>Support.</b> We are under no obligation to provide support for the Service. In instances where we may
              offer support, the support will be subject to published policies.
            </li>
            <li>
              <b>International Use.</b> Access to the Service from countries or territories or by individuals where such
              access is illegal is prohibited.
            </li>
            <li>
              <b>Prevailing Language.</b> These Terms and the related documents if any, have been drafted in English.
              Any translations to any other language shall be for convenience and for information purposes only. In case
              of conflict between the English version and another language version, the English version of these Terms
              shall prevail in all matters related to these Terms.
            </li>
          </ol>
        </li>
      </ol>
    </LayoutSingleCol>
  )
}
