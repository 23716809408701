import 'react-bootstrap-typeahead/css/Typeahead.scss'
import { AsyncTypeahead, Hint, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead'
import '@css/common/TypeaheadSearch.scss'
import { useNavigate } from 'react-router'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import React, { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import searchClear from '@web/images/community/search-clear.svg'
import searchIcon from '@web/images/community/icon-search.svg'
import advancedSearchIcon from '@web/images/search/icon-advanced-search.svg'
import { TypeaheadInputProps } from 'react-bootstrap-typeahead/types/types'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'
import { advancedSearchClicked, searchAbandoned, searchClicked } from '~/common/EventLogger'
import { useCommunity } from '~/contexts/CommunityContext'
import { GetCommunityDocument } from '~/api/generated/graphql'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { debounce, getMaxTypeaheadSlots } from '~/utils'
import { useLocation } from 'react-router-dom'
import { useTypeahead } from '~/contexts/TypeaheadContext'
import { AdvancedSearchState } from '~/pages/search/AdvancedSearch'
import { useAuth } from '~/auth/Auth'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { Form } from 'react-bootstrap'
import { useQuery } from '@apollo/client'

export enum SearchObjectType {
  community = 'Communities',
  company = 'Companies',
  user = 'Users',
  content = 'Content',
  summit = 'Summit',
  advancedLink = 'Advanced',
  release = 'Release',
}

export type SearchObject = {
  title: string
  type: SearchObjectType
  photo?: string | null
  subtitle?: string
  link: string
  visibleCompany?: boolean
  isHomepage?: boolean
  hidden?: boolean
  sortKey: string
  secondarySortKey?: string
  sortIndex?: number
  idKey: string
}

type TypeaheadSearchProps = {
  options: SearchObject[]
  handleSearch: (search: string) => void
  fullHeaderView: boolean
}

// Wraps any state updates from the Typeahead menu in useEffect so that other components can render concurrently:
// https://stackoverflow.com/a/63290045
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const TypeaheadStateWrapper = ({ update }) => {
  useEffect(update)
  return null
}

const longestPrefixLength = (a: string, b: string) => {
  const minLen = Math.min(a.length, b.length)
  for (let i = 0; i < minLen; i++) {
    if (a[i] !== b[i]) {
      return i
    }
  }
  return minLen
}

const saveRecentSearch = (searchKey: string) => {
  let key = searchKey
  if (searchKey.startsWith('Summit')) {
    // Summit objects are stored as release objects in the db
    key = searchKey.replace('Summit', 'Release')
  }
  let recentSearches = JSON.parse(localStorage.getItem('RECENT_SEARCH_SELECTION') ?? '[]') as string[]
  recentSearches = [key].concat(recentSearches.filter(r => r != key)).slice(0, 5)
  localStorage.setItem('RECENT_SEARCH_SELECTION', JSON.stringify(recentSearches))
}

const TypeaheadSearch = ({ options, handleSearch, fullHeaderView }: TypeaheadSearchProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isCondensed } = useWindowSize()
  const { communityId } = useCommunity()
  const { myCommunitiesTotal } = useCommunities()
  const { searchInputRef } = useTypeahead()
  const { isVeevan } = useAuth()
  const [showClearButton, setShowClearButton] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(true)
  const [activeIndex, setActiveIndex] = useState(-1)
  const placeHolder = 'Search Veeva Connect'
  const isAdvancedSearch = location.pathname.toString().includes('/search')
  const [showMenu, setShowMenu] = useState(false)
  const [showingRecentSearches, setShowingRecentSearches] = useState(false)
  const [imeFinished, setImeFinished] = useState(true)
  const { focusSearch } = useShortcuts()

  const { data: communityData } = useQuery(GetCommunityDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
    fetchPolicy: 'cache-and-network',
  })

  const communityName = communityData?.community?.name

  const clearInput = () => {
    typeaheadRef.current?.clear()

    if (searchInputRef?.current) {
      searchInputRef.current.value = ''
      searchInputRef.current.blur()
    }

    if (isAdvancedSearch) {
      navigateToAdvancedSearch()
    }
  }

  const typeaheadRef = useRef<Typeahead>(null)
  const navigateToAdvancedSearch = useCallback(
    (filter?: string) => {
      typeaheadRef?.current?.hideMenu()
      const currentSearchTerm = searchInputRef?.current?.value ?? ''
      let searchNavigationState: AdvancedSearchState
      if (communityId && communityName) {
        searchNavigationState = {
          searchTerm: currentSearchTerm,
          filter: filter,
          freshSearch: true,
          scrollTop: 0,
          historyCommunityFacets: communityId && communityName ? [{ communityId, name: communityName }] : [],
        }
      } else {
        searchNavigationState = {
          searchTerm: currentSearchTerm,
          filter: filter,
          freshSearch: true,
          scrollTop: 0,
        }
      }
      navigate(`/search`, { state: searchNavigationState })
    },
    [searchInputRef, communityId, communityName, navigate]
  )

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      // activeIndex === -1 means no result item selected with arrow keys
      if (activeIndex === -1 && e.key === 'Enter' && imeFinished) {
        if (isAdvancedSearch) {
          navigateToAdvancedSearch()
        } else {
          navigateToAdvancedSearch('all')
        }
      }
    },
    [activeIndex, navigateToAdvancedSearch, isAdvancedSearch, imeFinished]
  )

  useEffect(() => {
    const searchTerm = history.state?.usr?.searchTerm ?? history.state?.searchTerm
    if (searchInputRef?.current && searchTerm && searchTerm !== '') {
      searchInputRef.current.value = searchTerm
      searchInputRef.current.blur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.state])

  useEffect(() => {
    if (focusSearch) {
      searchInputRef?.current?.focus()
    }
  }, [focusSearch, searchInputRef])

  useEffect(() => {
    globalThis.addEventListener('change', (e: Event) => {
      if (searchInputRef?.current && searchInputRef.current.value !== '') {
        searchAbandoned(e, searchInputRef.current.value, 'instant-search-abandoned')
      }
    })
  }, [searchInputRef])

  useEffect(() => {
    if (searchInputRef?.current?.value !== '') {
      setShowClearButton(true)
    }
  }, [searchInputRef])
  const { setFocusSearch } = useShortcuts()
  const advancedSearchOptions = (results: SearchObject[], posIndex: number, onlyShowAll?: boolean) => {
    return (
      <>
        <div key={'all-communities'}>
          <div className={'result-section'}>
            <MenuItem
              onClick={e => {
                const communityLink = 'all'
                advancedSearchClicked(e, communityLink)
                navigateToAdvancedSearch(communityLink)
              }}
              key={'all-communities-item'}
              className={`result-row advanced-link blue-highlights ${isCondensed && 'condensed'}`}
              option={results[0]}
              position={posIndex++}
            >
              <img className={`result-icon advanced`} src={advancedSearchIcon} alt={'Search'} />
              <div className={'info-column'}>
                <span className={'search-posts'}>Search posts in All Communities</span>
              </div>
            </MenuItem>
          </div>
        </div>
        {communityId && !onlyShowAll ? (
          <div key={`${communityName}-community`}>
            <div className={'result-section'}>
              <MenuItem
                onClick={e => {
                  const communityLink = communityId
                  advancedSearchClicked(e, communityLink)
                  navigateToAdvancedSearch(communityLink)
                }}
                key={`${communityName}-community-item`}
                className={`result-row advanced-link ${isCondensed && 'condensed'}`}
                option={results[2]}
                position={posIndex++}
              >
                <img className={`result-icon advanced`} src={advancedSearchIcon} alt={'Search'} />
                <div className={'info-column'}>
                  <span className={'search-posts'}>Search posts in {communityName}</span>
                </div>
              </MenuItem>
            </div>
          </div>
        ) : (
          <>
            {(myCommunitiesTotal ?? 0) > 1 && !onlyShowAll && (
              <div key={'my-communities'}>
                <div className={'result-section'}>
                  <MenuItem
                    onClick={e => {
                      const communityLink = 'my'
                      advancedSearchClicked(e, communityLink)
                      navigateToAdvancedSearch(communityLink)
                    }}
                    key={'my-communities-item'}
                    className={`result-row advanced-link ${isCondensed && 'condensed'}`}
                    option={results[1]}
                    position={posIndex++}
                  >
                    <img className={`result-icon advanced`} src={advancedSearchIcon} alt={'Search'} />
                    <div className={'info-column'}>
                      <span className={'search-posts'}>Search posts in My Communities</span>
                    </div>
                  </MenuItem>
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  const renderInput = (inputProps: TypeaheadInputProps) => {
    const { onFocus, onKeyDown, onBlur, onChange } = inputProps

    // Wait for IME such as Romaji to complete before allowing search query to initiate
    searchInputRef?.current?.addEventListener('compositionstart', () => {
      setImeFinished(false)
    })
    searchInputRef?.current?.addEventListener('compositionend', () => {
      setImeFinished(true)
    })

    return (
      <Hint>
        <div className={`global-search-container`}>
          <img src={searchIcon} className={'global-search-icon'} alt={'Global Search'} />
          <Form.Control
            id={'global-search-input'}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={e => {
              onBlur?.(e as FocusEvent<HTMLInputElement>)
              setFocusSearch?.(false)
            }}
            onChange={onChange}
            placeholder={placeHolder}
            ref={searchInputRef}
            data-testid={'global-search-input'}
            autoComplete={'off'}
          />
          {showClearButton && (
            <img
              src={searchClear}
              alt={'Clear Search'}
              className={'global-search-clear'}
              role={'button'}
              onClick={() => {
                setShowClearButton(false)
                clearInput()
              }}
              data-testid={'global-search-clear'}
            />
          )}
        </div>
      </Hint>
    )
  }

  const renderMenu = (results: SearchObject[], menuProps: RenderMenuProps) => {
    const searchString = searchInputRef?.current?.value ?? ''
    let groupedResults = new Map<SearchObjectType, SearchObject[]>()
    const filteredResults = results.filter(r => !r.hidden && r.title)
    for (let result of filteredResults) {
      result = { ...result }
      result.sortIndex =
        searchString.length - longestPrefixLength(searchString.toLowerCase(), result.title.toLowerCase())
      if (groupedResults.has(result.type)) groupedResults.get(result.type)?.push(result)
      else groupedResults.set(result.type, [result])
    }

    const keys = [
      SearchObjectType.release,
      SearchObjectType.summit,
      SearchObjectType.community,
      SearchObjectType.company,
      SearchObjectType.user,
      SearchObjectType.content,
    ]

    let displayedSlots = 0
    let totalItems = 0

    for (const k of keys) {
      if (!showingRecentSearches) {
        groupedResults.get(k)?.sort((a, b) => {
          if (a.sortIndex === b.sortIndex) return a.sortKey.localeCompare(b.sortKey)
          else return (a.sortIndex || 0) - (b.sortIndex || 0)
        })
      }
      displayedSlots += groupedResults.get(k)?.slice(0, k === SearchObjectType.release ? 5 : 2).length ?? 0
      totalItems += groupedResults.get(k)?.length ?? 0
    }

    const order = [
      SearchObjectType.content,
      SearchObjectType.release,
      SearchObjectType.user,
      SearchObjectType.community,
      SearchObjectType.company,
      SearchObjectType.summit,
    ]
    const sizes = new Map()
    const availableSlots = getMaxTypeaheadSlots(isVeevan)

    // Record how many matches we display before re-allocating space
    for (const element of order) {
      const slicedList = groupedResults.get(element)?.slice(0, element === SearchObjectType.release ? 5 : 2)
      sizes.set(element, slicedList?.length ?? 0)
    }

    let spotsLeft = availableSlots - displayedSlots
    let i = 0

    while (spotsLeft > 0 && displayedSlots < totalItems) {
      const totalMatches = groupedResults.get(order[i])?.length ?? 0 // total possible items of this category that matched
      const displayedMatches = sizes.get(order[i]) // how many matches we are displaying right now

      // If we have more items we can use, add them to what we are displaying
      if (displayedMatches < totalMatches) {
        sizes.set(order[i], displayedMatches + 1)
        spotsLeft -= 1
        displayedSlots += 1
      }

      i = (i + 1) % order.length
    }

    // When typing then deleting to an empty search string, we do not want to trigger searching on that empty string or showing the recently selected results.
    if (!showingRecentSearches && !searchString) {
      const advancedLinkResults = new Map<SearchObjectType, SearchObject[]>()
      advancedLinkResults.set(SearchObjectType.advancedLink, [
        { title: 'all', type: SearchObjectType.advancedLink, link: 'all', sortKey: 'all', idKey: '' },
        { title: 'my', type: SearchObjectType.advancedLink, link: 'my', sortKey: 'my', idKey: '' },
        {
          title: 'custom',
          type: SearchObjectType.advancedLink,
          link: communityId ?? 'custom',
          sortKey: 'custom',
          idKey: '',
        },
      ])
      groupedResults = advancedLinkResults
    }

    const advancedLinkResults = groupedResults.get(SearchObjectType.advancedLink)

    let posIndex = 0
    return (
      <div>
        <Menu
          className={`${loadingSearch ? 'loading' : ''}`}
          id={menuProps.id}
          ref={menuProps.ref}
          data-testid={'instant-search-container'}
        >
          {groupedResults.size > 0 && (
            <>
              {keys
                .filter(key => !!groupedResults.get(key)?.length)
                .map(key => (
                  <div key={key} className={'results-section-parent'}>
                    <div className={'result-section'}>
                      {groupedResults
                        .get(key)
                        ?.slice(
                          0,
                          showingRecentSearches
                            ? (groupedResults.get(key)?.length ?? 0)
                            : (sizes.get(key) ?? (key == SearchObjectType.release ? 5 : 2))
                        )
                        .map((result, i) => (
                          <MenuItem
                            onClick={e => {
                              saveRecentSearch(result.idKey)
                              searchClicked(e, key, i)
                            }}
                            key={posIndex}
                            className={`result-row ${result.hidden && 'deleted'}`}
                            option={result}
                            position={posIndex++}
                          >
                            <div
                              className={`result-icon ${isCondensed && 'condensed'} ${
                                result.type === SearchObjectType.release
                                  ? 'search-release-icon'
                                  : result.type === SearchObjectType.content || result.type === SearchObjectType.summit
                                    ? `search-content-icon`
                                    : `profile-photo no-margin ${
                                        result.type === SearchObjectType.company ||
                                        result.type === SearchObjectType.community
                                          ? 'community-photo'
                                          : ''
                                      }`
                              }`}
                              style={result.photo ? { backgroundImage: `url(${result.photo})` } : {}}
                            />
                            <div className={'info-column'}>
                              <h5>{result.title}</h5>
                              <div className={`subtitle ${result.type === SearchObjectType.user ? 'user' : ''}`}>
                                {result.subtitle}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                    </div>
                  </div>
                ))}
              {advancedLinkResults && advancedSearchOptions(advancedLinkResults, posIndex, showingRecentSearches)}
            </>
          )}
        </Menu>
      </div>
    )
  }

  const debouncedSearchResults = useMemo(() => debounce(() => setLoadingSearch(false), 300), [])
  const bufferSearch = () => {
    setLoadingSearch(true)
    void debouncedSearchResults()
    setShowMenu(true)
  }

  return (
    <>
      <AsyncTypeahead
        ref={typeaheadRef}
        id={'typeahead-search'}
        className={`${fullHeaderView ? '' : 'extra-padding '}${isCondensed ? 'mobile' : ''}`}
        options={options}
        placeholder={placeHolder}
        labelKey={'title'}
        filterBy={() => true}
        renderMenu={renderMenu}
        renderInput={renderInput}
        isLoading={false}
        maxResults={100000}
        minLength={1}
        onSearch={handleSearch}
        onKeyDown={handleKeydown}
        onFocus={() => {
          setShowingRecentSearches(searchInputRef?.current?.value == '')
          handleSearch(searchInputRef?.current?.value ?? '')
          bufferSearch()
        }}
        onBlur={() => {
          setShowMenu(false)
        }}
        open={showMenu}
        onInputChange={(text: string) => {
          bufferSearch()
          // we only show recent searches when focusing into the input box, not when typing even if empty search string
          setShowingRecentSearches(false)
          if (text) setShowClearButton(true)
          else setShowClearButton(false)
        }}
        onChange={selected => {
          const castedSelect = (selected as SearchObject[])[0]
          if (castedSelect) {
            const link = castedSelect.link
            if (castedSelect.type === SearchObjectType.advancedLink) {
              navigateToAdvancedSearch(link)
            } else {
              clearInput()
              navigate(link)
            }
          }
        }}
        inputProps={{}}
      >
        {({ activeIndex }) => (
          <TypeaheadStateWrapper
            update={() => {
              setActiveIndex(activeIndex)
            }}
          />
        )}
      </AsyncTypeahead>
    </>
  )
}

export default TypeaheadSearch
