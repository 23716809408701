import React, { useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import '@css/pages/company/GlobalLeadSelector.scss'
import '@css/pages/company/CompanyMemberRow.scss'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import Globe from '@web/images/community/globe-icon.svg'

interface GlobalLeadSelectorProps {
  userId: string
  type: string
  accountPartnerChecked: boolean
  globalAccountChecked: boolean
  globalCommercialChecked: boolean
  globalQualityChecked: boolean
  handleAccountPartnerCheck: () => void
  handleGlobalAccountCheck: () => void
  handleGlobalCommercialCheck: () => void
  handleGlobalQualityCheck: () => void
  handleRolesDone: () => void
  showGlobe: boolean
}

const GlobalLeadSelector = ({
  userId,
  type,
  accountPartnerChecked,
  globalAccountChecked,
  globalCommercialChecked,
  globalQualityChecked,
  handleAccountPartnerCheck,
  handleGlobalAccountCheck,
  handleGlobalCommercialCheck,
  handleGlobalQualityCheck,
  handleRolesDone,
  showGlobe,
}: GlobalLeadSelectorProps) => {
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.lg
  const toggleRef = useRef<HTMLDivElement>(null)

  const handleToggle = (nextShow: boolean) => {
    if (!nextShow) handleRolesDone()
  }

  return (
    <div className={`global-lead-selector ${isCondensed ? '' : 'company-type-column'}`}>
      <Dropdown
        ref={toggleRef}
        id="dropdown-member-type"
        className={`${isCondensed ? 'condensed' : ''}`}
        role="type-dropdown"
        onToggle={handleToggle}
      >
        <Dropdown.Toggle id="dropdown-basic" role={'dropdown-toggle'}>
          {showGlobe && <img className={'globe'} src={Globe} alt={'Global'} />}
          <span className={'member-leader'}>{type}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
          <div className={'role-checkboxes'}>
            <div>
              <input
                type="checkbox"
                id={`account-partner${userId}`}
                name="interest"
                onChange={handleAccountPartnerCheck}
                checked={accountPartnerChecked}
                role={'checkbox'}
              />
              <label htmlFor={`account-partner${userId}`}>Account Partner</label>
            </div>
            <hr
              style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: 0.5,
                borderColor: '#000000',
              }}
            />
            <div>
              <input
                type="checkbox"
                id={`account${userId}`}
                onChange={handleGlobalAccountCheck}
                checked={globalAccountChecked}
                role={'checkbox'}
              />
              <label htmlFor={`account${userId}`}>Global Account Lead</label>
            </div>
            <div>
              <input
                type="checkbox"
                id={`commercial${userId}`}
                onChange={handleGlobalCommercialCheck}
                checked={globalCommercialChecked}
                role={'checkbox'}
              />
              <label htmlFor={`commercial${userId}`}>Global Commercial Lead</label>
            </div>
            <div>
              <input
                type="checkbox"
                id={`quality${userId}`}
                onChange={handleGlobalQualityCheck}
                checked={globalQualityChecked}
                role={'checkbox'}
              />
              <label htmlFor={`quality${userId}`}>Global R&D and Quality Lead</label>
            </div>
          </div>
          <span id={'done'} onClick={() => toggleRef?.current?.click()} role={'button'}>
            Done
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default GlobalLeadSelector
