import React, { SyntheticEvent, useMemo, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useAuth } from '~/auth/Auth'
import ToastComponent from '~/common/ToastComponent'
import { usePermissions } from '~/pages/posts/PostUtils'
import {
  CacheCommunityFragment,
  CacheCommunityFragmentDoc,
  CanAddContentDocument,
  CanAddContentQuery,
  CommunityType,
  GetContentsDocument,
  GetContentsQuery,
  GetDropdownItemsCommunityDocument,
  GetDropdownItemsPostDocument,
  GetDropdownItemsRepostDocument,
  GetDropdownItemsUserDocument,
  GetOriginalDropdownItemsCommunityDocument,
  GetPostsDocument,
  GetPostsQuery,
  GetThreadEmailsDocument,
  MovePostDocument,
  PostType,
  SetFeaturedContentDocument,
  SetHidePostDocument,
} from '~/api/generated/graphql'
import { asHtmlWithMentions, getPostPath, searchCache } from '~/utils'
import { Link } from 'react-router-dom'
import { elementClicked } from '~/common/EventLogger'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import PostConvertModal from '~/pages/posts/PostConvertModal'
import MentionableText from '~/common/MentionableText'
import '@css/pages/posts/PostDropdownItems.scss'
import PageCommunityTypeahead from '~/pages/page/PageCommunityTypeahead'
import { CommunityToDisplay } from '~/pages/page/PostSelectionModal'

type PostDropdownItemsProps = {
  postId: string
  onDelete?: (id: string) => void
  onClickEdit?: () => void
  isCommPost?: boolean
  isRecentActivity?: boolean
  showEditor?: boolean
  postListPath?: string
  fromContentPage?: boolean
  isSinglePost?: boolean
}

export const PostDropdownItems = ({
  postId,
  onDelete,
  onClickEdit,
  isCommPost,
  isRecentActivity,
  showEditor,
  postListPath,
  fromContentPage,
  isSinglePost,
}: PostDropdownItemsProps) => {
  const { data: postData } = useQuery(GetDropdownItemsPostDocument, { variables: { id: postId } })
  const { post } = postData ?? {}
  const navigate = useNavigate()
  const { isVeevan, authUserId, actingSysAdmin } = useAuth()
  const { hasLeaderPermissions } = usePermissions(post?.createdById)
  const authorId = post?.createdById
  const { canEdit, canDelete } = usePermissions(authorId, postId)
  const { data: authorData } = useQuery(GetDropdownItemsUserDocument, {
    variables: { id: authorId ?? '' },
    skip: !authorId,
  })
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showConvertModal, setShowConvertModal] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false)
  const [showMovePostModal, setShowMovePostModal] = useState<boolean>(false)
  const [showMovePostToast, setShowMovePostToast] = useState<boolean>(false)
  const [moveDisabled, setMoveDisabled] = useState<boolean>(true)
  const [communitiesOptions, setCommunitiesOptions] = useState<CommunityToDisplay[]>([])
  const [moveCommunityId, setMoveCommunityId] = useState('')
  const [sourceCommunityId, setSourceCommunityId] = useState('')

  const isRepost = post?.isRepost
  const isDraft = post?.draft
  const isContent = post?.postType === PostType.Content
  const { data: originalPostData } = useQuery(GetDropdownItemsRepostDocument, {
    variables: { id: post?.repostId ?? '' },
    skip: !post?.repostId,
  })

  // These hooks are mutually exclusive, but avoid a more expensive query for non-leaders and posts that aren't content
  const { data: communityDataFull } = useQuery(GetDropdownItemsCommunityDocument, {
    variables: { id: post?.communityId ?? '' },
    skip: !post?.communityId || !(hasLeaderPermissions && isContent),
  })
  const { data: communityData } = useQuery(GetOriginalDropdownItemsCommunityDocument, {
    variables: { id: post?.communityId ?? '' },
    skip: !post?.communityId || (hasLeaderPermissions && isContent),
  })
  const community = (communityDataFull || communityData)?.community
  const postCommunityId = community?.communityId

  const { data: originalCommunityData } = useQuery(GetOriginalDropdownItemsCommunityDocument, {
    variables: { id: post?.repost?.communityId ?? '' },
    skip: !post?.repost?.communityId,
  })
  const originalCommunity = originalCommunityData?.community
  const originalPost = originalPostData?.post
  const createdById = originalPost?.createdById
  const { data: createdByData } = useQuery(GetDropdownItemsUserDocument, {
    variables: { id: createdById ?? '' },
    skip: !createdById,
  })

  const isPublicPost = isRepost
    ? originalCommunity?.type === CommunityType.Public
    : community?.type === CommunityType.Public
  const isPostByVeevan = isRepost ? createdByData?.user?.isVeevan : authorData?.user?.isVeevan
  const canRepost = isVeevan && isPublicPost && isPostByVeevan
  const canConvert = hasLeaderPermissions && (!isRepost || originalPost?.postType === PostType.Content)

  const singlePostPath = `${getPostPath(community, post, fromContentPage)}`
  const repostPath = isRepost // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    ? // @ts-ignore
      `${getPostPath(originalCommunity, originalPost)}/repost`
    : `${singlePostPath}/repost`

  const repostsCount = post?.repostsCount
  const hasReposts = (repostsCount ?? 0) > 0

  const [getThreadEmails] = useLazyQuery(GetThreadEmailsDocument, {
    variables: { postId },
    fetchPolicy: 'network-only',
  })

  const [movePost] = useMutation(MovePostDocument)

  const client = useApolloClient()
  const [hidePost] = useMutation(SetHidePostDocument, {
    update(cache, { data }) {
      const oldCount = cache.readQuery<CanAddContentQuery>({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
      })

      const oldData = oldCount?.canAddContent
      const newCount = (oldData?.count ?? 0) - (data?.setHidePost?.post?.draft ? 0 : 1)

      const newCountData = {
        canAddContent: {
          ok: true,
          count: newCount,
          max: oldData?.max ?? 42,
          __typename: 'ContentValidator' as const,
        },
      }

      client.writeQuery({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
        data: newCountData,
      })

      const oldPostsData = cache.readQuery<GetPostsQuery>({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
      })

      const newPostsData = {
        posts: {
          ...oldPostsData?.posts,
          pageInfo: {
            ...oldPostsData?.posts?.pageInfo,
            hasNextPage: oldPostsData?.posts?.pageInfo.hasNextPage ?? false,
          },
          edges: [
            ...(oldPostsData?.posts?.edges.filter(
              // hidden posts should still show up for admin
              n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
            ) ?? []),
          ],
        },
      }

      client.writeQuery({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        data: newPostsData,
      })

      if (isContent) {
        const oldContentData = cache.readQuery<GetContentsQuery>({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        })

        const newContentData = {
          posts: {
            ...oldContentData?.posts,
            edges: [
              ...(oldContentData?.posts?.edges.filter(
                // hidden posts should still show up for admin
                n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
              ) ?? []),
            ],
          },
        }

        client.writeQuery({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
          data: newContentData,
        })
      }
    },
  })

  const handleCloseLinkCopiedToast = () => setToastMessage(null)

  const handleCancelDelete = () => setShowDeleteDialog(false)
  const handleConfirmDelete = async (e: SyntheticEvent) => {
    setDeleteDisabled(true)
    await hidePost({ variables: { id: postId, value: true } })
    onDelete?.(postId)
    setDeleteDisabled(false)
    setShowDeleteDialog(false)
    elementClicked(e, isRecentActivity ? 'click-profile-recent-post-delete' : 'click-post-delete', {
      postId: postId,
    })
    if (isCommPost && postListPath) {
      navigate(postListPath)
    }
  }

  const handleConvert = (succeeded: boolean, wasContent: boolean) => {
    setShowConvertModal(false)
    if (succeeded) {
      navigate(`${getPostPath(community, post, !wasContent)}?cv=${wasContent ? PostType.Post : PostType.Content}`)
    }
  }
  const getThreadsEmailsWrapper = async () => {
    const response = await getThreadEmails()
    const emailText = response.data?.post?.threadEmails.join(',') ?? ''
    return new Blob([emailText], { type: 'text/plain' })
  }

  const handleCopyThread = (e: SyntheticEvent) => {
    elementClicked(e, 'click-post-email-participants', { postId: postId })
    navigator.clipboard
      .write([new ClipboardItem({ 'text/plain': getThreadsEmailsWrapper() })])
      .then(() => {
        setToastMessage('Thread participant emails copied to clipboard')
      })
      .catch(error => {
        console.log(error)
        setToastMessage('Unable to copy participants. Please try again.')
      })
  }

  const handleClickCopy = (e: SyntheticEvent) => {
    const permalink = `${document.location.origin}${singlePostPath}`
    void navigator.clipboard.writeText(permalink).then(() => setToastMessage('Link to post copied to clipboard.'))
    elementClicked(e, isRecentActivity ? 'click-profile-recent-post-copy-link' : 'click-post-copy-link', {
      postId,
    })
  }

  const handleClickPermalink = (e: SyntheticEvent) => {
    elementClicked(e, isRecentActivity ? 'click-profile-recent-post-single-view' : 'click-post-single-view', {
      postId,
    })
  }

  const isFeatured = useMemo(() => {
    return post?.featured ?? false
  }, [post])
  const featuredText = isFeatured ? 'Remove as Featured' : 'Set as Featured'

  const [setFeaturedContent] = useMutation(SetFeaturedContentDocument)

  const handleFeatureContent = async (e: SyntheticEvent) => {
    elementClicked(e, isRecentActivity ? 'click-profile-recent-featured' : 'click-post-featured', { postId: postId })
    if (communityDataFull?.community?.posts?.totalCount == 3 && !isFeatured) {
      setToastMessage('There are already three items set as featured, please remove at least one')
    } else {
      const title = post?.contentTitle ?? ''
      const response = await setFeaturedContent({
        variables: {
          id: postId,
          value: !isFeatured,
        },
      })
      if (response.data?.setFeaturedContent?.ok) {
        setToastMessage(`${title} ${isFeatured ? 'removed' : 'set'} as featured`)
      }
    }
  }

  const handleClickDelete = () => setShowDeleteDialog(true)
  const getDeletePostBody = () => {
    if (isRepost) {
      return 'Deleting this repost will not affect the original post or other reposts'
    } else if (hasReposts) {
      return (
        <>
          Deleting this post will also delete {repostsCount} {repostsCount === 1 ? `repost` : `reposts`}.{' '}
          <Link to={repostPath}>View reposts</Link>
        </>
      )
    } else {
      return 'Any comments will also be deleted.'
    }
  }
  const { cache } = useApolloClient()
  const handleSearch = (search: string) => {
    const normalizedSearch = search.normalize('NFKD')
    const filteredResults = searchCache(normalizedSearch)

    const newOptions = filteredResults.map(k => {
      const [objectType, id] = k.split(':')
      if (objectType === 'Community') {
        const community = cache.readFragment<CacheCommunityFragment>({
          id: cache.identify({ __typename: 'Community' as const, communityId: id }),
          fragment: CacheCommunityFragmentDoc,
        })

        if (community?.type == CommunityType.Public && community.communityId !== postCommunityId) {
          return {
            name: community?.name ?? '',
            communityId: community?.communityId ?? '',
          }
        }
      }

      return { name: '', communityId: '' }
    })

    setCommunitiesOptions(newOptions)
  }

  return (
    <>
      <Dropdown.Menu className={'post-dropdown-menu'}>
        {!isCommPost && (
          <Dropdown.Item className={'view'} as={Link} to={singlePostPath} onClick={handleClickPermalink}>
            View as single post
          </Dropdown.Item>
        )}
        <Dropdown.Item className={'copy-link'} onClick={handleClickCopy}>
          Copy link to {isDraft ? 'draft' : 'post'}
        </Dropdown.Item>
        {canEdit &&
          (showEditor ? (
            <Dropdown.Item
              className={'edit'}
              onClick={e => {
                elementClicked(e, isRecentActivity ? 'click-profile-recent-post-edit' : 'click-post-edit', {
                  postId: postId,
                })
                onClickEdit?.()
              }}
              data-testid={'edit-post-button'}
            >
              Edit {isDraft ? 'draft' : 'post'}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              className={'edit'}
              data-testid={'edit-post-button'}
              onClick={e => {
                elementClicked(e, isRecentActivity ? 'click-profile-recent-post-edit' : 'click-post-edit', {
                  postId: postId,
                })
              }}
              as={Link}
              to={`${singlePostPath}/?edit=1`}
            >
              Edit post
            </Dropdown.Item>
          ))}
        {canDelete && (
          <Dropdown.Item className={'delete'} onClick={handleClickDelete}>
            {isRepost ? 'Delete repost' : isDraft ? 'Delete draft' : 'Delete post'}
          </Dropdown.Item>
        )}
        {canRepost && !isDraft && (
          <Dropdown.Item
            className={'repost'}
            onClick={e => {
              elementClicked(e, isRecentActivity ? 'click-profile-recent-post-repost' : 'click-post-repost', {
                postId: postId,
              })
              navigate(repostPath)
            }}
          >
            Repost
          </Dropdown.Item>
        )}
        {canConvert && !isDraft && (
          <Dropdown.Item
            className={'convert'}
            onClick={e => {
              elementClicked(e, 'click-post-convert', { postId: postId })
              setShowConvertModal(true)
            }}
          >
            Convert to {isContent ? 'regular' : 'content'} post
          </Dropdown.Item>
        )}
        {isVeevan && !isDraft && (
          <Dropdown.Item className={'email'} onClick={handleCopyThread}>
            Email thread participants
          </Dropdown.Item>
        )}
        {hasLeaderPermissions && isContent && !isDraft && (
          <Dropdown.Item className={isFeatured ? 'feature-remove' : 'feature-set'} onClick={handleFeatureContent}>
            {featuredText}
          </Dropdown.Item>
        )}
        {actingSysAdmin && community?.type === CommunityType.Public && !post?.isRepost && (
          <Dropdown.Item className={'move'} onClick={() => setShowMovePostModal(true)} data-testid={'move-post-button'}>
            Move Post
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
      <Modal show={showDeleteDialog} onHide={handleCancelDelete} className={'delete-post'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>
            Are you sure you want to delete this {isRepost ? 'repost' : isDraft ? 'draft' : 'post'}?
          </p>
          <div className={'modal-sub-message'}>
            {isContent ? (
              post?.contentTitle
            ) : (
              <MentionableText value={asHtmlWithMentions(post?.title?.htmlWithMentions)} />
            )}
          </div>
          {!isDraft && <p className={`notation ${hasReposts ? 'warning' : ''}`}>{getDeletePostBody()}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={deleteDisabled}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMovePostModal} onHide={() => setShowMovePostModal(false)} className={'move-post'}>
        <Modal.Header closeButton>Move post to another community</Modal.Header>
        <Modal.Body>
          <h3 className={'message'}>Destination Community</h3>
          <PageCommunityTypeahead
            handleSearch={handleSearch}
            onSelect={(s: string) => {
              setMoveCommunityId(s)
              setMoveDisabled(false)
            }}
            options={communitiesOptions}
            onClear={() => {
              setMoveDisabled(true)
              setMoveCommunityId('')
            }}
            placeholder={'Search communities'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              setShowMovePostModal(false)
              setMoveCommunityId('')
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            data-testid={'move-post-modal-confirm-button'}
            onClick={() => {
              setSourceCommunityId(community?.communityId ?? '')
              setMoveDisabled(true)
              void movePost({
                variables: {
                  postId: postId,
                  communityId: moveCommunityId,
                },
              }).then(r => {
                if (r.data?.movePost?.ok) {
                  setShowMovePostModal(false)
                  setShowMovePostToast(true)
                } else {
                  console.log(`post move failed. Error: ${r.data?.movePost?.error?.message}`)
                }
              })
            }}
            disabled={moveDisabled}
          >
            Move Post
          </Button>
        </Modal.Footer>
      </Modal>
      {canConvert && !isDraft && (
        <PostConvertModal
          show={showConvertModal}
          onConvert={handleConvert}
          postId={postId}
          wasContent={isContent}
          existingContentTitle={post?.contentTitle}
          existingPostTitle={post?.title?.htmlWithMentions}
        />
      )}
      <ToastComponent onClose={handleCloseLinkCopiedToast} show={!!toastMessage}>
        {toastMessage ?? ''}
      </ToastComponent>
      <ToastComponent
        onClose={() => {
          if (isSinglePost) {
            globalThis.location.assign(`/communities/${sourceCommunityId}/posts`)
          } else {
            globalThis.location.reload()
          }
        }}
        show={showMovePostToast}
      >
        {'The post was moved successfully'}
      </ToastComponent>
    </>
  )
}
