import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CommunityListViewModel, FeedCommunityListViewModel } from '~/types'
import { ApolloError, useQuery } from '@apollo/client'
import {
  CommunitySortEnum,
  GetCommunitiesByNameDocument,
  GetMyCommunitiesDocument,
  GetMyCustomerHomepagesDocument,
  GetSuggestedCommunitiesDocument,
  GetUserCommunitiesDocument,
} from '~/api/generated/graphql'
import { asCommunities, asRecommendedCommunities, communitiesToCommunitiesListView, debounce } from '~/utils'
import { useAuth } from '~/auth/Auth'
import { Outlet } from 'react-router'

export type CommunitiesContextType = {
  loadingAll: boolean
  loadingMine: boolean
  loadingRecommended: boolean
  errorAll?: ApolloError
  errorMine?: ApolloError
  errorRecommended?: ApolloError
  searchAll: (searchAll: string) => void
  searchMine: (searchMine: string) => void
  allCommunitiesTotal?: number
  myCommunitiesTotal?: number
  recommendedCommunitiesTotal?: number
  allCommunities?: CommunityListViewModel[]
  myCommunities?: CommunityListViewModel[]
  recommendedCommunities?: CommunityListViewModel[]
  allMyCommunities?: CommunityListViewModel[]
  homepages?: FeedCommunityListViewModel[]
  homepagesLoading: boolean
  reloadHomepages: () => void
  unjoinedCommunities?: CommunityListViewModel[]
  loadRecommended: () => void
}

export const CommunitiesContext = React.createContext<CommunitiesContextType>({
  loadingAll: false,
  loadingMine: false,
  loadingRecommended: false,
  homepagesLoading: false,
  searchAll: () => {},
  searchMine: () => {},
  reloadHomepages: () => {},
  loadRecommended: () => {},
})

export const CommunitiesProvider = ({ children }: { children: ReactNode }) => {
  const { authUserId, canPostIds } = useAuth()
  const {
    loading: loadingAll,
    error: errorAll,
    data: dataAll,
    refetch: refetchAll,
  } = useQuery(GetCommunitiesByNameDocument, {
    variables: { communityFilter: { isHomepage: false }, communitySort: [CommunitySortEnum.NameAsc] },
  })
  const {
    loading: loadingMine,
    error: errorMine,
    data: dataMine,
    refetch: refetchMine,
    previousData: previousDataMine,
  } = useQuery(GetMyCommunitiesDocument, {
    variables: { communityFilter: {}, communitySort: [CommunitySortEnum.NameAsc] },
    notifyOnNetworkStatusChange: true,
  })
  const { data } = useQuery(GetUserCommunitiesDocument, { variables: { id: authUserId ?? '' }, skip: !authUserId })
  const communityIds = useMemo(() => data?.user?.memberships?.edges.map(e => e?.node?.communityId) || [], [data])

  const {
    loading: loadingRecommended,
    error: errorRecommended,
    data: dataRecommended,
    refetch: refetchRecommended,
  } = useQuery(GetSuggestedCommunitiesDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const loadRecommended = () => {
    void refetchRecommended()
  }
  const allCommunitiesData = dataAll?.communities
  const allCommunities: CommunityListViewModel[] = useMemo(
    () => (allCommunitiesData && asCommunities(allCommunitiesData)) ?? [],
    [allCommunitiesData]
  )

  const myCommunitiesData = dataMine ? dataMine?.currentUser?.communities : previousDataMine?.currentUser?.communities
  const myCommunities = useMemo(
    () => communitiesToCommunitiesListView(dataMine || previousDataMine, canPostIds) ?? [],
    [dataMine, previousDataMine, canPostIds]
  )
  const recommendedCommunitiesData = dataRecommended?.suggestedCommunities
  const recommendedCommunities: CommunityListViewModel[] = useMemo(
    () =>
      (recommendedCommunitiesData && asRecommendedCommunities(recommendedCommunitiesData))
        ?.filter(c => !communityIds?.includes(c.communityId))
        .sort(function (a, b) {
          return a.name.localeCompare(b.name)
        }) ?? [],
    [recommendedCommunitiesData, communityIds]
  )

  const unjoinedCommunities: CommunityListViewModel[] = useMemo(() => {
    const joinedCommIds = new Set(myCommunitiesData?.edges?.map(edge => edge?.node?.communityId ?? '') ?? [])
    return allCommunities.filter(comm => !joinedCommIds.has(comm.communityId))
  }, [allCommunities, myCommunitiesData])

  const [allMyCommunities, setAllMyCommunities] = useState<CommunityListViewModel[]>([])
  const hasRefetched = useRef<boolean>(false)
  const allCommunitiesTotal = allCommunitiesData?.edges?.length ?? 0
  const myCommunitiesTotal = myCommunitiesData?.edges?.length ?? 0
  const recommendedCommunitiesTotal = recommendedCommunitiesData?.length ?? 0

  useEffect(() => {
    // store results from first call in a property to avoid having to repeat network call to get all communities
    if (!hasRefetched.current) {
      setAllMyCommunities(myCommunities)
    }
  }, [myCommunities, setAllMyCommunities])

  const searchAll = useMemo(() => {
    const func = debounce((search: string) => {
      void refetchAll({
        communityFilter: search
          ? { and: [{ or: [{ nameLike: `%${search}%` }, { aliasLike: `%${search}%` }] }, { isHomepage: false }] }
          : { isHomepage: false },
      }).then()
    }, 300)

    return (search: string) => {
      void func(search)
    }
  }, [refetchAll])

  const searchMine = useMemo(() => {
    const func = debounce((search: string) => {
      void refetchMine({
        communityFilter: search ? { or: [{ nameLike: `%${search}%` }, { aliasLike: `%${search}%` }] } : {},
      }).then()
    }, 300)
    return (search: string) => {
      void func(search)
    }
  }, [refetchMine])

  const {
    data: homepagesData,
    loading: homepagesLoading,
    refetch: refetchHomepages,
  } = useQuery(GetMyCustomerHomepagesDocument, {
    skip: !authUserId,
    fetchPolicy: 'cache-and-network',
  })

  const homepages = useMemo(() => {
    if (!homepagesLoading && homepagesData) {
      return (
        (homepagesData?.currentUser?.homepages?.edges
          .map(e => {
            return {
              companyId: e?.node?.companyId,
              name: e?.node?.name,
              photo: e?.node?.photo,
              description: e?.node?.description,
              link: `/companies/${e?.node?.companyId}`,
              communityId: e?.node?.communityId,
            }
          })
          .filter(Boolean) as FeedCommunityListViewModel[]) ?? []
      )
    }
  }, [homepagesLoading, homepagesData])

  const reloadHomepages = () => void refetchHomepages()

  return (
    <CommunitiesContext.Provider
      value={{
        loadingAll,
        loadingMine: loadingMine,
        loadingRecommended,
        homepagesLoading: homepagesLoading && !homepagesData,
        reloadHomepages,
        homepages,
        errorAll,
        errorMine,
        errorRecommended,
        searchAll,
        searchMine,
        myCommunities,
        allCommunities,
        recommendedCommunities,
        allCommunitiesTotal,
        myCommunitiesTotal,
        recommendedCommunitiesTotal,
        allMyCommunities,
        unjoinedCommunities,
        loadRecommended,
      }}
    >
      {children}
    </CommunitiesContext.Provider>
  )
}

export const useCommunities = () => useContext(CommunitiesContext)

export const RequireCommunities = () => {
  return (
    <CommunitiesProvider>
      <Outlet />
    </CommunitiesProvider>
  )
}
