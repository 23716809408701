import { RefObject, useEffect, useRef } from 'react'

const clickOnEnter = <T extends HTMLElement>(ref: RefObject<T>) => {
  const ButtonPressEnter = (e: KeyboardEvent) => {
    if (e.key == 'Enter') {
      ref?.current?.click()
    }
  }
  ref?.current?.addEventListener('keydown', ButtonPressEnter)
  return () => {
    ref?.current?.removeEventListener('keydown', ButtonPressEnter)
  }
}

export const useClickOnEnter = <T extends HTMLElement>() => {
  const ref = useRef<T>(null)
  useEffect(() => clickOnEnter(ref), [])
  return ref
}
