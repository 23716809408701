type MemberRemoveProps = {
  fill: string
}

const MemberRemove = ({ fill }: MemberRemoveProps) => {
  return (
    <svg width="30" height="30" viewBox="0 0 45 36" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2656 21.875H12.1641C5.41406 21.875 0 27.3594 0 34.1094C0 35.4453 1.05469 36.5 2.39062 36.5H29.0391C30.375 36.5 31.5 35.4453 31.5 34.1094C31.5 27.3594 26.0156 21.875 19.2656 21.875ZM15.75 18.5C20.6719 18.5 24.75 14.4922 24.75 9.5C24.75 4.57812 20.6719 0.5 15.75 0.5C10.7578 0.5 6.75 4.57812 6.75 9.5C6.75 14.4922 10.7578 18.5 15.75 18.5ZM40.5703 16.25L43.875 12.9453C44.5781 12.2422 44.5781 11.1875 43.875 10.5547C43.2422 9.85156 42.1875 9.85156 41.4844 10.5547L38.25 13.9297L34.875 10.625C34.2422 9.92188 33.1875 9.92188 32.5547 10.625C31.8516 11.2578 31.8516 12.3125 32.5547 13.0156L35.8594 16.3203L32.5547 19.625C31.8516 20.2578 31.8516 21.3125 32.5547 22.0156C33.1875 22.6484 34.2422 22.6484 34.875 22.0156L38.25 18.6406L41.5547 21.9453C42.1875 22.6484 43.2422 22.6484 43.875 21.9453C44.5781 21.3125 44.5781 20.2578 43.875 19.5547L40.5703 16.25Z"
        fill={fill}
      />
    </svg>
  )
}

export default MemberRemove
