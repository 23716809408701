import React, { SyntheticEvent } from 'react'
import '@css/common/BadgeComponent.scss'
import { contentIconSrc } from '~/utils'
import { MediaType } from '~/api/generated/graphql'
import { Maybe } from '~/api/generated/graphql'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'

interface ContentBadgeProps {
  featuredContent?: Maybe<{
    contentTitle?: Maybe<string>
    mediaType?: Maybe<MediaType>
    hidden?: Maybe<boolean>
    featured?: Maybe<boolean>
  }>
  clickEvent: (e: SyntheticEvent) => void
  className?: string
}

export const ContentBadgeComponent = ({ featuredContent, clickEvent, className }: ContentBadgeProps) => {
  const badgeRef = useClickOnEnter<HTMLDivElement>()

  const title = featuredContent?.contentTitle ?? ''

  return (
    <div
      className={`content-badge${featuredContent?.hidden ? ' deleted' : ''} ${className}`}
      title={title}
      onClick={clickEvent}
      tabIndex={0}
      ref={badgeRef}
    >
      <div className="badge-body">
        <img alt="" src={contentIconSrc(featuredContent?.mediaType ?? MediaType.Other)} className={'content-icon'} />
        <div className={'content-info'}>
          <p className={'title'}>{title}</p>
        </div>
      </div>
    </div>
  )
}
