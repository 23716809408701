import React, { KeyboardEvent } from 'react'
import '@css/common/AddPersonBox.scss'
import { FacetResponse } from '~/pages/search/AdvancedSearch'
import ReactDatePicker from 'react-datepicker'

export class SelectedTimeFacet {
  static readonly ANY = new SelectedTimeFacet('Any Time', undefined)
  static readonly DAY = new SelectedTimeFacet('Past Day', 'Today')
  static readonly WEEK = new SelectedTimeFacet('Past Week', 'Week')
  static readonly MONTH = new SelectedTimeFacet('Past Month', 'Month')
  static readonly QUARTER = new SelectedTimeFacet('Past 3 Months', 'Quarter')
  static readonly CUSTOM = new SelectedTimeFacet('Custom', undefined)

  private constructor(
    public readonly label: string,
    public readonly requestString: string | undefined
  ) {}
}

type TimeFacetsProps = {
  responseTimeFacets: FacetResponse[]
  startDate: Date
  endDate: Date
  onSelectTime: (range: SelectedTimeFacet) => void
  selectedTime: SelectedTimeFacet
  onSelectStartDate: (date: Date) => void
  onSelectEndDate: (date: Date) => void
}

const TimeFacets = ({
  responseTimeFacets,
  startDate,
  endDate,
  onSelectTime,
  selectedTime,
  onSelectStartDate,
  onSelectEndDate,
}: TimeFacetsProps) => {
  const handleKeyPress = (e: KeyboardEvent, time: SelectedTimeFacet) => {
    if (e.key == 'Enter') {
      onSelectTime(time)
    }
  }

  return (
    <form className={'time-facets'}>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.ANY.label}
          type={'radio'}
          value={SelectedTimeFacet.ANY.label}
          checked={selectedTime.label === SelectedTimeFacet.ANY.label}
          onChange={() => onSelectTime(SelectedTimeFacet.ANY)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.ANY)}
          role={'input'}
          data-testid={'member-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.ANY.label} className={'time-range-label'}>
          {SelectedTimeFacet.ANY.label}
        </label>
        <span className={'count'}>({responseTimeFacets?.find(f => f.key === 'Any')?.count ?? 0})</span>
      </div>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.DAY.label}
          type={'radio'}
          value={SelectedTimeFacet.DAY.label}
          checked={selectedTime.label === SelectedTimeFacet.DAY.label}
          onChange={() => onSelectTime(SelectedTimeFacet.DAY)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.DAY)}
          role={'input'}
          data-testid={'day-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.DAY.label} className={'time-range-label'}>
          {SelectedTimeFacet.DAY.label}
        </label>
        <span className={'count'}>({responseTimeFacets?.find(f => f.key === 'Today')?.count ?? 0})</span>
      </div>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.WEEK.label}
          type={'radio'}
          value={SelectedTimeFacet.WEEK.label}
          checked={selectedTime.label === SelectedTimeFacet.WEEK.label}
          onChange={() => onSelectTime(SelectedTimeFacet.WEEK)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.WEEK)}
          role={'input'}
          data-testid={'week-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.WEEK.label} className={'time-range-label'}>
          {SelectedTimeFacet.WEEK.label}
        </label>
        <span className={'count'}>({responseTimeFacets?.find(f => f.key === 'Week')?.count ?? 0})</span>
      </div>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.MONTH.label}
          type={'radio'}
          value={SelectedTimeFacet.MONTH.label}
          checked={selectedTime.label === SelectedTimeFacet.MONTH.label}
          onChange={() => onSelectTime(SelectedTimeFacet.MONTH)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.MONTH)}
          role={'input'}
          data-testid={'month-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.MONTH.label} className={'time-range-label'}>
          {SelectedTimeFacet.MONTH.label}
        </label>
        <span className={'count'}>({responseTimeFacets?.find(f => f.key === 'Month')?.count ?? 0})</span>
      </div>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.QUARTER.label}
          type={'radio'}
          value={SelectedTimeFacet.QUARTER.label}
          checked={selectedTime.label === SelectedTimeFacet.QUARTER.label}
          onChange={() => onSelectTime(SelectedTimeFacet.QUARTER)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.QUARTER)}
          role={'input'}
          data-testid={'quarter-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.QUARTER.label} className={'time-range-label'}>
          {SelectedTimeFacet.QUARTER.label}
        </label>
        <span className={'count'}>({responseTimeFacets?.find(f => f.key === 'Quarter')?.count ?? 0})</span>
      </div>
      <div className={'time-range-row'}>
        <input
          id={SelectedTimeFacet.CUSTOM.label}
          type={'radio'}
          value={SelectedTimeFacet.CUSTOM.label}
          checked={selectedTime.label === SelectedTimeFacet.CUSTOM.label}
          onChange={() => onSelectTime(SelectedTimeFacet.CUSTOM)}
          onKeyDown={e => handleKeyPress(e, SelectedTimeFacet.CUSTOM)}
          role={'input'}
          data-testid={'custom-radio-button'}
        />
        <label htmlFor={SelectedTimeFacet.CUSTOM.label} className={'time-range-label'}>
          <b>{SelectedTimeFacet.CUSTOM.label}</b>
        </label>
      </div>
      {selectedTime.label == SelectedTimeFacet.CUSTOM.label && (
        <div className="picker-row">
          <div className="picker">
            <h6>Start Date</h6>
            <ReactDatePicker
              id="event-start-date"
              maxDate={new Date()}
              selected={startDate}
              onChange={onSelectStartDate}
            />
          </div>
          <div className="picker">
            <h6>End Date</h6>
            <ReactDatePicker
              id="event-end-date"
              minDate={startDate}
              maxDate={new Date()}
              selected={endDate}
              onChange={onSelectEndDate}
            />
          </div>
        </div>
      )}
    </form>
  )
}

export default TimeFacets
