import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { PageType } from '~/api/generated/graphql'

export enum SummitTitleErrorType {
  NO_TITLE,
  NEW_UNSAVED,
}

export type SummitCategoryPostsContextType = {
  updateEditModeRows?: (s: string, b: boolean, e?: SummitTitleErrorType) => void
  updateAllPendingRows?: (s: string, b: boolean, e?: SummitTitleErrorType) => void
  editModePostIds?: Map<string, SummitTitleErrorType>
  setEditModePostIds?: React.Dispatch<React.SetStateAction<Map<string, SummitTitleErrorType>>>
  pageType?: PageType
  hasEditModeSummitRows?: boolean
  postsWithErrors?: Set<string>
  setPostsWithErrors?: React.Dispatch<React.SetStateAction<Set<string> | undefined>>
  allPendingPostIds?: Set<string>
  setAllPendingPostIds?: React.Dispatch<React.SetStateAction<Set<string> | undefined>>
  removePostWithError?: (s: string) => void
}
export const SummitCategoryPostsContext = React.createContext<SummitCategoryPostsContextType>({})
export const SummitCategoryPostsProvider = ({ children, pageType }: { children: ReactNode; pageType: PageType }) => {
  const [editModePostIds, setEditModePostIds] = useState<Map<string, SummitTitleErrorType>>(new Map()) // posts that are mid-edit (should prevent navigating away or exiting edit mode)
  const hasEditModeSummitRows = editModePostIds.size > 0
  const [postsWithErrors, setPostsWithErrors] = useState(new Set<string>())
  const [allPendingPostIds, setAllPendingPostIds] = useState(new Set<string>())

  const updateEditModeRows = useCallback(
    (postId: string, removing: boolean, error?: SummitTitleErrorType) => {
      setEditModePostIds(postIds => {
        const newPostIds = new Map(postIds)
        if (removing) newPostIds.delete(postId)
        else newPostIds.set(postId, error ?? SummitTitleErrorType.NO_TITLE)

        return newPostIds
      })
    },
    [setEditModePostIds]
  )

  const updateAllPendingRows = useCallback(
    (postId: string, removing: boolean) => {
      setAllPendingPostIds(postIds => {
        const newPostIds = new Set(postIds)
        if (removing) newPostIds.delete(postId)
        else newPostIds.add(postId)

        return newPostIds
      })
    },
    [setAllPendingPostIds]
  )

  const removePostWithError = useCallback(
    (postId: string) => {
      setPostsWithErrors?.(posts => {
        const newPosts = new Set(posts)
        newPosts?.delete(postId)
        return newPosts
      })
    },
    [setPostsWithErrors]
  )

  return (
    <SummitCategoryPostsContext.Provider
      value={{
        editModePostIds,
        setEditModePostIds,
        pageType,
        hasEditModeSummitRows,
        updateEditModeRows,
        postsWithErrors,
        setPostsWithErrors,
        allPendingPostIds,
        updateAllPendingRows,
        setAllPendingPostIds,
        removePostWithError,
      }}
    >
      {children}
    </SummitCategoryPostsContext.Provider>
  )
}

export const useSummitCategoryPosts = () => useContext(SummitCategoryPostsContext)
