import { DocumentNode } from 'graphql/language'
import { OperationVariables, QueryHookOptions, useApolloClient, useQuery } from '@apollo/client'
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBackgroundFetch = <TData extends { posts?: any }, TVariables extends OperationVariables>(
  previewDocument: DocumentNode,
  fullDocument: DocumentNode,
  options: QueryHookOptions<TData, TVariables>,
  poll?: boolean
) => {
  const {
    data: previewData,
    loading: previewLoading,
    error: previewError,
    fetchMore: previewFetchMore,
    previousData: previewPreviousData,
    startPolling: previewStartPolling,
    stopPolling: previewStopPolling,
    refetch: refetchPreview,
  } = useQuery<TData, TVariables>(previewDocument, options)
  const { cache } = useApolloClient()
  const {
    data: fullData,
    error: fullError,
    fetchMore: fullFetchMore,
    startPolling: fullStartPolling,
    stopPolling: fullStopPolling,
    previousData: fullPreviousData,
    refetch: refetchFull,
  } = useQuery(fullDocument, {
    ...options,
    onCompleted: data => {
      // If there is a next page, we may have pushed some data onto it, so GC to free it up.
      if (data?.posts?.pageInfo?.hasNextPage) {
        cache.gc()
      }
    },
  })

  const pageInfo = previewData?.posts?.pageInfo
  const hasMore = pageInfo?.hasNextPage ?? false
  const endCursor = pageInfo?.endCursor

  const endCursorFull = fullData?.posts.pageInfo?.endCursor

  const refetch = () => {
    return Promise.all([refetchPreview(), refetchFull()])
  }

  const loadMore = () => {
    void previewFetchMore({ variables: { cursor: endCursor } })
    void fullFetchMore({ variables: { cursor: endCursorFull } })
  }
  useEffect(() => {
    if (poll) {
      previewStartPolling(options.pollInterval || 0)
      fullStartPolling(options.pollInterval || 0)
    } else {
      previewStopPolling()
      fullStopPolling()
    }
  }, [poll, previewStopPolling, fullStopPolling, previewStartPolling, options.pollInterval, fullStartPolling])

  return {
    previewData: previewData ?? previewPreviousData,
    loadMore,
    hasMore,
    loading: previewLoading,
    error: previewError ?? fullError,
    fullData: fullData ?? fullPreviousData,
    refetch,
  }
}
