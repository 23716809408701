import React, { EventHandler, RefObject, SyntheticEvent } from 'react'

export const KebabToggle = React.forwardRef(
  (props: { onClick: EventHandler<SyntheticEvent> }, ref: RefObject<HTMLAnchorElement>) => (
    <a
      className={'post-context-menu-control'}
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        props.onClick(e)
      }}
      data-testid={'post-context-menu'}
    >
      &#8942;
    </a>
  )
)
