import React, { useMemo } from 'react'
import '@css/pages/community/CommunityHistory.scss'
import { useAuth } from '~/auth/Auth'
import { usePermissions } from '~/pages/posts/PostUtils'
import { useCommunity } from '~/contexts/CommunityContext'
import { GetCommunityHistoryDocument, HistoryFilter, HistoryLevelType } from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import { decodeHTMLSymbols, getNameOrEmail, htmlToText, HtmlWithMentionsValue, removeTags, UTCtoLocal } from '~/utils'
import { Button } from 'react-bootstrap'
import { useQuery } from '@apollo/client'

interface CommunityHistoryProps {
  isCompany: boolean
}

const makeRow = (field: string, oldValue: string, newValue: string, isHtml?: boolean, url?: string) => {
  if (isHtml) {
    oldValue = htmlToText(oldValue) ?? ''
    newValue = htmlToText(newValue) ?? ''
  } else {
    oldValue = decodeHTMLSymbols(oldValue) as string
    newValue = decodeHTMLSymbols(newValue) as string
  }
  return [
    <td key={'field'}>{field}</td>, //
    <td key={'oldVal'}>{oldValue}</td>,
    <td key={'newVal'}>{url ? <Link to={url}>{newValue}</Link> : newValue}</td>,
  ]
}

const CompanyLink = ({ user }: { user: { company: { name: string; companyId: string } } }) => (
  <Link to={`/companies/${user.company.companyId}`} className={'history-link'}>
    ({user.company.name})
  </Link>
)

const CommunityHistory = ({ isCompany }: CommunityHistoryProps) => {
  const { authUserId, isVeevan } = useAuth()
  const { hasMemberPermissions } = usePermissions(authUserId)
  const { isPrivate, communityId, companyId } = useCommunity()
  const canView = (isVeevan && !isPrivate) || (isVeevan && hasMemberPermissions)

  const columns = ['Modified date', 'Modified by', 'Field', 'Old value', 'New value']
  const filters: HistoryFilter = {
    and: [{ level: HistoryLevelType.User }],
  }
  if (isCompany) {
    filters.and?.push({ or: [{ communityId }, { companyId }] })
  } else {
    filters.and?.push({ communityId })
  }

  const { data, loading, fetchMore } = useQuery(GetCommunityHistoryDocument, {
    variables: { filters },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const hasMore = data?.history?.pageInfo.hasNextPage ?? false

  const loadMore = () => {
    void fetchMore({ variables: { cursor: data?.history?.pageInfo.endCursor } })
  }

  type HistoryRow = {
    table: string
    user: {
      email: string
      firstName: string
      lastName: string
      userId: string
      hidden: boolean
      company: {
        name: string
        companyId: string
      }
    }
    event: {
      hidden: boolean
      title: string
      eventId: string
      isRepost: boolean
    }
    post: {
      hidden: boolean
      isRepost: boolean
      title: HtmlWithMentionsValue
      postId: string
    }
    company: {
      companyId: string
      name: string
    }
    invitation: {
      inviter: {
        userId: string
        firstName: string
        lastName: string
        email: string
      }
      email: string
    }
    modifiedTime: string
    modifiedBy: {
      firstName: string
      lastName: string
      userId: string
      email: string
      hidden: boolean
      company: {
        name: string
        companyId: string
      }
    }
    comment: {
      commentId: string
      hidden: boolean
      story: HtmlWithMentionsValue
      postId: string
    }
    action: string
    field: string
    oldValue: string
    newValue: string
    historyId: string
    groupId: string
  }

  const filteredHistoryData = useMemo(() => {
    if (data) {
      return data?.history?.edges?.map(edge => {
        const node = edge?.node
        return {
          historyId: node?.historyId,
          modifiedTime: node?.modifiedTime,
          groupId: node?.groupId,
          modifiedBy: {
            email: node?.modifiedBy?.email,
            firstName: node?.modifiedBy?.firstName,
            lastName: node?.modifiedBy?.lastName,
            userId: node?.modifiedBy?.userId,
            hidden: node?.modifiedBy?.hidden,
            company: {
              name: node?.modifiedBy?.company?.name,
              companyId: node?.modifiedBy?.company?.companyId,
            },
          },
          field: node?.field,
          oldValue: node?.oldValue,
          newValue: node?.newValue,
          table: node?.table,
          action: node?.action,
          user: {
            email: node?.user?.email,
            firstName: node?.user?.firstName,
            lastName: node?.user?.lastName,
            userId: node?.user?.userId,
            hidden: node?.user?.hidden,
            company: {
              name: node?.user?.company?.name,
              companyId: node?.user?.company?.companyId,
            },
          },
          post: {
            isRepost: node?.post?.isRepost,
            title: node?.post?.title,
            postId: node?.post?.postId,
            hidden: node?.post?.hidden,
          },
          comment: {
            commentId: node?.comment?.commentId,
            hidden: node?.comment?.hidden,
            story: node?.comment?.story?.htmlWithMentions,
            postId: node?.comment?.postId,
          },
          event: {
            eventId: node?.event?.eventId,
            title: node?.event?.title,
            hidden: node?.event?.hidden,
            isRepost: node?.event?.isRepost,
          },
          company: {
            companyId: node?.company?.companyId,
            name: node?.company?.name,
          },
          invitation: {
            inviter: {
              userId: node?.invitation?.inviter?.userId,
              firstName: node?.invitation?.inviter?.firstName,
              lastName: node?.invitation?.inviter?.lastName,
              email: node?.invitation?.inviter?.email,
            },
            email: node?.invitation?.email,
          },
        }
      }) as HistoryRow[]
    }
  }, [data])

  type UserHistoryParts = {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }

  const getUserLink = (user: UserHistoryParts, s: string) => {
    if (!user.hidden && user.firstName) {
      return (
        <>
          <Link to={`/profiles/${user.userId}`} className={'history-link'}>
            {getNameOrEmail(user)}
          </Link>{' '}
          <CompanyLink user={user} />
          {s}
        </>
      )
    } else {
      return (
        <>
          {getNameOrEmail(user)} <CompanyLink user={user} />
        </>
      )
    }
  }

  const getTableInfo = (row: HistoryRow) => {
    if (row.table == 'MEMBERSHIP') {
      let oldVal, newVal, field
      if (row.action == 'CREATE') {
        field = 'Membership'
        oldVal = removeTags(row.oldValue)
        newVal =
          row.user.userId == row.modifiedBy.userId ? (
            getUserLink(row.user, ' joined the community.')
          ) : (
            <>
              {getUserLink(row.modifiedBy, ' added')} {getUserLink(row.user, ' to the community.')}
            </>
          )
      } else if (row.action == 'DELETE') {
        field = 'Membership'
        oldVal = removeTags(row.oldValue)
        newVal =
          row.user.userId == row.modifiedBy.userId ? (
            getUserLink(row.user, ' left the community.')
          ) : (
            <>
              {getUserLink(row.modifiedBy, ' removed')} {getUserLink(row.user, ' from the community.')}
            </>
          )
      } else if (row.action === 'UPDATE') {
        field = 'Role'
        oldVal = row.newValue === '1' ? 'Member' : isCompany ? 'Account Partner' : 'Leader'
        const op = row.newValue === '1' ? 'added' : 'removed'
        const role = isCompany ? 'an account partner' : 'a leader'
        newVal = (
          <>
            {getUserLink(row.modifiedBy, '')} {op} {getUserLink(row.user, '')} as {role}
          </>
        )
      }
      return [
        <td key={'field'}>{field}</td>, //
        <td key={'oldVal'}>{oldVal}</td>,
        <td key={'newVal'}>{newVal}</td>,
      ]
    } else if (row.table == 'EVENT') {
      const url =
        (row.event.hidden ?? true) || row.field === 'repost_id' ? undefined : `/communities/${communityId}/events/`
      const type = row.event.isRepost ? 'EVENT REPOST' : 'EVENT'
      if (row.action == 'CREATE') {
        return makeRow(`${type} - created`, '', row.event.eventId, false, url)
      } else if (row.action == 'UPDATE') {
        let isHtml = false
        switch (row.field) {
          case 'hidden':
            if (row.newValue == '1') {
              return makeRow(`${type} - deleted`, row.event.title, 'DELETED')
            }
            break
          case 'description':
          case 'location':
            isHtml = true
        }
        return makeRow(`${type} - ${row.field}`, row.oldValue, row.newValue, isHtml, url)
      }
    } else if (row.table == 'POST') {
      if (row.action == 'UPDATE') {
        let url = (row.post.hidden ?? true) ? undefined : `/communities/${communityId}/posts/${row.post.postId}`
        let isHtml = false
        switch (row.field) {
          case 'hidden':
            return row.newValue == '1'
              ? makeRow(row.post.isRepost ? `REPOST - deleted` : 'POST - deleted', '', 'DELETED')
              : null
          case 'community_id':
            url = (row.post.hidden ?? true) ? undefined : `/communities/${row.newValue}/posts/${row.post.postId}`
            break
          case 'story':
          case 'title':
            isHtml = true
        }
        const prefix = row.post.isRepost ? 'REPOST - ' : 'POST - '
        return makeRow(prefix + row.field, row.oldValue, row.newValue, isHtml, url)
      }
    } else if (row.table == 'COMMENT') {
      if (row.action == 'UPDATE') {
        let oldValue = row.oldValue
        let newValue = row.newValue
        let isHtml = false
        switch (row.field) {
          case 'hidden':
            return row.newValue == '1' ? makeRow('COMMENT - deleted', '', 'DELETED') : null
          case 'story':
            isHtml = true
            // old and new values are returned in the form '["${value}"]'
            oldValue = oldValue ? row.oldValue.slice(2, -2) : oldValue
            newValue = newValue ? row.newValue.slice(2, -2) : newValue
        }
        const url = (row.comment.hidden ?? true) ? undefined : `/communities/${communityId}/posts/${row.comment.postId}`
        return makeRow(`COMMENT - ${row.field}`, oldValue, newValue, isHtml, url)
      }
    } else if (row.table == 'INVITATION') {
      if (row.action == 'CREATE') {
        return [
          <td key={'field'}>{`Invitation`}</td>,
          <td key={'oldVal'}>{removeTags(row.oldValue)}</td>,
          <td key={'newVal'}>
            <>
              <Link to={`/communities/${communityId}/members#all`}>{removeTags(row.invitation.email)}</Link>
            </>
          </td>,
        ]
      }
    } else if (row.table == 'COMMUNITY') {
      if (row.action == 'CREATE') {
        return makeRow('id - COMMUNITY created', row.oldValue, row.newValue)
      } else if (row.action == 'UPDATE' && row.field == 'name') {
        return makeRow('name - UPDATE', row.oldValue, row.newValue)
      } else {
        switch (row.field) {
          case 'content_order':
            return makeRow(row.field, '', '')
          case 'about':
            return makeRow(row.field, row.oldValue, row.newValue, true)
          default:
            return makeRow(row.field, row.oldValue, row.newValue)
        }
      }
    } else if (row.table == 'COMPANY') {
      if (row.action == 'UPDATE' && row.field == 'name') {
        return makeRow('company name - UPDATE', row.oldValue, row.newValue)
      } else {
        return makeRow(row.field, row.oldValue, row.newValue)
      }
    } else if (row.table == 'ROLE') {
      return makeRow(row.field, row.oldValue, row.newValue)
    } else if (row.table == 'DOMAIN') {
      return makeRow('Domain', row.oldValue, row.newValue)
    } else if (row.table == 'ALIAS') {
      return makeRow('Alias', row.oldValue, row.newValue)
    }
  }

  const showRow = (row: HistoryRow) => {
    return !(row.table === 'POST_FOLLOW' || row.field === 'created_by_id' || row.field === 'modified_by_id')
  }

  if (!canView) {
    return null
  }

  return (
    <div>
      <h3 className={'comm-title'}>History</h3>
      {loading && !data ? (
        <div className={'loading'}>Loading...</div>
      ) : (
        <div>
          <table className={'history-table'}>
            <thead>
              <tr>
                {columns.map(col => (
                  <td key={col}>{col}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredHistoryData?.filter(showRow).map(row => (
                <tr key={row.historyId}>
                  <td>{UTCtoLocal(row.modifiedTime)}</td>
                  <td>
                    {row.modifiedBy.hidden ? (
                      <>{getNameOrEmail(row.modifiedBy)}</>
                    ) : (
                      <Link to={`/profiles/${row.modifiedBy.userId}`} className={'history-link'}>
                        {getNameOrEmail(row.modifiedBy)}
                      </Link>
                    )}
                  </td>
                  {getTableInfo(row)}
                </tr>
              ))}
            </tbody>
          </table>
          {loading ? (
            <div className={'loading show-more-loading'}>Loading...</div>
          ) : (
            hasMore && (
              <div className="show-more">
                <Button size={'sm'} onClick={loadMore}>
                  Show more
                </Button>
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default CommunityHistory
