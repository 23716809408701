import React, { useMemo, useRef } from 'react'
import '@css/common/TimeSelector.scss'
import { useOutsideAlerter } from '~/common/hooks/useOutsideAlerter'

type TimeSelectorProps = {
  selectedDate?: Date
  selectedTime: Date
  interval: number
  onSelect: (time: Date) => void
}

const TimeSelector = ({ selectedTime, interval, onSelect, selectedDate }: TimeSelectorProps) => {
  const selectorRef = useRef(null)
  const { showElement: showTimeDropdown, setShowElement: setShowTimeDropdown } = useOutsideAlerter(false, selectorRef)
  const handleTimeClick = (time: Date) => {
    onSelect(time)
    setShowTimeDropdown(false)
  }

  const selectionTimes: Date[] = useMemo(() => {
    const accompanyingDate = selectedDate ?? new Date()
    const date = new Date(accompanyingDate.getFullYear(), accompanyingDate.getMonth(), accompanyingDate.getDate(), 0, 0)
    const times: Date[] = []
    for (let i = 0; i < (24 * 60) / interval; i++) {
      const tempDate = new Date(date.getTime())
      times.push(tempDate)
      date.setMinutes(date.getMinutes() + interval)
    }
    return times
  }, [interval, selectedDate])

  const isSelected = (time: Date, selectedTime: Date): boolean => {
    return time.getHours() == selectedTime.getHours() && time.getMinutes() == selectedTime.getMinutes()
  }

  return (
    <div ref={selectorRef}>
      <input
        className="time-selector"
        value={selectedTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
        onClick={() => setShowTimeDropdown(!showTimeDropdown)}
        readOnly
      />
      {showTimeDropdown && (
        <div className="selector-dropdown">
          {selectionTimes.map(time => (
            <div
              className={`time-row ${isSelected(time, selectedTime) ? 'selected' : ''}`}
              key={time.toLocaleTimeString()}
              onClick={() => handleTimeClick(time)}
            >
              {time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TimeSelector
