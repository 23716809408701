import { Maybe, Release } from '~/api/generated/graphql'

export const sortReleases = (releaseOrderRaw: Maybe<string>, releases: Partial<Maybe<Release>>[] | undefined) => {
  if (!releaseOrderRaw) return releases
  const releaseOrder = JSON.parse(releaseOrderRaw) as string[]
  const releasesToSort = [...(releases ?? [])]
  return (
    releasesToSort?.sort((a, b) => {
      const aIndex = releaseOrder.indexOf(a?.releaseId ?? '')
      const bIndex = releaseOrder.indexOf(b?.releaseId ?? '')
      if (aIndex === -1) return 1
      if (bIndex === -1) return -1
      return aIndex - bIndex
    }) ?? []
  )
}
