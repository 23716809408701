import React, { SyntheticEvent } from 'react'
import '@css/common/PhotoUploadOptions.scss'
import linkedInIcon from '@web/images/profile/LinkedIn-icon.svg'
import uploadIcon from '@web/images/profile/icon-upload.svg'

type Props = {
  firstName: string
  lastName: string
  onClickLinkedin: (e: SyntheticEvent) => void
  onClickUpload: (e: SyntheticEvent) => void
  extraRightPadding?: boolean
}

const PhotoUploadOptions = ({
  firstName,
  lastName,
  onClickLinkedin,
  onClickUpload,
  extraRightPadding = false,
}: Props) => {
  return (
    <div className={`photo-options-container${extraRightPadding ? ' right-padding' : ''}`}>
      <div className={'photo'} title={`${firstName} ${lastName}`} />
      <button className={'photo-upload-option'} onClick={onClickLinkedin}>
        <img src={linkedInIcon} />
        Use LinkedIn Photo
      </button>
      <button className={'photo-upload-option'} onClick={onClickUpload}>
        <img src={uploadIcon} />
        Upload Photo
      </button>
    </div>
  )
}

export default PhotoUploadOptions
