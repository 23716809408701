import React, { useEffect, useRef } from 'react'
import deleteIcon from '@web/images/community/icon-xdelete-inverted.png'

type FadingAlertProps = {
  timeout: number
  message: string
  showAlert: boolean
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
}

const FadingAlert = ({ message, showAlert, setShowAlert }: FadingAlertProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onAnimationEnd = () => {
      setShowAlert(false)
    }

    if (ref?.current) {
      const elem = ref.current
      elem.addEventListener('animationend', onAnimationEnd)
      return () => {
        elem.removeEventListener('animationend', onAnimationEnd)
      }
    }
  }, [ref, setShowAlert])

  return (
    <div ref={ref} className={`fading-alert ${showAlert ? 'visible-alert' : 'hidden-alert'}`}>
      <span>{message}</span>
      <img src={deleteIcon} alt={'delete'} className={'delete-toast-icon'} onClick={() => setShowAlert(false)} />
    </div>
  )
}

export default FadingAlert
