// @flow
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ColProps } from 'react-bootstrap/Col'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Link } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function UnauthLayout(props: Props & ColProps) {
  const { isCondensedPortrait } = useWindowSize()

  return (
    <>
      <div className={`${props.className} unauth`}>
        <Container fluid="xl" className={'content'}>
          <Row className={'align-items-center dialog-row'}>
            <Col
              xs={props.xs || 12}
              sm={props.sm || 12}
              md={props.md || 7}
              lg={props.lg || 6}
              xl={props.xl || 6}
              xxl={props.xxl || 6}
              className={'dialog-col'}
            >
              {props.children}
            </Col>
          </Row>
        </Container>
        <footer className={'login-page'}>
          {!isCondensedPortrait && <a href={'https://veeva.com'} target={'_blank'} className={'veeva-logo'} />}
          <div className={`link-area ${isCondensedPortrait ? 'condensed' : ''}`}>
            {!isCondensedPortrait && (
              <>
                <span>
                  <a href={'https://veeva.com'} target={'_blank'}>
                    Veeva.com
                  </a>
                </span>
                <span>&middot;</span>
              </>
            )}
            <span>
              <Link to={'/terms'} target="_blank">
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link to={'/privacy-policy'} target="_blank">
                Privacy Policy
              </Link>
            </span>
            <span>&middot;</span>
            <span>
              <a href={'mailto:veevaconnect@veeva.com'}>Contact Us</a>
            </span>
          </div>
        </footer>
      </div>
    </>
  )
}
