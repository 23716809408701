type LessArrowProps = {
  fill: string
  width?: string
  height?: string
}

const LessArrow = ({ fill, height, width }: LessArrowProps) => {
  return (
    <svg width={width ?? '11'} height={height ?? '7'} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 0.570312C5.41406 0.359375 5.0625 0.359375 4.85156 0.570312L0.28125 5.11719C0.0703125 5.35156 0.0703125 5.70312 0.28125 5.91406L0.820312 6.45312C1.03125 6.66406 1.38281 6.66406 1.61719 6.45312L5.25 2.84375L8.85938 6.45312C9.09375 6.66406 9.44531 6.66406 9.65625 6.45312L10.1953 5.91406C10.4062 5.70312 10.4062 5.35156 10.1953 5.11719L5.625 0.570312Z"
        fill={fill}
      />
    </svg>
  )
}

export default LessArrow
