import { Maybe } from '~/api/generated/graphql'
import { UserLinkDocument } from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import React from 'react'
import { useQuery } from '@apollo/client'

export const UserLink = ({ userId }: { userId?: Maybe<string> }) => {
  const { data } = useQuery(UserLinkDocument, { variables: { id: userId ?? '' }, skip: !userId })
  if (!data?.user) return null
  return (
    <Link
      to={`/profiles/${data.user.userId}`}
    >{`${data.user.firstName} ${data.user.lastName} (${data.user.company?.name}) `}</Link>
  )
}
