import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import '@css/pages/community/CommunityEventEdit.scss'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TimeSelector from '~/common/TimeSelector'
import { AddEventDocument, EditEventDocument, Html, Maybe } from '~/api/generated/graphql'
import FormError from '~/common/FormError'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { asDate, getStartOfCurrentDay, padDigit } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import TimeAgo from '~/common/TimeAgo'
import { Link } from 'react-router-dom'
import { EventRepostLink } from '~/common/RepostOverlay'
import { useMutation } from '@apollo/client'

type CommunityEventEditProps = {
  communityId: string
  event?: Maybe<
    Partial<{
      eventStart?: string
      eventEnd?: string
      lastNotifiedTime?: Date
      title?: string
      location?: Maybe<string>
      description?: Maybe<Html>
      eventId?: string
      reposts?: Maybe<Maybe<{ hidden?: boolean; eventId?: string }>[]>
    }>
  >
  onClose: () => void
  setToast?: (toast: string) => void
}

const CommunityEventEdit = ({ communityId, event, onClose, setToast }: CommunityEventEditProps) => {
  const [addEvent] = useMutation(AddEventDocument)
  const [editEvent] = useMutation(EditEventDocument)

  const { isCondensedPortrait } = useWindowSize()

  const roundTime = (date: Date) => {
    const ms = 1000 * 60 * 15 // convert 15 m to ms
    return new Date(Math.ceil(date.getTime() / ms) * ms)
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [startDate, setStartDate] = useState<Date>(asDate(event?.eventStart) ?? new Date())
  const [startTime, setStartTime] = useState<Date>(asDate(event?.eventStart) ?? roundTime(new Date()))
  const [endDate, setEndDate] = useState<Date>(asDate(event?.eventEnd) ?? new Date(Date.now() + 1000 * 60 * 60))
  const [endTime, setEndTime] = useState<Date>(
    asDate(event?.eventEnd) ?? roundTime(new Date(Date.now() + 1000 * 60 * 60))
  )
  const [title, setTitle] = useState<string>(event?.title ?? '')
  const [location, setLocation] = useState(event?.location)
  const [description, setDescription] = useState(event?.description)
  const [titleError, setTitleError] = useState<boolean>(false)
  const [dateRangeError, setDateRangeError] = useState<boolean>(false)
  const [pastDateError, setPastDateError] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)
  const reposts = event?.reposts?.filter(e => !e?.hidden)
  const hasReposts = Boolean(reposts && reposts.length > 0)

  const formatDate = (date: Date, time: Date): string => {
    const combinedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    )
    return `${combinedDate.getUTCFullYear()}-${padDigit(combinedDate.getUTCMonth() + 1)}-${padDigit(
      combinedDate.getUTCDate()
    )} ${padDigit(combinedDate.getUTCHours())}:${padDigit(combinedDate.getUTCMinutes())}:00`
  }

  const handleTitleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value)
      setTitleError(false)
    },
    [setTitle, setTitleError]
  )

  const handleStartDateChange = useCallback(
    (date: Date) => {
      setStartDate(date)
      setEndDate(date)
      setDateRangeError(false)
      setPastDateError(false)
    },
    [setStartDate, setDateRangeError]
  )

  const handleEndDateChange = useCallback(
    (date: Date) => {
      setEndDate(date)
      setDateRangeError(false)
    },
    [setEndDate, setDateRangeError]
  )

  const handleStartTimeChange = useCallback(
    (time: Date) => {
      setStartTime(time)
      // set date in case time crosses date boundary
      setStartDate(time)
      setDateRangeError(false)
    },
    [setStartTime, setDateRangeError]
  )

  const handleEndTimeChange = useCallback(
    (time: Date) => {
      setEndTime(time)
      // set date in case time crosses date boundary
      setEndDate(time)
      setDateRangeError(false)
    },
    [setEndTime, setDateRangeError]
  )

  const handleEventAction = async (notify: boolean, hidden = false) => {
    const variables = {
      eventStart: formatDate(startDate, startTime),
      eventEnd: formatDate(endDate, endTime),
      title: title ?? '',
      location: location ?? '',
      description: description?.html,
      hidden: hidden ?? null,
      date: getStartOfCurrentDay(),
      notify: notify,
    }
    if (event) {
      await editEvent({ variables: { ...variables, eventId: event?.eventId || '' } })
      onClose()
    } else {
      await addEvent({ variables: { ...variables, communityId } })
      onClose()
    }
  }

  const validateEvent = () => {
    const fullStartDate = formatDate(startDate, startTime)
    const fullEndDate = formatDate(endDate, endTime)
    let valid = true

    if (!title) {
      valid = false
      setTitleError(true)
    }
    if (fullEndDate <= fullStartDate) {
      valid = false
      setDateRangeError(true)
    }
    // if creating a new event, cannot set time to be in the past
    if (!event && fullStartDate < formatDate(new Date(), new Date())) {
      valid = false
      setPastDateError(true)
    }
    return valid
  }

  const saveEvent = async (e: SyntheticEvent, notify: boolean) => {
    setButtonsDisabled(true)
    await handleEventAction(notify, false)
    setButtonsDisabled(false)
    setToast?.(event ? 'Event updated successfully' : 'Event created successfully')
    elementClicked(e, `click-community-event-save`, {
      community_id: communityId,
      event_id: event?.eventId,
    })
  }

  const checkEvent = () => {
    setButtonsDisabled(true)
    const valid = validateEvent()
    setButtonsDisabled(false)
    if (valid) {
      setShowNotifyDialog(true)
    }
  }
  const handleConfirmDelete = async (e: SyntheticEvent) => {
    setButtonsDisabled(true)
    await handleEventAction(false, true)
    setButtonsDisabled(false)
    setToast?.('Event deleted')
    elementClicked(e, 'click-community-event-delete', { community_id: communityId, event_id: event?.eventId })
    setShowDeleteDialog(false)
  }

  const repostedComms = () => {
    if (event?.reposts?.length == 1) {
      return (
        <span>
          <EventRepostLink eventId={reposts?.[0]?.eventId} />.
        </span>
      )
    } else if (event?.reposts?.length == 2) {
      return (
        <span>
          <EventRepostLink eventId={reposts?.[0]?.eventId} /> and <EventRepostLink eventId={reposts?.[1]?.eventId} />.
        </span>
      )
    } else if (event?.reposts?.length == 3) {
      return (
        <span>
          <EventRepostLink eventId={reposts?.[0]?.eventId} />, <EventRepostLink eventId={reposts?.[1]?.eventId} />, and{' '}
          <EventRepostLink eventId={reposts?.[2]?.eventId} />.
        </span>
      )
    } else {
      return (
        <span>
          <EventRepostLink eventId={reposts?.[0]?.eventId} />, <EventRepostLink eventId={reposts?.[1]?.eventId} />, and{' '}
          <Link to={`./${event?.eventId}/repost`}>{reposts?.length ? reposts?.length - 2 : 0} other</Link> communities.
        </span>
      )
    }
  }

  return (
    <div className="edit-event-container">
      <div className={'top-items'}>
        <h3>{event ? 'Edit Event' : 'Create Event'}</h3>
        {!isCondensedPortrait && (
          <span className="actions">
            <button className="discard" onClick={onClose}>
              Cancel
            </button>
            <Button variant="primary" onClick={checkEvent} disabled={buttonsDisabled}>
              Save
            </Button>
          </span>
        )}
      </div>
      {event?.lastNotifiedTime ? (
        <div className={`event-notification-statement ${isCondensedPortrait ? ' condensed' : ''}`}>
          Last notification sent <TimeAgo time={event?.lastNotifiedTime} />
        </div>
      ) : (
        <div className={`event-notification-statement ${isCondensedPortrait ? ' condensed' : ''}`}>
          No notification has been sent.
        </div>
      )}
      <div className="title">
        <h6>Event Title</h6>
        <Form.Control
          placeholder="Title this event"
          maxLength={200}
          value={title}
          onChange={handleTitleTextChange}
          bsPrefix={' '}
        />
        {titleError && <FormError message={'Title is required'} />}
      </div>
      <div className={`date-time-pickers ${isCondensedPortrait ? 'condensed' : ''}`}>
        <div className="picker-row">
          <div className="picker">
            <h6>Start Date</h6>
            <ReactDatePicker
              id="event-start-date"
              minDate={new Date()}
              selected={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="picker">
            <h6>End Date</h6>
            <ReactDatePicker
              id="event-end-date"
              minDate={new Date()}
              selected={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
        <div className="picker-row">
          <div id="start-time-picker" className="picker">
            <h6>Start Time</h6>
            <TimeSelector
              selectedTime={startTime}
              selectedDate={startDate}
              interval={15}
              onSelect={handleStartTimeChange}
            />
          </div>
          <div id="end-time-picker" className="picker">
            <h6>End Time</h6>
            <TimeSelector selectedTime={endTime} selectedDate={endDate} interval={15} onSelect={handleEndTimeChange} />
          </div>
          {!isCondensedPortrait && (
            <div id="timezone-picker" className="picker">
              <h6>Time Zone</h6>
              <Form.Control className="timezone" value={timeZone} readOnly bsPrefix={' '} />
            </div>
          )}
        </div>
        {isCondensedPortrait && (
          <div className="picker-row">
            <div id="timezone-picker" className="picker timezone-picker">
              <h6>Time Zone</h6>
              <Form.Control className="timezone" value={timeZone} readOnly bsPrefix={' '} />
            </div>
          </div>
        )}
      </div>
      {dateRangeError && <FormError message={'Event end must be after event start.'} />}
      {pastDateError && <FormError message={'Event cannot be created in the past.'} />}
      <div className="location">
        <h6>Location</h6>
        <Form.Control
          placeholder="Address or Meeting Link"
          value={location ?? ''}
          onChange={event => setLocation(event.target.value)}
          bsPrefix={' '}
        />
      </div>
      <div className="description">
        <h6>Description</h6>
        <QuillEditor<Maybe<Html>>
          toolbar={QuillToolbar.Limited}
          placeholder="Describe this event"
          initialHtml={description}
          setHtml={setDescription}
        />
      </div>
      {isCondensedPortrait && (
        <div className={'top-items condensed'}>
          <span className="actions">
            <button className="discard" onClick={onClose}>
              Cancel
            </button>
            <Button variant="primary" onClick={checkEvent} disabled={buttonsDisabled}>
              Save
            </Button>
          </span>
        </div>
      )}
      {event && (
        <div className={`bottom-row${hasReposts ? '' : ' no-reposts'}`}>
          {!!hasReposts && (
            <div className={'repost-statement'}>
              <i>This event has been reposted in {repostedComms()}</i>
            </div>
          )}
          <div className="delete">
            <button onClick={() => setShowDeleteDialog(true)}>Delete Event</button>
          </div>
        </div>
      )}
      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Are you sure you want to delete this event?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowDeleteDialog(false)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={buttonsDisabled}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showNotifyDialog} onHide={() => setShowNotifyDialog(false)} className={'save'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Do you want to notify community members about this event?</p>
          {Boolean(hasReposts) && (
            <p className={'sub-message'}>Notifications will also be sent for {reposts?.length} event reposts.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" className="save-btn" onClick={e => saveEvent(e, false)}>
            Save
          </Button>
          <Button variant="primary" size="sm" onClick={e => saveEvent(e, true)} disabled={buttonsDisabled}>
            Save and notify
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CommunityEventEdit
