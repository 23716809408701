import { useEffect, useState } from 'react'

export enum KeyPressKeys {
  downArrow = 'ArrowDown',
  upArrow = 'ArrowUp',
  enter = 'Enter',
}

// https://usehooks.com/useKeyPress/
const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false)

  useEffect(() => {
    const upHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey) setKeyPressed(false)
    }

    const downHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey) setKeyPressed(true)
    }

    globalThis.addEventListener('keydown', downHandler)
    globalThis.addEventListener('keyup', upHandler)
    return () => {
      globalThis.removeEventListener('keydown', downHandler)
      globalThis.removeEventListener('keyup', upHandler)
    }
  }, [targetKey])

  return keyPressed
}

export default useKeyPress
