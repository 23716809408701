import React from 'react'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const { breakpoint } = useWindowSize()
  const isCondensedPortrait = breakpoint <= SizeBreakpoint.md
  const currentYear = new Date().getFullYear()

  return (
    <footer className={isCondensedPortrait ? 'condensed' : ''}>
      <div className={`link-area ${isCondensedPortrait ? 'condensed' : ''}`}>
        <span>Copyright © {currentYear} Veeva Systems, Inc. All rights reserved.</span>
        {!isCondensedPortrait && <span>&#124;</span>}
        <div className={`${isCondensedPortrait ? '' : 'inline'}`}>
          <span>
            <a href={'mailto:veevaconnect@veeva.com'}>Contact Us</a>
          </span>
          <span>&middot;</span>
          <span>
            <Link to={'/terms'} target="_blank">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to={'/privacy-policy'} target="_blank">
              Privacy Policy
            </Link>
          </span>
        </div>
      </div>
    </footer>
  )
}
