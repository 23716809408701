import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useBlocker } from 'react-router'
import { Button, Modal } from 'react-bootstrap'

export type KeyType = string

export type PromptInfo = {
  key: KeyType
  message: string
  priority?: number
  cancelText?: string
  onProceed?: VoidFunction
}

export type PromptContextType = {
  block: (info: PromptInfo) => void
  unblock: (key: KeyType) => void
}
export const PromptContext = createContext<PromptContextType | undefined>(undefined)

export const PromptProvider = ({ children }: { children: ReactNode }) => {
  const [blockers, setBlockers] = useState<Map<KeyType, PromptInfo>>(new Map())
  const blocker = useBlocker(blockers.size > 0)
  const topBlocker = useMemo(() => {
    return blockers.size > 0 ? Array.from(blockers.values()).sort(i => i.priority ?? 0)[0] : null
  }, [blockers])

  const block = useCallback((info: PromptInfo) => {
    setBlockers(blockers => {
      const newBlockers = new Map(blockers)
      newBlockers.set(info.key, info)
      return newBlockers
    })
  }, [])
  const unblock = useCallback((key: KeyType) => {
    setBlockers(blockers => {
      const newBlockers = new Map(blockers)
      newBlockers.delete(key)
      return newBlockers
    })
  }, [])

  const cancel = useCallback(() => {
    if (blocker.state === 'blocked') blocker.reset()
  }, [blocker])
  const proceed = useCallback(() => {
    if (blocker.state === 'blocked') {
      topBlocker?.onProceed?.()
      blocker.proceed()
    }
  }, [blocker, topBlocker])

  return (
    <PromptContext.Provider
      value={{
        block,
        unblock,
      }}
    >
      {children}
      <Modal show={blocker.state === 'blocked'} onHide={cancel} data-testid={'unsaved-warning-modal'}>
        <Modal.Body>
          <p>{topBlocker?.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={cancel} data-testid={'modal-cancel'}>
            {topBlocker?.cancelText ?? 'Cancel'}
          </Button>
          <Button variant="primary" onClick={proceed} data-testid={'modal-continue'}>
            Discard Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </PromptContext.Provider>
  )
}

export const usePrompt = () => {
  const context = useContext(PromptContext)
  if (context === undefined) {
    throw new Error('usePrompt was used outside of PromptProvider')
  }
  return context
}
