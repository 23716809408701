import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  CommunityType,
  GetCommunityDocument,
  GetCompanyCommunityDocument,
  Maybe,
  NewAllCommunityActivityAlertDocument,
  NewPublicCommunityActivityAlertDocument,
  SubscriptionInfo,
  ViewCommunityDocument,
} from '~/api/generated/graphql'
import { ApolloError, useMutation, useQuery, useSubscription } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { getSafeLocationState } from '~/utils'
import { useAuth } from '~/auth/Auth'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

export type CommunityContextType = {
  loading: boolean
  communityId: Maybe<string>
  companyId: Maybe<string>
  error?: ApolloError
  companyName: Maybe<string>
  refetch: () => void
  isPrivate?: boolean
  isVeeva: boolean
  newActivity: SubscriptionInfo[]
  setNewActivity?: React.Dispatch<React.SetStateAction<SubscriptionInfo[]>>
}

export const CommunityContext = React.createContext<CommunityContextType>({
  loading: false,
  communityId: null,
  companyId: null,
  companyName: null,
  refetch: () => {
    return
  },
  isVeeva: false,
  newActivity: [],
})

type CommunityProviderProps = {
  children: ReactNode
  communityId?: string
  companyId?: string
}

export const CommunityProvider = ({ children, communityId, companyId }: CommunityProviderProps) => {
  const {
    data,
    loading,
    error,
    refetch: communityRefetch,
  } = useQuery(GetCommunityDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
    fetchPolicy: 'cache-and-network',
  })
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
    refetch: companyRefetch,
  } = useQuery(GetCompanyCommunityDocument, {
    variables: { id: companyId ?? '' },
    skip: !companyId,
    fetchPolicy: 'cache-and-network',
  })

  const [viewCommunity] = useMutation(ViewCommunityDocument)
  const { isVeevan, authUserId } = useAuth()
  const companyName = companyData?.company?.name
  const isVeeva = companyData?.company?.isVeeva ?? false
  const isPrivate = data?.community?.isPrivate
  const location = useLocation()

  const community = useMemo(() => {
    if (data || companyData) {
      return companyData?.company?.homepage || data?.community
    }
  }, [companyData, data])

  useEffect(() => {
    const communityId = community?.communityId
    const state = getSafeLocationState<{ fromLoginRedirect?: boolean }>(location.state)
    const fromLogin = state?.fromLoginRedirect
    if (communityId) {
      // if viewing this from a redirect after login, tell the mutation, so it knows not to add the default interest
      void viewCommunity({ variables: { communityId, fromLogin } })
    }
  }, [community?.communityId, viewCommunity, location.state])

  const refetch = () => {
    if (community?.type === CommunityType.Homepage) {
      void companyRefetch({ id: companyId })
    } else {
      void communityRefetch({ id: communityId })
    }
  }

  // use a map in the form [communityId]:[new posts] to prevent posts from persisting between communities
  const [newActivity, setNewActivity] = useState<SubscriptionInfo[]>([])
  const id = communityId || community?.communityId || ''

  // when we switch between tabs or communities it grabs the latest posts/comment activity, so we need to clear any "pending" new activity
  useEffect(() => {
    setNewActivity([])
  }, [location])

  const { pageIsVisible } = usePageVisibility()

  useSubscription(isVeevan ? NewAllCommunityActivityAlertDocument : NewPublicCommunityActivityAlertDocument, {
    variables: { communityIds: [id], authUserId: authUserId ?? '' },
    onData: ({ data }) => {
      if (data.data && data.data.newCommunityActivityAlert) {
        const p = data.data.newCommunityActivityAlert
        const activityObjs = [...(newActivity ?? [])]
        if (p.parentId) {
          activityObjs.filter(a => a.objId != p.parentId)
        }
        if (!activityObjs.some(activity => activity.objId === p.objId)) {
          activityObjs.unshift(p)
        }
        setNewActivity(activityObjs)
      }
    },
    skip: !id || !pageIsVisible || globalThis.disableWebsocketServer,
  })

  return (
    <CommunityContext.Provider
      value={{
        communityId: communityId ?? community?.communityId ?? null,
        companyId: companyId ?? null,
        companyName: companyName ?? null,
        error: companyError || error,
        isPrivate,
        isVeeva,
        loading: companyLoading || loading,
        refetch,
        newActivity,
        setNewActivity,
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export const useCommunity = () => useContext(CommunityContext)
