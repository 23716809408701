import {
  CommunityType,
  Html,
  HtmlWithMentions,
  Maybe,
  MediaType,
  NotificationType,
  PostType,
} from '~/api/generated/graphql'

export type CommunityModel = {
  name: string
  photo: Maybe<string>
  about: Maybe<Html>
  description: Maybe<string>
  events?: EventModel[]
  communityId: string
  company?: CompanyModel
  leaders?: UserModel[]
  leaderCount?: number
  memberCount?: number
  postCount?: number
  lastPost?: PostModel
  type: string
  isHomepage: boolean
}

export type CommunitySettingsDataModel = {
  communityId: string
  name: string
  photo: Maybe<string>
  description: Maybe<string>
  company?: {
    companyId: string
    name: string
    domains: string[]
  }
  type: CommunityType
  aliases: string[]
}

export type EventModel = {
  eventId: string
  communityId: string
  title: string
  eventStart: Date
  eventEnd: Date
  description: Maybe<Html>
  location: string
  hidden: boolean
  createdTime: Date
  createdById: string
  repost: {
    eventId: string
    communityId: string
    hidden: boolean
  }
}

export type CommunityListViewModel = {
  communityId: string
  name: string
  description: Maybe<string>
  companyId?: string
  memberCount?: number
  photo: Maybe<string>
  lastActivityTime?: Date
  type: string
}

export type RepostCommunityModel = {
  communityId: string
  name: string
  description: Maybe<string>
  companyId?: string
  photo: Maybe<string>
  type: string
}

export type RoleModel = {
  companyId: string
  userId: string
  company: CompanyModel
  modifiedTime?: Date
  description?: Maybe<string>
  member?: { accountLead: boolean; commercialLead: boolean; rdqLead: boolean }
}

export type UserModel = {
  userId: string
  email?: string
  firstName: string
  lastName: string
  nickName: string
  title: string
  photo: string
  hidden: boolean
  isVeevan: boolean
  fullName: string
  company: CompanyModel
  roles?: RoleModel[]
  profileVisible?: boolean
  communityIds?: string[]
  otherContact?: string
  notificationFrequency: string
  createdTime?: string
}

export type PreUserModel = {
  email: string
  companyId: string
  companyName: string
  firstName?: string
  lastName?: string
  nickName?: string
  title?: string
  photo?: string
  isVeevan: boolean
  primaryLocation?: LocationModel
  secondaryLocation?: LocationModel
  linkedinUrl?: string
}

export type NotificationModel = {
  notificationId: string
  notificationType: NotificationType
  userId?: string
  actorId?: string
  comment?: CommentModel & { post: { title: string; postId: string } }
  communityId?: string
  created: Date
  event?: EventModel
  extra?: NotificationExtraModel
  post?: PostModel
  seenAt: Date | null
  viewedAt: Date | null
}

export type NotificationExtraModel = {
  title: string
  name: string
  contentTitle: string
  leader: boolean
}

export type LocationModel = {
  type?: string
  country?: string
  state?: string
  city?: string
  countryCode?: string
}

export type CompanyModel = {
  companyId: string
  name: string
  isVeeva?: boolean
  photo?: string
  homePageName?: string
  memberCount?: number
}

export type ActivityModel = {
  authorId: Maybe<string>
  postId: string
  postTitle: Maybe<HtmlWithMentions>
  postComments: number
  postViews: number
  likeCount: number
  postLastActivityTime: Date
  communityId: string
  activityTime: Date
  isComment: boolean
  commentId?: string
  isHomeFeed?: boolean
  hidden: boolean
  veevanViewCount?: number
  veevanCommentCount?: number
  veevanLikeCount?: number
  viewed: boolean
  comments?: CommentModel[]
  createdBy?: {
    hidden: boolean
  }
  isRepost: boolean
  isFollowing?: boolean
  postType?: PostType
}

export type HomeFeedActivityModel = ActivityModel & {
  last_comment_time?: Date
  last_comment_author_id: Maybe<string>
  postAuthorId: string
  createdTime: Date
}

export type UserProfileModel = Omit<UserModel, 'company'> & {
  company: CompanyModel
  aboutMe: Maybe<Html>
  email?: string
  orgwikiProfile?: string
  profileVisible?: boolean
  aboutMeModifiedTime?: Date
  otherContact?: string
  linkedinUrl?: string
  primaryLocation?: LocationModel
  secondaryLocation?: LocationModel
  syncOrgwikiLocation?: boolean
  roleAtCompany?: Maybe<Html>
  roleModifiedTime?: Date
  roles?: RoleModel[]
  memberships?: CommunityModel[]
  profileModifiedTime?: Date
}

export type MemberModel = {
  user: UserModel
  communityId: string
  created: Date
  leader: boolean
  accountLead: boolean
  commercialLead: boolean
  rdqLead: boolean
}

export type PendingMemberModel = {
  email: string
  companyName: string
  inviter: UserModel
  createdTime: Date
}

export type CommentModel = {
  authorId: string
  commentId: string
  postId: string
  created_datetime: Date
  media_url?: string
  media_type: Maybe<MediaType>
  content_title?: string
  story: Maybe<HtmlWithMentions>
  views?: number
  likes: string[]
  hidden: boolean
  veevanViewCount: number
  veevanLikeCount: number
  isVeevanDiscussion?: boolean
}

export type PostModel = {
  authorId: string
  postId: string
  communityId: string
  created_datetime: Date
  title: Maybe<HtmlWithMentions>
  media_url?: string
  media_type: Maybe<MediaType>
  story: Maybe<HtmlWithMentions>
  views?: number
  likes: string[]
  lastComment?: {
    commenter_id: string
    time: Date
  }
  content_title?: string
  comment_count: number
  last_activity_time: Date
  hidden: boolean
  postType: PostType
  comments: CommentModel[]
  viewed: boolean
  veevanViewCount: number
  veevanLikeCount: number
  veevanCommentCount: number
  draft?: boolean
  isRepost?: boolean
  repostId?: string
  repost?: {
    postId: string
    createdById: string
    communityId: string
  }
  hasBeenReposted?: boolean
  featured?: boolean
  isFollowing?: boolean
}

export type PageInfoModel = {
  hasNextPage: boolean
  endCursor: string
}

export type LikeModel = {
  isVeevan: boolean
  userId: string
  fullName: string
  company?: CompanyModel
  hidden: boolean
}

export type PostLikeModel = LikeModel & {
  postId: string
}

export type CommentLikeModel = LikeModel & {
  commentId: string
}

export type HistoryRowModel = {
  company?: {
    companyId: string
    name: string
  }
  historyId: string
  modifiedTime: string
  modifiedBy: {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }
  field: string
  oldValue: string
  newValue: string
  table: string
  action: string
  community: {
    communityId: string
    name: string
    isHomepage: boolean
  }
  user: {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }
}

export type FeedCommunityListViewModel = {
  communityId: string
  companyId?: string
  companyName?: string
  name: string
  photo: string
  description?: string
  link: string
}

export type SummitModel = {
  summitId: string
  name: string
  content?: SummitContentModel
  menuName?: Maybe<string>
  menuCategory?: Maybe<string>
  menuOrder?: Maybe<number>
}

export type SummitContentModel = {
  title: string
  groups: {
    name: string
    posts: {
      title?: string
      postId: string
      hideBanner?: boolean
    }[]
  }[]
}

export type SearchMatchModel = {
  __typename?: 'Match'
  post: {
    __typename?: 'PostMatch'
    id: string
    postId: string
    title: string
    authorId: string
    communityId: string
    createdTime: string
  }
  highlight: Array<Maybe<{ __typename?: 'Highlight'; highlights: Array<Maybe<string>>; field: string }>>
}

export enum SearchHighlightKeyModel {
  title = 'title',
  titlePhrases = 'title.phrases',
  contentTitle = 'contentTitle',
  contentTitlePhrases = 'contentTitle.phrases',
  story = 'story',
  storyPhrases = 'story.phrases',
  files = 'files.filePath',
  commentsStory = 'comments.story',
  commentsStoryPhrases = 'comments.story.phrases',
  commentsFiles = 'comments.files.filePath',
  authors = 'authors.name',
  allAuthors = 'allAuthors.name',
  discussionsStory = 'discussions.story',
  discussionsStoryPhrases = 'discussions.story.phrases',
  discussionsFiles = 'discussions.files.filePath',
}
