import React, { useEffect, useState } from 'react'
import { PostRow } from '~/pages/posts/PostRow'
import { PostType, ViewCommunityDocument } from '~/api/generated/graphql'
import { PostEdit } from '~/pages/posts/PostEdit'
import { useMutation } from '@apollo/client'

type ExpandedActivityRowProps = {
  postId: string
  postType: PostType
  communityId: string
  isExpanded: boolean
  eventKey: string
  onDelete: () => void
}

const ExpandedActivityRow = ({
  postId,
  postType,
  isExpanded,
  communityId,
  eventKey,
  onDelete,
}: ExpandedActivityRowProps) => {
  const [editingPost, setEditingPost] = useState<boolean>(false)

  const [viewCommunity] = useMutation(ViewCommunityDocument)
  useEffect(() => {
    if (isExpanded) {
      void viewCommunity({ variables: { communityId } }).then()
    }
  }, [isExpanded, communityId, viewCommunity])

  if (!isExpanded) return null

  return editingPost ? (
    <PostEdit postId={postId} onDone={() => setEditingPost(false)} />
  ) : (
    <PostRow
      postId={postId}
      eventKey={eventKey}
      isHomeFeed={true}
      onClickEdit={() => setEditingPost(true)}
      fromContentPage={false}
      onDelete={onDelete}
      showEditor={postType == PostType.Post}
    />
  )
}

export default ExpandedActivityRow
