import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import '@css/pages/community/CommunitySidebar.scss'
import CommunitiesGroupIcon from '@web/images/community/CommunitiesGroupIcon.svg'
import CommunitySelectorDropdown from '~/common/CommunitySelectorDropdown'
import { Link } from 'react-router-dom'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { CommunityListTab } from '~/pages/community/CommunityListLayout'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { elementClicked } from '~/common/EventLogger'

const CommunityListSidebar = ({ communityListTab }: { communityListTab: CommunityListTab }) => {
  const { isCondensed } = useWindowSize()
  const { recommendedCommunities } = useCommunities()
  const tab =
    communityListTab === CommunityListTab.My
      ? 'my'
      : communityListTab === CommunityListTab.All || !recommendedCommunities?.length
        ? 'all'
        : 'recommended'

  return (
    <Navbar className={`navbar-container${isCondensed ? ' condensed' : ''}`}>
      {isCondensed ? (
        <>
          <div className={'communities-header'}>
            <img className="communities-icon" src={CommunitiesGroupIcon} />
            <h1>Communities</h1>
          </div>
          <p>Discover and join communities and manage your community memberships.</p>
          <CommunitySelectorDropdown communityListTab={communityListTab} listPage={true} />
        </>
      ) : (
        <>
          <CommunitySelectorDropdown communityListTab={communityListTab} listPage={true} />
          <img className="communities-icon" src={CommunitiesGroupIcon} />
          <div className="community-description">
            <h1>Communities</h1>
            <p>Discover and join communities and manage your community memberships.</p>
          </div>
        </>
      )}
      {!isCondensed && (
        <>
          <Nav className={`navbar-section`} activeKey={tab}>
            <Nav.Item>
              <Nav.Link
                onClick={e => elementClicked(e, 'click-communities-sidebar-all')}
                eventKey="all"
                as={Link}
                role="all-tab"
                to="/allcommunities"
              >
                All Communities
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={e => elementClicked(e, 'click-communities-sidebar-my')}
                eventKey="my"
                as={Link}
                role="my-tab"
                to="/mycommunities"
              >
                My Communities
              </Nav.Link>
            </Nav.Item>
            {!!recommendedCommunities?.length && (
              <Nav.Item>
                <Nav.Link
                  onClick={e => elementClicked(e, 'click-communities-sidebar-recommended')}
                  eventKey="recommended"
                  as={Link}
                  role="recommended-tab"
                  to="/recommendedcommunities"
                >
                  Recommended Communities
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </>
      )}
    </Navbar>
  )
}

export default CommunityListSidebar
