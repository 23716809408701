import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import '@css/pages/profile/ProfileHeader.scss'
import { Alert, Button, Col, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { CreateMediaMutation, CreateMediaDocument, EditUserDocument } from '~/api/generated/graphql'
import { getCroppedUploadHandler, getLinkedinImage, getOfficeHomeLocation } from '~/utils'
import { useAuth } from '~/auth/Auth'
import { PlacesAutocomplete } from '~/pages/profile/PlacesAutocomplete'
import 'react-image-crop/dist/ReactCrop.css'
import { ModalCropper } from '~/common/ModalCropper'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { CustomToggle } from '~/common/CustomToggle'
import { elementClicked } from '~/common/EventLogger'
import { UserProfileModel } from '~/types'
import { useSearchParams } from 'react-router-dom'
import ToastComponent from '~/common/ToastComponent'
import { useProfile } from '~/contexts/ProfileContext'
import { useMutation } from '@apollo/client'

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'JFIF']

export const ProfileEdit = ({
  closeEdit,
  user,
  loading,
}: {
  closeEdit: () => void
  user?: UserProfileModel
  loading: boolean
}) => {
  const { isCondensedPortrait, breakpoint } = useWindowSize()
  const [searchParams, setSearchParams] = useSearchParams()
  const [toast, setToast] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [showLinkedinLoading, setShowLinkedinLoading] = useState(false)
  const { profileInfoChanges, setProfileInfoChanges } = useProfile()

  const { authUserId } = useAuth()
  const [editUser] = useMutation(EditUserDocument)
  const isMediumOrSmaller = breakpoint <= SizeBreakpoint.md

  const { office, home } = getOfficeHomeLocation(user?.primaryLocation, user?.secondaryLocation)

  const [homeCity, setHomeCity] = useState(home?.city ?? '')
  const [homeState, setHomeState] = useState(home?.state ?? '')
  const [homeCountry, setHomeCountry] = useState(home?.country ?? '')

  const [officeCity, setOfficeCity] = useState(office?.city ?? '')
  const [officeState, setOfficeState] = useState(office?.state ?? '')
  const [officeCountry, setOfficeCountry] = useState(office?.country ?? '')

  const [syncOrgwikiLocation, setSyncOrgwikiLocation] = useState(user?.syncOrgwikiLocation)

  const [firstName, setFirstName] = useState(user?.firstName)
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(user?.lastName)
  const [lastNameError, setLastNameError] = useState('')
  const [nickName, setNickName] = useState(user?.nickName)
  const [title, setTitle] = useState(user?.title)
  const [titleError, setTitleError] = useState('')
  const [linkedIn, setLinkedIn] = useState(user?.linkedinUrl)
  const [otherContact, setOtherContact] = useState(user?.otherContact)
  const [uploading, setUploading] = useState(false)
  const [createMedia] = useMutation(CreateMediaDocument)
  const [createMediaResponse, setCreateMediaResponse] = useState<CreateMediaMutation>()
  const [mediaUrl, setMediaUrl] = useState(user?.photo)
  const [cropImage, setCropImage] = useState<File | undefined>()
  const [showPhotoEdit, setShowPhotoEdit] = useState<boolean>(false)
  const fileUploaderRef = useRef<HTMLInputElement>(null)
  const [removeProfilePhoto, setRemoveProfilePhoto] = useState(false)
  const [addedProfilePhoto, setAddedProfilePhoto] = useState<boolean>(!!user?.photo)
  const [showLinkedinWarning, setShowLinkedinWarning] = useState<boolean>(false)

  useEffect(() => {
    getLinkedinImage(
      searchParams,
      setSearchParams,
      setToast,
      setShowToast,
      setShowLinkedinLoading,
      setShowPhotoEdit,
      setCropImage
    )
  }, [searchParams, setSearchParams])

  if (loading) return null
  if (!user) return <>Could not find user</>
  const handleFileFileCrop = getCroppedUploadHandler(setUploading, createMedia, setCreateMediaResponse, setMediaUrl)
  const isVeevan = user.isVeevan
  const isSelf = authUserId === user.userId

  const clickRemovePhoto = (e: SyntheticEvent) => {
    elementClicked(e, 'click-profile-photo-remove')
    setRemoveProfilePhoto(true)
    setCreateMediaResponse(undefined)
    setMediaUrl('')
    setAddedProfilePhoto(false)
  }
  const onChangeFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      const e = ev.target.files[0]
      if (e) {
        setCropImage(e)
        setShowPhotoEdit(true)
      }
      ev.target.value = ''
    }
  }

  const handleCancel = () => {
    closeEdit()
  }
  const handleSubmit = (e: SyntheticEvent) => {
    elementClicked(e, 'click-profile-save')
    if (firstName?.trim()) {
      setFirstNameError('')
    } else {
      setFirstNameError('Required')
    }
    if (lastName?.trim()) {
      setLastNameError('')
    } else {
      setLastNameError('Required')
    }
    if (title?.trim()) {
      setTitleError('')
    } else {
      setTitleError('Required')
    }
    if (!firstName?.trim() || !lastName?.trim() || !title?.trim()) {
      return false
    } else {
      void editUser({
        variables: {
          user_id: user.userId,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          nick_name: nickName?.trim(),
          primary_location_city: officeCity?.trim(),
          primary_location_state: officeState?.trim(),
          primary_location_country: officeCountry?.trim(),
          secondary_location_city: homeCity?.trim(),
          secondary_location_state: homeState?.trim(),
          secondary_location_country: homeCountry?.trim(),
          title: title.trim(),
          linkedin_url: linkedIn?.trim(),
          other_contact: otherContact?.trim(),
          upload_id: createMediaResponse?.createMedia?.uploadedFile?.id,
          delete_media: removeProfilePhoto,
          sync_orgwiki_location: syncOrgwikiLocation,
        },
      }).then(() => {
        closeEdit()
      })
    }
  }

  const clickUploadPhoto = (ev: SyntheticEvent) => {
    elementClicked(ev, 'click-profile-upload')
    ev.stopPropagation()
    fileUploaderRef.current?.click()
  }

  const clickLinkedIn = (ev: SyntheticEvent) => {
    if (profileInfoChanges) {
      setShowLinkedinWarning(true)
      return
    }
    ev.stopPropagation()
    elementClicked(ev, 'click-profile-photo-use-linkedin')
    globalThis.location.href = '/linkedin/auth_code'
  }

  const handleSyncClicked = () => {
    setSyncOrgwikiLocation(!syncOrgwikiLocation)
  }

  return (
    <>
      {showPhotoEdit && (
        <ModalCropper
          file={cropImage}
          handleBlobUpload={(blob, contentType, fileName) => {
            handleFileFileCrop(blob, contentType, fileName)
            setRemoveProfilePhoto(false)
            setAddedProfilePhoto(true)
          }}
          onClose={() => {
            setCropImage(undefined)
            setShowPhotoEdit(false)
          }}
          isCommunity={false}
          loading={showLinkedinLoading}
        />
      )}

      <Form onChange={() => setProfileInfoChanges?.(true)}>
        <div className={`user-header ${isCondensedPortrait ? 'condensed' : ''}`}>
          <div
            className={`profile-photo registration-photo ${mediaUrl ? 'has-photo' : ''} ${
              uploading ? 'uploading' : ''
            }`}
          >
            {isVeevan ? (
              <div
                className={'photo'}
                style={{ backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover' }}
                title={user.fullName}
              />
            ) : uploading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id={'profile-edit-photo'}>
                  <div
                    className={'photo'}
                    style={{ backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover' }}
                    title={user.fullName}
                    data-company={user.company.name}
                  >
                    <div className={'edit-photo'} title={'Edit Photo'} data-testid={'photo-edit-button'} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className={'edit-photo-dropdown'}>
                  <Dropdown.Item onClick={clickUploadPhoto}>Upload Photo</Dropdown.Item>
                  <Dropdown.Item onClick={clickLinkedIn}>Use LinkedIn Photo</Dropdown.Item>
                  {addedProfilePhoto && (
                    <>
                      <Dropdown.Item onClick={clickRemovePhoto}>Remove Photo</Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div className="profile-info d-flex">
            {!isSelf && (
              <Row>
                <Col>
                  <Alert variant={'warning'}>Users will be notified of any changes made to their profile.</Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={8} md={12}>
                <h2>Edit Profile</h2>
              </Col>
              <Col lg={4} md={12} className={'d-flex justify-content-end p-2'}>
                <Button variant={'light'} onClick={handleCancel} role={'cancel-profile'}>
                  Cancel
                </Button>
                <Button variant={'primary'} onClick={handleSubmit} role={'save-profile'} disabled={uploading}>
                  Save
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <hr />
              </Col>
            </Row>
            {user.isVeevan && (
              <Row>
                <Col lg={12}>
                  <div className="veevan-sync-message">
                    Veeva user information is sync'd from OrgWiki. To edit disabled fields, edit your OrgWiki profile.
                  </div>
                </Col>
              </Row>
            )}
            <Row className={'mb-3'}>
              <Form.Group as={Col} controlId={'firstName'}>
                <Form.Label>
                  First Name{firstNameError ? <div className={'error'}>{firstNameError}</div> : ''}
                </Form.Label>
                <Form.Control
                  value={firstName}
                  maxLength={100}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder={'First Name'}
                  disabled={isVeevan}
                  role="form-first-name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId={'lastName'}>
                <Form.Label>Last Name{lastNameError ? <div className={'error'}>{lastNameError}</div> : ''}</Form.Label>
                <Form.Control
                  value={lastName}
                  maxLength={100}
                  onChange={e => setLastName(e.target.value)}
                  placeholder={'Last Name'}
                  disabled={isVeevan}
                  role="form-last-name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId={'nickName'}>
                <Form.Label>Nick Name</Form.Label>
                <Form.Control
                  value={nickName}
                  maxLength={15}
                  onChange={e => setNickName(e.target.value)}
                  placeholder={'Nick Name'}
                  role="form-nick-name"
                />
              </Form.Group>
            </Row>
            {isCondensedPortrait ? (
              <>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} controlId={'title'}>
                    <Form.Label>Job Title{titleError ? <div className={'error'}>{titleError}</div> : ''}</Form.Label>
                    <Form.Control
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      maxLength={100}
                      placeholder={'Job Title'}
                      disabled={isVeevan}
                      role="form-title"
                    />
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} controlId={'company'}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control value={user.company.name} disabled={true} role="form-company" />
                  </Form.Group>
                </Row>
              </>
            ) : (
              <Row className={'mb-3'}>
                <Form.Group as={Col} controlId={'title'}>
                  <Form.Label>Job Title{titleError ? <div className={'error'}>{titleError}</div> : ''}</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    maxLength={100}
                    placeholder={'Job Title'}
                    disabled={isVeevan}
                    role="form-title"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId={'company'}>
                  <Form.Label>Company</Form.Label>
                  <Form.Control value={user.company.name} disabled={true} role="form-company" />
                </Form.Group>
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <hr />
              </Col>
            </Row>
            {isVeevan && (
              <Row className={'mb-3'}>
                <Form.Group as={Col} controlId={'sync_from_orgwiki'}>
                  <Form.Label className={'location-label'}>Home</Form.Label>
                </Form.Group>
                {!isMediumOrSmaller && (
                  <Form.Group as={Col} controlId={'office-label'}>
                    <Form.Label className={'location-label'}>Office</Form.Label>
                  </Form.Group>
                )}
                <div className={'checkbox-container'}>
                  <input
                    id="sync-checkbox"
                    type="checkbox"
                    checked={syncOrgwikiLocation}
                    onChange={() => {
                      handleSyncClicked()
                    }}
                    disabled={!isVeevan}
                    role={'sync-checkbox'}
                  />
                  <label>Sync from OrgWiki</label>
                </div>
              </Row>
            )}
            <Row className={'mb-3'}>
              {isVeevan && (
                <>
                  <Form.Group lg={2} md={12} as={Col} controlId={'home_location_city'}>
                    <Form.Label>City</Form.Label>
                    <PlacesAutocomplete
                      city={homeCity}
                      setCity={setHomeCity}
                      setCountry={setHomeCountry}
                      setState={setHomeState}
                      disabled={syncOrgwikiLocation}
                    />
                  </Form.Group>
                  <Form.Group lg={2} md={12} as={Col} controlId={'home_location_state'}>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      value={homeState}
                      onChange={e => setHomeState(e.target.value)}
                      maxLength={255}
                      disabled={syncOrgwikiLocation}
                      role="form-state"
                    />
                  </Form.Group>
                  <Form.Group lg={2} md={12} as={Col} controlId={'home_location_country'}>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      value={homeCountry}
                      onChange={e => setHomeCountry(e.target.value)}
                      maxLength={200}
                      disabled={syncOrgwikiLocation}
                      role="form-country"
                    />
                  </Form.Group>
                </>
              )}
              {isVeevan && isMediumOrSmaller && (
                <Form.Group as={Col} controlId={'office-label'}>
                  <Form.Label className={'location-label'} id={'office-label-condensed'}>
                    Office
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group lg={2} md={12} as={Col} controlId={'office_location_city'}>
                <Form.Label>City</Form.Label>
                <PlacesAutocomplete
                  city={officeCity}
                  setCity={setOfficeCity}
                  setCountry={setOfficeCountry}
                  setState={setOfficeState}
                  disabled={isVeevan}
                />
              </Form.Group>
              <Form.Group lg={2} md={12} as={Col} controlId={'office_location_state'}>
                <Form.Label>State</Form.Label>
                <Form.Control
                  value={officeState}
                  onChange={e => setOfficeState(e.target.value)}
                  maxLength={255}
                  disabled={isVeevan}
                  role="form-state"
                />
              </Form.Group>
              <Form.Group lg={2} md={12} as={Col} controlId={'office_location_country'}>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  value={officeCountry}
                  onChange={e => setOfficeCountry(e.target.value)}
                  maxLength={200}
                  disabled={isVeevan}
                  role="form-country"
                />
              </Form.Group>
            </Row>
            <Row className={'mb-3'}>
              <Col lg={6}>
                <Form.Group controlId={'email'}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control value={user.email} disabled role="form-email" />
                </Form.Group>
              </Col>
              <Col lg={6} className={`${isMediumOrSmaller && 'linkedin-url-condensed'}`}>
                <Form.Group controlId={'linkedin'}>
                  <Form.Label>LinkedIn URL</Form.Label>
                  <Form.Control
                    value={linkedIn}
                    onChange={e => setLinkedIn(e.target.value)}
                    maxLength={100}
                    placeholder={'LinkedIn URL'}
                    disabled={isVeevan}
                    role="form-linkedin"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group controlId={'otherContact'}>
                  <Form.Label>Other Contact Info</Form.Label>
                  <Form.Control
                    value={otherContact || ''}
                    maxLength={150}
                    onChange={e => setOtherContact(e.target.value)}
                    placeholder={'Other Contact Info'}
                    role="form-other-contact"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <input
        type={'file'}
        name={'file'}
        ref={fileUploaderRef}
        onChange={onChangeFile}
        accept={fileTypes.map(t => `.${t.toLowerCase()}`).join(',')}
        style={{ display: 'none' }}
      />
      <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
        {toast ?? ''}
      </ToastComponent>
      <Modal show={showLinkedinWarning} onHide={() => setShowLinkedinWarning(false)}>
        <Modal.Body>You have unsaved changes to your profile. Please save your profile edits and try again.</Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setShowLinkedinWarning(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
