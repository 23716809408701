import { BlockEmbed } from 'quill/blots/block'

class MeetupBlot extends BlockEmbed {
  static blotName = 'meetup'
  static tagName = 'meetup'

  static create(args: { meetupId: string }) {
    const container = document.createElement('meetup')
    container.className = 'meetup'
    container.contentEditable = 'false'
    container.className = 'meetup'

    const registerBtn = document.createElement('button')
    registerBtn.setAttribute('class', 'btn-plain')
    const title = document.createElement('svg')
    title.className = 'btn-title'
    const span = document.createElement('span')
    span.textContent = 'Click to register!'

    registerBtn.append(title)
    registerBtn.append(span)
    container.append(registerBtn)
    container.dataset.id = args.meetupId
    container.dataset.testid = 'meetup-embed'
    return container
  }

  static value(node: HTMLElement) {
    return {
      meetupId: node.dataset.id,
    }
  }
}

export default MeetupBlot
