import React from 'react'
import { useCommunity } from '~/contexts/CommunityContext'
import { GetCommunitySettingsDocument } from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { usePermissions } from '~/pages/posts/PostUtils'
import '@css/common/AuthoringArea.scss'
import '@css/pages/community/CommunitySettings.scss'
import { useNavigate } from 'react-router'
import CommunitySettingsEdit from '~/pages/community/CommunitySettingsEdit'
import { CommunitySettingsDataModel } from '~/types'
import { useQuery } from '@apollo/client'

type Props = {
  isCompany: boolean
}

const CommunitySettings = ({ isCompany }: Props) => {
  const navigate = useNavigate()
  const { loading: loadingAuth, authUserId, isVeevan, actingSysAdmin, profileVisible } = useAuth()
  const { loading: loadingPermissions, hasLeaderPermissions } = usePermissions(authUserId)
  const { communityId } = useCommunity()
  const { loading: loadingCommunity, data } = useQuery(GetCommunitySettingsDocument, {
    variables: {
      id: communityId ?? '',
      shouldFetchCompany: isCompany,
    },
    skip: !communityId,
  })

  if (!loadingAuth && !loadingPermissions && !(actingSysAdmin || hasLeaderPermissions || (isCompany && isVeevan))) {
    navigate(`../${isCompany ? 'home' : 'about'}`)
  }

  if (loadingCommunity || loadingAuth || loadingPermissions || !data?.community?.communityId) return <>Loading...</>
  if (isCompany && !data.community.company?.companyId) return <>Could not retrieve company data</>

  const community: CommunitySettingsDataModel = {
    communityId: data.community.communityId,
    name: data.community.name,
    description: data.community.description ?? null,
    photo: data.community.photo ?? null,
    aliases: (data.community.aliases as string[]) ?? [],
    company: data.community.company?.companyId
      ? {
          companyId: data.community.company.companyId,
          name: data.community.company.name,
          domains: (data.community.company.domains as string[]) ?? [],
        }
      : undefined,
    type: data.community.type,
  }

  return (
    <CommunitySettingsEdit
      community={community}
      isCompany={isCompany}
      isLeader={hasLeaderPermissions}
      profileVisible={profileVisible}
      creatingCompany={false}
    />
  )
}

export default CommunitySettings
