import 'react-bootstrap-typeahead/css/Typeahead.scss'
import '@css/common/TypeaheadSearch.scss'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ColumnLayout from '~/common/ColumnLayout'
import '@css/pages/search/AdvancedSearch.scss'
import AdvancedSearchResult from '~/pages/search/AdvancedSearchResult'
import { Button } from 'react-bootstrap'
import { AdvancedSearchDocument, GetMyCommunityFacetsDocument } from '~/api/generated/graphql'
import { useNavigate } from 'react-router'
import moreIcon from '@web/images/profile/more-icon-light.svg'
import { elementClicked } from '~/common/EventLogger'
import { SearchMatchModel } from '~/types'
import { useTitle } from '~/common/hooks/useTitle'
import { AdvancedSearchWordMatching } from '~/pages/search/AdvancedSearchWordMatching'
import { getPluralizedPost, getSafeLocationState } from '~/utils'
import { SearchObject, SearchObjectType } from '~/common/TypeaheadSearch'
import { AdvancedSearchSidebar, AuthorFacet, CommunityFacet, CompanyFacet } from '~/pages/search/AdvancedSearchSidebar'
import { getInstantSearchOptions, getSearchResponseFacets } from '~/pages/search/SearchUtilities'
import { SelectedTimeFacet } from '~/pages/search/TimeFacets'
import AdvancedSearchReleaseResults from '~/pages/search/AdvancedSearchReleaseResults'
import { useApolloClient, useQuery } from '@apollo/client'
import { useAuth } from '~/auth/Auth'
import { useLocation } from 'react-router-dom'

export type AdvancedSearchState = {
  searchTerm?: string
  filter?: string
  historyCommunityFacets?: CommunityFacet[]
  selectedAuthorFacets?: AuthorFacet[]
  selectedCompanyFacets?: CompanyFacet[]
  time?: SelectedTimeFacet
  startDate?: Date
  endDate?: Date
  includeDiscussions?: boolean
  freshSearch?: boolean
  scrollTop?: number
  showingPartialMatches?: boolean
}

export enum ResultsTab {
  all,
  my,
  custom,
  none,
}

export class FacetResponse {
  key: string
  count: number
}

export type SearchVars = {
  query: string
  tab: string
  allMatch: boolean
  authors?: string[]
  companies?: string[]
  afterDate?: string
  beforeDate?: string
  time?: string
  includeDiscussions: boolean
  first: number
}

export const AdvancedSearch = () => {
  const { isCondensed } = useWindowSize()
  const navigate = useNavigate()
  const location = useLocation()
  const state = getSafeLocationState<AdvancedSearchState>(location.state) ?? {}
  const { cache } = useApolloClient()
  const { actingSysAdmin, actingRelAdmin } = useAuth()

  const [currentResultsTab, setCurrentResultsTab] = useState<ResultsTab>(ResultsTab.none)
  const currentSearchTerm = state.searchTerm ?? ''
  const [currentFilter, setCurrentFilter] = useState<string>(state.filter ?? '')

  const [selectedCommunityFacets, updateSelectedCommunityFacets] = useState<CommunityFacet[]>([])
  const [historyCommunityFacets, updateHistoryCommunityFacets] = useState<CommunityFacet[]>(
    state.historyCommunityFacets ?? []
  )
  const [selectedAuthorFacets, updateSelectedAuthorFacets] = useState<AuthorFacet[]>(state.selectedAuthorFacets ?? [])
  const [selectedCompanyFacets, updateSelectedCompanyFacets] = useState<CompanyFacet[]>(
    state.selectedCompanyFacets ?? []
  )
  const [selectedTime, setSelectedTime] = useState<SelectedTimeFacet>(state.time ?? SelectedTimeFacet.ANY)
  const [startDate, setStartDate] = useState<Date>(state.startDate ?? new Date())
  const [endDate, setEndDate] = useState<Date>(state.endDate ?? new Date())
  const [includeDiscussions, setIncludeDiscussions] = useState(state.includeDiscussions ?? false)
  const [scrollTop, setScrollTop] = useState<number>(state.scrollTop ?? 0)
  const [showingPartialMatches, setShowingPartialMatches] = useState(state.showingPartialMatches ?? false)

  const [fetchingMoreAll, setFetchingMoreAll] = useState(false)
  const [fetchingMoreMy, setFetchingMoreMy] = useState(false)
  const [fetchingMoreCustom, setFetchingMoreCustom] = useState(false)
  useTitle('Search - Veeva Connect')

  const releases: SearchObject[] = useMemo(() => {
    if (currentSearchTerm === undefined) {
      return []
    } else {
      const options: SearchObject[] = getInstantSearchOptions(
        cache,
        currentSearchTerm,
        actingSysAdmin || actingRelAdmin
      )
      return options.filter(o => o.type === SearchObjectType.release)
    }
  }, [cache, currentSearchTerm, actingSysAdmin, actingRelAdmin])

  const communities = useMemo(() => {
    if (historyCommunityFacets) {
      return historyCommunityFacets.map(f => f.communityId)
    }
  }, [historyCommunityFacets])

  const authors = useMemo(() => {
    if (selectedAuthorFacets.length > 0) {
      return selectedAuthorFacets.map(f => f.authorId)
    }
  }, [selectedAuthorFacets])

  const companies = useMemo(() => {
    if (selectedCompanyFacets.length > 0) {
      return selectedCompanyFacets.map(f => f.companyId)
    }
  }, [selectedCompanyFacets])

  const isCustomTimeSelected = selectedTime?.label === SelectedTimeFacet.CUSTOM.label
  const allVars = {
    query: currentSearchTerm,
    tab: '',
    allMatch: true,
    authors,
    companies,
    afterDate: isCustomTimeSelected ? startDate?.toISOString().slice(0, 10) : undefined,
    beforeDate: isCustomTimeSelected ? endDate?.toISOString().slice(0, 10) : undefined,
    time: selectedTime?.requestString,
    includeDiscussions,
    first: 25,
  }
  const {
    data: allData,
    loading: loadingAllResults,
    fetchMore: fetchMoreAll,
    previousData: previousAllData,
  } = useQuery(AdvancedSearchDocument, {
    variables: allVars,
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })

  const {
    data: allDataPartial,
    loading: loadingAllPartialResults,
    fetchMore: fetchMoreAllPartial,
    previousData: previousAllPartialData,
  } = useQuery(AdvancedSearchDocument, {
    variables: { ...allVars, allMatch: false },
    skip: currentSearchTerm.trim().split(/(\s+)/).length < 2, // More than one word in search str
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })
  const myVars = {
    query: currentSearchTerm,
    tab: 'my',
    allMatch: true,
    authors,
    companies,
    afterDate: isCustomTimeSelected ? startDate?.toISOString().slice(0, 10) : undefined,
    beforeDate: isCustomTimeSelected ? endDate?.toISOString().slice(0, 10) : undefined,
    time: selectedTime?.requestString,
    includeDiscussions,
    first: 25,
  }
  const {
    data: myData,
    loading: loadingMyResults,
    fetchMore: fetchMoreMy,
  } = useQuery(AdvancedSearchDocument, {
    variables: myVars,
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })
  const {
    data: myDataPartial,
    loading: loadingMyPartialResults,
    fetchMore: fetchMoreMyPartial,
  } = useQuery(AdvancedSearchDocument, {
    variables: { ...myVars, allMatch: false },
    skip: currentSearchTerm.trim().split(/(\s+)/).length < 2,
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })
  const customVars = {
    query: currentSearchTerm,
    tab: '',
    allMatch: true,
    communities,
    authors,
    companies,
    afterDate: isCustomTimeSelected ? startDate?.toISOString().slice(0, 10) : undefined,
    beforeDate: isCustomTimeSelected ? endDate?.toISOString().slice(0, 10) : undefined,
    time: selectedTime?.requestString,
    includeDiscussions,
    first: 25,
  }
  const {
    data: customData,
    loading: loadingCustomResults,
    fetchMore: fetchMoreCustom,
  } = useQuery(AdvancedSearchDocument, {
    variables: customVars,
    skip: !communities,
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })
  const {
    data: customDataPartial,
    loading: loadingCustomPartialResults,
    fetchMore: fetchMoreCustomPartial,
  } = useQuery(AdvancedSearchDocument, {
    variables: { ...customVars, allMatch: false },
    skip: !communities || currentSearchTerm.trim().split(/(\s+)/).length < 2,
    fetchPolicy: state.freshSearch ? 'cache-and-network' : 'cache-first',
  })

  // We want to display the previous counts until we have our new data (to prevent flashing a count of 0).
  const allDataTimeFacet = loadingAllResults && previousAllData ? previousAllData : allData
  const allPartialDataTimeFacet =
    loadingAllPartialResults && previousAllPartialData ? previousAllPartialData : allDataPartial

  const fullCountAll = allData?.advancedSearch?.count || 0
  const fullCountMy = myData?.advancedSearch?.count || 0
  const fullCountCustom = customData?.advancedSearch?.count || 0
  const partialCountAll = allDataPartial?.advancedSearch?.count || 0
  const partialCountMy = myDataPartial?.advancedSearch?.count || 0
  const partialCountCustom = customDataPartial?.advancedSearch?.count || 0

  const displayedResultsAll = allData?.advancedSearch?.matches as SearchMatchModel[]
  const displayedResultsMy = myData?.advancedSearch?.matches as SearchMatchModel[]
  const displayedResultsCustom = customData?.advancedSearch?.matches as SearchMatchModel[]
  const partialResultsAll = allDataPartial?.advancedSearch?.matches as SearchMatchModel[]
  const partialResultsMy = myDataPartial?.advancedSearch?.matches as SearchMatchModel[]
  const partialResultsCustom = customDataPartial?.advancedSearch?.matches as SearchMatchModel[]

  const hasMoreResultsAll = (fullCountAll ?? 0) > (displayedResultsAll?.length ?? 0)
  const hasMoreResultsMy = (fullCountMy ?? 0) > (displayedResultsMy?.length ?? 0)
  const hasMoreResultsCustom = (fullCountCustom ?? 0) > (displayedResultsCustom?.length ?? 0)
  const hasMorePartialResultsAll = (partialCountAll ?? 0) > (partialResultsAll?.length ?? 0)
  const hasMorePartialResultsMy = (partialCountMy ?? 0) > (partialResultsMy?.length ?? 0)
  const hasMorePartialResultsCustom = (partialCountCustom ?? 0) > (partialResultsCustom?.length ?? 0)
  const loadingResults =
    loadingAllResults ||
    loadingMyResults ||
    loadingCustomResults ||
    loadingAllPartialResults ||
    loadingMyPartialResults ||
    loadingCustomPartialResults

  const { data: myCommunitiesData } = useQuery(GetMyCommunityFacetsDocument)
  const myCommunities = myCommunitiesData?.currentUser?.communities?.edges

  const savePageState = useCallback(
    (resetScroll = false, resetPartialMatches = false) => {
      const currentState = {
        searchTerm: currentSearchTerm,
        filter: currentFilter,
        historyCommunityFacets: historyCommunityFacets,
        selectedAuthorFacets: selectedAuthorFacets,
        selectedCompanyFacets: selectedCompanyFacets,
        time: selectedTime,
        startDate: startDate,
        endDate: endDate,
        includeDiscussions: includeDiscussions,
        scrollTop: resetScroll ? 0 : componentRef?.current?.parentElement?.scrollTop,
        showingPartialMatches: resetPartialMatches ? false : showingPartialMatches,
      }
      navigate('/search', { state: currentState, replace: true })
    },
    [
      currentSearchTerm,
      currentFilter,
      historyCommunityFacets,
      selectedAuthorFacets,
      selectedCompanyFacets,
      selectedTime,
      startDate,
      endDate,
      includeDiscussions,
      showingPartialMatches,
      navigate,
    ]
  )

  const selectAllTab = useCallback(() => {
    setCurrentResultsTab(ResultsTab.all)
    updateSelectedCommunityFacets([])
    setCurrentFilter('all')
    savePageState()
  }, [setCurrentResultsTab, updateSelectedCommunityFacets, savePageState])

  const selectMyTab = useCallback(() => {
    setCurrentResultsTab(ResultsTab.my)
    const myFacets = myCommunities?.map(c => c?.node) as CommunityFacet[]
    updateSelectedCommunityFacets(myFacets)
    setCurrentFilter('my')
    savePageState()
  }, [setCurrentResultsTab, myCommunities, updateSelectedCommunityFacets, savePageState])

  const selectCustomTab = useCallback(
    (alreadyUpdatedVisibleFacets?: boolean) => {
      setCurrentResultsTab(ResultsTab.custom)
      if (!alreadyUpdatedVisibleFacets) updateSelectedCommunityFacets(historyCommunityFacets ?? [])
      setCurrentFilter(historyCommunityFacets?.map(f => f.communityId).join(','))
      savePageState()
    },
    [setCurrentResultsTab, updateSelectedCommunityFacets, historyCommunityFacets, savePageState]
  )

  const doesCustomMatchMy = useCallback(
    (selectedIds: string[]) => {
      return JSON.stringify(myCommunities?.map(c => c?.node?.communityId).sort()) === JSON.stringify(selectedIds.sort())
    },
    [myCommunities]
  )

  const handleAddCommunityFacet = useCallback(
    (facet: CommunityFacet) => {
      const newFacetList = [...selectedCommunityFacets, facet]
      if (doesCustomMatchMy(newFacetList.map(f => f.communityId))) {
        selectMyTab()
      } else {
        updateHistoryCommunityFacets(newFacetList)
        updateSelectedCommunityFacets(newFacetList)
        setCurrentFilter(newFacetList.map(f => f.communityId).join(','))
      }
    },
    [doesCustomMatchMy, selectMyTab, selectedCommunityFacets]
  )

  const handleRemoveCommunityFacet = (facet: CommunityFacet) => {
    const newFacetList = selectedCommunityFacets.filter(c => c.communityId !== facet.communityId)
    if (doesCustomMatchMy(newFacetList.map(f => f.communityId))) {
      selectMyTab()
    } else {
      updateHistoryCommunityFacets(newFacetList)
      updateSelectedCommunityFacets(newFacetList)
      setCurrentFilter(newFacetList.map(f => f.communityId).join(','))
    }
  }

  const handleAddAuthorFacet = useCallback(
    (facet: AuthorFacet) => {
      const newFacetList = [...(selectedAuthorFacets ?? []), facet]
      updateSelectedAuthorFacets(newFacetList)
    },
    [selectedAuthorFacets]
  )

  const handleRemoveAuthorFacet = (facet: AuthorFacet) => {
    const newFacetList = selectedAuthorFacets?.filter(f => f.authorId !== facet.authorId)
    updateSelectedAuthorFacets(newFacetList)
  }

  const handleAddCompanyFacet = useCallback(
    (facet: CompanyFacet) => {
      const newFacetList = [...(selectedCompanyFacets ?? []), facet]
      updateSelectedCompanyFacets(newFacetList)
    },
    [selectedCompanyFacets]
  )

  const handleRemoveCompanyFacet = (facet: CompanyFacet) => {
    const newFacetList = selectedCompanyFacets?.filter(f => f.companyId !== facet.companyId)
    updateSelectedCompanyFacets(newFacetList)
  }

  const handleClearFacets = () => {
    updateHistoryCommunityFacets([])
    updateSelectedAuthorFacets([])
    updateSelectedCompanyFacets([])
    setSelectedTime(SelectedTimeFacet.ANY)
    setIncludeDiscussions(false)
    selectAllTab()
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const allRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && scrollTop) {
        componentRef.current?.parentElement?.scrollTo(0, scrollTop)
        setScrollTop(0)
      }
    },
    [scrollTop]
  )
  const myRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && scrollTop) {
        componentRef.current?.parentElement?.scrollTo(0, scrollTop)
        setScrollTop(0)
      }
    },
    [scrollTop]
  )
  const customRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && scrollTop) {
        componentRef.current?.parentElement?.scrollTo(0, scrollTop)
        setScrollTop(0)
      }
    },
    [scrollTop]
  )

  useEffect(() => {
    if (currentFilter !== null && currentFilter !== undefined) {
      switch (currentFilter) {
        case '':
        case 'all':
          selectAllTab()
          break
        case 'my':
          selectMyTab()
          break
        default: {
          selectCustomTab()
          break
        }
      }
    }
  }, [currentFilter, selectAllTab, selectMyTab, selectCustomTab])

  window.addEventListener('beforeunload', () => {
    savePageState(true, true)
  })

  const resultsSection = () => {
    return (
      <>
        {releases && (releases?.length ?? 0) > 0 && <AdvancedSearchReleaseResults releases={releases} />}
        <div className={'post-results-section'}>
          {loadingResults ? (
            <div className={'loading-results'}>Loading Results...</div>
          ) : (
            <>
              <span className={'section-header'}>Posts</span>
              <div className={`filter-tabs ${isCondensed ? 'condensed' : ''}`}>
                <Button
                  className={`filter-button ${currentResultsTab === ResultsTab.all ? '' : 'unselected'}`}
                  onClick={e => {
                    elementClicked(e, 'click-advanced-search-result-filter-community', {
                      filter_community_tab: 'all',
                    })
                    selectAllTab()
                  }}
                  tabIndex={0}
                  data-testid={'all-filter-button'}
                >
                  {showingPartialMatches ? fullCountAll + partialCountAll : fullCountAll}{' '}
                  {getPluralizedPost(displayedResultsAll?.length)} in All Communities
                </Button>
                {(myCommunities?.length ?? 0) > 1 && (
                  <Button
                    className={`filter-button ${currentResultsTab === ResultsTab.my ? '' : 'unselected'}`}
                    onClick={e => {
                      elementClicked(e, 'click-advanced-search-result-filter-community', {
                        filter_community_tab: 'my',
                      })
                      selectMyTab()
                    }}
                    tabIndex={0}
                    data-testid={'my-filter-button'}
                  >
                    {showingPartialMatches ? fullCountMy + partialCountMy : fullCountMy}{' '}
                    {getPluralizedPost(displayedResultsMy?.length)} in My Communities
                  </Button>
                )}
                {(historyCommunityFacets?.length ?? 0) > 0 && (
                  <Button
                    className={`filter-button ${currentResultsTab === ResultsTab.custom ? '' : 'unselected'}`}
                    onClick={e => {
                      elementClicked(e, 'click-advanced-search-result-filter-community', {
                        filter_community_tab: 'custom',
                      })
                      selectCustomTab(false)
                    }}
                    tabIndex={0}
                    data-testid={'custom-filter-button'}
                  >
                    {showingPartialMatches ? fullCountCustom + partialCountCustom : fullCountCustom}{' '}
                    {getPluralizedPost(displayedResultsCustom?.length)} in{' '}
                    {historyCommunityFacets?.length === 1
                      ? `${historyCommunityFacets[0].name}`
                      : `${historyCommunityFacets?.length} Communities`}
                  </Button>
                )}
              </div>
              {currentResultsTab === ResultsTab.all && (
                <div ref={allRef}>
                  {displayedResultsAll?.map((result, index) => (
                    <div key={`all-${result?.post.id}-${index}`}>
                      <AdvancedSearchResult
                        result={result}
                        selectedAuthors={selectedAuthorFacets ?? []}
                        selectedCompanies={selectedCompanyFacets ?? []}
                        onResultClicked={(link, postId, e) => {
                          elementClicked(e, 'advanced-search-result-click', {
                            post_id: postId,
                            index,
                            all_words_matched: true,
                            filter_community_tab: 'all',
                          })
                        }}
                      />
                    </div>
                  ))}
                  {hasMoreResultsAll ? (
                    <div className="show-more">
                      <Button
                        onClick={e => {
                          setFetchingMoreAll(true)
                          elementClicked(e, 'click-advanced-search-result-show-more', {
                            filter_community_tab: 'all',
                          })
                          void fetchMoreAll({ variables: { after: allData?.advancedSearch?.pageInfo.endCursor } }).then(
                            () => setFetchingMoreAll(false)
                          )
                        }}
                        disabled={fetchingMoreAll}
                        tabIndex={0}
                        className={'show-more-button'}
                      >
                        Show more <img src={moreIcon} alt={'Show more'} />
                      </Button>
                    </div>
                  ) : (
                    <>
                      {currentSearchTerm && (
                        <AdvancedSearchWordMatching
                          currentResultsTab={currentResultsTab}
                          fullMatchCountAll={fullCountAll}
                          fullMatchCount={fullCountAll}
                          partialMatchCountAll={partialCountAll}
                          partialMatchCount={partialCountAll}
                          showingPartialMatches={showingPartialMatches ?? false}
                          toggleShowingPartialMatches={() => setShowingPartialMatches(!showingPartialMatches)}
                          selectAllTab={selectAllTab}
                        />
                      )}
                      {showingPartialMatches && partialCountAll != 0 && (
                        <div className={'partial-matches'}>
                          {partialResultsAll?.map((result, index) => (
                            <div key={`all-${result?.post.id}-${index + fullCountAll}`}>
                              <AdvancedSearchResult
                                result={result}
                                selectedAuthors={selectedAuthorFacets ?? []}
                                selectedCompanies={selectedCompanyFacets ?? []}
                                onResultClicked={(link, postId, e) => {
                                  elementClicked(e, 'advanced-search-result-click', {
                                    post_id: postId,
                                    index: index + fullCountAll,
                                    all_words_matched: false,
                                    filter_community_tab: 'all',
                                  })
                                }}
                              />
                            </div>
                          ))}
                          {hasMorePartialResultsAll && (
                            <div className="show-more">
                              <Button
                                onClick={e => {
                                  setFetchingMoreAll(true)
                                  elementClicked(e, 'click-advanced-search-result-show-more', {
                                    filter_community_tab: 'all',
                                  })
                                  void fetchMoreAllPartial({
                                    variables: { after: allDataPartial?.advancedSearch?.pageInfo.endCursor },
                                  }).then(() => setFetchingMoreAll(false))
                                }}
                                disabled={fetchingMoreAll}
                                tabIndex={0}
                                className={'show-more-button'}
                              >
                                Show more <img src={moreIcon} alt={'Show more'} />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {currentResultsTab === ResultsTab.my && (
                <div ref={myRef}>
                  {displayedResultsMy?.map((result, index) => (
                    <div key={`my-${result?.post.id}-${index}`}>
                      <AdvancedSearchResult
                        result={result}
                        selectedAuthors={selectedAuthorFacets ?? []}
                        selectedCompanies={selectedCompanyFacets ?? []}
                        onResultClicked={(link, postId, e) => {
                          elementClicked(e, 'advanced-search-result-click', {
                            post_id: postId,
                            index,
                            all_words_matched: true,
                            filter_community_tab: 'my',
                          })
                        }}
                      />
                    </div>
                  ))}
                  {hasMoreResultsMy ? (
                    <div className="show-more">
                      <Button
                        onClick={e => {
                          setFetchingMoreMy(true)
                          elementClicked(e, 'click-advanced-search-result-show-more', {
                            filter_community_tab: 'my',
                          })
                          void fetchMoreMy({ variables: { after: myData?.advancedSearch?.pageInfo.endCursor } }).then(
                            () => setFetchingMoreMy(false)
                          )
                        }}
                        disabled={fetchingMoreMy}
                        tabIndex={0}
                        className={'show-more-button'}
                      >
                        Show more <img src={moreIcon} alt={'Show more'} />
                      </Button>
                    </div>
                  ) : (
                    <>
                      {currentSearchTerm && (
                        <AdvancedSearchWordMatching
                          currentResultsTab={currentResultsTab}
                          fullMatchCountAll={fullCountAll}
                          fullMatchCount={fullCountMy}
                          partialMatchCountAll={partialCountAll}
                          partialMatchCount={partialCountMy}
                          showingPartialMatches={showingPartialMatches ?? false}
                          toggleShowingPartialMatches={() => setShowingPartialMatches(!showingPartialMatches)}
                          selectAllTab={selectAllTab}
                        />
                      )}
                      {showingPartialMatches && partialCountMy != 0 && (
                        <div className={'partial-matches'}>
                          {partialResultsMy?.map((result, index) => (
                            <div key={`all-${result?.post.id}-${index + fullCountMy}`}>
                              <AdvancedSearchResult
                                result={result}
                                selectedAuthors={selectedAuthorFacets ?? []}
                                selectedCompanies={selectedCompanyFacets ?? []}
                                onResultClicked={(link, postId, e) => {
                                  elementClicked(e, 'advanced-search-result-click', {
                                    post_id: postId,
                                    index: index + fullCountMy,
                                    all_words_matched: false,
                                    filter_community_tab: 'my',
                                  })
                                }}
                              />
                            </div>
                          ))}
                          {hasMorePartialResultsMy && (
                            <div className="show-more">
                              <Button
                                onClick={e => {
                                  setFetchingMoreMy(true)
                                  elementClicked(e, 'click-advanced-search-result-show-more', {
                                    filter_community_tab: 'my',
                                  })
                                  void fetchMoreMyPartial({
                                    variables: { after: myDataPartial?.advancedSearch?.pageInfo.endCursor },
                                  }).then(() => setFetchingMoreMy(false))
                                }}
                                disabled={fetchingMoreAll}
                                tabIndex={0}
                                className={'show-more-button'}
                              >
                                Show more <img src={moreIcon} alt={'Show more'} />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {currentResultsTab === ResultsTab.custom && (
                <div ref={customRef}>
                  {displayedResultsCustom?.map((result, index) => (
                    <div key={`custom-${result?.post.id}-${index}`}>
                      <AdvancedSearchResult
                        result={result}
                        selectedAuthors={selectedAuthorFacets ?? []}
                        selectedCompanies={selectedCompanyFacets ?? []}
                        onResultClicked={(link, postId, e) => {
                          elementClicked(e, 'advanced-search-result-click', {
                            post_id: postId,
                            index,
                            all_words_matched: true,
                            filter_community_tab: 'custom',
                          })
                        }}
                      />
                    </div>
                  ))}
                  {hasMoreResultsCustom ? (
                    <div className="show-more">
                      <Button
                        onClick={e => {
                          setFetchingMoreCustom(true)
                          elementClicked(e, 'click-advanced-search-result-show-more', {
                            filter_community_tab: 'custom',
                          })
                          void fetchMoreCustom({
                            variables: { after: customData?.advancedSearch?.pageInfo.endCursor },
                          }).then(() => setFetchingMoreCustom(false))
                        }}
                        disabled={fetchingMoreCustom}
                        tabIndex={0}
                        className={'show-more-button'}
                      >
                        Show more <img src={moreIcon} alt={'Show more'} />
                      </Button>
                    </div>
                  ) : (
                    <>
                      {currentSearchTerm && (
                        <AdvancedSearchWordMatching
                          currentResultsTab={currentResultsTab}
                          fullMatchCountAll={fullCountAll}
                          fullMatchCount={fullCountCustom}
                          partialMatchCountAll={partialCountAll}
                          partialMatchCount={partialCountCustom}
                          showingPartialMatches={showingPartialMatches ?? false}
                          toggleShowingPartialMatches={() => setShowingPartialMatches(!showingPartialMatches)}
                          selectAllTab={selectAllTab}
                        />
                      )}
                      {showingPartialMatches && partialCountCustom != 0 && (
                        <div className={'partial-matches'}>
                          {partialResultsCustom?.map((result, index) => (
                            <div key={`all-${result?.post.id}-${index + fullCountCustom}`}>
                              <AdvancedSearchResult
                                result={result}
                                selectedAuthors={selectedAuthorFacets ?? []}
                                selectedCompanies={selectedCompanyFacets ?? []}
                                onResultClicked={(link, postId, e) => {
                                  elementClicked(e, 'advanced-search-result-click', {
                                    post_id: postId,
                                    index: index + fullCountCustom,
                                    all_words_matched: false,
                                    filter_community_tab: 'custom',
                                  })
                                }}
                              />
                            </div>
                          ))}
                          {hasMorePartialResultsCustom && (
                            <div className="show-more">
                              <Button
                                onClick={e => {
                                  setFetchingMoreCustom(true)
                                  elementClicked(e, 'click-advanced-search-result-show-more', {
                                    filter_community_tab: 'custom',
                                  })
                                  void fetchMoreCustomPartial({
                                    variables: { after: customDataPartial?.advancedSearch?.pageInfo.endCursor },
                                  }).then(() => setFetchingMoreCustom(false))
                                }}
                                disabled={fetchingMoreCustom}
                                tabIndex={0}
                                className={'show-more-button'}
                              >
                                Show more <img src={moreIcon} alt={'Show more'} />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div ref={componentRef} className={'advanced-post-search-layout'}>
        <ColumnLayout
          threshold={SizeBreakpoint.md}
          currentPage={'search'}
          additionalColumnClass={'advanced-post-search-layout'}
        >
          <AdvancedSearchSidebar
            visibleCommunityFacets={selectedCommunityFacets}
            visibleAuthorFacets={selectedAuthorFacets ?? []}
            visibleCompanyFacets={selectedCompanyFacets ?? []}
            addCommunityFacet={handleAddCommunityFacet}
            removeCommunityFacet={handleRemoveCommunityFacet}
            addAuthorFacet={handleAddAuthorFacet}
            removeAuthorFacet={handleRemoveAuthorFacet}
            addCompanyFacet={handleAddCompanyFacet}
            removeCompanyFacet={handleRemoveCompanyFacet}
            clearFacets={handleClearFacets}
            responseCommunityFacets={getSearchResponseFacets(
              allData?.advancedSearch?.communities,
              allDataPartial?.advancedSearch?.communities,
              showingPartialMatches ?? false
            )}
            responseCompanyFacets={getSearchResponseFacets(
              allData?.advancedSearch?.companies,
              allDataPartial?.advancedSearch?.companies,
              showingPartialMatches ?? false
            )}
            responseTimeFacets={getSearchResponseFacets(
              allDataTimeFacet?.advancedSearch?.time,
              allPartialDataTimeFacet?.advancedSearch?.time,
              showingPartialMatches ?? false
            )}
            startDate={startDate ?? new Date()}
            endDate={endDate ?? new Date()}
            selectedTime={selectedTime ?? SelectedTimeFacet.ANY}
            handleSelectTime={(time: SelectedTimeFacet) => {
              setSelectedTime(time)
            }}
            handleSelectStartDate={(date: Date) => {
              setStartDate(date)
            }}
            handleSelectEndDate={(date: Date) => {
              setEndDate(date)
            }}
            includeDiscussions={includeDiscussions ?? false}
            clickedDiscussions={() => {
              setIncludeDiscussions(!includeDiscussions)
            }}
            searchState={
              currentResultsTab === ResultsTab.all ? allVars : currentResultsTab === ResultsTab.my ? myVars : customVars
            }
          />
          <>{resultsSection()}</>
        </ColumnLayout>
      </div>
    </>
  )
}
