import React, { useLayoutEffect, useRef } from 'react'
import '@css/common/PlainTextInput.scss'
import { Maybe } from '~/api/generated/graphql'

type PlainTextInputProps = {
  placeholder: string
  onChange: (text: string) => void
  value: Maybe<string>
  className?: string
  onKeyDown?: (e?: React.KeyboardEvent) => void
  onBeforeInput?: (e?: React.FormEvent) => void
  onFocus?: () => void
  onBlur?: () => void
  disabled?: boolean
  inputRef?: React.RefObject<HTMLTextAreaElement>
  maxLength?: number
}

const PlainTextInput = ({
  placeholder,
  onChange,
  value,
  className,
  onKeyDown,
  onBeforeInput,
  onFocus,
  onBlur,
  disabled,
  inputRef,
  maxLength,
}: PlainTextInputProps) => {
  const localRef = useRef<HTMLTextAreaElement>(null)
  const ref = inputRef || localRef

  useLayoutEffect(() => {
    // make text area respond to changes in height
    if (ref.current) {
      ref.current.style.height = 'inherit'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }
  }, [value, ref])

  return (
    <textarea
      className={`plain-text-input ${className}`}
      rows={1}
      ref={ref}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      value={value ?? undefined}
      onKeyDown={onKeyDown}
      onBeforeInput={onBeforeInput}
      data-testid={'plain-text-input'}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      maxLength={maxLength}
    />
  )
}

export default PlainTextInput
