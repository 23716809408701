import React, { useCallback, useEffect, useRef, useState } from 'react'
import moreIcon from '@web/images/profile/more-icon-light.svg'
import '@css/pages/profile/ProfileActivityList.scss'
import { ApolloError } from '@apollo/client'
import { Accordion, Button, Nav } from 'react-bootstrap'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { HomeFeedTab } from '~/pages/feed/HomeFeed'
import { Link } from 'react-router-dom'
import { PostImagePreloader } from '~/common/PostImagePreloader'
import { elementClicked } from '~/common/EventLogger'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { FeedActivityRow } from '~/pages/feed/FeedActivityRow'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { UnsavedWarningModal } from '~/common/UnsavedWarningModal'
import { SubscriptionInfo } from '~/api/generated/graphql'
import { NewActivityButton } from '~/common/NewActivityButton'

type Props = {
  error?: ApolloError
  postIds?: string[]
  hasMore: boolean
  loadMore: () => void
  loading: boolean
  loadingMore?: boolean
  feedOption?: HomeFeedTab
  setExpanded?: (value: boolean) => void
  newActivity?: SubscriptionInfo[]
  showNewActivity: () => Promise<void>
}

export const FeedActivityList = ({
  error,
  postIds,
  hasMore,
  loadMore,
  loading,
  loadingMore,
  feedOption,
  setExpanded,
  newActivity,
  showNewActivity,
}: Props) => {
  const [rowsExpanded, setRowsExpanded] = useState<Map<string, boolean>>(new Map<string, boolean>())
  const { isCondensed } = useWindowSize()
  const { setPostList, resetSelectedPostState } = useShortcuts()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const clickShowMore = (e: React.MouseEvent) => {
    elementClicked(e, 'click-posts-show-more')
    loadMore()
    buttonRef?.current?.blur()
  }

  const rowExpanded = useCallback(
    (rowId: string, expanded: boolean) => {
      const rows: Map<string, boolean> = rowsExpanded ?? new Map()
      rows.set(rowId, expanded)
      setRowsExpanded(rows)

      if (expanded) {
        if (setExpanded) {
          setExpanded(true)
        }
      } else {
        if (Array.from(rowsExpanded.values()).includes(true)) {
          if (setExpanded) {
            setExpanded(true)
          }
        } else {
          if (setExpanded) {
            setExpanded(false)
          }
        }
      }
    },
    [rowsExpanded, setExpanded]
  )

  useEffect(() => {
    if (postIds) {
      setPostList?.(postIds)
    }
  }, [postIds, setPostList])
  useEffect(() => {
    return () => {
      resetSelectedPostState?.()
    }
  }, [resetSelectedPostState])

  const [clickedRow, setClickedRow] = useState('')
  const [activeRows, setActiveRows] = useState<string[]>([])
  const [showWarningModal, setShowWarningModal] = useState(false)

  const { checkDraftingComment, resetPostComments, checkDraftingPost } = useDraftingCommentPost()

  const onSelect = (keys: string[]) => {
    // If a row was collapsed, it will not be listed in the keys. We can find which row was collapsed by looking at the difference from the activeRows
    const collapsedRow = activeRows.find(i => !keys.includes(i))
    if (collapsedRow && (checkDraftingComment?.(collapsedRow) || checkDraftingPost?.(collapsedRow))) {
      setClickedRow(collapsedRow)
      setShowWarningModal(true)
    } else {
      setActiveRows(keys)
    }
  }

  const collapsePost = () => {
    setShowWarningModal(false)
    resetPostComments?.(clickedRow)
    setActiveRows(activeRows.filter(i => i != clickedRow))
  }

  if (loading) return <div className={'list-container loading'}>Loading...</div>
  if (!postIds || error) return null

  return (
    <>
      <PostImagePreloader postIds={postIds} />
      <Accordion alwaysOpen flush activeKey={activeRows} onSelect={onSelect}>
        <div className="list-container">
          <div className={'top-row-controls'}>
            <Nav className={`tab-controls${isCondensed ? ' condensed' : ''}`}>
              <Nav.Item className={`tab-options`}>
                <Nav.Link
                  onClick={e => elementClicked(e, 'click-feed-my')}
                  eventKey="myFeed"
                  as={Link}
                  role="my-feed"
                  to="/myfeed"
                  className={`${feedOption == HomeFeedTab.My ? ' selected' : ''}`}
                >
                  MY COMMUNITIES
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`tab-options`}>
                <Nav.Link
                  onClick={e => elementClicked(e, 'click-feed-following')}
                  eventKey="followingFeed"
                  as={Link}
                  role="following-feed"
                  to="/followingfeed"
                  className={`${feedOption == HomeFeedTab.Following ? ' selected' : ''}`}
                >
                  FOLLOWED POSTS
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <NewActivityButton loading={loading} newActivity={newActivity ?? []} showNewActivity={showNewActivity} />
          </div>
          {postIds?.length > 0 ? (
            <>
              {postIds.map((id, index) => (
                <FeedActivityRow key={index} postId={id} rowExpanded={rowExpanded} />
              ))}
            </>
          ) : (
            <div className="section-empty">
              {feedOption == HomeFeedTab.Following ? (
                'Follow posts to stay up-to-date on conversations important to you.'
              ) : (
                <span>
                  <Link to={'/communities'}>Join communities</Link> to see posts and ask questions.
                </span>
              )}
            </div>
          )}
        </div>
        {loadingMore ? (
          <div className={'loading show-more-loading'}>Loading...</div>
        ) : (
          hasMore && (
            <div className="show-more-activity">
              <Button
                ref={buttonRef}
                onClick={clickShowMore}
                disabled={loadingMore}
                tabIndex={0}
                className={'show-more-button'}
              >
                Show more <img src={moreIcon} alt={'Show more'} />
              </Button>
            </div>
          )
        )}
      </Accordion>
      <UnsavedWarningModal
        showWarningModal={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        onContinue={collapsePost}
        leavingPost={true}
      />
    </>
  )
}
