import React from 'react'
import FormError from '~/common/FormError'
import { Dropdown } from 'react-bootstrap'

type VideoOptionsProps = {
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  startTime?: string
  setStartTime: (t: string) => void
  hasPrimaryMedia: boolean
  timeInputError: string | undefined
}

const VideoOptions = ({
  isExpanded,
  setIsExpanded,
  startTime,
  setStartTime,
  hasPrimaryMedia,
  timeInputError,
}: VideoOptionsProps) => {
  return (
    <div className={'video-options-container'}>
      <div onClick={() => setIsExpanded(!isExpanded)}>
        <span className={`arrow ${isExpanded ? 'down' : 'right'}`} />
        <span className={'options-header'}>Video Options</span>
      </div>
      {isExpanded && (
        <>
          <p className={'video-link-info'}>Insert a link that opens the video at a selected start time</p>
          {!hasPrimaryMedia && <FormError message={'To create a video link, please save as draft first.'} />}
          <div className={'options-inputs'}>
            <div className={'option-column'}>
              <label>Video</label>
              <Dropdown.Toggle disabled={true} variant={'default'}>
                <span>Primary Video</span>
              </Dropdown.Toggle>
            </div>
            <div className={'option-column'}>
              <label>Time (mm:ss)</label>
              <input
                disabled={!hasPrimaryMedia}
                className={'form-control'}
                placeholder={'00:00'}
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
                data-testid={'timestamp-time-input'}
              />
            </div>
          </div>
          {timeInputError && <FormError message={timeInputError} />}
        </>
      )}
    </div>
  )
}

export default VideoOptions
