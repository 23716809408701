import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import '@css/pages/community/CommunityEvents.scss'
import { Button, Nav } from 'react-bootstrap'
import { usePermissions } from '~/pages/posts/PostUtils'
import { useCommunity } from '~/contexts/CommunityContext'
import { useAuth } from '~/auth/Auth'
import {
  AddMemberDocument,
  EventPartsFragment,
  GetCommunityPastEventsDocument,
  GetCommunityUpcomingEventsDocument,
  GetNotificationsDocument,
  MarkEventsViewedUpToDocument,
  Maybe,
  NotificationType,
} from '~/api/generated/graphql'
import CommunityEventEdit from '~/pages/community/CommunityEventEdit'
import CommunityEventRow from '~/pages/community/CommunityEventRow'
import ToastComponent from '~/common/ToastComponent'
import { asDate, asNotification, getStartOfCurrentDay } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import LiveStreamQA from '~/pages/community/LiveStreamQA'

type CommunityEventsProps = {
  isCompany: boolean
}

enum EventTabs {
  Upcoming,
  Past,
  LiveStream,
}

const CommunityEvents = ({ isCompany }: CommunityEventsProps) => {
  const { hasLeaderPermissions, hasMemberPermissions, loading: memberLoading } = usePermissions()
  const { communityId, isPrivate } = useCommunity()
  const { isCondensedPortrait: isMobile } = useWindowSize()
  const [tab, setTab] = useState(EventTabs.Upcoming)

  const {
    data: communityPastEventsData,
    fetchMore: fetchMorePast,
    loading: loadingPast,
  } = useQuery(GetCommunityPastEventsDocument, {
    variables: { id: communityId || '', date: getStartOfCurrentDay(), pageSize: 25 },
    skip: !communityId,
    notifyOnNetworkStatusChange: true,
  })

  const pastPageInfo = communityPastEventsData?.community?.events?.pageInfo
  const pastHasMore = pastPageInfo?.hasNextPage ?? false
  const pastEndCursor = pastPageInfo?.endCursor

  const {
    data: communityUpcomingEventsData,
    fetchMore: fetchMoreUpcoming,
    loading: loadingUpcoming,
  } = useQuery(GetCommunityUpcomingEventsDocument, {
    variables: { id: communityId || '', date: getStartOfCurrentDay(), pageSize: 25 },
    skip: !communityId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const upcomingPageInfo = communityUpcomingEventsData?.community?.events?.pageInfo
  const upcomingHasMore = upcomingPageInfo?.hasNextPage ?? false
  const upcomingEndCursor = upcomingPageInfo?.endCursor

  const isUpcomingTab = tab == EventTabs.Upcoming

  const communityEventsData = isUpcomingTab ? communityUpcomingEventsData : communityPastEventsData
  const hasMore = isUpcomingTab ? upcomingHasMore : pastHasMore
  const loadingEvents = isUpcomingTab ? loadingUpcoming : loadingPast

  const liveStreamEvent = useMemo(() => {
    return communityUpcomingEventsData?.community?.events?.edges?.find(e => {
      const now = new Date()
      const end = new Date(e?.node?.eventEnd)
      const start = new Date(e?.node?.eventStart)
      return start <= now && now <= end && e?.node?.isLivestream
    })?.node
  }, [communityUpcomingEventsData?.community?.events?.edges])

  const liveStreamId = liveStreamEvent?.eventId ?? ''
  const hasLivestream = Boolean(liveStreamId)

  const loadMoreEvents = () => {
    if (isUpcomingTab) {
      void fetchMoreUpcoming({ variables: { cursor: upcomingEndCursor } })
    } else {
      void fetchMorePast({ variables: { cursor: pastEndCursor } })
    }
  }

  const { authUserId, isVeevan, profileVisible, loading: authLoading } = useAuth()
  const canViewInfo = isVeevan || hasMemberPermissions
  const canEdit = profileVisible && ((isCompany && isVeevan) || hasLeaderPermissions)
  const [addMember] = useMutation(AddMemberDocument)
  const [showCreateView, setShowCreateView] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [eventsInEditMode, setEventsInEditMode] = useState<string[]>([])

  const handleJoinCommunity = async (e: SyntheticEvent) => {
    await addMember({
      variables: {
        userId: authUserId ?? '',
        communityId: communityId ?? '',
      },
    })
    elementClicked(e, 'click-community-join-link-in-event', { communityId: communityId })
  }

  const handleCreateClicked = (e: SyntheticEvent) => {
    setShowCreateView(true)
    elementClicked(e, 'click-community-event-create', { communityId: communityId })
  }

  const mappedEvents: Map<string, Maybe<Partial<EventPartsFragment>>[]> | undefined = useMemo(() => {
    if (communityEventsData) {
      const map = new Map<string, Maybe<Partial<EventPartsFragment>>[]>()
      const events = communityEventsData?.community?.events?.edges?.map(e => e?.node ?? null) ?? []
      for (const event of events) {
        const key = asDate(event?.eventStart)?.toLocaleDateString([], { month: 'long', year: 'numeric' }) || ''
        if (map.has(key)) map.get(key)?.push(event)
        else map.set(key, [event])
      }
      return map
    }
  }, [communityEventsData])

  const { data } = useQuery(GetNotificationsDocument, {
    variables: { recipientId: authUserId },
    skip: !authUserId,
  })

  const [markEventsViewedUpTo] = useMutation(MarkEventsViewedUpToDocument, { refetchQueries: ['GetNotifications'] })
  useEffect(() => {
    const eventNotifications = data?.notifications?.edges
      ?.map(e => e?.node)
      .map(asNotification)
      ?.filter(
        n =>
          n.notificationType === NotificationType.UpdEventTellMmbr ||
          n.notificationType === NotificationType.NewEventTellMmbr
      )
      .sort((a, b) => new Date(b?.created || '').getTime() - new Date(a?.created || '').getTime())
    const newestNotification = eventNotifications?.find(el => el !== undefined)
    if (newestNotification && communityId) {
      void markEventsViewedUpTo({
        variables: { notificationId: newestNotification?.notificationId, communityId: communityId },
      })
    }
  }, [communityId, markEventsViewedUpTo, data?.notifications?.edges])

  const handleEditingChange = (isEditing: boolean, eventId?: string | null) => {
    if (!eventId) return

    if (isEditing) {
      setEventsInEditMode([...eventsInEditMode, eventId])
    } else {
      setEventsInEditMode(eventsInEditMode.filter(e => e !== eventId))
    }
  }

  const editingInProgress = eventsInEditMode.length > 0

  const setToast = (s: string) => {
    setToastMessage(s)
    setShowToast(s != '')
  }

  const [searchParams] = useSearchParams()
  const scrollEventId = searchParams.get('e') ?? ''
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (scrollEventId && !scrolled) {
      let isUpcoming = true
      const events = communityPastEventsData?.community?.events?.edges ?? []
      for (const e of events) {
        if (e?.node?.eventId === scrollEventId) {
          isUpcoming = false
        }
      }

      if (!isUpcoming) {
        setTab(EventTabs.Past)
      }
    }
  }, [communityPastEventsData, scrollEventId, scrolled])

  const tabs = (
    <Nav className={`tab-controls ${isMobile ? 'mobile' : ''}`}>
      <Nav.Item className={`tab-options`}>
        <button
          role="upcoming-events"
          className={`${isUpcomingTab ? ' selected' : ''}`}
          onClick={() => setTab(EventTabs.Upcoming)}
        >
          UPCOMING EVENTS
        </button>
      </Nav.Item>
      <Nav.Item className={`tab-options`}>
        <button
          role="past-events"
          className={`${tab == EventTabs.Past ? ' selected' : ''}`}
          onClick={() => setTab(EventTabs.Past)}
        >
          PAST EVENTS
        </button>
      </Nav.Item>
      {hasLivestream && (
        <Nav.Item className={`tab-options`}>
          <button
            role="live-stream"
            className={`${tab == EventTabs.LiveStream ? ' selected' : ''} live-stream`}
            onClick={() => setTab(EventTabs.LiveStream)}
          >
            LIVE
          </button>
        </Nav.Item>
      )}
    </Nav>
  )

  if (!mappedEvents || memberLoading || authLoading)
    return (
      <div className={'events-container'}>
        <div className="top-items">{tabs}</div>
        <div className={'loading'}>Loading...</div>
      </div>
    )

  return (
    <div className={'events-container'}>
      <div className="top-items">
        {tabs}
        {canEdit && !showCreateView && (
          <Button onClick={handleCreateClicked} disabled={editingInProgress}>
            Create Event
          </Button>
        )}
      </div>
      {tab != EventTabs.LiveStream && !showCreateView && mappedEvents.size === 0 && (
        <div className="no-events">This community has no {isUpcomingTab ? 'upcoming' : 'past'} events</div>
      )}
      {showCreateView && (
        <CommunityEventEdit
          communityId={communityId ?? ''}
          onClose={() => setShowCreateView(false)}
          setToast={setToast}
        />
      )}
      {tab == EventTabs.LiveStream && hasLivestream ? (
        <>
          <CommunityEventRow
            canEdit={canEdit && isUpcomingTab}
            eventId={liveStreamEvent?.eventId}
            canViewInfo={canViewInfo}
            handleJoinCommunity={handleJoinCommunity}
            communityId={communityId ?? ''}
            setToast={setToast}
            isVeevan={isVeevan}
            canRepost={isVeevan && !isCompany && !isPrivate && isUpcomingTab}
            onEditingChange={handleEditingChange}
            canDelete={canEdit}
            scrollToEvent={scrollEventId}
            scrolled={scrolled}
            setScrolled={setScrolled}
            currentLiveStreamEventId={liveStreamEvent?.eventId}
          />
          <LiveStreamQA eventId={liveStreamId} />
        </>
      ) : (
        <>
          <div className="upcoming-events">
            {Array.from(mappedEvents.keys()).map(key => (
              <div className="month-section" key={key}>
                <h6>{key}</h6>
                {mappedEvents
                  .get(key)
                  ?.map(event => (
                    <CommunityEventRow
                      key={event?.eventId}
                      canEdit={canEdit && isUpcomingTab}
                      eventId={event?.eventId}
                      canViewInfo={canViewInfo}
                      handleJoinCommunity={handleJoinCommunity}
                      communityId={communityId ?? ''}
                      setToast={setToast}
                      isVeevan={isVeevan}
                      canRepost={isVeevan && !isCompany && !isPrivate && isUpcomingTab}
                      onEditingChange={handleEditingChange}
                      canDelete={canEdit}
                      scrollToEvent={scrollEventId}
                      scrolled={scrolled}
                      joinLivestream={() => setTab(EventTabs.LiveStream)}
                      setScrolled={setScrolled}
                      currentLiveStreamEventId={liveStreamEvent?.eventId}
                    />
                  ))}
              </div>
            ))}
          </div>
          {loadingEvents ? (
            <div className={'loading show-more-loading'}>Loading...</div>
          ) : (
            hasMore && (
              <div className="show-more">
                <Button size={'sm'} onClick={loadMoreEvents}>
                  Show more
                </Button>
              </div>
            )
          )}
        </>
      )}
      <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
        {toastMessage ?? ''}
      </ToastComponent>
    </div>
  )
}

export default CommunityEvents
