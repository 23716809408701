import React, { useMemo, useState } from 'react'
import { useAuth } from '~/auth/Auth'
import { AppHeader } from '~/common/AppHeader'
import { Footer } from '~/common/Footer'
import '@css/pages/page/PageMenu.scss'
import { Button, Modal } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { useNavigate } from 'react-router'
import { removeWhiteSpace } from '~/utils'
import {
  PageType,
  AddSectionDocument,
  EditSectionDocument,
  GetMenuSectionsQuery,
  GetMenuSectionsDocument,
} from '~/api/generated/graphql'
import SectionMenuRow from '~/pages/page/SectionMenuRow'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useMutation, useQuery } from '@apollo/client'

export type SectionRow = {
  sectionId: string
  name: string
  index: number
  visible: boolean
  collapseSection: boolean
}

const SectionMenu = ({ pageType }: { pageType: PageType }) => {
  const { actingSysAdmin, loading: permissionsLoading } = useAuth()
  const [addingRow, setAddingRow] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { data: sectionsData } = useQuery(GetMenuSectionsDocument, { variables: { pageType: pageType } })

  const orderedSections = useMemo(() => {
    return (
      sectionsData?.sections?.edges?.map(section => {
        const s = section?.node
        return {
          sectionId: s?.sectionId,
          name: s?.name,
          index: s?.index,
          visible: s?.visible,
          collapseSection: s?.collapseSection,
        } as SectionRow
      }) ?? []
    )
  }, [sectionsData])

  const { isCondensed } = useWindowSize()

  const [addSection] = useMutation(AddSectionDocument, {
    update(cache, { data }) {
      if (data?.addSection?.ok) {
        const oldSections = cache.readQuery<GetMenuSectionsQuery>({
          query: GetMenuSectionsDocument,
          variables: { pageType: pageType },
        })

        if (oldSections) {
          const newSectionsData = {
            sections: {
              ...oldSections?.sections,
              edges: [
                ...(oldSections?.sections?.edges ?? []),
                { node: data?.addSection?.section, __typename: 'SectionEdge' as const },
              ],
            },
          }

          cache.writeQuery({ query: GetMenuSectionsDocument, variables: { pageType: pageType }, data: newSectionsData })
        }
      }
    },
  })

  const [editSection] = useMutation(EditSectionDocument, {
    update(cache, { data }) {
      if (data?.editSection?.ok) {
        const oldSections = cache.readQuery<GetMenuSectionsQuery>({
          query: GetMenuSectionsDocument,
          variables: { pageType: pageType },
        })

        if (oldSections) {
          let oldIndex = 0
          const newIndex = data?.editSection?.section?.index ?? 0
          const sections = oldSections?.sections?.edges ?? []

          for (const [i, section] of sections.entries()) {
            if (section?.node?.sectionId == data?.editSection?.section?.sectionId) {
              oldIndex = i
              break
            }
          }

          const newSections = [...sections]
          const [removed] = newSections.splice(oldIndex, 1)
          newSections.splice(newIndex, 0, removed)

          const newSectionsData = {
            sections: {
              ...oldSections?.sections,
              edges: [...newSections],
            },
          }

          cache.writeQuery({ query: GetMenuSectionsDocument, variables: { pageType: pageType }, data: newSectionsData })
        }
      }
    },
  })

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const originalIndex = result.source.index
    const newIndex = result.destination.index
    await editSection({
      variables: { sectionId: orderedSections[originalIndex].sectionId, index: newIndex },
      optimisticResponse: {
        editSection: {
          ok: true,
          error: {},
          section: {
            sectionId: orderedSections[originalIndex].sectionId,
            index: newIndex,
            name: orderedSections[originalIndex].name,
            visible: orderedSections[originalIndex].visible,
            releaseOrder: [],
            collapseSection: orderedSections[originalIndex].collapseSection,
            __typename: 'Section' as const,
          },
        },
      },
    })
  }

  const onRowEdit = async (sectionTitle: string, sectionId: string, collapsed: boolean) => {
    const resp = await editSection({
      variables: { sectionId: sectionId, name: sectionTitle.trim(), collapseSection: collapsed },
    })
    if (!resp.data?.editSection?.ok) {
      setErrorMessage(resp.data?.editSection?.error?.message ?? 'Could not add section at this time.')
      setShowModal(true)
    }
    return resp.data?.editSection?.ok
  }

  const onRowAdd = async (sectionTitle: string, collapsed: boolean) => {
    const resp = await addSection({
      variables: {
        name: removeWhiteSpace(sectionTitle),
        pageType: pageType,
        collapseSection: collapsed,
      },
    })

    if (!resp.data?.addSection?.ok) {
      setErrorMessage(resp.data?.addSection?.error?.message ?? 'Could not add section at this time.')
      setShowModal(true)
    }
    return resp.data?.addSection?.ok
  }

  const navigate = useNavigate()
  if (!actingSysAdmin && !permissionsLoading) {
    navigate('/')
    return <></>
  }

  return (
    <div>
      <AppHeader />
      <div className={`releases-menu-wrapper ${isCondensed && 'condensed'}`}>
        <div className={'releases-menu-container'}>
          <div className={'releases-menu-header'}>
            <h3 className="comm-title">{pageType == PageType.Release ? 'Releases' : 'Summits'} Menu</h3>
            <Button variant="primary" onClick={() => setAddingRow(true)}>
              Add Section
            </Button>
          </div>
          <div className={'releases-menu-table'}>
            <div className={'table-header-wrapper'}>
              <div className={'table-header'}>
                <h4 className={'section-name-header'}>Section Name</h4>
                <h4>Default State</h4>
              </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'droppable'}>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {orderedSections.map((section, index) => (
                      <Draggable
                        draggableId={section.sectionId}
                        index={index}
                        key={`ordered_section_${section.sectionId}`}
                      >
                        {provided => (
                          <SectionMenuRow
                            section={section}
                            dragHandleProps={provided.dragHandleProps}
                            draggableInnerRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            onRowEdit={onRowEdit}
                            onRowAdd={onRowAdd}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {addingRow && (
              <SectionMenuRow
                section={{
                  name: '',
                  sectionId: '',
                  index: orderedSections.length,
                  visible: true,
                  collapseSection: true,
                }}
                addingRow={true}
                setAddingRow={setAddingRow}
                onRowEdit={onRowEdit}
                onRowAdd={onRowAdd}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={showModal} onHide={() => setShowModal(false)} className={''}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={() => setShowModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SectionMenu
