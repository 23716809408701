import React, { ReactNode, useCallback, useContext, useState } from 'react'

export type CommentLoadingMediaContextType = {
  mediaLoaded?: Set<number>
  onMediaLoaded?: (n: number | undefined) => void
  onAllCommentMediaLoaded?: (s: string | undefined) => void
}
export const CommentLoadingMediaContext = React.createContext<CommentLoadingMediaContextType>({
  mediaLoaded: new Set<number>(),
})
export const CommentLoadingMediaProvider = ({
  children,
  setLoadedComments,
}: {
  children: ReactNode
  setLoadedComments?: React.Dispatch<React.SetStateAction<Set<string> | undefined>>
}) => {
  const [mediaLoaded, setMediaLoaded] = useState<Set<number>>(new Set<number>())

  // use a callback to prevent useEffect in PostStory from getting called on every render
  const onMediaLoaded = useCallback(
    (index: number | undefined) => {
      setMediaLoaded?.(mediaLoadedTemp => {
        const media = new Set(mediaLoadedTemp)
        if (index != undefined) {
          media.add(index)
        }
        return media
      })
    },
    [setMediaLoaded]
  )

  // use a callback to prevent useEffect in PostStory from getting called on every render
  const onAllCommentMediaLoaded = useCallback(
    (commentId: string | undefined) => {
      if (commentId) {
        setLoadedComments?.(ids => {
          const newIds = new Set(ids)
          if (commentId) {
            newIds.add(commentId)
          }
          return newIds
        })
      }
    },
    [setLoadedComments]
  )

  return (
    <CommentLoadingMediaContext.Provider value={{ mediaLoaded, onMediaLoaded, onAllCommentMediaLoaded }}>
      {children}
    </CommentLoadingMediaContext.Provider>
  )
}

export const useCommentLoadingMedia = () => useContext(CommentLoadingMediaContext)
