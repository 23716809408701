import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useCommunity } from '~/contexts/CommunityContext'
import searchIcon from '@web/images/community/icon-search.svg'
import { useAuth } from '~/auth/Auth'
import { usePermissions } from '~/pages/posts/PostUtils'
import CommunityMemberRow from '~/pages/community/CommunityMemberRow'
import '@css/pages/community/CommunityTable.scss'
import '@css/pages/community/CommunityMembers.scss'
import { Alert, Button, Dropdown, Form, Modal } from 'react-bootstrap'
import {
  AddMemberByEmailDocument,
  AddMemberDocument,
  ChangeMemberTypeDocument,
  CommunityMembersSearchDocument,
  CommunityType,
  GetCompanyRolesDocument,
  GetInvitesDocument,
  GetInvitesQuery,
  GetLeaderLikeNotificationsCommunitiesDocument,
  GetMembersCommunityDocument,
  GetUserCommunitiesDocument,
  Membership,
  MembersSortType,
  SetMemberNotificationFrequencyDocument,
  SortDirection,
} from '~/api/generated/graphql'
import filterIcon from '@web/images/community/icon-filter.svg'
import { Navigate, useNavigate } from 'react-router'
import CommunityPendingMemberRow from '~/pages/community/CommunityPendingMemberRow'
import MoreArrow from '@web/images/community/MoreArrow'
import LessArrow from '@web/images/community/LessArrow'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import searchClear from '@web/images/community/search-clear.svg'
import { useStickyState } from '~/common/hooks/useStickyState'
import { useLocation } from 'react-router-dom'
import { asUser, debounce, getEmailParts } from '~/utils'
import { PendingMemberModel } from '~/types'
import { ChangeMemberTypeParams, CompanyMemberRow, getLeads } from '~/pages/company/CompanyMemberRow'
import { elementClicked, searchQueryLogger } from '~/common/EventLogger'
import AddMembershipBox from '~/common/addPerson/AddMembershipBox'
import FormError from '~/common/FormError'
import moreIcon from '@web/images/profile/more-icon.svg'
import { useMutation, useQuery } from '@apollo/client'

export enum FilterState {
  all,
  veeva,
  company,
}

type CommunityMembersProps = {
  isCompany: boolean
}

const CommunityMembers = ({ isCompany }: CommunityMembersProps) => {
  const { isCondensed, breakpoint } = useWindowSize()
  const isNotXLarge = breakpoint <= SizeBreakpoint.lg
  const { authUserId, isVeevan, profileVisible, canPostIds, sysAdminId, relAdminId, summitAdminId } = useAuth()
  const { hasLeaderPermissions, hasMemberPermissions } = usePermissions(authUserId)
  const {
    loading: communityLoading,
    error: communityError,
    communityId,
    companyId,
    companyName,
    isVeeva,
  } = useCommunity()

  const isAdminComm = [sysAdminId, relAdminId, summitAdminId].includes(communityId ?? '')

  const { data: basicCommunityData } = useQuery(GetMembersCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })

  const { data: roleData } = useQuery(GetCompanyRolesDocument, {
    variables: { companyId: companyId ?? '' },
    skip: !companyId,
  })

  const COMMUNITY_MEMBERS_SEARCH_KEY = `${communityId}MembersSearchKey`
  const [searchText, setSearchText] = useStickyState('', COMMUNITY_MEMBERS_SEARCH_KEY)
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>(searchText)
  const COMMUNITY_MEMBERS_SORT_KEY = `${communityId}SortTypeKey`
  const COMMUNITY_MEMBERS_SORT_ORDER_KEY = `${communityId}SortOrderKey`
  const [sortType, setSortType] = useStickyState('', COMMUNITY_MEMBERS_SORT_KEY)
  const [asc, setAsc] = useStickyState('', COMMUNITY_MEMBERS_SORT_ORDER_KEY)
  const showSearchClear = searchText !== ''
  const showAddButton =
    !isVeeva &&
    profileVisible &&
    (basicCommunityData?.community?.type === CommunityType.Public
      ? canPostIds.has(communityId ?? '') || isVeevan
      : hasLeaderPermissions ||
        (basicCommunityData?.community?.type === CommunityType.Homepage && (isVeevan || hasMemberPermissions)))
  const [addEmailText, setAddEmailText] = useState<string>('')
  const [isPendingCollapsed, setIsPendingCollapsed] = useState<boolean>(true)
  const [shouldRefetchMembers, setShouldRefetchMembers] = useState<boolean>(false)

  const debouncedSetSearchText = useMemo(() => debounce(setDebouncedSearchText, 300), [setDebouncedSearchText])
  const { hash } = useLocation()

  const [isVeevanFilter, dropdownText] = useMemo(() => {
    if (hash === '#veeva') {
      return [true, 'Veeva Members']
    } else if (hash === '#company') {
      return [false, 'Customer Members']
    } else {
      return [undefined, 'All Members']
    }
  }, [hash])

  const {
    data: currentMembersData,
    loading: membersLoading,
    fetchMore,
    previousData: previousMembersData,
    refetch: refetchMembers,
  } = useQuery(CommunityMembersSearchDocument, {
    variables: {
      communityId: communityId ?? '',
      searchQuery: debouncedSearchText,
      isVeevan: isVeevanFilter,
      sortType: sortType ? (sortType as MembersSortType) : MembersSortType.Leader,
      sortDirection: asc ? SortDirection.Asc : SortDirection.Desc,
    },
    skip: !communityId,
  })
  const membersData = currentMembersData ?? previousMembersData

  const navigate = useNavigate()
  const { data: userCommunitiesData, loading: userCommunitiesLoading } = useQuery(GetUserCommunitiesDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
  })
  const isMember = useMemo(
    () => userCommunitiesData?.user?.memberships?.edges.some(e => e?.node?.communityId === communityId),
    [userCommunitiesData, communityId]
  )

  const isLeader = useMemo(
    () =>
      userCommunitiesData?.user?.memberships?.edges.some(e => e?.node?.communityId === communityId && e?.node?.leader),
    [userCommunitiesData, communityId]
  )

  const showLeaderLikeNotificationsMessage = isMember && isVeevan && !isLeader

  const { data: leaderLikeNotificationsData, loading: leaderLikeNotificationsLoading } = useQuery(
    GetLeaderLikeNotificationsCommunitiesDocument,
    {
      skip: !authUserId || !showLeaderLikeNotificationsMessage,
    }
  )

  const isLeaderLikeNotificationCommunity = useMemo(
    () => leaderLikeNotificationsData?.currentUser?.memberships?.edges.some(e => e?.node?.communityId === communityId),
    [communityId, leaderLikeNotificationsData]
  )

  const [modalText, setModalText] = useState<string | null>(null)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const debouncedSearchLogger = useMemo(() => debounce(searchQueryLogger, 300), [])
  const [disabledAdd, setDisabledAdd] = useState<boolean>(false)
  const [showNotificationPreferenceModal, setShowNotificationPreferenceModal] = useState<boolean>(false)
  const [selectedNotificationFrequency, setSelectedNotificationFrequency] = useState<string>(
    isLeaderLikeNotificationCommunity ? 'Leader' : 'Member'
  )
  const [notificationPreferenceSubmitDisabled, setNotificationPreferenceSubmitDisabled] = useState<boolean>(false)
  const [setNotificationFrequency] = useMutation(SetMemberNotificationFrequencyDocument)
  const [updateNotificationFrequencyError, setUpdateNotificationFrequencyError] = useState<boolean>(false)

  useEffect(() => {
    // since we have to wait for the list of leader-like notification communities to load before
    // we know what the user has selected for this community, we don't know what the initial state should
    // be on first render
    setSelectedNotificationFrequency(isLeaderLikeNotificationCommunity ? 'Leader' : 'Member')
  }, [isLeaderLikeNotificationCommunity, setSelectedNotificationFrequency])

  const getFilterDisplayText = useCallback(
    (state: FilterState): string => {
      switch (state) {
        case FilterState.all:
          return 'All Members'
        case FilterState.company:
          if (isCompany) return `${companyName} Members`
          else return `Customer Members`
        case FilterState.veeva:
          return 'Veeva Members'
      }
    },
    [companyName, isCompany]
  )

  const [addMemberByEmail] = useMutation(AddMemberByEmailDocument, {
    update(cache, { data }) {
      const oldInvites = cache.readQuery<GetInvitesQuery>({ query: GetInvitesDocument, variables: { communityId } })
      if (oldInvites && communityId) {
        const newInviteData = {
          community: {
            communityId,
            ...oldInvites.community,
            invitations: {
              edges: oldInvites.community?.invitations?.edges ?? [],
              pageInfo: { hasNextPage: oldInvites.community?.invitations?.pageInfo.hasNextPage ?? false },
              ...oldInvites.community?.invitations,
              totalCount: data?.addMemberByEmail?.invitation?.community?.invitations?.totalCount ?? 0,
            },
          },
        }
        cache.writeQuery({ query: GetInvitesDocument, variables: { communityId: communityId }, data: newInviteData })
      }
    },
  })
  const [addMember] = useMutation(AddMemberDocument)

  const { data: inviteData, fetchMore: fetchMoreInvites } = useQuery(GetInvitesDocument, {
    variables: { communityId: communityId ?? '' },
    skip: !communityId,
  })

  const loadMore = () => {
    const endCursor = membersData?.communityMembers?.pageInfo?.endCursor ?? ''
    void fetchMore({ variables: { after: Number.parseInt(endCursor) || 0 } })
  }

  const loadMoreInvites = async () => {
    return fetchMoreInvites({ variables: { cursor: invitationPageInfo?.endCursor } })
  }
  // Augment the memberships list with user data from the cache, skipping any memberships that don't resolve
  const members = useMemo(
    () => membersData?.communityMembers?.members?.filter(Boolean) as Membership[] | undefined,
    [membersData]
  )
  const roles = useMemo(() => {
    const rolesMap = new Map<string, string | undefined>()
    const edges = roleData?.company?.roles?.edges ?? []
    for (const role of edges) {
      if (role?.node?.userId) {
        rolesMap.set(role?.node?.userId, role?.node?.description ?? undefined)
      }
    }
    return rolesMap
  }, [roleData])
  const canRemoveLeader = useMemo(() => (members?.filter(m => m?.leader).length ?? 0) > 1, [members])

  const clickSort = useCallback(
    (newSort: MembersSortType) => {
      if (sortType == newSort) {
        setAsc(asc ? '' : '1')
      } else {
        setSortType(newSort)

        if (sortType ? newSort === MembersSortType.Leader : isVeeva && newSort === MembersSortType.Name) {
          setAsc('')
        } else {
          setAsc('1')
        }
      }
    },
    [asc, sortType, isVeeva, setAsc, setSortType]
  )

  const leads = useMemo(() => getLeads(members), [members])

  const invitationPageInfo = inviteData?.community?.invitations?.pageInfo
  const hasMore = membersData?.communityMembers?.pageInfo?.hasNextPage
  const hasMoreInvitations = !!invitationPageInfo?.hasNextPage

  const pendingMembers = useMemo(() => {
    if (inviteData?.community?.invitations) {
      const pendingMembers: PendingMemberModel[] = inviteData.community?.invitations?.edges
        .map(edge => {
          if (edge?.node) {
            return {
              email: edge.node.email,
              companyName: edge.node.inviteeCompany?.name,
              inviter: asUser(edge.node.inviter),
              inviteeId: edge.node.inviteeId,
              createdTime: edge.node.createdTime,
            }
          }
        })
        // when inviteeId is null, the user has registered so remove them from the invite list
        .filter(member => member?.inviteeId !== null) as PendingMemberModel[]
      return pendingMembers.sort((a: PendingMemberModel, b: PendingMemberModel) => {
        return `${a.email}`.localeCompare(`${b.email}`)
      })
    }
  }, [inviteData])

  const pendingMemberCount = inviteData?.community?.invitations?.totalCount ?? 0
  const showPendingMembers = pendingMemberCount > 0
  const pendingMemberText =
    isVeevan || isCompany
      ? `Pending Members (${pendingMemberCount})`
      : `Pending Members I Added (${pendingMemberCount})`

  const setFilterState = (state: FilterState) => {
    const filterPrefix = isCompany ? `/companies/${companyId}` : `/communities/${communityId}`
    switch (state) {
      case FilterState.all:
        navigate(`${filterPrefix}/members`)
        break
      case FilterState.company:
        navigate(`${filterPrefix}/members#company`)
        break
      case FilterState.veeva:
        navigate(`${filterPrefix}/members#veeva`)
        break
    }
  }

  const handleFilterChange = (filter: FilterState) => {
    setFilterState(filter)
  }

  const clearSearch = () => {
    setSearchText('')
    void debouncedSetSearchText('')
  }

  const handleSearchTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== undefined) {
        setSearchText(e.target.value)
        void debouncedSetSearchText(e.target.value)
        void debouncedSearchLogger(e.target.value, 'member-search', { communityId: communityId }).then()
      }
    },
    [setSearchText, debouncedSearchLogger, communityId, debouncedSetSearchText]
  )

  const handleAddMemberTextChanged = (email: string) => {
    setAddEmailText(email)
  }

  const [changeMemberType] = useMutation(ChangeMemberTypeDocument)
  const handleChangeMemberType = useCallback(
    async ({ userId, leader, accountLead, commercialLead, rdqLead }: ChangeMemberTypeParams) => {
      await changeMemberType({
        variables: {
          userId,
          communityId: communityId ?? '',
          leader,
          accountLead,
          commercialLead,
          rdqLead,
        },
      })
    },
    [changeMemberType, communityId]
  )

  const handleAddMemberClicked = async (userId: string | undefined, allowDuplicate: boolean, e: SyntheticEvent) => {
    setDisabledAdd(true)
    const domain = getEmailParts(addEmailText)?.domain
    elementClicked(e, 'click-community-member-add', { inviteeEmailDomain: domain, communityId: communityId })
    if (userId) {
      try {
        const response = await addMember({
          variables: {
            communityId: communityId ?? '',
            userId: userId,
          },
        })
        if (response?.data?.addMember?.ok) {
          setModalText('The member was added successfully')
          setAddEmailText('')
          setShouldRefetchMembers(true)
        } else if (response?.data?.addMember?.error) {
          switch (response.data.addMember.error.code) {
            case 'alreadyAMember':
              setModalText('That user is already a member.')
              setAddEmailText('')
              break
            case 'ineligibleMember':
              setModalText(
                `You entered an email address with a domain that is not Veeva or ${companyName}. ` +
                  `Only Veeva or ${companyName} users can be added as members of the customer homepage.`
              )
              break
            case 'hiddenUser':
              setModalText('This user cannot be added to the community.')
              break
            case 'notAVeevan':
              setModalText(
                `You entered an email address with a domain that is not Veeva. ` +
                  `Only Veeva users can be added as members of this community.`
              )
              break
            default: // fallback just in case
              setModalText('Something went wrong. Please try again.')
              break
          }
        }
      } catch {
        setModalText('Something went wrong. Please try again.')
      }
    } else {
      if (!addEmailText) {
        setModalText('Please enter a valid email address.')
        setDisabledAdd(false)
        return
      }
      try {
        const response = await addMemberByEmail({
          variables: {
            communityId: communityId ?? '',
            email: addEmailText ?? '',
            allowDuplicate,
          },
        })
        if (response?.data?.addMemberByEmail?.ok) {
          if (response?.data?.addMemberByEmail?.membership) {
            setModalText('The member was added successfully')
            setShouldRefetchMembers(true)
          }
          if (response?.data?.addMemberByEmail?.invitation) {
            setModalText('The new member has been invited and will show as a pending member until they register')
          }
          setAddEmailText('')
        } else if (response?.data?.addMemberByEmail?.error) {
          switch (response.data.addMemberByEmail.error.code) {
            case 'alreadyAMember':
              setModalText('You entered an email address for someone who is already a member.')
              setAddEmailText('')
              break
            case 'alreadyInvited':
              setShowConfirmModal(true)
              break
            case 'notACustomer':
              setModalText(
                'That address is from an email domain that is not associated with a Veeva customer. ' +
                  'Please contact veevaconnect@veeva.com if we should add this domain.'
              )
              setAddEmailText('')
              break
            case 'ineligibleMember':
              setModalText(
                `You entered an email address with a domain that is not Veeva or ${companyName}. ` +
                  `Only Veeva or ${companyName} users can be added as members of the customer homepage.`
              )
              setAddEmailText('')
              break
            case 'badEmail':
              setModalText('The email address you entered is not a valid format.')
              break
            case 'noOrgwikiUser':
              setModalText("You entered a Veeva email address that doesn't match anyone in OrgWiki.")
              break
            case 'partnerDomain':
              setModalText(
                'You entered an email address for a partner company. Partners do not have access to Veeva Connect.'
              )
              break
            case 'publicDomain':
              setModalText("You entered a non-work email address. Please enter the person's work email.")
              break
            case 'blockedSubdomain':
              setModalText(
                'That address is from an email sub-domain that is not authorized. ' +
                  'Please contact veevaconnect@veeva.com if we should add this user or domain.'
              )
              break
            case 'competitorDomain':
              setModalText(
                'That address is from an email domain that is associated with a Veeva competitor. ' +
                  'Please contact veevaconnect@veeva.com if we should add this domain.'
              )
              break
            case 'hiddenUser':
              setModalText('This user cannot be added to the community.')
              break
            case 'alreadyInvitedByOther':
              setShowConfirmModal(true)
              break
            case 'notAVeevan':
              setModalText(
                `You entered an email address with a domain that is not Veeva. ` +
                  `Only Veeva users can be added as members of this community.`
              )
              break
            default: // fallback just in case
              setModalText('Something went wrong. Please try again.')
              break
          }
        }
      } catch {
        setModalText('Something went wrong. Please try again.')
      }
    }
    setDisabledAdd(false)
  }

  const handlePendingClicked = (e: SyntheticEvent) => {
    setIsPendingCollapsed(!isPendingCollapsed)
    elementClicked(e, 'click-community-members-pending-expanded', { communityId: communityId })
  }

  const handleHideModal = () => {
    setModalText(null)
    if (shouldRefetchMembers) {
      void refetchMembers().then()
      setShouldRefetchMembers(false)
    }
  }

  const handleSubmitNotificationPreference = async () => {
    setUpdateNotificationFrequencyError(false)
    setNotificationPreferenceSubmitDisabled(true)
    const resp = await setNotificationFrequency({
      variables: {
        communityId: communityId ?? '',
        leaderLike: selectedNotificationFrequency === 'Leader',
      },
    })
    if (resp.data?.setMemberNotificationFrequency?.error) {
      setUpdateNotificationFrequencyError(true)
    } else {
      setShowNotificationPreferenceModal(false)
    }
    setNotificationPreferenceSubmitDisabled(false)
  }

  if (communityLoading)
    return (
      <div className={`members-container`}>
        <h3 className={'comm-title'}>Community Members</h3>
        <div className={'loading'} data-testid={'loading'}>
          Loading...
        </div>
      </div>
    )

  if (communityError) return <Alert variant="danger">{communityError?.message}</Alert>
  if (!(hasMemberPermissions || isCompany || isVeevan)) return <Navigate to={'../'} />
  if (isVeeva && hash) return <Navigate to={'../members'} />

  const confirmationModal = (
    <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        You entered an email address for someone who has already been invited. Do you want to send another invitation?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setShowConfirmModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={e => {
            setShowConfirmModal(false)
            void handleAddMemberClicked(undefined, true, e).then()
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )

  const addButton = (
    <AddMembershipBox
      searchText={addEmailText}
      placeholder={'Enter email address or name'}
      onTextChange={handleAddMemberTextChanged}
      onSubmit={(userId, e) => handleAddMemberClicked(userId, false, e as SyntheticEvent)}
      veevansOnly={isCompany || isAdminComm}
      communityId={communityId || ''}
      leaders={false}
      showExcluded={true}
      excludedMessage={'Already a member'}
      disabled={disabledAdd}
      refetchMembers={refetchMembers}
    />
  )

  const notificationPreferenceModal = (
    <Modal
      className={'notification-preference-modal'}
      show={showNotificationPreferenceModal}
      onHide={() => setShowNotificationPreferenceModal(false)}
    >
      <Modal.Header closeButton>Email Notification Preference</Modal.Header>
      <Modal.Body>
        <form>
          <div className={'form-row'}>
            <input
              id={'Member'}
              type={'radio'}
              value={'Member'}
              checked={selectedNotificationFrequency === 'Member'}
              onChange={e => setSelectedNotificationFrequency(e.target.value)}
              data-testid={'member-radio-button'}
            />
            <label htmlFor={'Member'}>
              <h4>Member notifications</h4>
              <p>
                <i>These are the notifications you get for any community you’ve joined.</i>
              </p>
            </label>
          </div>
          <div className={'form-row'}>
            <input
              id={'Leader'}
              type={'radio'}
              value={'Leader'}
              checked={selectedNotificationFrequency === 'Leader'}
              onChange={e => setSelectedNotificationFrequency(e.target.value)}
              data-testid={'leader-radio-button'}
            />
            <label htmlFor={'Leader'}>
              <h4>{isCompany ? 'Account Partner notifications' : 'Leader notifications'}</h4>
              <p>
                <i>
                  You will also get immediate notifications for new posts and a daily summary of membership changes.
                </i>
              </p>
            </label>
          </div>
        </form>
        {updateNotificationFrequencyError && <FormError message={'Something went wrong. Please try again.'} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => {
            setShowNotificationPreferenceModal(false)
            setSelectedNotificationFrequency('Member')
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmitNotificationPreference}
          disabled={notificationPreferenceSubmitDisabled}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )

  const notificationPreferenceMessage = (mobile: boolean) => {
    return (
      <>
        {!(leaderLikeNotificationsLoading || userCommunitiesLoading) &&
          (showLeaderLikeNotificationsMessage ? (
            <div className={`notification-preference${mobile ? ' mobile' : ''}`}>
              <em>
                You are receiving{' '}
                <button onClick={() => setShowNotificationPreferenceModal(true)}>
                  {isLeaderLikeNotificationCommunity ? 'leader notifications' : 'member notifications'}
                </button>{' '}
                for this community.
              </em>
            </div>
          ) : (
            // dummy component to test that the message doesn't show when it's not supposed to
            <div data-testid={'not-showing-notifications-message'} />
          ))}
      </>
    )
  }

  const standardLayout = (
    <div className={`members-container`}>
      <h3 className={'comm-title'}>Community Members</h3>
      {notificationPreferenceMessage(false)}
      <div className={`table-list`} role="list">
        <div className={`top-actions top-actions-flex-row ${isVeeva && 'right-align'}`}>
          {showAddButton && addButton}
        </div>
        <div className="top-actions">
          <div className="filtering">
            <div className="searchbar">
              <img className="search-icon" src={searchIcon} alt={'Search'} />
              <Form.Control
                type="text"
                placeholder="Search Members"
                value={searchText}
                onChange={handleSearchTextChanged}
                role="searchBar"
                bsPrefix={' '}
              />
              {showSearchClear && (
                <img className={'search-clear'} src={searchClear} alt={'Clear'} title={'Clear'} onClick={clearSearch} />
              )}
            </div>
            {!isVeeva && (
              <Dropdown id="dropdown-filter">
                <Dropdown.Toggle id="dropdown-basic" role={'dropdown'}>
                  <img src={filterIcon} alt={'Filter'} />
                  <span>{dropdownText}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item key="all" onClick={() => handleFilterChange(FilterState.all)}>
                    All Members
                  </Dropdown.Item>
                  <Dropdown.Item key="veeva" onClick={() => handleFilterChange(FilterState.veeva)}>
                    Veeva Members
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="company"
                    onClick={() => handleFilterChange(FilterState.company)}
                    role={'company-filter'}
                  >
                    {getFilterDisplayText(FilterState.company)}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        {showPendingMembers && (
          <div className={'pending-members-container'}>
            <a
              href={'#'}
              className={'pending-header'}
              onClick={handlePendingClicked}
              tabIndex={0}
              data-testid={'pending-header'}
            >
              {isPendingCollapsed ? (
                <span className="pending-more">
                  <MoreArrow fill="#565656" width={'15'} height={'15'} />
                </span>
              ) : (
                <span className="pending-less">
                  <LessArrow fill="#565656" width={'15'} height={'15'} />
                </span>
              )}
              <h3 className={'members-h3'}>{pendingMemberText}</h3>
            </a>
            {!isPendingCollapsed && (
              <>
                {' '}
                <div className="table-title">
                  <span className="wide-column">Email</span>
                  <span className="semi-wide-column">Company</span>
                  {(isVeevan || isCompany) && <span className="semi-wide-column">Added By</span>}
                  <span className="semi-wide-column">When Added</span>
                </div>
                {pendingMembers?.length === 0 && <div>No results found</div>}
                <div className={'members-list'}>
                  {pendingMembers?.map(member => (
                    <CommunityPendingMemberRow
                      key={`${member.email}-${member.inviter.userId}`}
                      member={member}
                      isCompany={isCompany}
                    />
                  ))}
                </div>
                {hasMoreInvitations && (
                  <div className={'button-zone load-more'}>
                    <button
                      className={'btn btn-primary btn-sm'}
                      onClick={loadMoreInvites}
                    >{`Show More (showing ${pendingMembers?.length} of ${pendingMemberCount})`}</button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <h3 className={'registered-header members-h3'}>Members</h3>
        <div className="table-title">
          {isCompany ? (
            <>
              {!isNotXLarge && (
                <>
                  {isVeeva ? (
                    <>
                      <span className={`semi-wide-column`}>
                        <p
                          onClick={() => {
                            clickSort(MembersSortType.Name)
                          }}
                        >
                          Name & Job Title
                        </p>
                        <img
                          src={moreIcon}
                          alt={'Show more'}
                          className={`${sortType == 'NAME' ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={`semi-wide-column`}>
                        <p
                          onClick={() => {
                            clickSort(MembersSortType.Name)
                          }}
                        >
                          Name & Job Title
                        </p>
                        <img
                          src={moreIcon}
                          alt={'Show more'}
                          className={`${sortType == 'NAME' ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                        />
                      </span>
                      <span className="company-type-column">
                        <p
                          onClick={() => {
                            clickSort(MembersSortType.Leader)
                          }}
                        >
                          Type
                        </p>
                        <img
                          src={moreIcon}
                          alt={'Show more'}
                          className={`${sortType === MembersSortType.Leader ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                        />
                      </span>
                      <span className="wide-column">
                        <p
                          onClick={() => {
                            clickSort(MembersSortType.Role)
                          }}
                        >
                          Role
                        </p>
                        <img
                          src={moreIcon}
                          alt={'Show more'}
                          className={`${sortType === MembersSortType.Role ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                        />
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <span className={`wide-column`}>
                <p
                  onClick={() => {
                    clickSort(MembersSortType.Name)
                  }}
                >
                  Name & Job Title
                </p>
                <img
                  src={moreIcon}
                  alt={'Show more'}
                  className={`${sortType == 'NAME' ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                />
              </span>
              <span className="semi-wide-column">
                <p
                  onClick={() => {
                    clickSort(MembersSortType.Company)
                  }}
                >
                  Company
                </p>
                <img
                  src={moreIcon}
                  alt={'Show more'}
                  className={`${sortType == 'COMPANY' ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                />
              </span>
              <span className="semi-wide-column">
                <p
                  onClick={() => {
                    clickSort(MembersSortType.Leader)
                  }}
                >
                  Type
                </p>
                <img
                  src={moreIcon}
                  alt={'Show more'}
                  className={`${sortType === MembersSortType.Leader ? 'sorting' : ''}${asc ? ' asc' : ''}`}
                />
              </span>
            </>
          )}
        </div>
        {membersLoading && !membersData ? (
          <>Loading...</>
        ) : (
          <>
            {members?.length === 0 && <div>No results found</div>}
            <div className={'members-list'}>
              {members?.map(member => (
                <div key={member?.userId} className={'member-row-container'}>
                  {isCompany ? (
                    <CompanyMemberRow
                      member={member}
                      onChangeMemberType={handleChangeMemberType}
                      isVeeva={isVeeva}
                      leads={leads}
                      setParentModalText={setModalText}
                      role={roles.get(member.userId ?? '')}
                    />
                  ) : (
                    <CommunityMemberRow
                      member={member}
                      canRemoveLeader={canRemoveLeader}
                      setParentModalText={setModalText}
                    />
                  )}
                </div>
              ))}
            </div>
            {hasMore && (
              <div className={'button-zone load-more'}>
                <button
                  className={'btn btn-primary btn-sm'}
                  onClick={loadMore}
                >{`Show More (showing ${members?.length} of ${membersData?.communityMembers?.count})`}</button>
              </div>
            )}
          </>
        )}
        {confirmationModal}
        {notificationPreferenceModal}
        <Modal show={!!modalText} onHide={handleHideModal}>
          <Modal.Header closeButton>
            <Modal.Body>
              <p>{modalText}</p>
            </Modal.Body>
          </Modal.Header>
        </Modal>
      </div>
    </div>
  )

  const mobileLayout = (
    <>
      {notificationPreferenceMessage(true)}
      {showAddButton && addButton}
      <div className="condensed-searchbar">
        <img className="search-icon" src={searchIcon} alt={'Search'} />
        <Form.Control
          type="text"
          placeholder="Search Members"
          value={searchText}
          onChange={handleSearchTextChanged}
          role="searchBar"
          bsPrefix={' '}
        />
        {showSearchClear && (
          <img className={'search-clear'} src={searchClear} alt={'Clear'} title={'Clear'} onClick={clearSearch} />
        )}
      </div>
      {membersLoading && !membersData ? (
        <>Loading...</>
      ) : (
        <>
          {members?.length === 0 && (
            <div className={'no-members'}>
              <span>No results found</span>
            </div>
          )}
          <div className={'condensed-members-list'}>
            {members?.map(member => (
              <div key={member.userId}>
                {isCompany ? (
                  <CompanyMemberRow
                    member={member}
                    onChangeMemberType={handleChangeMemberType}
                    isVeeva={isVeeva}
                    leads={leads}
                    setParentModalText={setModalText}
                    role={roles.get(member.userId ?? '')}
                  />
                ) : (
                  <CommunityMemberRow
                    member={member}
                    canRemoveLeader={canRemoveLeader}
                    setParentModalText={setModalText}
                  />
                )}
              </div>
            ))}
            {hasMore && (
              <div className={'button-zone load-more'}>
                <button
                  className={'btn btn-primary btn-sm'}
                  onClick={loadMore}
                >{`Show More (showing ${members?.length} of ${membersData?.communityMembers?.count})`}</button>
              </div>
            )}
          </div>
        </>
      )}
      {confirmationModal}
      {notificationPreferenceModal}
      <Modal show={!!modalText} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Body>
            <p>{modalText}</p>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </>
  )

  if (isCondensed) return mobileLayout
  else return standardLayout
}

export default CommunityMembers
