import React, { useRef, useState } from 'react'
import '@css/common/TextListSelector.scss'
import PlainTextInput from '~/common/PlainTextInput'
import FormError from '~/common/FormError'
import deleteIcon from '@web/images/community/icon-xdelete.png'
import { trimURL } from '~/utils'

type Props = {
  placeholder: string
  list: string[]
  onUpdateList: (updatedList: string[]) => void
  maxItemLength?: number
  mediumSeverityItemLength?: number
  duplicateErrorMessage?: string
  validateEntry?: (
    item: string,
    onValidate: (item: string, error: boolean, errorMessage?: string) => void
  ) => Promise<boolean>
  canRemoveItem?: (item: string) => Promise<boolean>
  errorMessageOverride?: string | null
  clearErrorMessageOverride?: () => void
  addMessage?: string
  disabled?: boolean
  className?: string
  onKeyDown?: () => void
  setPendingInput?: (s: string) => void
  isDomain?: boolean
}

const TextListSelector = ({
  placeholder,
  list,
  onUpdateList,
  maxItemLength,
  mediumSeverityItemLength,
  duplicateErrorMessage,
  validateEntry,
  canRemoveItem,
  errorMessageOverride,
  clearErrorMessageOverride,
  addMessage,
  disabled,
  className,
  onKeyDown,
  setPendingInput,
  isDomain,
}: Props) => {
  const [newItem, setNewItem] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [inputFocused, setInputFocused] = useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const clearErrorMessages = () => {
    setErrorMessage(null)
    clearErrorMessageOverride?.()
  }

  const handleValidation = (item: string, error: boolean, errorMessage?: string) => {
    if (error) {
      setErrorMessage(errorMessage ?? 'An unknown error occurred')
    } else {
      onUpdateList([...list, item])
      setText('')
    }
  }

  // Workaround for keycodes not implemented for Android keyboards on certain Chromium browsers
  // Detailed explanation: https://bugs.chromium.org/p/chromium/issues/detail?id=118639#c239
  const handleMobileInput = async (e: React.FormEvent) => {
    const inputEvent = e.nativeEvent as InputEvent
    if (inputEvent.data === '\n') {
      e.preventDefault()
      await attemptValidation()
    } else {
      clearErrorMessages()
    }
  }

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    onKeyDown?.()
    if (e.key == 'Enter') {
      e.preventDefault()
      await attemptValidation()
    } else {
      clearErrorMessages()
    }
  }

  const attemptValidation = async () => {
    const trimmed = isDomain ? trimURL(newItem.trim()) : newItem.trim()

    if ((!!maxItemLength && newItem.length > maxItemLength) || trimmed.length === 0) {
      return
    }

    if (list.includes(trimmed)) {
      setErrorMessage(duplicateErrorMessage ?? 'That item is already in the list')
      inputRef?.current?.blur()
      setText(trimmed)
      return
    }

    if (validateEntry) {
      const result = await validateEntry(trimmed, handleValidation)
      if (!result) {
        inputRef?.current?.blur()
      }
    } else {
      onUpdateList([...list, trimmed])
      setText('')
    }
  }

  const removeItem = async (item: string) => {
    const canRemove = (await canRemoveItem?.(item)) ?? true
    if (canRemove) {
      onUpdateList(list.filter(i => i !== item))
      clearErrorMessages()
    }
  }

  const handleInputFocus = () => {
    setInputFocused(true)
  }

  const handleInputBlur = () => {
    setInputFocused(false)
  }

  const itemLength = newItem.length
  const itemLengthSeverity =
    !maxItemLength || !mediumSeverityItemLength
      ? null
      : itemLength > maxItemLength
        ? ' red'
        : itemLength >= mediumSeverityItemLength
          ? ' yellow'
          : ' gone'

  const showAddMessage = inputFocused && !!addMessage && itemLength > 0 && itemLengthSeverity !== ' red'

  const setText = (s: string) => {
    setNewItem(s)
    setPendingInput?.(s)
  }

  return (
    <>
      <div className="entry-box authoring-area">
        <PlainTextInput
          placeholder={placeholder}
          onChange={setText}
          value={newItem}
          onKeyDown={handleKeyDown}
          onBeforeInput={handleMobileInput}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={disabled}
          className={className}
          inputRef={inputRef}
        />
        {itemLengthSeverity && (
          <span className={'character-counter' + itemLengthSeverity}>
            {itemLength < 10 ? `0${itemLength}` : itemLength} / {maxItemLength}
          </span>
        )}
      </div>
      {showAddMessage && (
        <div className="add-message" onMouseDown={attemptValidation}>
          <span data-testid={'add-prompt'}>
            {addMessage} "{newItem}"
          </span>
        </div>
      )}
      {(!!errorMessageOverride || !!errorMessage) && (
        <FormError message={errorMessageOverride ?? errorMessage ?? 'An unknown error occurred'} />
      )}
      <div className="text-list-items">
        {list.map(item => {
          return (
            <div className="text-list-item" key={item}>
              {item}{' '}
              {!disabled && (
                <span className="remove-btn" onClick={() => removeItem(item)}>
                  <img src={deleteIcon} />
                </span>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TextListSelector
