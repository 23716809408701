import React, { ReactNode, useContext, useState } from 'react'
import { Outlet } from 'react-router'
import { Maybe } from '~/api/generated/graphql'

export type SelectedUser = {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  nickName?: Maybe<string>
  userId?: Maybe<string>
  title?: Maybe<string>
  company?: Maybe<{ name?: Maybe<string> }>
  hidden?: Maybe<boolean>
}

export type AddPersonBoxContextType = {
  selectedUser?: SelectedUser | undefined
  setSelectedUser?: (s: SelectedUser | undefined) => void
  hasPendingUser?: boolean
  setHasPendingUser?: (b: boolean) => void
}

export const AddPersonBoxContext = React.createContext<AddPersonBoxContextType>({})

export const AddPersonBoxProvider = ({ children }: { children: ReactNode }) => {
  const [selectedUser, setSelectedUser] = useState<{
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    nickName?: Maybe<string>
    userId?: Maybe<string>
    title?: Maybe<string>
    company?: Maybe<{ name?: Maybe<string> }>
    hidden?: Maybe<boolean>
  }>()
  const [hasPendingUser, setHasPendingUser] = useState(false)

  return (
    <AddPersonBoxContext.Provider
      value={{
        selectedUser,
        setSelectedUser,
        hasPendingUser,
        setHasPendingUser,
      }}
    >
      {children}
    </AddPersonBoxContext.Provider>
  )
}

export const useAddPersonBox = () => useContext(AddPersonBoxContext)

export const RequireAddPersonBox = () => {
  return (
    <AddPersonBoxProvider>
      <Outlet />
    </AddPersonBoxProvider>
  )
}
