import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  AddContentDocument,
  AddMemberDocument,
  AddPostDocument,
  GetPermissionsDocument,
  GetPostAddCommunityDocument,
  GetPostBoxBannerDocument,
  HtmlWithMentions,
  Maybe,
  MediaAlignmentType,
  MediaType,
  MeetupInput,
  Post,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { useCommunity } from '~/contexts/CommunityContext'
import { AddMediaTarget, UploadZone } from '~/pages/posts/AddMediaTarget'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import ReactQuill from 'react-quill'
import {
  calculateFieldLengthSeverity,
  updateCacheContent,
  updateCachePosts,
  usePermissions,
} from '~/pages/posts/PostUtils'
import ToastComponent from '~/common/ToastComponent'
import { useMediaUpload } from '~/contexts/MediaUploadContext'
import { asHtmlWithMentions, asString, checkConfidentialWarning, cleanupHTML } from '~/utils'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { elementClicked } from '~/common/EventLogger'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import PostConfirmationModal from '~/pages/posts/PostConfirmationModal'
import MultipleEditor, { EditorRowData } from '~/common/quill/MultipleEditor'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import PlainTextInput from '~/common/PlainTextInput'
import { useLocation } from 'react-router-dom'
import AddPersonBox from '~/common/addPerson/AddPersonBox'
import { SelectedUser, useAddPersonBox } from '~/common/addPerson/AddPersonBoxContext'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'

type PostAddProps = {
  isContent: boolean
  resetScroll?: () => void
  setNewDraftId?: (postId: string) => void
}

const originalStoryRows = [{ alignment: MediaAlignmentType.FullText }]

export const PostAdd = ({ isContent, resetScroll, setNewDraftId }: PostAddProps) => {
  const [adding, setAdding] = useState<boolean>(false)
  const [showJoinSuccess, setShowJoinSuccess] = useState<boolean>(false)
  const handleCloseToast = () => setShowJoinSuccess(false)
  const { loading: communityLoading, communityId, companyId } = useCommunity()
  const [title, setTitle] = useState(asHtmlWithMentions(null))
  const [titleLength, setTitleLength] = useState<number>(0)
  const { hasMemberPermissions } = usePermissions()
  const [disableAdd, setDisableAdd] = useState<boolean>(false)
  const { isCondensedPortrait: isMobile, breakpoint } = useWindowSize()
  const isCondensed = breakpoint < SizeBreakpoint.md
  const [showSensitiveWarning, setShowSensitiveWarning] = useState<boolean>(false)

  const { authUserId, loading: authLoading, isVeevan, profileVisible, actingSysAdmin } = useAuth()
  const { loading: permLoading, data: permData } = useQuery(GetPermissionsDocument, {
    skip: !authUserId,
    fetchPolicy: 'cache-and-network',
  })
  const canPostIds = new Set(permData?.permissions?.canPostIds ?? [])
  const placeHolder = adding
    ? isContent
      ? 'Post Title (this title appears in the list of posts, required)'
      : 'Title: What is your post about?'
    : isContent
      ? 'Add Content'
      : 'Add a Post'

  const quillRef = useRef<ReactQuill>(null)
  const postAddRef = useClickOnEnter<HTMLDivElement>()
  const joinButtonRef = useClickOnEnter()
  const [videoURL, setVideoURL] = useState<string | null>(null)
  const [showConfidentialWarning, setShowConfidentialWarning] = useState<boolean>(false)
  const [rowsData, setRowsData] = useState<EditorRowData[]>([{ alignment: MediaAlignmentType.FullText }])
  const [confirmationMessage, setConfirmationMessage] = useState<string>()
  const [meetup, setMeetup] = useState<MeetupInput | undefined>()

  const client = useApolloClient()
  const [addPost] = useMutation(AddPostDocument, {
    update(cache, { data }) {
      updateCachePosts(cache, client, authUserId, data?.addPost?.post as Post)
    },
  })

  const [addContent] = useMutation(AddContentDocument, {
    update(cache, { data }) {
      updateCacheContent(cache, client, communityId, authUserId, data?.addContent?.post as Post)
    },
  })

  const { data: bannerData, loading: bannerLoading } = useQuery(GetPostBoxBannerDocument)

  const contentInput = useRef<HTMLTextAreaElement>(null)

  const {
    removeMedia,
    toastMessage: toast,
    setShowToast,
    showToast,
    uploading,
    mediaUrl,
    uploadId,
    setFileTitle,
    mediaType,
    isToastError,
  } = useMediaUpload()

  const [contentTitle, setContentTitle] = useState<string>('')
  const [contentTitleLength, setContentTitleLength] = useState<number>(0)

  const { data: basicCommunityData, loading: basicCommunityLoading } = useQuery(GetPostAddCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })

  const [addMember] = useMutation(AddMemberDocument)

  useEffect(() => {
    if (mediaUrl && isContent) {
      setFileTitle(contentInput?.current?.value || '')
    }
  }, [mediaUrl, setFileTitle, isContent])

  useEffect(() => {
    if (titleLength > 0) {
      setAdding(true)
    }
  }, [titleLength])

  const { setDraftingPost } = useDraftingCommentPost()

  const resetStates = useCallback(() => {
    setTitle(null)
    setTitleLength(0)
    removeMedia()
    setFileTitle('')
    setContentTitleLength(0)
    setContentTitle('')
    setAdding(false)
    setDisableAdd(false)
    setVideoURL(null)
    quillRef?.current?.blur()
    setRowsData([{ alignment: MediaAlignmentType.FullText }])
    resetScroll?.()
    setSelectedUser(undefined)
    setDraftingPost?.(communityId ?? '', false, true)
  }, [removeMedia, setFileTitle, resetScroll, setDraftingPost, communityId])
  const MAX_TITLE_LENGTH = 100
  const title_severity = adding ? calculateFieldLengthSeverity(titleLength, MAX_TITLE_LENGTH) : ''
  const content_title_severity = adding ? calculateFieldLengthSeverity(contentTitleLength, MAX_TITLE_LENGTH) : ''
  const postDisabled =
    titleLength === 0 ||
    titleLength > MAX_TITLE_LENGTH ||
    contentTitleLength > MAX_TITLE_LENGTH ||
    (isContent && contentTitleLength === 0) ||
    uploading ||
    disableAdd
  const doPost = async (args: {
    skipWarning?: boolean
    warned?: boolean
    e?: SyntheticEvent
    isDraft?: boolean
    meetup?: MeetupInput
  }) => {
    if (postDisabled) return
    const { skipWarning, warned, e, isDraft } = args
    const logName = isDraft ? 'click-post-draft-save' : 'click-post-add'
    elementClicked(e, logName, { isContent, community_id: communityId })
    setDisableAdd(true)
    if (
      !skipWarning &&
      !isVeevan &&
      !isDraft &&
      (rowsData?.some(r => checkConfidentialWarning(r.story?.htmlWithMentions) || r.mediaType) || mediaUrl || uploadId)
    ) {
      setShowConfidentialWarning(true)
      setDisableAdd(false)
      return
    }
    // if the user input a video url, set video_url as that video url
    // otherwise if the user uploaded a video, set video_url as the url of that upload
    const video_url = videoURL || (mediaType === MediaType.Video && !uploadId ? mediaUrl : undefined)

    if (isContent) {
      const response = await addContent({
        variables: {
          title: cleanupHTML(asString(title)) ?? '',
          story: rowsData
            ?.map(row => ({
              story: row.story?.htmlWithMentions,
              uploadId: row.uploadId,
              videoUrl: row.videoUrl,
              alignment: row.alignment,
              mediaType: row.mediaType,
              filename: row.filename,
            }))
            .filter(row => row.story || row.filename || row.uploadId || row.videoUrl),
          community_id: communityId ?? '',
          upload_id: uploadId,
          content_title: contentTitle,
          draft: isDraft,
          warned: warned,
          video_url,
          author_id: selectedUser ? selectedUser.userId : undefined,
          meetup: meetup,
        },
      })
      if (response.data?.addContent?.error?.code === 'sensitiveContent') {
        setShowSensitiveWarning(true)
        setConfirmationMessage(response.data?.addContent?.error?.message ?? undefined)
        setDisableAdd(false)
      } else if (response.data?.addContent?.ok && isDraft) {
        setNewDraftId?.(response.data.addContent?.post?.postId ?? '')
        resetStates()
      } else {
        resetStates()
      }
    } else {
      const response = await addPost({
        variables: {
          title: cleanupHTML(asString(title)) ?? '',
          story: rowsData
            ?.map(row => ({
              story: row.story?.htmlWithMentions,
              uploadId: row.uploadId,
              videoUrl: row.videoUrl,
              alignment: row.alignment,
              mediaType: row.mediaType,
              filename: row.filename,
            }))
            .filter(row => row.story || row.filename || row.uploadId || row.videoUrl),
          community_id: communityId ?? '',
          upload_id: uploadId,
          draft: isDraft,
          warned: warned,
          meetup: meetup,
          video_url,
        },
      })

      if (response.data?.addPost?.error?.code === 'sensitiveContent') {
        setShowSensitiveWarning(true)
        setConfirmationMessage(response.data?.addPost?.error?.message ?? undefined)
        setDisableAdd(false)
      } else if (response.data?.addPost?.ok && isDraft) {
        setNewDraftId?.(response.data.addPost?.post?.postId ?? '')
        resetStates()
      } else {
        resetStates()
      }
    }
  }

  const handleJoinCommunity = async (e: SyntheticEvent) => {
    const response = await addMember({
      variables: {
        userId: authUserId ?? '',
        communityId: communityId ?? '',
      },
    })
    if (response.data?.addMember?.ok) setShowJoinSuccess(true)
    postAddRef?.current?.blur()
    elementClicked(e, 'click-community-join-link-in-post', { communityId: communityId })
  }

  const clickPostAdd = () => {
    if (!adding) {
      setAdding(true)
      setTitle(null)
      setTitleLength(0)
    }
  }

  const [showChangeAuthor, setShowChangeAuthor] = useState(false)
  const {
    selectedUser: pendingSelectedUser,
    setSelectedUser: setPendingSelectedUser,
    hasPendingUser,
    setHasPendingUser,
  } = useAddPersonBox()
  const [selectedUser, setSelectedUser] = useState<SelectedUser | undefined>()
  const [addEmailText, setAddEmailText] = useState<string>('')

  const saveAuthorSelection = () => {
    setSelectedUser(pendingSelectedUser)
    setShowChangeAuthor(false)
  }

  const cancelAuthorSelection = () => {
    setPendingSelectedUser?.(selectedUser)
    setShowChangeAuthor(false)
    setHasPendingUser?.(Boolean(selectedUser))
  }

  const location = useLocation()

  useEffect(() => {
    setShowToast?.(false)
  }, [location, setShowToast])

  const uploadZone = useMemo(() => {
    // since we keep state all state for the post in this component, all subcomponents re-render any time you
    // update anything. When you upload a large image, this constant re-rendering causes all kinds of problems.
    // We need to re-organize the way the state is kept to prevent this, but as a quick before-page fix,
    // we can just memoize the upload zone to only re-render when a prop changes
    return <UploadZone setDirectUrlUpload={setVideoURL} directUrlUpload={videoURL} />
  }, [setVideoURL, videoURL])

  const onChangeContentTitle = (s: string) => {
    setContentTitle(s)
    setContentTitleLength(s.length)
    // Since we are currently in the post add box we don't have an associated postId, so we use the communityId as a "placeholder" id to keep track of it
    setDraftingPost?.(`content_title:${communityId ?? ''}`, Boolean(s), false)
  }

  if (communityLoading || authLoading || permLoading || bannerLoading) return null

  if (isContent) {
    if (companyId && (!hasMemberPermissions || !isVeevan)) return null
    if (!companyId && !isVeevan) return null
  }

  if (!profileVisible) return null

  if (!(isVeevan && companyId) && !canPostIds.has(communityId ?? '') && !basicCommunityLoading && !permLoading)
    return (
      <div className={'authoring-area post adding'} data-testid={'post-add-wrapper'}>
        <ProfilePhoto userId={authUserId} />
        <div className={'authoring-form'}>
          <div className={'join-community-container'}>
            <div className={'join-community-box'}>
              <span className={'join-community link'} onClick={handleJoinCommunity} tabIndex={0} ref={joinButtonRef}>
                {'Join this community'}
              </span>{' '}
              to add a post.
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <>
      {bannerData?.postBoxBanner ? (
        <i className={`post-banner${isMobile ? ' mobile' : ''}`}>{bannerData?.postBoxBanner}</i>
      ) : (
        <div hidden={true} data-testid={'no-banner'} />
      )}
      <div
        className={`authoring-area post adding${adding ? ' expanded' : ''} ${isMobile ? 'mobile' : ''}${
          bannerData?.postBoxBanner ? ' banner-spacing' : ''
        }`}
        data-testid={'post-add-wrapper'}
      >
        <div className={'author-wrapper'}>
          {!isMobile && <ProfilePhoto userId={selectedUser ? selectedUser.userId : authUserId} />}
          {isContent && adding && actingSysAdmin && (
            <button
              onClick={() => setShowChangeAuthor(true)}
              className={`change-author-button ${showChangeAuthor ? 'clicked' : ''}`}
            >
              <i>Change Author</i>
            </button>
          )}
        </div>
        <div className={'authoring-form'}>
          <ToastComponent
            bg={isToastError ? 'danger' : 'success'}
            show={showToast ?? false}
            onClose={() => setShowToast?.(false)}
          >
            {toast ?? ''}
          </ToastComponent>
          {isContent ? (
            <div
              className={`content-title-wrapper${adding ? ' adding' : ''}`}
              onClick={() => clickPostAdd()}
              onFocus={() => clickPostAdd()}
              ref={postAddRef}
            >
              <PlainTextInput
                className="content-title form-control"
                placeholder={adding ? 'Content Title (this title appears on the content tab, required)' : 'Add Content'}
                onChange={onChangeContentTitle}
                inputRef={contentInput}
                value={contentTitle}
              />
              <span
                className={'content-character-counter' + content_title_severity}
                id={'content-title-character-counter'}
              >
                {contentTitleLength < 10 ? `0${contentTitleLength}` : contentTitleLength} / {MAX_TITLE_LENGTH}
              </span>
            </div>
          ) : (
            <>
              <div
                className={'post-title-wrapper'}
                onClick={() => clickPostAdd()}
                ref={postAddRef}
                data-testid={'post-title-wrapper'}
              >
                <QuillEditor<Maybe<HtmlWithMentions>>
                  className="story title"
                  toolbar={QuillToolbar.None}
                  placeholder={placeHolder}
                  initialHtml={title}
                  setHtml={setTitle}
                  setTextLength={setTitleLength}
                  allowMentions={true}
                  communityId={communityId || undefined}
                  quillRef={quillRef}
                  preventLineBreak={true}
                  onFocus={() => setAdding(true)}
                  allowLinks={false}
                  isTitle={true}
                />
              </div>
              <span
                className={`character-counter ${isCondensed ? 'condensed' : ''}` + title_severity}
                id={'post-title-character-counter'}
              >
                {titleLength < 10 ? `0${titleLength}` : titleLength} / {MAX_TITLE_LENGTH}
              </span>
            </>
          )}
          {adding && !videoURL && uploadZone}
          {adding && videoURL && (
            <div className={'upload-zone'}>
              <AddMediaTarget
                directURLUpload={true}
                uploadURL={videoURL}
                uploadMediaType={MediaType.Video}
                clearUpload={() => setVideoURL(null)}
              />
            </div>
          )}
          {adding && isContent && (
            <div className={'post-title-wrapper'}>
              <div className={'post-title-container'}>
                <QuillEditor<Maybe<HtmlWithMentions>>
                  className="story title"
                  toolbar={QuillToolbar.None}
                  placeholder={placeHolder}
                  initialHtml={title}
                  setHtml={setTitle}
                  setTextLength={setTitleLength}
                  allowMentions={true}
                  communityId={communityId || undefined}
                  quillRef={quillRef}
                  preventLineBreak={true}
                  allowLinks={false}
                  isTitle={true}
                />
              </div>
              <span
                className={`post-title-character-counter ${isCondensed ? 'condensed' : ''}` + title_severity}
                id={'post-title-character-counter'}
              >
                {titleLength < 10 ? `0${titleLength}` : titleLength} / {MAX_TITLE_LENGTH}
              </span>
            </div>
          )}
          {adding && (
            <div className={'message-area'}>
              <MultipleEditor
                placeholder={
                  isContent ? 'Add Post Message (optional)' : 'Message: Share your question, idea, or comment'
                }
                rowsData={rowsData}
                originalRowsData={originalStoryRows}
                setRowsData={setRowsData}
                communityId={communityId ?? ''}
                onSubmit={doPost}
                setMeetup={setMeetup}
                allowVideoTimestamps={mediaType === MediaType.Video || !!videoURL}
              />
            </div>
          )}
          {adding && (
            <div className={'button-container'}>
              <div>
                <Button
                  onClick={e => doPost({ e: e, isDraft: true, meetup: meetup })}
                  variant="outline-secondary"
                  className={'save-draft'}
                  role="button"
                  disabled={postDisabled}
                >
                  <span>Save Draft</span>
                </Button>
              </div>
              <div className={'button-zone'}>
                <Button variant="light" onClick={resetStates} size="sm">
                  Cancel
                </Button>
                <Button
                  disabled={postDisabled}
                  size="sm"
                  onClick={e => {
                    void doPost({ e, meetup: meetup })
                  }}
                >
                  Post
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastComponent show={showJoinSuccess} onClose={handleCloseToast}>
        {`You've joined ${basicCommunityData?.community?.name}.`}
      </ToastComponent>
      <PostConfirmationModal
        show={showConfidentialWarning}
        hide={() => {
          setShowConfidentialWarning(false)
          setDisableAdd(false)
        }}
        onSubmit={e => {
          void doPost({ skipWarning: true, e, meetup: meetup })
        }}
        submitText={'Post'}
      />
      <PostConfirmationModal
        show={showSensitiveWarning}
        hide={() => {
          setShowSensitiveWarning(false)
          setDisableAdd(false)
        }}
        onSubmit={e => {
          void doPost({ skipWarning: true, warned: true, e })
        }}
        submitText={'Post'}
        message={confirmationMessage}
      />
      <Modal className={'change-author-modal'} show={showChangeAuthor} onHide={() => setShowChangeAuthor(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <h1>Change Author</h1>
          <h2>Author</h2>
          <p>
            <i>This user will appear as the author of the post</i>
          </p>
          <AddPersonBox
            searchText={addEmailText}
            placeholder={'Enter email address or name'}
            onTextChange={setAddEmailText}
            veevansOnly={true}
            communityId={''}
            leaders={false}
            showExcluded={true}
            disabled={false}
            hideAddButton={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={cancelAuthorSelection}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveAuthorSelection} disabled={!hasPendingUser}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
