import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { useAuth } from '~/auth/Auth'
import { usePermissions } from '~/pages/posts/PostUtils'
import CommunityAboutEdit from '~/pages/community/CommunityAboutEdit'
import ToastComponent from '~/common/ToastComponent'
import CommunityShareOptions from '~/pages/community/CommunityShareOptions'
import shareIcon from '@web/images/community/icon-share.svg'
import { useOutsideAlerter } from '~/common/hooks/useOutsideAlerter'
import '@css/pages/community/CommunityAboutDisplay.scss'
import { Html, Maybe } from '~/api/generated/graphql'
import { CommunityVisibilityStatement } from '~/pages/community/CommunityVisibilityStatement'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { elementClicked } from '~/common/EventLogger'
import { useCommunity } from '~/contexts/CommunityContext'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { asHtmlWithMentions, asString } from '~/utils'
import MentionableText from '~/common/MentionableText'

type Props = {
  about?: Maybe<Html>
  isCompany: boolean
  isPublic: boolean
  companyId?: Maybe<string>
  communityName: string
  expandAbout: boolean
  loading: boolean
}

const CommunityAboutDisplay = (props: Props) => {
  const { authUserId, isVeevan } = useAuth()
  const { hasLeaderPermissions } = usePermissions(authUserId)
  const { communityId } = useCommunity()
  const [isExpanded, setIsExpanded] = useState<boolean>(props.expandAbout)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showEditSuccess, setShowEditSuccess] = useState<boolean>(false)
  const handleCloseToast = useCallback(() => setShowEditSuccess(false), [])
  const optionsRef = useRef<HTMLDivElement>(null)
  const shareRef = useRef<HTMLButtonElement>(null)
  const { showElement, setShowElement } = useOutsideAlerter(false, optionsRef, shareRef)
  const canEdit = hasLeaderPermissions || (props.isCompany && isVeevan)
  const showShareButton = isVeevan && props.isPublic
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)

  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const handleExpansionClick = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const finishEdit = (shouldBeExpanded: boolean) => {
    setIsExpanded(shouldBeExpanded)
    setIsEditing(false)
    setShowEditSuccess(true)
  }

  useEffect(() => {
    setIsExpanded(props.expandAbout)
  }, [props.expandAbout])

  const editRef = useClickOnEnter<HTMLImageElement>()

  const aboutDisplayRef = useCallback(
    (node: HTMLDivElement | null) => {
      if ((node?.scrollHeight ?? 0) > (node?.offsetHeight ?? 0) || (node?.clientHeight && node?.clientHeight > 72)) {
        setShowMoreButton(true)
      } else {
        setShowMoreButton(false)
      }
    },
    [setShowMoreButton]
  )

  const handleShareClick = useCallback(
    (e: SyntheticEvent) => {
      elementClicked(e, 'click-community-share', { communityId: communityId })
      setShowElement(!showElement)
    },
    [communityId, showElement, setShowElement]
  )

  if (props.loading) {
    return <div>Loading ...</div>
  }

  return (
    <div className="flex-table no-highlight no-borders objectHeader" data-testid={'communityAbout'}>
      <ToastComponent show={showEditSuccess} onClose={handleCloseToast}>
        Community updated successfully
      </ToastComponent>
      <div className="flex-table-row">
        <div className="flex-col about-header-wrapper" tabIndex={0}>
          <div className="col align-bottom width-8 about-header">
            <h3 className="about-title">{props.communityName}</h3>
            {showShareButton && (
              <button className="share-button" ref={shareRef} onClick={handleShareClick}>
                <img src={shareIcon} alt={'Share'} />
                Share
              </button>
            )}
            {!isEditing && canEdit && (
              <img
                className="edit-button"
                src={pencilIcon}
                alt="Edit"
                onClick={() => setIsEditing(!isEditing)}
                tabIndex={0}
                ref={editRef}
                data-testid={'edit-icon'}
              />
            )}
          </div>
          <CommunityVisibilityStatement
            companyId={props.companyId}
            isCompany={props.isCompany}
            isPublic={props.isPublic}
          />
        </div>
      </div>
      {showElement && <CommunityShareOptions optionsRef={optionsRef} communityName={props.communityName} />}
      {isEditing ? (
        <CommunityAboutEdit
          about={props.about}
          onDone={finishEdit}
          onCancel={() => setIsEditing(false)}
          expandAbout={props.expandAbout}
        />
      ) : (
        <div className="flex-table-row object-about">
          <div className={`col about-container${isCondensed ? ' condensed' : ''}`}>
            <div className={'about-body-container'} tabIndex={0}>
              <div
                ref={aboutDisplayRef}
                className={`about-body quill-editor-elements ${isExpanded ? '' : 'collapsed'}${
                  isCondensed ? ' condensed' : ''
                }`}
              >
                {/*Community about descriptions don't include @ mentions but are still created from the quill editor. */}
                {/*We want to treat links the same as we do in the post stories. */}
                <MentionableText
                  value={asHtmlWithMentions(
                    asString(props.about?.html ?? 'This community currently has no about text')
                  )}
                />
              </div>
            </div>
            <div className={'about-more-button-container'}>
              {showMoreButton && (
                <button className={'about-more-button'} onClick={handleExpansionClick}>
                  <span>{isExpanded ? 'Show less' : 'Show more'}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CommunityAboutDisplay
