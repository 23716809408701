import React from 'react'
import '@css/pages/search/AdvancedSearchReleaseResults.scss'
import { SearchObject } from '~/common/TypeaheadSearch'
import { Link } from 'react-router-dom'

type AdvancedSearchReleaseResultsProps = {
  releases: SearchObject[]
}

const AdvancedSearchReleaseResults = ({ releases }: AdvancedSearchReleaseResultsProps) => {
  return (
    <div className={'release-results-section'}>
      <span className={'section-header'}>Release Pages</span>
      {releases.map(release => (
        <Link to={release.link} tabIndex={0} key={release.link} className={'search-result'}>
          <div className={`release-icon`} style={release.photo ? { backgroundImage: `url(${release.photo})` } : {}} />
          <div className={'info-column'}>
            <h5>{release.title}</h5>
            <div className={`subtitle`}>{release.subtitle}</div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default AdvancedSearchReleaseResults
