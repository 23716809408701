import { useCallback, useMemo, useState } from 'react'
import { CacheUserFragmentDoc, LoadPostUsersDocument, Maybe } from '~/api/generated/graphql'
import { useApolloClient, useQuery } from '@apollo/client'

const typeToIdFields: Map<string, Set<string>> = new Map()

typeToIdFields.set('PostConnection', new Set(['userId', 'createdById']))
typeToIdFields.set('Post', new Set(['userId', 'createdById']))
typeToIdFields.set('NotificationConnection', new Set(['userId', 'actorId']))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIdsForObj = (obj: Record<string, any>, idSet: Set<string>, userFields: Set<string> | undefined) => {
  for (const key of Object.keys(obj)) {
    if (Array.isArray(obj[key])) {
      for (const item of obj[key]) {
        getIdsForObj(item, idSet, userFields)
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      getIdsForObj(obj[key], idSet, userFields)
    } else if (userFields?.has(key)) {
      idSet.add(obj[key])
    }
  }
}

export const useLoadObjUsers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postsData: Maybe<Record<string, any>> | undefined,
  idField: string
) => {
  const { cache } = useApolloClient()
  const [processedPostIds, setProcessedPostIds] = useState<Set<string>>(new Set())

  const updateProcessedIds = useCallback(() => {
    if (postsData && 'edges' in postsData) {
      const postIds = postsData?.edges
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((edge: Record<string, any>) => edge?.node[idField])
        .filter(Boolean) as string[]
      setProcessedPostIds(new Set(postIds))
    }
  }, [idField, postsData, setProcessedPostIds])

  const userIdsToFetch = useMemo(() => {
    const userIds = new Set<string>()
    if (postsData) {
      getIdsForObj(postsData, userIds, typeToIdFields.get(postsData.__typename))
      for (const userId of userIds) {
        if (
          cache.readFragment({
            id: cache.identify({ __typename: 'User' as const, userId }),
            fragment: CacheUserFragmentDoc,
            fragmentName: 'CacheUser',
          })
        ) {
          userIds.delete(userId)
        }
      }
      if (userIds.size === 0) {
        // if all the users are in the cache already and there are none to fetch, we already
        // have processed the users for all posts
        updateProcessedIds()
      }
    }
    return Array.from(userIds)
  }, [postsData, cache, updateProcessedIds])

  const { loading } = useQuery(LoadPostUsersDocument, {
    variables: { ids: userIdsToFetch },
    skip: !userIdsToFetch?.length,
    onCompleted: updateProcessedIds,
    fetchPolicy: 'network-only',
  })

  return { loading, processedPostIds, loadingFirstPage: loading && !processedPostIds }
}
