import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import { CommunityListViewModel } from '~/types'
import '@css/pages/community/CommunityTable.scss'
import '@css/pages/community/CommunityList.scss'
import { Link } from 'react-router-dom'
import JoinCommunityButton, { MembershipAction } from '~/common/JoinCommunityButton'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import MobileCommunityListItem from '~/pages/community/MobileCommunityListItem'
import { useNavigate } from 'react-router'
import { useAuth } from '~/auth/Auth'

interface CommunityListRowProps {
  community: CommunityListViewModel
  refetch: () => void
  setCommunityName?: Dispatch<SetStateAction<string>>
  onAction: (action: MembershipAction, e?: SyntheticEvent) => void
}

const CommunityListRow = ({ community, refetch, onAction }: CommunityListRowProps) => {
  const { isCondensed } = useWindowSize()
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const link = community.companyId ? `/companies/${community.companyId}` : `/communities/${community.communityId}`

  const standardLayout = (
    <div className="table-row" onClick={() => navigate(link)} data-testid={'community-row'}>
      <div className={`wide-column image-with-details`}>
        <Link to={link} onClick={e => e.stopPropagation()}>
          <div
            className={`row-image profile-photo community-photo`}
            style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
            role={'img'}
            title={community.name}
          />
        </Link>
        <span className="description-container">
          <Link to={link} className="community-name" onClick={e => e.stopPropagation()}>
            {community.name}
          </Link>
          <div className="community-description">{community.description}</div>
        </span>
      </div>
      <>
        <div className="normal-column row-text" role="type">
          {community.type === 'Company' ? 'Customer Homepage' : community.type}
        </div>
        <div className="normal-column row-text" role="member-count">
          {community.memberCount}
        </div>
        <div className="normal-column row-text" role="last-post">
          {community.lastActivityTime ? new Date(community.lastActivityTime).toLocaleDateString() : 'None'}
        </div>
      </>
      <div className={'normal-column'} onClick={e => e.stopPropagation()}>
        <JoinCommunityButton
          communityName={community.name}
          communityId={community.communityId}
          onAction={onAction}
          fillFullWidth={true}
          disableLeave={community.companyId == companyId}
          showToast={false}
          className={'communities-tab-button'}
        />
      </div>
    </div>
  )
  if (isCondensed)
    return <MobileCommunityListItem community={community} refetch={refetch} companyId={companyId ?? ''} />
  else return standardLayout
}

export default CommunityListRow
