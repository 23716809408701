import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Navigate, useParams } from 'react-router'
import {
  PostType,
  GetAllMyCommunitiesDocument,
  GetRepostOverlayPostDocument,
  GetRepostsByPostDocument,
} from '~/api/generated/graphql'
import { PostModel, RepostCommunityModel } from '~/types'
import { asPost } from '~/utils'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/pages/posts/RepostOverlay.scss'
import { useAuth } from '~/auth/Auth'
import RepostOverlay, { RepostType } from '~/common/RepostOverlay'
import { useQuery } from '@apollo/client'

const PostRepostOverlay = ({ fromContentPage }: { fromContentPage: boolean }) => {
  const { isCondensed } = useWindowSize()
  const { isVeevan, loading: authLoading } = useAuth()
  const [repostedComms, setRepostedComms] = useState<string[]>([])
  const { postId } = useParams<{ postId: string }>()
  const { data: communitiesData } = useQuery(GetAllMyCommunitiesDocument)
  const { data: postData, loading: loadingPost } = useQuery(GetRepostOverlayPostDocument, {
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const {
    data: repostData,
    loading: loadingReposts,
    refetch: refetchReposts,
  } = useQuery(GetRepostsByPostDocument, {
    variables: { postId: postId ?? '' },
    skip: !postId,
  })

  const post = postData?.post

  const reposts = useMemo(() => {
    return repostData?.post?.reposts
      ?.map(e => {
        if (e) return asPost(e)
      })
      .filter(repost => repost && !repost?.hidden) as PostModel[]
  }, [repostData])

  const repostCommunities = useMemo(() => {
    const edges = communitiesData?.currentUser?.communities?.edges
    if (!edges) return
    const completedRepostCommunityIds = reposts?.filter(c => !c.hidden)?.map(r => r.communityId)
    const filteredCommunities = edges
      ?.map(e => {
        return {
          communityId: e?.node?.communityId ?? '',
          name: e?.node?.name ?? '',
          description: e?.node?.description ?? '',
          companyId: e?.node?.companyId ?? '',
          photo: e?.node?.photo ?? '',
          type: e?.node?.type,
        }
      })
      .filter(
        c =>
          c?.communityId !== post?.communityId &&
          // if someone reposts to a community, don't hide from the list while they're still on the page
          (!completedRepostCommunityIds?.includes(c.communityId) || repostedComms.includes(c.communityId))
      )
    return filteredCommunities as RepostCommunityModel[]
  }, [communitiesData, post, reposts, repostedComms])

  const refetch = () => {
    void refetchReposts({ postId: postId ?? '' })
  }

  if (loadingPost || loadingReposts || authLoading) return <div>Loading ...</div>
  if (!isVeevan) return <Navigate to={'../'} />
  if (!post) return null

  return (
    <>
      {post ? (
        <RepostOverlay
          fromPage={fromContentPage ? 'Content' : 'Post'}
          postTitle={post.title ?? undefined}
          repostType={RepostType.POST}
          postReposts={reposts}
          repostCommunities={repostCommunities ?? []}
          repostedComms={repostedComms}
          setRepostedComms={setRepostedComms}
          refetchReposts={refetch}
          originalPostId={postId}
          isContent={post.postType === PostType.Content}
        />
      ) : (
        <div className={'post-container'}>
          <div className={`nav-simple-zone${isCondensed ? ' condensed' : ''}`}>
            <Link to="../..">&larr; Back to {fromContentPage ? 'Content' : 'Post'}</Link>
          </div>
          <div className={'post-item'}>
            <div className={'error type01'}>Post could not be found</div>
          </div>
        </div>
      )}
    </>
  )
}

export default PostRepostOverlay
