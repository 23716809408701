import React from 'react'
import ReactDOM from 'react-dom'
import { Button, Toast } from 'react-bootstrap'
import deleteIcon from '@web/images/community/icon-xdelete.png'

type Props = {
  bg?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
  delay?: number
  onClose: () => void
  show: boolean
  header?: JSX.Element
  children: string
  className?: string
}

/*
 * Wrapper for react-bootstrap Toast that will automatically target the ToastContainer with id = 'toast-container'. Also
 * auto-sets several props regarding auto-hide
 */
export const ToastComponent = (props: Props) => {
  const toastContainer = document.getElementById('toast-container')

  if (!toastContainer) return null

  const { bg, delay = 6000, onClose, show, header, children, className } = props

  return ReactDOM.createPortal(
    <Toast bg={bg} delay={delay} onClose={onClose} show={show} autohide className={className}>
      <div className={'toast-inner'}>
        {header && <Toast.Header children={header} />}
        <Toast.Body>
          {children}
          <Button variant="light" size="sm" onClick={onClose}>
            <img src={deleteIcon} alt={'delete'} className={'delete-toast-icon'} />
          </Button>
        </Toast.Body>
      </div>
    </Toast>,
    toastContainer
  )
}
export default ToastComponent
