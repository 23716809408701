import { contentIconSrc, encodeRFC5987ValueChars } from '~/utils'
import { Exact, CreateMediaMutation, MediaType } from '~/api/generated/graphql'
import { FetchResult, MutationFunctionOptions } from '@apollo/client'

const xhrPromiseWrapper = (xhr: XMLHttpRequest, file: File) => {
  // https://stackoverflow.com/questions/30008114/how-do-i-promisify-native-xhr
  return new Promise((resolve, reject) => {
    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response)
      } else {
        reject(new Error(xhr.statusText))
      }
    })
    xhr.send(file)
  })
}

const readerPromiseWrapper = (reader: FileReader, file: File): Promise<string> => {
  return new Promise(resolve => {
    reader.readAsDataURL(file)
    reader.addEventListener('load', e => {
      resolve(e.target?.result?.toString() ?? '')
    })
  })
}

export const ImageUploadService = {
  progressListener: (ev: ProgressEvent, setProgress: (progress: number) => void) => {
    if (ev.lengthComputable) {
      const progressValue = (100 * ev.loaded) / ev.total

      // Stall at 99% until thumbnail finishes uploading.
      setProgress(progressValue > 99 ? 99 : progressValue)
    }
  },

  getThumbnailProgressInfo: (hashed_id: string) => {
    return fetch(`https://fast.wistia.com/embed/medias/${hashed_id}.json`)
      .then(response => response.json())
      .then(json => {
        // The thumbnail information is not always at the same index for each upload
        for (let i = 0; i < json.media.assets.length; i++) {
          if (json.media.assets[i].type == 'still_image') {
            return json.media.assets[i].progress
          }
        }
        return -1
      })
  },

  doThumbnailUpload: async (hashed_id: string, setProgress: (progress: number) => void) => {
    let thumbnail_progress = await ImageUploadService.getThumbnailProgressInfo(hashed_id)
    if (thumbnail_progress == -1) {
      setProgress(100)
    } else {
      while (thumbnail_progress < 1) {
        thumbnail_progress = await ImageUploadService.getThumbnailProgressInfo(hashed_id)
      }
    }
  },

  promisifiedVideoUpload: async (
    xhr: XMLHttpRequest,
    body: FormData,
    setProgress: (progress: number) => void,
    setUploading: (uploading: boolean) => void
  ) => {
    return new Promise<string>((resolve, reject) => {
      xhr.addEventListener('readystatechange', () => {
        if (!xhr || xhr.readyState !== 4) {
          return
        }

        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText)
          const { hashed_id } = data
          void ImageUploadService.doThumbnailUpload(hashed_id, setProgress).then(() => {
            resolve(`https://fast.wistia.com/embed/medias/${hashed_id}.jsonp`)
            setProgress(100)
          })
        } else if (xhr.status !== 200) {
          reject(new Error(xhr.statusText))
          setUploading(false)
        }
      })
      xhr.send(body)
    })
  },

  uploadVideo: async (
    video: File,
    uploadedVideo: { token: string; projectId: string },
    uploadURL: string,
    setUploading: (uploading: boolean) => void,
    setProgress: (progress: number) => void
  ) => {
    const body = new FormData()
    body.append('access_token', uploadedVideo.token)
    body.append('file', video)
    body.append('project_id', uploadedVideo.projectId)

    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener('progress', e => ImageUploadService.progressListener(e, setProgress))
    xhr.open('POST', uploadURL)
    const uploadedURL = await ImageUploadService.promisifiedVideoUpload(xhr, body, setProgress, setUploading)

    setUploading(false)
    setProgress(0)
    return {
      url: uploadedURL,
      type: MediaType.Video,
      videoUrl: uploadedURL,
    }
  },

  upload: async (
    file: File,
    setProgress: (progress: number) => void,
    setUploading: (uploading: boolean) => void,
    createMedia: (
      options?: MutationFunctionOptions<CreateMediaMutation, Exact<{ content_type: string; filename: string }>>
    ) => Promise<FetchResult<CreateMediaMutation>>
  ): Promise<{
    url?: string
    uploadId?: string
    type?: MediaType
    videoUrl?: string
    filename?: string
    errorMessage?: string
  }> => {
    setUploading(true)
    const createMediaResponse = await createMedia({
      variables: {
        content_type: file.type,
        filename: file.name,
      },
    })
    if (createMediaResponse.data?.createMedia?.error?.message) {
      return { errorMessage: createMediaResponse.data?.createMedia?.error.message }
    }
    const media = createMediaResponse.data?.createMedia
    if (media?.uploadedVideo) {
      return await ImageUploadService.uploadVideo(
        file,
        media.uploadedVideo,
        media.uploadUrl ?? '',
        setUploading,
        setProgress
      )
    } else {
      return await ImageUploadService.uploadFile(
        file,
        media?.uploadUrl ?? '',
        media?.uploadedFile?.id ?? '',
        media?.mediaType ?? MediaType.Other,
        media?.contentType ?? 'application/octet-stream',
        setProgress,
        setUploading
      )
    }
  },

  uploadFile: async (
    file: File,
    uploadUrl: string,
    uploadId: string,
    mediaType: MediaType,
    contentType: string,
    setProgress: (progress: number) => void,
    setUploading: (uploading: boolean) => void
  ) => {
    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener('progress', e => ImageUploadService.progressListener(e, setProgress))
    xhr.open('PUT', uploadUrl)
    xhr.setRequestHeader('Content-Disposition', "attachment; filename*=UTF-8''" + encodeRFC5987ValueChars(file.name))
    xhr.setRequestHeader('Content-Type', contentType)
    await xhrPromiseWrapper(xhr, file)
    setProgress(100)
    const dataURL = await readerPromiseWrapper(new FileReader(), file)
    const url = mediaType === MediaType.Image ? dataURL : contentIconSrc(mediaType)
    setUploading(false)
    setProgress(0)
    return {
      url,
      uploadId,
      filename: file.name,
      className: mediaType === MediaType.Image ? 'ql-full-image' : 'ql-file-icon',
      type: mediaType,
    }
  },
}
