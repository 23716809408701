import { QRCodeCanvas } from 'qrcode.react'
import React, { RefObject, useRef, useState } from 'react'
import '@css/pages/community/CommunityShareOptions.scss'
import ToastComponent from '~/common/ToastComponent'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { elementClicked } from '~/common/EventLogger'
import { useCommunity } from '~/contexts/CommunityContext'

type CommunityShareOptionsRef = {
  optionsRef?: RefObject<HTMLDivElement>
  communityName: string
}

const CommunityShareOptions = ({ optionsRef, communityName }: CommunityShareOptionsRef) => {
  const qrCodeRef = useRef<HTMLDivElement>(null)
  const { communityId } = useCommunity()
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const { windowSize } = useWindowSize()

  const size = Math.min(windowSize.width, windowSize.height) * 0.5

  const downloadQrCode = (e: React.MouseEvent) => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.children[0] as HTMLCanvasElement
      canvas.toBlob(blob => {
        if (blob) {
          const url = URL.createObjectURL(blob)
          const downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = `qr-join-${communityName}.png`
          downloadLink.id = url
          document.body.append(downloadLink)
          downloadLink.click()
          if (document.getElementById(url)) {
            downloadLink.remove()
          }
        }
      })
      elementClicked(e, 'click-community-download-qr', { communityId: communityId })
    }
  }

  return (
    <div ref={optionsRef}>
      <div className="share-options">
        <div ref={qrCodeRef}>
          <QRCodeCanvas value={`${location.href}?join=1`} size={size} />
        </div>

        <div className="buttons">
          <button onClick={downloadQrCode}>Download Join QR Code</button>

          <button
            onClick={e => {
              void navigator.clipboard.writeText(`${location.href}?join=1`).then(() => {
                setToastMessage('Community join link copied to clipboard')
                elementClicked(e, 'click-community-join-link-copy', { communityId: communityId })
              })
            }}
          >
            Copy Community Join Link
          </button>
        </div>

        <div className="callout">
          Share this invitation QR code or link with someone and they'll be added automatically!
        </div>
      </div>
      <ToastComponent show={!!toastMessage} onClose={() => setToastMessage(null)}>
        {toastMessage ?? ''}
      </ToastComponent>
    </div>
  )
}

export default CommunityShareOptions
